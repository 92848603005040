import React, { useEffect, useState } from 'react';

interface Props {
  milliSeconds: number;
  className?: string;
}
const Countdown: React.FC<Props> = ({ milliSeconds, className }) => {
  const [countdown, setCountdown] = useState(milliSeconds / 1000);
  const minutes = countdown > 0 ? Math.floor(countdown / 60) : 0;
  const seconds = countdown > 0 ? Math.floor(countdown - minutes * 60) : 0;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (countdown > 0) {
      timeout = setTimeout(() => setCountdown(countdown - 1), 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [countdown]);

  return (
    <div className={`${className} ${countdown <= 10 ? 'text-red-500' : ''}`}>
      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
    </div>
  );
};

export default Countdown;
