import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Alert from '../components/alert';
import { AddSupportMessage } from '../services/graphql/operations';
import { STORAGE_KEY_EMAIL } from '../constants';
import Button from '../components/button';
import { captureError } from '../helpers/error';
import { useMutation } from '@apollo/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isStorageAvailable } from '../helpers/localStorage';
import AppInitOptionsService from '../services/appInitOptionsService';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { appConfigState } from '../store/app';
import GetsbyLink from './getsbyLink';

type FormValues = {
  helpEmail: string;
  helpMessage: string;
};

const defaultValues: FormValues = {
  helpEmail: isStorageAvailable()
    ? localStorage.getItem(STORAGE_KEY_EMAIL) || ''
    : '',
  helpMessage: ''
};

const HelpForm: React.FC = () => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const appConfig = useRecoilValue(appConfigState);
  const gastronomy = useRecoilValue(gastronomyState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormValues>({
    defaultValues
  });

  const appInitOptions = AppInitOptionsService.getInstance().getOptions();
  const app_uuid = appConfig?.uuid;
  const user_uuid = gastronomy?.uuid;

  const [addSupportMessageMutation] =
    useMutation<AddSupportMessageMutation, AddSupportMessageMutationVariables>(
      AddSupportMessage
    );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const input: AddSupportMessageMutationVariables['input'] = {
        email: data.helpEmail,
        message: data.helpMessage,
        origin: {
          type: appInitOptions
            ? (appInitOptions.platform.toUpperCase() as RequestOriginType)
            : 'WEB',
          version:
            (appInitOptions && appInitOptions.version) ||
            process.env.REACT_APP_VERSION ||
            'unknown',
          userAgent: navigator.userAgent,
          debug: process.env.NODE_ENV !== 'production' ? true : false,
          url: window.location.href,
          host: window.location.hostname
        },
        user_uuid,
        app_uuid
      };

      await addSupportMessageMutation({
        variables: { input }
      });

      setIsSuccess(true);
      reset(defaultValues);
    } catch (error) {
      console.log('Error', error);
      captureError(error);
    }
  };

  return (
    <>
      {isSuccess ? (
        <Alert
          type="success"
          title={t('help.success')}
          message={t('help.successBody')}
        />
      ) : (
        <div className="mb-4">
          {t('help.contactUs')}
          <br />
          {t('help.referToFaqs')}{' '}
          <GetsbyLink to="/faq" className="underline">
            FAQs
          </GetsbyLink>
          .
        </div>
      )}
      <div className="w-full max-w-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3">
              <label className="block mb-2 font-bold text-secondary-500">
                {t('help.email')}*
              </label>
              <input
                className={`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none ${
                  errors.helpEmail
                    ? 'border-red-500 bg-red-100'
                    : 'border-gray-200'
                }`}
                type="email"
                {...register('helpEmail', { required: true })}
                placeholder={t('help.emailPlaceholder')}
              />
              {errors.helpEmail && (
                <span className="block text-sm font-bold text-red-500">
                  {t('formValidation.required')}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3">
              <label className="block mb-2 font-bold text-secondary-500">
                {t('help.message')}*
              </label>
              <textarea
                className={`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border  rounded appearance-none focus:outline-none ${
                  errors.helpMessage
                    ? 'border-red-500 bg-red-100'
                    : 'border-gray-200'
                }`}
                {...register('helpMessage', { required: true })}
                rows={3}
                placeholder={t('help.messagePlaceholder')}
              ></textarea>
              {errors.helpMessage && (
                <span className="block text-sm font-bold text-red-500">
                  {t('formValidation.required')}
                </span>
              )}
            </div>
          </div>
          <Button type="primary" classes="w-full" buttonType="submit">
            {t('help.send')}
          </Button>
        </form>
      </div>
    </>
  );
};

export default HelpForm;
