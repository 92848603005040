import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  values: Record<string, any>;
  showTable?: boolean;
  showHeader?: boolean;
  showDivider?: boolean;
  errors?: string[];
  fields: GuestRegistrationField[];
  onChange: (index: number, field: string, value: string) => void;
  onDelete: (index: number) => void;
}

const GuestRegistrationData: React.FC<Props> = ({
  index,
  values,
  showTable = true,
  showHeader = true,
  showDivider = false,
  errors,
  fields,
  onChange,
  onDelete
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <div>
      {showHeader && (
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold lg:text-2xl text-secondary-500">
            {index + 1}. Person
          </h2>
          <svg
            className={`w-6 h-6 block cursor-pointer`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => onDelete(index)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      )}
      {fields.map(
        (field, i) =>
          (field.name !== 'table' || (field.name === 'table' && showTable)) && (
            <div
              className="flex flex-wrap mb-6 -mx-3"
              key={index + '-' + field.name}
            >
              <div className="w-full px-3">
                <label className="block mb-2 font-bold text-secondary-500">
                  {t(field.translation_key)}
                  {field.required && '*'}
                </label>
                <input
                  autoFocus={i === 0}
                  ref={i === 0 ? inputRef : undefined}
                  type={field.html_type}
                  name={field.name}
                  v-model={field.name}
                  required={field.required}
                  onChange={(evt) =>
                    onChange(index, field.name, evt.target.value)
                  }
                  value={values[field.name]}
                  className={`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500 ${
                    errors && errors.includes(field.name)
                      ? 'border-red-500'
                      : ''
                  }`}
                />
              </div>
            </div>
          )
      )}
      {showDivider && <hr className="my-12" />}
    </div>
  );
};

export default GuestRegistrationData;
