import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useBreakpoint } from '../hooks/useBreakpoint';
import AppInitOptionsService from '../services/appInitOptionsService';
import { selectedCategoryState } from '../store/gastronomy';
import { productSearchState } from '../store/products';
import Picture from './Picture';
import SearchInput from './searchInput';

interface Props {
  categories?: CategoryFragment[];
}

const CategoryList: React.FC<Props> = ({ categories = [] }) => {
  const breakpoint = useBreakpoint();
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const appInitOptions = AppInitOptionsService.getInstance().getOptions();
  const isApp = AppInitOptionsService.getInstance().isApp();

  const [search, setSearch] = useRecoilState(productSearchState);
  const [showScrollToLeft, setShowScrollToLeft] = useState<boolean>(false);
  const [showScrollToRight, setShowScrollToRight] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const uListRef = useRef<HTMLUListElement>(null);
  const listElement = document.querySelector('#product-list');
  const navElement = document.querySelector('#product-group-nav');
  const windowWidth = useMemo(() => window.innerWidth, [window.innerWidth]);
  const listWidth = useMemo(() => {
    if (uListRef.current) {
      return uListRef.current.scrollWidth;
    }

    return 0;
  }, [uListRef.current]);

  useEffect(() => {
    setShowScrollToLeft(scrollPosition > 0);
    setShowScrollToRight(scrollPosition + windowWidth < listWidth);
  }, [windowWidth, listWidth, scrollPosition]);

  useEffect(() => {
    if (categories.length) {
      setSelectedCategory(categories[0].uuid);
    }
  }, []);

  const onClickHandler =
    (uuid: string) => (event?: MouseEvent<HTMLLIElement | HTMLDivElement>) => {
      setSelectedCategory(uuid);

      if (listElement && navElement) {
        const listElementBounds = listElement.getBoundingClientRect();
        const scrollTarget =
          listElementBounds.top + (window.scrollY - navElement.clientHeight);

        if (window.scrollY > scrollTarget) {
          window.scrollTo({
            top: scrollTarget
          });
        }
      }

      if (event && uListRef.current) {
        const scrollBy = event.pageX - breakpoint.width / 2;
        uListRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' });
      }

      if (uuid === 'search') {
        setSearch({ focused: true, search: '' });
      } else {
        setSearch({ focused: false, search: '' });
      }
    };

  const onClear = () => {
    setSearch({ focused: true, search: '' });
  };

  const onChange = (val: string) => {
    setSearch({ focused: true, search: val });
  };

  const onScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    if (event.currentTarget && uListRef.current) {
      setScrollPosition(event.currentTarget.scrollLeft);
    }
  };

  const scrollBar = (direction: 'left' | 'right') => {
    if (uListRef.current) {
      const scrollByValue = breakpoint.width * 0.5;
      const left = direction === 'left' ? scrollByValue * -1 : scrollByValue;
      uListRef.current.scrollBy({ behavior: 'smooth', left });
    }
  };

  return (
    <>
      <nav
        id="categories-nav"
        className={'sticky z-20 flex bg-white w-full shadow-lg'}
        style={{
          top: (appInitOptions?.insets?.top || 0) + (isApp ? 56 : 0) + 'px'
        }}
      >
        <div
          className={`rounded p-2 m-2 font-semibold items-center justify-center flex cursor-pointer ${
            'search' === selectedCategory
              ? 'bg-primary-500 text-secondary-500 active'
              : 'text-primary-500'
          }
            `}
          id="categories-nav-search"
          onClick={onClickHandler('search')}
        >
          <i className="material-icons">search</i>
        </div>

        <a
          onClick={() => scrollBar('left')}
          className={`absolute left-12 flex items-center h-full my-auto bg-white text-primary-500 cursor-pointer ${
            showScrollToLeft ? 'visible' : 'invisible'
          }`}
          id="categories-nav-left-arrow"
        >
          <i className="material-icons">keyboard_arrow_left</i>
        </a>

        <ul
          className="flex p-2 mr-4 overflow-auto"
          ref={uListRef}
          onScroll={onScroll}
          id="categories-nav-list"
        >
          {categories.map((category, index) => {
            return category.icon ? (
              <li
                className={`categories-nav-list-item rounded p-2 cursor-pointer text-center flex-col text-sm
              ${
                category.uuid === selectedCategory
                  ? 'bg-primary-500 text-secondary-500 active'
                  : 'text-primary-500'
              }
            `}
                key={index.toString()}
                onClick={onClickHandler(category.uuid)}
              >
                <div className="flex flex-col items-center w-24">
                  <Picture
                    imageSource={category.icon}
                    width={25}
                    height={25}
                    className={`mb-2`}
                    fit="max"
                    svgColor={{
                      themeColor:
                        category.uuid === selectedCategory
                          ? 'secondary'
                          : 'primary'
                    }}
                  />
                  {category.name}
                </div>
              </li>
            ) : (
              <li
                className={`categories-nav-list-item flex items-center justify-center rounded py-2 px-4 font-semibold cursor-pointer text-center
              ${
                category.uuid === selectedCategory
                  ? 'bg-primary-500 text-secondary-500 active'
                  : 'text-primary-500'
              }
            `}
                key={index.toString()}
                onClick={onClickHandler(category.uuid)}
              >
                {category.name}
              </li>
            );
          })}
        </ul>

        <a
          onClick={() => scrollBar('right')}
          className={`absolute right-0 flex items-center h-full my-auto bg-white text-primary-500 cursor-pointer ${
            showScrollToRight ? 'visible' : 'invisible'
          }`}
          id="categories-nav-right-arrow"
        >
          <i className="material-icons">keyboard_arrow_right</i>
        </a>
      </nav>
      {search.focused && (
        <div className="p-4">
          <SearchInput
            search={(search) => setSearch({ focused: true, search })}
            clear={onClear}
            searchValue={search.search}
            onChange={onChange}
            debounceDelay={300}
            focusOnRender={true}
          />
        </div>
      )}
    </>
  );
};

export default CategoryList;
