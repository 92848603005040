import React, { Suspense, useEffect } from 'react';
import Config from './config';
import Amplify from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import StatePersistance from './components/statePersistance';
import Spinner from './components/spinner';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import {
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
  HttpOptions
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { sendToSentry } from './helpers/error';
import { RetryLink } from '@apollo/client/link/retry';
import {
  PERSISTED_LOCKED_URL_PATH_ID,
  STORAGE_KEY_APP_DATA
} from './constants';
import { refreshToken } from './helpers/login';
import RedeemCouponPopup from './components/redeemCouponPopup';
import SnackbarContainer from './components/snackbarContainer';
import { isStorageAvailable } from './helpers/localStorage';
import UserMigration from './components/userMigration';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { HeadProvider, Meta } from 'react-head';
import Router from './Router';
import AppInitOptionsService from './services/appInitOptionsService';

const queryParams = queryString.parse(window.location.search);

const httpConfig: HttpOptions = {
  uri: Config.graphqlEndpoint,
  credentials: 'include'
};

const httpLink = new HttpLink(httpConfig);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map((graphQlError) => {
        const event: Sentry.Event = {
          message: 'ApolloError: ' + graphQlError.message,
          extra: graphQlError as any
        };
        if (sendToSentry(graphQlError)) {
          Sentry.captureEvent(event);
        }
        console.log(
          `[GraphQL error]: Message: ${graphQlError.message}, Location: ${graphQlError.locations}, Path: ${graphQlError.path}`
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      const event: Sentry.Event = {
        message: `[GraphQL network: ${operation.operationName}]: ${networkError.message}`,
        extra: {
          operation,
          response,
          graphQLErrors,
          networkError
        }
      };
      Sentry.captureEvent(event);
    }
  }
);

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (error && error.statusCode === 503) {
      location.href = '/maintenance';
    }

    return (
      !!error &&
      error.statusCode !== 503 &&
      count < 15 &&
      operation.operationName != 'addOrder'
    );
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  }
});

const link = ApolloLink.from([errorLink, retryLink, httpLink]);
const imCache = new InMemoryCache({});

const clientConfig = {
  link,
  cache: imCache.restore({})
};

const client = new ApolloClient(clientConfig);

const refreshHandlers =
  isStorageAvailable() &&
  typeof localStorage.getItem(STORAGE_KEY_APP_DATA) === 'string'
    ? {
        google: () => refreshToken('google'),
        facebook: () => refreshToken('facebook'),
        'appleid.apple.com': () => refreshToken('apple')
      }
    : undefined;

Amplify.configure({
  Auth: {
    identityPoolId: Config.identityPoolId,
    region: Config.region,
    userPoolId: Config.userPoolId,
    userPoolWebClientId: Config.userPoolWebClientId,
    mandatorySignIn: false
  },
  refreshHandlers,
  AWSS3: {
    bucket: Config.s3UploadBucket,
    region: Config.region
  }
});

// error from recoil: https://github.com/facebookexperimental/Recoil/issues/12

const isIframe = window.self !== window.top;
const path = window.location.pathname;
let gastronomyUrlPath: string | undefined = undefined;

if (path.startsWith('/cart') || path.startsWith('/payment/confirm')) {
  // do nothing
} else if (path.startsWith('/speisekarte/')) {
  const pathParts = path.split('/');
  if (isIframe && pathParts.length > 2) {
    gastronomyUrlPath = pathParts[2];
  }
}

// this is only for webviews, to lock to one gastronomy
if (isStorageAvailable() && !gastronomyUrlPath) {
  const persistedLockedUrlPathId = localStorage.getItem(
    PERSISTED_LOCKED_URL_PATH_ID
  );
  if (queryParams['hello-again-token'] && queryParams.lockTo) {
    gastronomyUrlPath = queryParams.lockTo as string;
    if (persistedLockedUrlPathId !== gastronomyUrlPath) {
      localStorage.setItem(PERSISTED_LOCKED_URL_PATH_ID, gastronomyUrlPath);
    }

    const localStorageProps = localStorage.getItem('gThirdPartyProperties');
    console.log('localStorageProps', localStorageProps);
    const thirdPartyProperties = (
      localStorageProps ? JSON.parse(localStorageProps) : {}
    ) as { helloAgain?: { userToken: string } };
    thirdPartyProperties.helloAgain = {
      userToken: queryParams['hello-again-token'] as string
    };
    localStorage.setItem(
      'gThirdPartyProperties',
      JSON.stringify(thirdPartyProperties)
    );
  } else if (persistedLockedUrlPathId) {
    gastronomyUrlPath = persistedLockedUrlPathId;
  }
}

const matomoClient = createInstance({
  urlBase: 'https://matomo.gets.by/',
  siteId: Config.matomoSiteId,
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setRequestMethod: 'POST'
  }
});

const App: React.FC = () => {
  const location = useLocation();

  const appInitOptions = AppInitOptionsService.getInstance();

  useEffect(() => {
    if (appInitOptions) {
      Sentry.setContext('app', appInitOptions);
    }
  }, []);

  useEffect(() => {
    matomoClient.trackPageView({});
  }, [location]);

  const headTags: any[] = [];

  return (
    <div className="App">
      <HeadProvider headTags={headTags}>
        <Meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <Suspense fallback={<Spinner center={true} />}>
          <ApolloProvider client={client}>
            <RecoilRoot>
              <MatomoProvider value={matomoClient}>
                <UserMigration>
                  <StatePersistance gastronomyUrlPath={gastronomyUrlPath} />
                  <RedeemCouponPopup />
                  <SnackbarContainer />
                  <Router gastronomyUrlPath={gastronomyUrlPath} />
                </UserMigration>
              </MatomoProvider>
            </RecoilRoot>
          </ApolloProvider>
        </Suspense>
      </HeadProvider>
    </div>
  );
};

export default App;
