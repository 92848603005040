import React from 'react';

interface Props {
  textOrientation: 'left' | 'right';
  title: string;
  text: React.ReactNode;
  image: React.ReactNode;
}
const Feature: React.FC<Props> = ({ textOrientation, title, text, image }) => {
  return (
    <>
      {textOrientation === 'left' ? (
        <div className="flex flex-col-reverse flex-wrap p-4 mb-16 sm:flex-row">
          <div className="w-full sm:w-1/2 text-secondary-500">
            <h3 className="mb-3 text-3xl font-bold leading-none">{title}</h3>
            {text}
          </div>
          <div className="flex items-center justify-center w-full p-4 sm:w-1/2">
            {image}
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap p-4 mb-16">
          <div className="flex items-center justify-center w-full p-4 sm:w-1/2">
            {image}
          </div>
          <div className="w-full sm:w-1/2 text-secondary-500">
            <h3 className="mb-3 text-3xl font-bold leading-none">{title}</h3>
            {text}
          </div>
        </div>
      )}
    </>
  );
};

export default Feature;
