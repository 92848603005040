import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import GetsbyRedirect from '../components/getsbyRedirect';
import { cartItemsState } from '../store/cart';

interface Props {
  receiptUuid: string;
}

const PaymentConfirm: React.FC<Props> = ({ receiptUuid }: Props) => {
  const setCartState = useSetRecoilState(cartItemsState);

  useEffect(() => {
    setCartState([]);
  }, []);

  return <GetsbyRedirect to="/order/:receiptUuid" params={{ receiptUuid }} />;
};

export default PaymentConfirm;
