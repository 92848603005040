import React, { useState, useEffect, useMemo } from 'react';
import GastronomyCard from '../components/gastronomyCard';
import { GetGastronomies, Search } from '../services/graphql/operations';
import Alert from '../components/alert';
import OpenOrders from '../components/openOrders';
import { useTranslation } from 'react-i18next';
import Heading from '../components/heading';
import SearchInput from '../components/searchInput';
import { DISTANCE_IN_KM } from '../constants';
import { userLocationState, restaurantFiltersState } from '../store/user';
import { useRecoilValue } from 'recoil';
import LocationIndicator from '../components/locationIndicator';
import LocationLinks from '../components/locationLinks';
import RestaurantFilters from '../components/restaurantFilters';
import GastronomyCardLoader from '../components/gastronomyCardLoader';
import { useBreakpoint } from '../hooks/useBreakpoint';
import PressBanner from './static/components/pressBanner';
import { captureError } from '../helpers/error';
import { useLazyQuery } from '@apollo/client';
import { Meta, Title } from 'react-head';
import { selectedLanguageCodeState } from '../store/app';
import ScanQrModal from '../components/scanQrModal';

interface Props {
  locationString?: string;
}

const applyFilters = (
  input: GastronomyFragment[],
  filters: RestaurantFilter[]
): GastronomyFragment[] => {
  if (filters.length === 0) {
    return input;
  }

  const filterCcProducts = filters.includes('HAS_CC_PRODUCTS');

  if (filterCcProducts) {
    input = input.filter((g) => g.has_cc_products);
  }

  if (!filterCcProducts || (filterCcProducts && filters.length > 1)) {
    input = input.filter((g) =>
      g.status === 'ENABLED' && g.options.tableTypes
        ? g.options.tableTypes.some((t) => {
            if (filters.includes(t)) {
              return t === 'DELIVERY' ? g.is_in_radius : true;
            }

            return false;
          })
        : false
    );
  }

  return input;
};

const GastronomyList: React.FC<Props> = ({ locationString }: Props) => {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const userLocation = useRecoilValue(userLocationState);
  const restaurantFilters = useRecoilValue(restaurantFiltersState);
  const selectedLanguageCode = useRecoilValue(selectedLanguageCodeState);
  const { breakpoint } = useBreakpoint();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [getGastronomiesQuery, gastronomyQueryResult] = useLazyQuery<
    GetGastronomiesQuery,
    GetGastronomiesQueryVariables
  >(GetGastronomies, { fetchPolicy: 'cache-and-network' });
  const [searchQuery, searchQueryResult] =
    useLazyQuery<SearchQuery, SearchQueryVariables>(Search);

  const filteredGastronomies = useMemo(() => {
    if (
      gastronomyQueryResult.data &&
      gastronomyQueryResult.data.getGastronomies
    ) {
      console.log(
        'filtering gastros',
        gastronomyQueryResult.data.getGastronomies.length,
        restaurantFilters
      );
      return applyFilters(
        gastronomyQueryResult.data.getGastronomies,
        restaurantFilters
      );
    } else {
      return [];
    }
  }, [gastronomyQueryResult.data, restaurantFilters]);

  const filteredSearchResults = useMemo(() => {
    if (searchQueryResult.data && searchQueryResult.data.search) {
      const results = searchQueryResult.data.search.map(
        (result) => result.gastronomy as GastronomyFragment
      );
      return applyFilters(results, restaurantFilters);
    }
    return [];
  }, [searchQueryResult.data, restaurantFilters]);

  useEffect(() => {
    setShowFilters(breakpoint === 'sm' ? false : true);
  }, [breakpoint]);

  useEffect(() => {
    if (userLocation) {
      const params: GastronomiesSearchParams = {
        languageCode: selectedLanguageCode
      };
      params.source_lat = userLocation.lat;
      params.source_lng = userLocation.lng;
      params.distance_in_km = DISTANCE_IN_KM;
      getGastronomiesQuery({ variables: { params } });
    }
  }, [userLocation]);

  const searchGastronomies = async () => {
    if (searchValue !== '') {
      try {
        const searchParams: SearchParams = {
          searchText: searchValue,
          type: ['GASTRONOMY']
        };

        if (userLocation) {
          searchParams.geoLocation = {
            lat: userLocation.lat,
            lng: userLocation.lng
          };
        }
        searchQuery({ variables: { params: searchParams } });
        setIsSearching(false);
      } catch (error) {
        console.log('error', error);
        captureError(error);
      }
    }
  };

  const toggleShowFilters = () => {
    setShowFilters((current) => !current);
  };

  const onClearSearchInput = () => {
    setIsSearching(false);
    setSearchValue('');
  };

  const onChangeSearchInput = (val: string) => {
    setIsSearching(val === '' ? false : true);
    setSearchValue(val);
  };

  return (
    <>
      <Title>getsby - Deine digitale Speisekarte</Title>
      <Meta property="og:title" content="getsby - die digitale Speisekarte" />
      <Meta
        property="og:description"
        content="Digitale Speisekarte, bestellen und bezahlen. Im Lokal, Takeaway oder Zustellung. Einfach mit getsby dein Essen genießen."
      />
      <Meta property="og:image" content="%PUBLIC_URL%/img/getsby_logo.png" />
      <Meta property="og:url" content="https://getsby.at/" />
      <Meta property="theme-color" content="#ffffff" />
      <div className="p-4">
        <OpenOrders />
        <Heading text={t('navigation.home')} />
        <LocationIndicator />
        {userLocation && (
          <>
            <div className="flex flex-row mb-8">
              <ScanQrModal
                title={t('QRCodeScanner.title')}
                subtitle={t('QRCodeScanner.qrOnTable')}
                buttonText={t('cancel')}
                expectedResult="any"
              >
                <div className="flex items-center p-2 mr-4 bg-white border rounded cursor-pointer hover:bg-gray-200">
                  <i className="material-icons">qr_code_scanner</i>
                </div>
              </ScanQrModal>
              {breakpoint === 'sm' &&
                (searchQueryResult.data || gastronomyQueryResult.data) && (
                  <div className="relative flex items-center p-2 mr-4 bg-white border rounded">
                    <div
                      className="relative flex items-center"
                      onClick={toggleShowFilters}
                    >
                      <i className="material-icons">filter_alt</i>
                    </div>
                    {restaurantFilters.length > 0 && (
                      <span className="absolute top-0 right-0 flex w-3 h-3 -mt-1 -mr-1">
                        <span className="absolute inline-flex w-full h-full rounded-full opacity-75 bg-primary-400 animate-ping"></span>
                        <span className="relative inline-flex w-3 h-3 rounded-full bg-primary-500"></span>
                      </span>
                    )}
                  </div>
                )}
              <SearchInput
                search={searchGastronomies}
                clear={onClearSearchInput}
                placeHolder={t('gastronomies.searchPlaceholder')}
                searchValue={searchValue}
                onChange={onChangeSearchInput}
              />
            </div>

            {showFilters &&
              (searchQueryResult.data || gastronomyQueryResult.data) && (
                <RestaurantFilters />
              )}
            {gastronomyQueryResult.error && (
              <Alert
                type="error"
                title={t('alerts.error')}
                message={gastronomyQueryResult.error.message}
              />
            )}
            {gastronomyQueryResult.loading ||
            searchQueryResult.loading ||
            isSearching ? (
              <GastronomyCardLoader />
            ) : (
              <>
                {searchValue.length > 0 ? (
                  <>
                    <h2 className="mb-4 text-2xl font-semibold">
                      {t('gastronomies.searchResults', {
                        amount: filteredSearchResults.length
                      })}
                    </h2>
                    {searchQueryResult.data && (
                      <>
                        {filteredSearchResults.length > 0 ? (
                          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            {filteredSearchResults.map((gastronomy, index) => (
                              <GastronomyCard
                                gastronomy={gastronomy}
                                key={index.toString()}
                              />
                            ))}
                          </div>
                        ) : (
                          <Alert
                            type="info"
                            title={t('alerts.info')}
                            message={t('gastronomies.noSearchResults')}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h2 className="mb-4 text-2xl font-semibold">
                      {t('gastronomies.nearYou')}
                    </h2>
                    {gastronomyQueryResult.data && (
                      <>
                        {filteredGastronomies.length > 0 && (
                          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            {filteredGastronomies.map((gastronomy, index) => (
                              <GastronomyCard
                                gastronomy={gastronomy}
                                key={index.toString()}
                              />
                            ))}
                          </div>
                        )}
                        {filteredGastronomies.length === 0 && (
                          <Alert
                            type="info"
                            title={t('alerts.info')}
                            message={
                              userLocation
                                ? t('gastronomies.noResultsInLocation', {
                                    location: userLocation.formatted_address
                                  })
                                : t('gastronomies.noSearchResults')
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <LocationLinks />
        {userLocation && (
          <div className="mt-24 duration-300 ease-in">
            <PressBanner />
          </div>
        )}
      </div>
    </>
  );
};

export default GastronomyList;
