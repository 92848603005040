import React from 'react';
import { IMG_BASE_PATH } from '../../constants';
import { useTranslation } from 'react-i18next';

const OpenInBrowser: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 opacity-75">
      <div className="flex flex-col items-center w-1/2 p-8 bg-white rounded-lg opacity-100">
        <img
          src={IMG_BASE_PATH + '/getsby_logo_black.svg'}
          alt="getsby Logo"
          className="w-auto h-12"
        />
        {t('storage.openInBrowser')}
      </div>
    </div>
  );
};

export default OpenInBrowser;
