import React, { useState, useEffect } from 'react';
import { IsGastronomyActive, GetTables } from '../services/graphql/operations';
import GastronomyHeader from '../components/gastronomyHeader';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  gastronomyState,
  tableTypeState,
  isMenuOnlySelector,
  selectedTableState
} from '../store/gastronomy';
import TableTypeInitialSelect from '../components/tableTypeInitialSelect';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { userLocationState } from '../store/user';
import GastronomyHtmlHeader from '../components/gastronomyHtmlHeader';
import { useApolloClient, useQuery } from '@apollo/client';
import { isInDeliveryRadius, requestGeolocation } from '../helpers/location';
import CheckDeliveryLocation from '../components/checkDeliveryLocation';
import RestaurantMenu from '../components/restaurantMenu';
import PreorderDateSelectModal from '../components/preorderDateSelectModal';
import { isStorageAvailable } from '../helpers/localStorage';
import CartButton from '../components/cartButton';
import NewCustomerCoupon from '../components/newCustomerCoupon';
import GetsbyRedirect from '../components/getsbyRedirect';

const isIframe = window.self !== window.top;

type DeliveryLocationState = 'checking' | 'input' | 'fixed' | 'warning';

const RestaurantView: React.FC = () => {
  const apolloClient = useApolloClient();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const isMenuOnly = useRecoilValue(isMenuOnlySelector);
  const [selectedTableType, setSelectedTableType] =
    useRecoilState(tableTypeState);
  const [selectedTable, setSelectedTable] = useRecoilState(selectedTableState);
  const [deliveryLocationState, setDeliveryLocationState] =
    useState<DeliveryLocationState>();
  const browserLocation = useLocation();
  const userLocation = useRecoilValue(userLocationState);
  const history = useHistory();

  const params = queryString.parse(browserLocation.search);

  const isGastronomyActiveQueryResult = useQuery<
    IsGastronomyActiveQuery,
    IsGastronomyActiveQueryVariables
  >(IsGastronomyActive, {
    fetchPolicy: 'network-only',
    skip: !gastronomy,
    variables: { params: { uuid: gastronomy?.uuid } },
    pollInterval: 60000
  });

  useEffect(() => {
    console.log('got result from active query');
    if (isGastronomyActiveQueryResult.data) {
      const data = isGastronomyActiveQueryResult.data;
      if (gastronomy?.uuid === data.getGastronomy?.uuid) {
        setGastronomy((prevGastronomy) => {
          console.log(
            'setting gastro in active query',
            prevGastronomy,
            data.getGastronomy
          );
          if (prevGastronomy) {
            return { ...prevGastronomy, ...data.getGastronomy };
          }
          return null;
        });
      }
    }
  }, [isGastronomyActiveQueryResult]);

  useEffect(() => {
    if (gastronomy) {
      let requestedTableType: TableType | null = null;
      if (
        params &&
        params.tt &&
        gastronomy.options &&
        gastronomy.options.tableTypes
      ) {
        const type = params.tt as TableType;
        if (gastronomy.options.tableTypes.includes(type)) {
          requestedTableType = type;
        }
      }

      if (
        !requestedTableType &&
        !isIframe &&
        gastronomy.options.tableTypes &&
        gastronomy.options.tableTypes.length === 1 &&
        gastronomy.options.tableTypes[0] !== 'DELIVERY'
      ) {
        requestedTableType = gastronomy.options.tableTypes[0];
        console.log(
          'pre select table type: ',
          gastronomy.options.tableTypes[0],
          params.tt
        );
        if (gastronomy.options.tableTypes[0] !== params.tt) {
          history.push({
            pathname: history.location.pathname,
            search: `tt=${gastronomy.options.tableTypes[0]}`
          });
        }
      }
      setSelectedTableType(requestedTableType);

      // set the open state in recoil
      // setGastronomyState(gastronomy);
    }
  }, [gastronomy]);

  useEffect(() => {
    if (selectedTableType && gastronomy) {
      if (selectedTableType === 'DELIVERY') {
        checkIfDeliversToLocation();
      } else {
        const loadTables = async () => {
          const tablesResult = await apolloClient.query<
            GetTablesQuery,
            GetTablesQueryVariables
          >({
            query: GetTables,
            fetchPolicy: 'network-only',
            variables: {
              params: {
                user_uuid: gastronomy.uuid,
                table_types: [selectedTableType],
                status: 'ACTIVE'
              }
            }
          });
          if (tablesResult.data && tablesResult.data.getTables.length === 1) {
            setSelectedTable(tablesResult.data.getTables[0]);
          }
        };
        loadTables();
      }
    }
  }, [selectedTableType, gastronomy]);

  useEffect(() => {
    if (selectedTableType && selectedTableType === 'DELIVERY' && gastronomy) {
      if (userLocation) {
        checkIfDeliversToLocation();
      } else {
        setDeliveryLocationState('input');
      }
    }
  }, [userLocation, selectedTableType, gastronomy]);

  const checkIfDeliversToLocation = async () => {
    console.log('checking radius', deliveryLocationState, userLocation);
    if (deliveryLocationState === 'checking') {
      return;
    }
    try {
      setDeliveryLocationState('checking');
      let lat = 0;
      let lng = 0;
      if (userLocation && userLocation.selected) {
        lat = userLocation.lat;
        lng = userLocation.lng;
      } else {
        const geo = await requestGeolocation();
        if (geo.coords.accuracy < 100) {
          lat = geo.coords.latitude;
          lng = geo.coords.longitude;
        }
      }
      console.log('location', lat, lng, gastronomy !== undefined);
      if (lat && lng && gastronomy && gastronomy.uuid) {
        const tableInRadius = await isInDeliveryRadius(
          apolloClient,
          lat,
          lng,
          gastronomy.uuid
        );
        console.log('table in radius', tableInRadius);
        if (tableInRadius) {
          setSelectedTable(tableInRadius);
          setDeliveryLocationState('fixed');
        } else {
          setDeliveryLocationState('warning');
        }
        return;
      }
    } catch (error) {
      console.log('Error getting locaiton', error);
    }
    setSelectedTable(null);
    setDeliveryLocationState('input');
  };

  if (gastronomy) {
    if (gastronomy.status === 'DISABLED') {
      return <GetsbyRedirect to="/404" />;
    }

    if (isMenuOnly) {
      return (
        <div id="restaurant-view">
          <GastronomyHtmlHeader gastronomy={gastronomy} />
          <GastronomyHeader />
          <RestaurantMenu gastronomy={gastronomy} tableType={null} />
        </div>
      );
    }

    return (
      <div id="restaurant-view">
        <GastronomyHtmlHeader gastronomy={gastronomy} />
        <GastronomyHeader />
        <NewCustomerCoupon />
        {!params.tt ? (
          <TableTypeInitialSelect gastronomy={gastronomy} />
        ) : (
          <>
            {selectedTableType === 'DELIVERY' && (
              <CheckDeliveryLocation
                checking={deliveryLocationState === 'checking'}
                showAddressInput={
                  deliveryLocationState === 'input' ||
                  deliveryLocationState === 'warning'
                }
                showWarning={deliveryLocationState === 'warning'}
              />
            )}
            {(selectedTableType !== 'DELIVERY' ||
              (deliveryLocationState === 'fixed' && selectedTable)) && (
              <>
                <RestaurantMenu
                  gastronomy={gastronomy}
                  tableType={selectedTableType}
                />
                <CartButton />
              </>
            )}
          </>
        )}
        <PreorderDateSelectModal />
      </div>
    );
  }
  return null;
};

export default RestaurantView;
