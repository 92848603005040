import React, { MouseEvent } from 'react';

interface Props {
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'default';
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  title?: string;
  children?: React.ReactNode | string;
  disabled?: boolean;
  classes?: string;
  buttonType?: 'button' | 'reset' | 'submit';
}

const Button: React.FC<Props> = ({
  type,
  title,
  disabled,
  onClick,
  children,
  classes,
  buttonType = 'button'
}) => {
  const disabledClass = disabled ? 'cursor-not-allowed opacity-50' : '';

  const styles = {
    primary: 'bg-primary-500 text-secondary-500',
    secondary: 'bg-secondary-500 text-primary-500',
    success: 'bg-green-500 text-white',
    info: 'bg-blue-500 text-white',
    warning: 'bg-yellow-500 text-white',
    error: 'bg-red-500 text-white',
    default: 'bg-transparent border border-gray-400 text-secondary-500'
  };

  return (
    <button
      className={`${styles[type]} font-bold py-2 px-4 rounded shadow ${disabledClass} ${classes}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      type={buttonType}
    >
      {title || children}
    </button>
  );
};

export default Button;
