import { atom } from 'recoil';

export const selectedProductGroupState = atom<string | null>({
  key: 'selectedProductGroupState',
  default: null
});

export const productSearchState = atom<{ focused: boolean; search: string }>({
  key: 'productSearchState',
  default: { focused: false, search: '' }
});
