import React, { useEffect, useState } from 'react';
import Modal from './modal';
import RedemptionQrCode from './redemptionQrCode';

interface Props {
  bonusProgramRedemptions?: BonusProgramRedemptionFragment[];
}

const BonusProgramRedemptions: React.FC<Props> = ({
  bonusProgramRedemptions = []
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setShowModal(!!bonusProgramRedemptions.length);
  }, [bonusProgramRedemptions]);

  return (
    <>
      {bonusProgramRedemptions.map((redemption, i) => (
        <div key={i}>
          {showModal && (
            <Modal>
              <RedemptionQrCode
                redemption={redemption}
                handleClose={() => setShowModal(false)}
              />
            </Modal>
          )}
        </div>
      ))}
    </>
  );
};

export default BonusProgramRedemptions;
