import { isBefore } from 'date-fns';
import { atom, DefaultValue, selector } from 'recoil';
import { PERSISTED_GASTRONOMY_KEY, PREORDER_DAYS_DEFAULT } from '../constants';
import { AvailabilityMixin } from '../helpers/availability';
import {
  calculateTimeOfNextUpdate,
  getDisabledUntilFromGastronomy
} from '../helpers/gastronomy';
import {
  isStorageAvailable,
  setTableInLocalStorage
} from '../helpers/localStorage';
import { canOrderAt } from '../helpers/serviceTimes';
import {
  cartItemsState,
  preorderModalState,
  preorderRadioAtom,
  preorderSlotState
} from './cart';
import {
  availableBonusProgramPointsState,
  useBonusProgramPointsState
} from './user';

export const preSetGastronomyUrlPathState = atom<string | null>({
  key: 'preSetGastronomyUrlPath',
  default: null
});

const getGastronomyDefault = () => {
  if (
    (document.location.pathname === '/cart' ||
      document.location.pathname.startsWith('/order/') ||
      document.location.pathname.startsWith('/payment/confirm/')) &&
    isStorageAvailable()
  ) {
    const persistedGastronomyJson = localStorage.getItem(
      PERSISTED_GASTRONOMY_KEY
    );
    if (persistedGastronomyJson) {
      const persistedGastronomy = JSON.parse(
        persistedGastronomyJson
      ) as GastronomyFragment;
      if (persistedGastronomy) {
        return persistedGastronomy;
      }
    }
  }
  return null;
};

const gastronomyAtom = atom<GastronomyFragment | null>({
  key: 'gastronomy',
  default: getGastronomyDefault(),
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      let timeout: NodeJS.Timeout | null = null;
      onSet((newGastronomy, oldValue) => {
        if (timeout) clearInterval(timeout);
        if (
          !(newGastronomy instanceof DefaultValue) &&
          newGastronomy &&
          newGastronomy.opening_times
        ) {
          const mixin = new AvailabilityMixin(newGastronomy.opening_times);
          let nextUpdate = calculateTimeOfNextUpdate(mixin);
          if (nextUpdate) {
            timeout = setInterval(() => {
              if (!nextUpdate && timeout) clearInterval(timeout);
              if (nextUpdate && nextUpdate.time < new Date().getTime()) {
                nextUpdate = calculateTimeOfNextUpdate(mixin);
                setSelf({
                  ...newGastronomy,
                  isOpen: mixin.nowOpen().length > 0
                });
              }
            }, 1000);
          }
        }
      });
      return () => {
        if (timeout) {
          clearInterval(timeout);
        }
      };
    }
  ]
});

export const gastronomyState = selector<GastronomyFragment | null>({
  key: 'gastronomySelector',
  get: ({ get }) => get(gastronomyAtom),
  set: ({ get, set, reset }, newGastronomy) => {
    const selectedTable = get(selectedTableAtom);
    if (newGastronomy) {
      const gastronomy = newGastronomy as GastronomyFragment;
      if (selectedTable && selectedTable.user_uuid !== gastronomy.uuid) {
        set(selectedTableAtom, null);
      }
      const cartItems = get(cartItemsState);
      for (const cartItem of cartItems) {
        if (cartItem.product.user_uuid !== gastronomy.uuid) {
          reset(cartItemsState);
          reset(preorderRadioAtom);
          break;
        }
      }
      const oldGastro = get(gastronomyAtom);
      if (
        oldGastro &&
        oldGastro.uuid &&
        gastronomy.uuid &&
        oldGastro.uuid !== gastronomy.uuid
      ) {
        reset(preorderModalState);
        reset(preorderRadioAtom);
        reset(availableBonusProgramPointsState);
        reset(useBonusProgramPointsState);
      }
      if (gastronomy.categories && gastronomy.categories.length > 0) {
        set(selectedCategoryState, gastronomy.categories[0].uuid);
      }
    }
    set(gastronomyAtom, newGastronomy);
  }
});

const tableTypeAtom = atom<TableType | null>({
  key: 'tableType',
  default: null
});

export const tableTypeState = selector<TableType | null>({
  key: 'tableTypeSelector',
  get: ({ get }) => {
    return get(tableTypeAtom);
  },
  set: ({ get, set, reset }, newTableType) => {
    const selectedTable = get(selectedTableState);
    if (selectedTable && selectedTable.table_type !== newTableType) {
      if (newTableType !== null) {
        set(selectedTableAtom, null);
      }
      reset(preorderRadioAtom);
    }
    if (newTableType && !(newTableType instanceof DefaultValue)) {
      const gastronomy = get(gastronomyState);
      if (gastronomy) {
        setTableInLocalStorage({
          table_type: newTableType,
          user_uuid: gastronomy.uuid
        });
      }
    }
    set(tableTypeAtom, newTableType);
  }
});

const selectedTableAtom = atom<TableFragment | null>({
  key: 'selectedTable',
  default: null
});

export const selectedTableState = selector<TableFragment | null>({
  key: 'selectedTableSelector',
  get: ({ get }) => {
    return get(selectedTableAtom);
  },
  set: ({ get, set, reset }, newTable) => {
    if (newTable) {
      const table = newTable as TableFragment;
      if (table.table_type) {
        console.log('setting table type to', table.table_type);
        set(tableTypeAtom, table.table_type);
      }
      const preorderSlot = get(preorderSlotState);
      if (preorderSlot && preorderSlot.table_uuid !== table.uuid) {
        reset(preorderSlotState);
        reset(preorderModalState);
      }
      reset(preorderRadioAtom);
      // open preorder slot modal
      const gastronomy = get(gastronomyAtom);

      if (gastronomy && gastronomy.isActive) {
        let isOpen = true;
        const serviceTimeMixin = table.effective_service_times
          ? new AvailabilityMixin(table.effective_service_times)
          : undefined;
        if (gastronomy.isOpen) {
          if (serviceTimeMixin) {
            const tableRanges = serviceTimeMixin.timerangeAt(
              preorderSlot ? preorderSlot.from : new Date()
            );
            if (tableRanges.length === 0) {
              isOpen = false;
            }
          }
        } else {
          isOpen = false;
        }

        if (!isOpen) {
          const canOrder = serviceTimeMixin
            ? canOrderAt(serviceTimeMixin) !== false
            : true;
          if (table.allow_preorder && canOrder && !preorderSlot) {
            set(preorderModalState, {
              showModal: false,
              title: `Bei ${gastronomy.name} können Sie für folgende Zeiten vorbestellen:`,
              closeOnSelect: true
            });
          }
        }
      }

      setTableInLocalStorage({
        user_uuid: table.user_uuid,
        table_type: table.table_type,
        table
      });
    }
    set(selectedTableAtom, newTable);
  }
});

export const selectedTableLeadTime = selector<null | number>({
  key: 'selectedTableLeadTime',
  get: ({ get }) => {
    const table = get(selectedTableAtom);
    if (table && table.tableConfig && table.tableConfig.leadTime) {
      return table.tableConfig.leadTime;
    }
    return null;
  }
});

export const selectedTablePreorderDaysSelector = selector<number>({
  key: 'selectedTablePreorderDaysSelector',
  get: ({ get }) => {
    const table = get(selectedTableAtom);
    if (table && table.tableConfig && table.tableConfig.preorderDays) {
      return table.tableConfig.preorderDays;
    }
    return 1;
  }
});

export const getAvailabilityMixinForSelectedTableSelector = selector<
  AvailabilityMixin | undefined
>({
  key: 'getAvailabilityMixinForSelectedTableSelector',
  get: ({ get }) => {
    console.log('getting mixin...');
    const table = get(selectedTableState);
    const gastronomy = get(gastronomyState);
    if (gastronomy?.uuid === table?.uuid && table?.effective_service_times) {
      console.log(
        'effective service times of selected table',
        table.effective_service_times
      );
      return new AvailabilityMixin(table.effective_service_times);
    }
    if (gastronomy?.opening_times) {
      console.log('gastronomy mixin from', gastronomy.opening_times);
      return new AvailabilityMixin(gastronomy.opening_times);
    }
  }
});

export const canPreorderNowOnTableSelector = selector<boolean>({
  key: 'canPreorderNowOnTableSelector',
  get: ({ get }) => {
    const mixin = get(getAvailabilityMixinForSelectedTableSelector);
    const selectedTable = get(selectedTableAtom);
    if (mixin && selectedTable) {
      const preorderDays =
        selectedTable?.tableConfig?.preorderDays || PREORDER_DAYS_DEFAULT;
      const canOrder = canOrderAt(mixin, preorderDays);
      return canOrder === 'NOW' ? true : canOrder;
    }
    return false;
  }
});

export const canOrderAtSelectedTimeOnTableSelector = selector<boolean>({
  key: 'canOrderAtSelectedTimeOnTableSelector',
  get: ({ get }) => {
    const preorderSlot = get(preorderSlotState);
    const time = preorderSlot ? preorderSlot.from : new Date();

    const gastronomy = get(gastronomyState);
    const selectedTableType = get(tableTypeState);

    if (selectedTableType && gastronomy) {
      const disabledUntil = getDisabledUntilFromGastronomy(
        selectedTableType,
        gastronomy
      );

      if (disabledUntil && isBefore(time, new Date(disabledUntil))) {
        return false;
      }
    }

    const mixin = get(getAvailabilityMixinForSelectedTableSelector);
    if (mixin) {
      return mixin.timerangeAt(time).length > 0;
    }
    return false;
  }
});

export const isMenuOnlySelector = selector<boolean>({
  key: 'isMenuOnlySelector',
  get: ({ get }) => {
    const gastronomy = get(gastronomyAtom);
    if (gastronomy) {
      if (gastronomy.status === 'MENUONLY') {
        return true;
      }

      const hasNoTableTypes =
        gastronomy.options.tableTypes &&
        gastronomy.options.tableTypes.length === 0
          ? true
          : false;

      return hasNoTableTypes;
    }
    return false;
  }
});

export const selectedCategoryState = atom<string | null>({
  key: 'selectedCategory',
  default: null
});

export const disabledPaymentTypesSelector = selector<PaymentMethodType[]>({
  key: 'disabledPaymentTypesSelector',
  get: ({ get }) => {
    const gastronomy = get(gastronomyAtom);
    if (
      gastronomy &&
      gastronomy.options &&
      gastronomy.options.disabledPaymentTypes
    ) {
      return gastronomy.options.disabledPaymentTypes.map((t) => t.type);
    }
    return [];
  }
});
