import React, { useState } from 'react';
import Heading from '../components/heading';
import { useTranslation } from 'react-i18next';
import { Title } from 'react-head';
import { useQuery } from '@apollo/client';
import { GetNewsletterSubscription } from '../services/graphql/operations';
import Spinner from '../components/spinner';
import NewsletterSubscriptionUpdateForm from '../components/newsletterSubscriptionUpdateForm';
import GetsbyRedirect from '../components/getsbyRedirect';

interface Props {
  subscriptionUuid?: string;
}

const NewsletterSubscription: React.FC<Props> = ({ subscriptionUuid }) => {
  const [redirectTo404, setRedirectTo404] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data, loading } = useQuery<
    GetNewsletterSubscriptionQuery,
    GetNewsletterSubscriptionQueryVariables
  >(GetNewsletterSubscription, {
    variables: {
      params: {
        uuid: subscriptionUuid
      }
    },
    skip: !subscriptionUuid,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.getNewsletterSubscription) {
        setRedirectTo404(true);
      }
    },
    onError: () => {
      setRedirectTo404(true);
    }
  });

  if (!subscriptionUuid || redirectTo404) {
    return <GetsbyRedirect to="/404" />;
  }

  return (
    <div className="w-full p-4">
      <Title>getsby - News</Title>
      <Heading
        text={
          data?.getNewsletterSubscription?.name
            ? t('newsletter.subscription') +
              ` - ${data?.getNewsletterSubscription?.name}`
            : t('newsletter.subscription')
        }
      />
      {loading && <Spinner center={true} />}
      {data?.getNewsletterSubscription && (
        <NewsletterSubscriptionUpdateForm
          subscription={data.getNewsletterSubscription}
        />
      )}
    </div>
  );
};

export default NewsletterSubscription;
