import React, { useEffect, useState } from 'react';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { HTMLReactParserOptions } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { Link } from 'react-router-dom';
import { Style } from 'react-head';
import BonusProgramBadgeWrapper from '../components/bonusProgramBadgeWrapper';
import { useRecoilValue } from 'recoil';
import { userIsAuthenticatedState } from '../store/user';
import GastronomyHeader from '../components/gastronomyHeader';
import Picture from '../components/Picture';
import ScanQrModal from '../components/scanQrModal';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';
import OpeningTimesWidget from '../components/openingTimesWidget';

interface Props {
  html: string;
  css?: string;
}

const CustomPage: React.FC<Props> = ({ html, css }) => {
  const [processedHtml, setProcessedHtml] = useState<any>();
  const isAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const { t } = useTranslation();

  useEffect(() => {
    const scriptIds: string[] = [];
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (domNode instanceof Element && domNode.attribs) {
          if (domNode.name === 'a' && domNode.attribs.href) {
            const props = attributesToProps(domNode.attribs);
            const href = domNode.attribs.href;

            if (href.startsWith('/')) {
              return (
                <Link to={href} {...props}>
                  {domToReact(domNode.children, options)}
                </Link>
              );
            } else if (domNode.attribs.target === '_blank') {
              const hasParams = href.indexOf('?');

              return (
                <a
                  {...props}
                  href={`${href}${
                    hasParams === -1 ? '?target=_blank' : '&target=_blank'
                  }`}
                >
                  {domToReact(domNode.children, options)}
                </a>
              );
            }
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'bonus-program-points-badge'
          ) {
            return <BonusProgramBadgeWrapper />;
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'bonus-program-points'
          ) {
            return <BonusProgramBadgeWrapper renderAsText={true} />;
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'condition:isAuthenticated'
          ) {
            return (
              <>{isAuthenticated && domToReact(domNode.children, options)}</>
            );
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'condition:isUnauthenticated'
          ) {
            return (
              <>{!isAuthenticated && domToReact(domNode.children, options)}</>
            );
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'gastronomy-header'
          ) {
            return (
              <GastronomyHeader>
                {domToReact(domNode.children, options)}
              </GastronomyHeader>
            );
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'opening-times' &&
            domNode.attribs['data-gastronomy-uuid']
          ) {
            return (
              <OpeningTimesWidget
                gastronomyUuid={domNode.attribs['data-gastronomy-uuid']}
              />
            );
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'scan-invoice'
          ) {
            return (
              <ScanQrModal
                expectedResult={
                  (domNode.attribs['data-expected-result'] as any) || 'any'
                }
                title={
                  domNode.attribs['data-scanner-title'] ||
                  t('QRCodeScanner.title')
                }
                subtitle={
                  domNode.attribs['data-scanner-subtitle'] ||
                  t('bonusProgram.scanInvoiceInformation')
                }
                buttonText={
                  domNode.attribs['data-scanner-button-text'] || t('close')
                }
              >
                {domToReact(domNode.children, options)}
              </ScanQrModal>
            );
          }
          if (
            domNode.name === 'getsby' &&
            domNode.attribs['data-type'] === 'picture'
          ) {
            return (
              <Picture
                imageSource={domNode.attribs['data-src']}
                svgColor={{
                  rgba: domNode.attribs['data-color-rgba'],
                  hex: domNode.attribs['data-color-hex'],
                  themeColor: domNode.attribs['data-color-theme'] as
                    | 'primary'
                    | 'secondary'
                    | undefined
                }}
                height={
                  domNode.attribs['data-height']
                    ? +domNode.attribs['data-height']
                    : undefined
                }
                width={
                  domNode.attribs['data-width']
                    ? +domNode.attribs['data-width']
                    : undefined
                }
                fit={
                  domNode.attribs['data-fit'] as
                    | 'fill'
                    | 'scale'
                    | 'crop'
                    | 'clip'
                    | 'min'
                    | 'max'
                    | undefined
                }
                className={domNode.attribs['data-class']}
              />
            );
          }
          if (domNode.type === 'script') {
            const script = document.createElement('script');
            const scriptId = 'script-' + uuid();
            script.id = scriptId;
            scriptIds.push(scriptId);
            if (domNode.children) {
              for (const child of domNode.children) {
                //@ts-ignore
                if (child.type === 'text' && child.data) {
                  //@ts-ignore
                  console.log('script child:', child.data);
                  // @ts-ignore
                  script.append(child.data);
                }
              }
            }
            if (domNode.attribs.src) {
              script.src = domNode.attribs.src;
            }
            document.head.appendChild(script);
          }
        }
      }
    };

    setProcessedHtml(parse(html, options));
    return () => {
      const headChildren = Array.from(document.head.children);
      for (const child of headChildren) {
        if (scriptIds.includes(child.id)) {
          document.head.removeChild(child);
        }
      }
    };
  }, [html]);

  return (
    <>
      {css && <Style>{css}</Style>}
      {processedHtml}
    </>
  );
};

export default CustomPage;
