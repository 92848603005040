import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';
import ScanQrModal from './scanQrModal';

interface Props {
  user_uuid?: string;
  urlPathId?: string;
  bonus_program_uuid?: string;
  renderAsBadge?: boolean;
  className?: string;
}

const ScanInvoiceButton: React.FC<Props> = ({
  user_uuid,
  urlPathId,
  bonus_program_uuid,
  renderAsBadge = false,
  className
}) => {
  const { t } = useTranslation();

  return (
    <ScanQrModal
      expectedResult={'invoice'}
      user_uuid={user_uuid}
      urlPathId={urlPathId}
      bonus_program_uuid={bonus_program_uuid}
      title={t('QRCodeScanner.title')}
      subtitle={t('bonusProgram.scanInvoiceInformation')}
      buttonText={t('close')}
    >
      <>
        {renderAsBadge ? (
          <span
            className={`scan-invoice-badge inline-flex items-center px-3 py-1 text-sm font-semibold bg-primary-500 text-secondary-500 border border-secondary-500 rounded-full cursor-pointer ${className}`}
            title={t('bonusProgram.scanInvoice')}
          >
            <i className="mr-2 text-sm material-icons">add_a_photo</i>
            {t('bonusProgram.scanInvoice')}
          </span>
        ) : (
          <Button
            type="default"
            classes={`w-full flex items-center justify-center my-4 ${className}`}
          >
            <span className="mr-4 material-icons">add_a_photo</span>
            {t('bonusProgram.scanInvoice')}
          </Button>
        )}
      </>
    </ScanQrModal>
  );
};

export default ScanInvoiceButton;
