import React, { useState } from 'react';
import Heading from '../components/heading';
import { useTranslation } from 'react-i18next';
import { Title } from 'react-head';
import { useQuery } from '@apollo/client';
import {
  GetNewsletter,
  GetNewsletterSubscription
} from '../services/graphql/operations';
import Spinner from '../components/spinner';
import parse from 'html-react-parser';
import NewsletterSubscriptionAddForm from '../components/newsletterSubscriptionAddForm';
import { getTimeAgo } from '../helpers/utilities';
import GetsbyLink from '../components/getsbyLink';
import GetsbyRedirect from '../components/getsbyRedirect';

interface Props {
  postUuid?: string;
}

const NewsletterPost: React.FC<Props> = ({ postUuid }) => {
  const [redirectTo404, setRedirectTo404] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data, loading } = useQuery<
    GetNewsletterQuery,
    GetNewsletterQueryVariables
  >(GetNewsletter, {
    variables: {
      uuid: postUuid || 'NOT_SET'
    },
    skip: !postUuid,
    onCompleted: (data) => {
      if (!data.getNewsletter) {
        setRedirectTo404(true);
      }
    },
    onError: () => {
      setRedirectTo404(true);
    }
  });

  const { data: newsletterSubscriptionData } = useQuery<
    GetNewsletterSubscriptionQuery,
    GetNewsletterSubscriptionQueryVariables
  >(GetNewsletterSubscription, {
    variables: {
      params: {
        user_uuid: data?.getNewsletter?.user_uuid,
        app_uuid: data?.getNewsletter?.app_uuid
      }
    },
    fetchPolicy: 'network-only',
    skip: !data?.getNewsletter?.user_uuid && !data?.getNewsletter?.app_uuid
  });

  if (!postUuid || redirectTo404) {
    return <GetsbyRedirect to="/404" />;
  }

  return (
    <div className="w-full p-4">
      <Title>getsby - News</Title>
      <Heading text={data?.getNewsletter?.subject || ''} classes="mb-4" />
      {loading && <Spinner center={true} />}
      {data?.getNewsletter?.sent_at && (
        <div className="mb-2 text-sm italic bold">
          {getTimeAgo(new Date(data.getNewsletter.sent_at), t)}
        </div>
      )}
      <div className="p-4 mb-4 border rounded">
        {data?.getNewsletter && (
          <>
            {['EMAIL', 'BOTH'].includes(data.getNewsletter.type) ? (
              <>{parse(data.getNewsletter.body_html || '')}</>
            ) : (
              <>{data.getNewsletter.body_push}</>
            )}
          </>
        )}
      </div>
      <hr className="my-8" />
      {newsletterSubscriptionData?.getNewsletterSubscription?.uuid ? (
        <GetsbyLink
          to="/news/subscription/:subscriptionUuid"
          params={{
            subscriptionUuid:
              newsletterSubscriptionData.getNewsletterSubscription.uuid
          }}
          className="underline text-primary-500"
          target="_blank"
        >
          {t('newsletter.updateSubscription')}
        </GetsbyLink>
      ) : (
        <NewsletterSubscriptionAddForm />
      )}
    </div>
  );
};

export default NewsletterPost;
