import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartItemsState } from '../store/cart';
import {
  preSetGastronomyUrlPathState,
  gastronomyState,
  selectedTableState,
  tableTypeState
} from '../store/gastronomy';
import {
  deliveryAddressState,
  guestRegistrationState,
  userLocationState
} from '../store/user';
import { useSetRecoilState } from 'recoil';
import {
  STORAGE_KEY_DELIVERY_ADDRESS,
  STORAGE_KEY_GUEST_REGISTRATION,
  PERSISTED_GASTRONOMY_KEY
} from '../constants';
import queryString from 'query-string';
import { languagesState, selectedLanguageCodeState } from '../store/app';
import { setLastSelectedLocation } from '../helpers/location';
import {
  getTableFromLocalStorage,
  isStorageAvailable
} from '../helpers/localStorage';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GetLanguages, GetTable } from '../services/graphql/operations';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const queryParams = queryString.parse(window.location.search);
interface Props {
  gastronomyUrlPath?: string;
}

const StatePersistance: React.FC<Props> = ({ gastronomyUrlPath }: Props) => {
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  const setPreSetGastronomyUrlPath = useSetRecoilState(
    preSetGastronomyUrlPathState
  );
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const setGuestRegistration = useSetRecoilState(guestRegistrationState);
  const deliveryAddress = useRecoilValue(deliveryAddressState);
  const userLocation = useRecoilValue(userLocationState);
  const gastronomy = useRecoilValue(gastronomyState);
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const setTableType = useSetRecoilState(tableTypeState);
  const setLanguages = useSetRecoilState(languagesState);
  const selectedLanguageCode = useRecoilValue(selectedLanguageCodeState);
  useQuery<GetLanguagesQuery>(GetLanguages, {
    onCompleted: (data) => {
      if (data && data.getLanguages) {
        setLanguages(data.getLanguages);
      }
    }
  });
  const [getTableQuery] = useLazyQuery<GetTableQuery, GetTableQueryVariables>(
    GetTable,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.getTable?.status === 'ACTIVE') {
          setSelectedTable(data.getTable);
        } else {
          setSelectedTable(null);
        }
      }
    }
  );

  useEffect(() => {
    if (gastronomyUrlPath) {
      setPreSetGastronomyUrlPath(gastronomyUrlPath);
    }

    if (pathname.startsWith('/q/') || queryParams.t) {
      setSelectedTable(null);
    }

    if (isStorageAvailable()) {
      const savedState = localStorage.getItem('gCartItemsState');
      if (savedState) {
        const savedCartItems = JSON.parse(savedState);
        setCartItems(savedCartItems);
      }

      const guestReg = localStorage.getItem(STORAGE_KEY_GUEST_REGISTRATION);
      if (guestReg) {
        setGuestRegistration(JSON.parse(guestReg));
      }
    }

    if (selectedLanguageCode) {
      i18n.changeLanguage(selectedLanguageCode);
    }
  }, []);

  useEffect(() => {
    if (isStorageAvailable()) {
      localStorage.setItem('gCartItemsState', JSON.stringify(cartItems));
    }
  }, [cartItems]);

  useEffect(() => {
    if (gastronomy && isStorageAvailable()) {
      localStorage.setItem(
        PERSISTED_GASTRONOMY_KEY,
        JSON.stringify(gastronomy)
      );
      const tableFromStorage = getTableFromLocalStorage(gastronomy.uuid);
      if (tableFromStorage) {
        if (tableFromStorage.table) {
          getTableQuery({ variables: { uuid: tableFromStorage.table.uuid } });
        } else {
          if (
            tableFromStorage &&
            gastronomy.options.tableTypes?.includes(tableFromStorage.table_type)
          ) {
            setTableType(tableFromStorage.table_type);
          }
        }
      }
    }
  }, [gastronomy]);

  useEffect(() => {
    if (isStorageAvailable() && deliveryAddress) {
      localStorage.setItem(
        STORAGE_KEY_DELIVERY_ADDRESS,
        JSON.stringify(deliveryAddress)
      );
    }
  }, [deliveryAddress]);

  useEffect(() => {
    if (isStorageAvailable() && userLocation) {
      setLastSelectedLocation(userLocation);
    }
  }, [userLocation]);

  return null;
};

export default StatePersistance;
