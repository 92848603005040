import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  NEW_CUSTOMER_CC_COUPON_CODE,
  NEW_CUSTOMER_COUPON_CODE
} from '../constants';
import { isNewCustomerCoupon } from '../helpers/utilities';
import { CheckCouponCode } from '../services/graphql/operations';
import { appConfigState } from '../store/app';
import { preliminaryCreditState } from '../store/cart';
import { gastronomyState, tableTypeState } from '../store/gastronomy';
import { userIsAuthenticatedState } from '../store/user';
import Alert from './alert';
import GetsbyLink from './getsbyLink';

const NewCustomerCoupon: React.FC = () => {
  const { t } = useTranslation();
  const [preliminaryCredit, setPreliminaryCredit] = useRecoilState(
    preliminaryCreditState
  );
  const isAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const gastronomy = useRecoilValue(gastronomyState);
  const selectedTableType = useRecoilValue(tableTypeState);
  const app = useRecoilValue(appConfigState);

  const isCorrectCredit = useMemo(() => {
    if (preliminaryCredit) {
      return isNewCustomerCoupon(preliminaryCredit.campaign_coupon_code);
    }
    return false;
  }, [preliminaryCredit]);

  const { refetch } = useQuery<
    CheckCouponCodeQuery,
    CheckCouponCodeQueryVariables
  >(CheckCouponCode, {
    variables: {
      code: NEW_CUSTOMER_COUPON_CODE
    },
    fetchPolicy: 'network-only',
    skip:
      !!app ||
      !gastronomy ||
      selectedTableType !== 'DINEIN' ||
      gastronomy.options.disableNewCustomerCoupon,
    onCompleted: (data) => {
      if (!preliminaryCredit && data?.checkCouponCode) {
        if (
          selectedTableType !== 'DINEIN' ||
          (gastronomy && gastronomy.options.disableNewCustomerCoupon)
        ) {
          setPreliminaryCredit(null);
        } else {
          setPreliminaryCredit(data.checkCouponCode);
        }
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [isAuthenticated]);

  const renderCanOrderText = (hasCcProducts?: boolean): React.ReactNode => {
    return (
      <>
        <div>{t('newCustomerCoupon.couponTextStart')}</div>
        <div className="my-4 text-4xl text-center">
          {t('newCustomerCoupon.couponTextDiscount', {
            amount: hasCcProducts ? '5' : '3'
          })}
        </div>
        {hasCcProducts && (
          <div>{t('newCustomerCoupon.requireCcProductsCouponHint')}</div>
        )}
        <div>{t('newCustomerCoupon.couponTextEnd')}</div>
      </>
    );
  };

  const renderGenericText = (): React.ReactNode => {
    return (
      <>
        <div>{t('newCustomerCoupon.genericCouponTextStart')}</div>
        <div className="my-4 text-4xl text-center">
          {t('newCustomerCoupon.couponTextDiscount', { amount: '3' })}
        </div>
        <div className="mb-4">
          <Trans
            i18nKey="newCustomerCoupon.genericCouponTextEnd"
            components={[
              <GetsbyLink key={0} to="/" className="underline">
                getsby.at
              </GetsbyLink>
            ]}
          />
        </div>
        <div>
          <Trans
            i18nKey="newCustomerCoupon.genericCouponTextHint"
            components={[
              <GetsbyLink key={0} to="/" className="underline">
                getsby.at
              </GetsbyLink>
            ]}
          />
        </div>
        <div>
          <Trans
            i18nKey="newCustomerCoupon.genericCouponTextValidityHint"
            components={[
              <GetsbyLink key={0} to="/" className="underline">
                getsby.at
              </GetsbyLink>
            ]}
          />
        </div>
      </>
    );
  };

  if (!isCorrectCredit || selectedTableType !== 'DINEIN') {
    return null;
  }

  return (
    <div className="flex w-full p-4" id="new-customer-coupon">
      <Alert
        type="success"
        title={t('credits.credit')}
        classes="w-full"
        message={
          <div className="font-bold">
            {gastronomy?.options.canOrder
              ? renderCanOrderText(gastronomy?.has_cc_products)
              : renderGenericText()}
          </div>
        }
      />
    </div>
  );
};

export default NewCustomerCoupon;
