import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  times: ReadableOpeningTimes[];
  containerClassName?: HTMLAttributes<HTMLDivElement>['className'];
  elementClassName?: HTMLAttributes<HTMLDivElement>['className'];
}

const formatTime = (timeString: string) => {
  return parseInt(timeString.slice(0, 2), 10) + ':' + timeString.slice(2, 4);
};

const OpeningTimes: React.FC<Props> = ({
  times,
  containerClassName,
  elementClassName
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={containerClassName}>
      {times.map((time, index) => (
        <div key={index.toString()} className={elementClassName}>
          {time.days.map((d) => t('openingTimes.days.' + d)).join(', ')}:{' '}
          {time.times
            .map((t) => formatTime(t.time_from) + ' - ' + formatTime(t.time_to))
            .join(' & ')}
        </div>
      ))}
    </div>
  );
};

export default OpeningTimes;
