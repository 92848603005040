import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { uuid } from 'short-uuid';
import {
  getMatrixFromHex,
  getMatrixFromRGBA,
  getMatrixFromStyle
} from '../helpers/svg';
import { currentColorsState } from '../store/app';
import Spinner from './spinner';

interface Props {
  imageSource: string;
  className?: string;
  width?: number;
  height?: number;
  quality?: number;
  devicePixelRatio?: number;
  altTag?: string;
  onClick?: () => void;
  showSpinner?: boolean;
  fm?: 'jpeg' | 'png' | 'svg';
  fit?: 'fill' | 'scale' | 'crop' | 'clip' | 'min' | 'max';
  svgColor?: {
    hex?: string;
    rgba?: string;
    class?: string;
    themeColor?: 'primary' | 'secondary';
  };
}

const Picture: React.FC<Props> = ({
  imageSource,
  className,
  height,
  width,
  quality = 35,
  devicePixelRatio = Math.min(2, Math.round(window.devicePixelRatio)),
  altTag = '',
  onClick,
  showSpinner = false,
  fm,
  fit,
  svgColor
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const colors = useRecoilValue(currentColorsState);

  useEffect(() => {
    setIsLoading(true);
  }, [imageSource]);

  const formatFromExtension = imageSource.substring(
    imageSource.lastIndexOf('.') + 1
  );

  const queryParams = useMemo(() => {
    const params: string[] = [];

    if (!fm && formatFromExtension === 'svg') {
      fm = 'svg';
    }

    if (height) params.push(`h=${height}`);
    if (width) params.push(`w=${width}`);
    if (quality) params.push(`q=${quality}`);
    if (devicePixelRatio) params.push(`dpr=${devicePixelRatio}`);
    if (!fm) params.push(`auto=format`);
    if (fit) params.push(`fit=${fit}`);

    return params.join('&');
  }, [imageSource, height, width, quality, devicePixelRatio]);

  const onLoad = () => {
    setIsLoading(false);
  };

  const feColorMatrixValue = useMemo(() => {
    if (formatFromExtension === 'svg' && svgColor) {
      if (svgColor.themeColor === 'primary' && colors?.primary) {
        return getMatrixFromHex(colors.primary);
      } else if (svgColor.themeColor === 'secondary' && colors?.secondary) {
        return getMatrixFromHex(colors.secondary);
      } else if (svgColor.hex) {
        return getMatrixFromHex(svgColor.hex);
      } else if (svgColor.rgba) {
        return getMatrixFromRGBA(svgColor.rgba);
      } else if (svgColor.class) {
        return getMatrixFromStyle(svgColor.class);
      }
    }
  }, [svgColor, colors]);

  const uniqueId = useMemo(() => {
    if (formatFromExtension === 'svg') {
      return uuid();
    }
  }, [formatFromExtension]);

  return (
    <>
      {isLoading && showSpinner && <Spinner center={true} />}
      <div className={className} onClick={onClick}>
        {fm === 'svg' || (!fm && formatFromExtension === 'svg') ? (
          <svg width={width || '100%'} height={height}>
            {feColorMatrixValue && (
              <defs>
                <filter id={uniqueId}>
                  <feColorMatrix
                    colorInterpolationFilters="sRGB"
                    in="SourceGraphic"
                    type="matrix"
                    values={feColorMatrixValue}
                  ></feColorMatrix>
                </filter>
              </defs>
            )}
            <image
              href={`${imageSource}?${queryParams}&fm=svg+xml`}
              width={width || '100%'}
              height={height}
              filter={feColorMatrixValue ? `url(#${uniqueId})` : undefined}
            />
          </svg>
        ) : (
          <picture onError={onLoad} onLoad={onLoad}>
            <source
              type={`image/webp`}
              srcSet={`${imageSource}?${queryParams}&fm=webp`}
            />
            {fm === 'png' ? (
              <source
                type={`image/png`}
                srcSet={`${imageSource}?${queryParams}&fm=png`}
              />
            ) : (
              <source
                type={`image/jpeg`}
                srcSet={`${imageSource}?${queryParams}&fm=jpeg`}
              />
            )}
            <img alt={altTag} src={`${imageSource}?${queryParams}`} />
          </picture>
        )}
      </div>
    </>
  );
};

export default Picture;
