import React from 'react';
import { useRecoilValue } from 'recoil';
import { tableTypeState } from '../store/gastronomy';
import LinkWrapper from './linkWrapper';
import Picture from './Picture';

interface Props {
  gastronomy: GastronomyFragment;
  useLink?: boolean;
  subTitleOverride?: string;
  showAdress?: boolean;
}

const GastronomyBanner: React.FC<Props> = ({
  gastronomy,
  useLink = true,
  subTitleOverride,
  showAdress = true
}) => {
  const selectedTableType = useRecoilValue(tableTypeState);
  const link: GetsbyRouter = {
    to:
      `/:urlPathId/speisekarte` +
      (selectedTableType ? `?tt=${selectedTableType}` : ''),
    params: { urlPathId: gastronomy.url_path_id }
  };
  const phone =
    gastronomy && gastronomy.phone ? gastronomy.phone.replace(/\s/g, '') : null;

  return (
    <div className="flex w-full">
      {gastronomy.logo_image && (
        <LinkWrapper useLink={useLink} external={false} target={link}>
          <div className="mt-1">
            <div className="flex items-center w-12 h-12 mr-4 overflow-hidden rounded-full">
              <Picture
                className="w-full h-auto"
                imageSource={gastronomy.logo_image}
                width={48}
                quality={75}
                // fm="png"
              />
            </div>
          </div>
        </LinkWrapper>
      )}
      <div>
        <LinkWrapper useLink={useLink} external={false} target={link}>
          <div className="text-xl font-bold">{gastronomy.name}</div>
          <p className="text-base text-secondary-200">
            {subTitleOverride || gastronomy.type}
          </p>
        </LinkWrapper>
        {showAdress && (
          <div className="my-4">
            <LinkWrapper
              useLink={useLink}
              external={true}
              target={{
                to: `https://www.google.com/maps/search/?api=1&query=${
                  gastronomy.lat
                },${gastronomy.lng}${
                  gastronomy.place_id
                    ? '&query_place_id=' + gastronomy.place_id
                    : ''
                }`
              }}
            >
              <div className="flex items-center text-secondary-200">
                <i className="mr-1 text-sm material-icons">place</i>
                <p className="text-base">
                  {gastronomy.street}, {gastronomy.zip} {gastronomy.city}
                </p>
              </div>
            </LinkWrapper>

            {phone && (
              <LinkWrapper
                useLink={useLink}
                external={true}
                target={{ to: `tel:${phone}` }}
              >
                <div className="flex items-center text-secondary-200">
                  <i className="mr-1 text-sm material-icons">phone</i>
                  <p className="text-base">{phone}</p>
                </div>
              </LinkWrapper>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GastronomyBanner;
