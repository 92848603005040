import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { cartItemsState } from '../store/cart';

interface Props {
  productUuid: string;
  className?: string;
  onIncrease: () => void;
}

const ProductPlusMinus: React.FC<Props> = ({
  className,
  productUuid,
  onIncrease
}) => {
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  // const { trackEvent } = useMatomo();

  const quantity = useMemo(() => {
    if (!productUuid || !cartItems) {
      return 0;
    }

    return cartItems
      .filter((item) => item.product.uuid === productUuid)
      .reduce((prev, item) => prev + item.quantity, 0);
  }, [productUuid, cartItems]);

  // const increase = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();

  //   onIncrease();
  // };

  // const decrease = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();

  //   trackEvent({
  //     category: 'Product',
  //     action: 'Decrease'
  //   });

  //   const index = cartItems.findIndex(
  //     (item) => item.product.uuid === productUuid
  //   );

  //   if (index === -1) {
  //     return;
  //   }

  //   if (cartItems[index].quantity > 1) {
  //     setCartItems([
  //       ...cartItems.slice(0, index),
  //       {
  //         ...cartItems[index],
  //         quantity: cartItems[index].quantity - 1
  //       },
  //       ...cartItems.slice(index + 1)
  //     ]);
  //   } else {
  //     setCartItems([
  //       ...cartItems.slice(0, index),
  //       ...cartItems.slice(index + 1)
  //     ]);
  //   }
  // };

  return (
    <div
      style={{ width: '16px', height: '16px' }}
      className={`flex items-center justify-center mt-1 font-semibold border rounded-sm border-primary-500 ${
        quantity === 0
          ? ' text-primary-500 '
          : ' bg-primary-500 text-secondary-500 text-sm'
      } ${className} product-plus-minus`}
    >
      {quantity === 0 ? (
        <i className="text-sm material-icons">add</i>
      ) : (
        quantity
      )}
    </div>
  );

  // return (
  //   <div
  //     className={`flex flex-col items-center ${className} product-plus-minus`}
  //   >
  //     {quantity === 0 ? (
  //       <i className="material-icons">add_box</i>
  //     ) : (
  //       <>
  //         <i className="material-icons" onClick={increase}>
  //           add_box
  //         </i>
  //         {quantity && <span className="mx-2">{quantity}</span>}
  //         <i className="material-icons" onClick={decrease}>
  //           indeterminate_check_box
  //         </i>
  //       </>
  //     )}
  //   </div>
  // );
};

export default ProductPlusMinus;
