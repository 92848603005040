import React from 'react';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { MenuSection, MenuItem, Offer, Restaurant } from 'schema-dts';

interface Props {
  gastronomy?: GastronomyFragment;
  productGroups?: ProductGroupInclProductFragment[];
}

const RestaurantMenuHtmlHeader: React.FC<Props> = ({
  gastronomy,
  productGroups
}: Props) => {
  if (gastronomy && productGroups) {
    const url = `https://getsby.at/${gastronomy.url_path_id}/speisekarte`;
    return (
      <Helmet
        script={[
          helmetJsonLdProp<Restaurant>({
            '@context': 'https://schema.org',
            '@type': 'Restaurant',
            image: [gastronomy.header_image ? gastronomy.header_image : ''],
            '@id': url,
            menu: url,
            name: gastronomy.name,
            logo: gastronomy.logo_image,
            servesCuisine: gastronomy.type,
            url,
            address: {
              '@type': 'PostalAddress',
              streetAddress: gastronomy.street,
              addressLocality: gastronomy.city,
              postalCode: gastronomy.zip
            },
            hasMenu: {
              '@type': 'Menu',
              name: gastronomy.name,
              image: gastronomy.header_image,
              hasMenuSection: productGroups.map(
                (productGroup) =>
                  ({
                    '@type': 'MenuSection',
                    name: productGroup.title,
                    hasMenuItem: productGroup.products
                      ? productGroup.products.map((product) => {
                          // const menuAddOn: MenuItem[] = [];
                          // if (product.modifier) {
                          //   for (const productModifier of product.modifier) {
                          //     menuAddOn.push({ '@type': 'MenuItem' });
                          //   }
                          // }

                          return {
                            '@type': 'MenuItem',
                            name: product.title,
                            description: product.description,
                            offers: {
                              '@type': 'Offer',
                              price: product.gross_price
                                ? (product.gross_price / 100).toFixed(2)
                                : undefined,
                              priceCurrency: 'EUR'
                            } as Offer
                            // menuAddOn,
                          } as MenuItem;
                        })
                      : undefined
                  } as MenuSection)
              )
            }
          })
        ]}
      ></Helmet>
    );
  } else {
    return null;
  }
};

export default RestaurantMenuHtmlHeader;
