import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AvailabilityMixin } from '../helpers/availability';
import { getReadableTimesFromMixin } from '../helpers/serviceTimes';
import { preorderSlotState } from '../store/cart';
import OpeningTimes from './openingTimes';

interface Props {
  productGroup: ProductGroupFragment;
  availability?: AvailabilityMixin;
  canOrder?: boolean;
  isActive?: boolean;
  className?: string;
}

const ProductGroupHeader: React.FC<Props> = ({
  productGroup,
  availability,
  canOrder = true,
  isActive = false,
  className
}: Props) => {
  const [readableOpeningTimes, setReadableOpeningTimes] =
    useState<ReadableOpeningTimes[]>();
  const preorderSlot = useRecoilValue(preorderSlotState);

  useEffect(() => {
    if (availability) {
      setReadableOpeningTimes(getReadableTimesFromMixin(availability));
    }
  }, [availability]);

  const nowAvailable =
    availability === undefined ||
    availability.timerangeAt(preorderSlot ? preorderSlot.from : new Date())
      .length > 0;

  return (
    <div
      className={`flex items-center justify-between w-full mx-2 ${className} product-group-list-item-header`}
    >
      <div className="flex flex-col">
        <h2
          className={`flex text-xl lg:text-2xl break-words ${
            nowAvailable ? 'text-secondary-500' : 'text-secondary-200'
          }`}
        >
          {productGroup.alias || productGroup.title}
        </h2>
        {availability && readableOpeningTimes && (
          <OpeningTimes
            times={readableOpeningTimes}
            containerClassName={`text-xs ${
              nowAvailable ? 'text-secondary-500' : 'text-secondary-200'
            } product-group-times`}
          />
        )}
      </div>
      <div className="flex items-center justify-center pt-1">
        <i
          className={`material-icons transition transform duration-500 ${
            !nowAvailable ? 'text-secondary-200' : ''
          } ${isActive ? 'rotate-180' : 'rotate-0'}`}
        >
          expand_more
        </i>
      </div>
    </div>
  );
};

export default ProductGroupHeader;
