import React, { HTMLAttributes } from 'react';

interface Props {
  size?: 'xs' | 'small' | 'medium' | 'large';
  className?: HTMLAttributes<HTMLDivElement>['className'];
  center?: boolean;
}

const Spinner: React.FC<Props> = ({
  center,
  className,
  size = 'medium'
}: Props) => {
  const styles = {
    xs: 'border-2 w-4 h-4 mx-2',
    small: 'border-2 w-8 h-8 m-4',
    medium: 'border-4 w-16 h-16 m-8',
    large: 'border-8 w-32 h-32 m-8'
  };

  return (
    <div className={`${center ? 'flex justify-center' : ''} ${className}`}>
      <div
        className={`loader self-center ease-linear rounded-full border-primary-500 ${styles[size]}`}
        style={{ borderRightColor: 'transparent' }}
      />
    </div>
  );
};

export default Spinner;
