import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { isMenuOnlySelector, selectedTableState } from '../store/gastronomy';
import InfoCard from './infoCard';
import LocationButton from './locationButton';
import PreorderDateSelectButton from './preorderDateSelectButton';

const RestaurantMenuInfoCards: React.FC = () => {
  const selectedTable = useRecoilValue(selectedTableState);
  const isMenuOnly = useRecoilValue(isMenuOnlySelector);
  const isDelivery = useMemo(
    () => selectedTable && selectedTable.table_type === 'DELIVERY',
    [selectedTable]
  );
  const hasPreOrder = useMemo(
    () => selectedTable && selectedTable.allow_preorder,
    [selectedTable]
  );

  if (isMenuOnly) {
    return null;
  }

  return (
    <div className="flex flex-wrap items-center w-full">
      {isDelivery && <InfoCard icon="place" content={<LocationButton />} />}
      {hasPreOrder && (
        <InfoCard icon="more_time" content={<PreorderDateSelectButton />} />
      )}
    </div>
  );
};

export default RestaurantMenuInfoCards;
