import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { captureError } from '../helpers/error';
import { getPaymentProviderImagePath } from '../helpers/order';

interface Props {
  receipt: FullReceiptFragment;
}

const PaymentDetails: React.FC<Props> = ({ receipt }: Props) => {
  const [paymentDetails, setPaymentDetails] =
    useState<PaymentProvider | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (receipt && receipt.payment_provider_json) {
      try {
        const result = JSON.parse(
          receipt.payment_provider_json
        ) as PaymentProvider;

        if (result) {
          setPaymentDetails(result);
        }
      } catch (error) {
        console.log(error);
        captureError(error);
      }
    }
  }, [receipt]);

  return (
    <React.Fragment>
      {receipt.payment_type && (
        <div className="flex items-center">
          <img
            src={getPaymentProviderImagePath(
              receipt.payment_type,
              paymentDetails
            )}
            className="w-8 h-8 mr-2 lg:mr-4"
            alt=""
          />
          {paymentDetails && paymentDetails.last4 && (
            <span>...{paymentDetails.last4}</span>
          )}
          {receipt.payment_type === 'CASH' && (
            <span>{t('payment.methods.CASH')}</span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PaymentDetails;
