import React, { useState } from 'react';
import Heading from '../components/heading';
import { useTranslation } from 'react-i18next';
import { Title } from 'react-head';
import BonusProgramPoints from '../components/bonusProgramPoints';
import BonusProgramTransactions from '../components/bonusProgramTransactions';
import {
  GetBonusProgramPoints,
  GetBonusProgramRedemptions,
  GetBonusProgramTransactions,
  GetGroupedBonusProgramPoints
} from '../services/graphql/operations';
import { useQuery } from '@apollo/client';
import BonusProgramRedemptions from '../components/bonusProgramRedemptions';
import GetsbyRedirect from '../components/getsbyRedirect';

interface Props {
  urlPathId?: string;
  appUrlPathId?: string;
}

const BonusProgram: React.FC<Props> = ({ urlPathId, appUrlPathId }) => {
  const { t } = useTranslation();
  const [redirectTo404, setRedirectTo404] = useState<boolean>(false);

  const { data: bonusProgramPointsData, loading: bonusProgramPointsLoading } =
    useQuery<GetBonusProgramPointsQuery, GetBonusProgramPointsQueryVariables>(
      GetBonusProgramPoints,
      {
        fetchPolicy: 'network-only',
        skip: !urlPathId && !appUrlPathId,
        variables: {
          params: {
            url_path_id: urlPathId,
            app_url_path_id: appUrlPathId
          }
        },
        onCompleted: (data) => {
          if (!data.getBonusProgramPoints) {
            setRedirectTo404(true);
          }
        }
      }
    );

  const {
    data: groupedBonusProgramPointsData,
    loading: groupedBonusProgramPointsLoading
  } = useQuery<
    GetGroupedBonusProgramPointsQuery,
    GetGroupedBonusProgramPointsQueryVariables
  >(GetGroupedBonusProgramPoints, {
    fetchPolicy: 'network-only',
    skip: !!urlPathId || !!appUrlPathId
  });

  const {
    data: bonusProgramTransactionsData,
    loading: bonusProgramTransactionsLoading
  } = useQuery<
    GetBonusProgramTransactionsQuery,
    GetBonusProgramTransactionsQueryVariables
  >(GetBonusProgramTransactions, {
    fetchPolicy: 'network-only',
    variables: {
      params: { url_path_id: urlPathId, app_url_path_id: appUrlPathId }
    }
  });

  const { data: openBonusProgramRedemptionsData } = useQuery<
    GetBonusProgramRedemptionsQuery,
    GetBonusProgramRedemptionsQueryVariables
  >(GetBonusProgramRedemptions, {
    variables: { params: { filter: 'OPEN' } }
  });

  const getHeaderText = () => {
    if (bonusProgramPointsData) {
      return `${
        bonusProgramPointsData?.getBonusProgramPoints?.bonus_program
          ?.bonus_program_name || t('bonusProgram.genericName')
      }`;
    }

    return t('navigation.bonus');
  };

  if (redirectTo404) {
    return <GetsbyRedirect to="/404" />;
  }

  return (
    <div className="w-full p-4">
      <Title>getsby - Bonusprogramm</Title>
      <Heading text={getHeaderText()} />
      <BonusProgramPoints
        bonusProgramPoints={bonusProgramPointsData?.getBonusProgramPoints}
        groupedBonusProgramPoints={
          groupedBonusProgramPointsData?.getGroupedBonusProgramPoints
        }
        isLoading={
          bonusProgramPointsLoading || groupedBonusProgramPointsLoading
        }
      />
      <BonusProgramTransactions
        bonusProgramTransactions={
          bonusProgramTransactionsData?.getBonusProgramTransactions
        }
        isLoading={bonusProgramTransactionsLoading}
      />
      <BonusProgramRedemptions
        bonusProgramRedemptions={
          openBonusProgramRedemptionsData?.getBonusProgramRedemptions
        }
      />
    </div>
  );
};

export default BonusProgram;
