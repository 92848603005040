import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from './alert';
import { UpdateNewsletterSubscription } from '../services/graphql/operations';
import Button from './button';
import { captureError } from '../helpers/error';
import { useMutation } from '@apollo/client';
import { SubmitHandler, useForm } from 'react-hook-form';

type FormValues = {
  emailSubscription: boolean;
  pushSubscription: boolean;
};

interface Props {
  subscription: NewsletterSubscriptionFragment;
}

const NewsletterSubscriptionUpdateForm: React.FC<Props> = ({
  subscription
}) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      emailSubscription: subscription.email || false,
      pushSubscription: subscription.push || false
    }
  });

  const [updateSubscriptionMutation, { loading }] = useMutation<
    UpdateNewsletterSubscriptionMutation,
    UpdateNewsletterSubscriptionMutationVariables
  >(UpdateNewsletterSubscription);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const input: MutationUpdateNewsletterSubscriptionArgs['input'] = {
        uuid: subscription.uuid,
        email: data.emailSubscription,
        push: data.pushSubscription
      };

      await updateSubscriptionMutation({ variables: { input } });

      setIsSuccess(true);
    } catch (error) {
      console.log('Error', error);
      captureError(error);
    }
  };

  return (
    <>
      {isSuccess && !loading && (
        <Alert
          type="success"
          title={t('alerts.success')}
          message={t('newsletter.subscriptionUpdated')}
        />
      )}
      <div className="mb-4">
        {t('newsletter.subscriptionChannels', { name: subscription.name })}:
      </div>
      <div className="w-full max-w-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`flex flex-row items-center w-full mb-4`}>
            <label className="font-bold">
              <input
                className="flex-shrink-0 mr-2 leading-tight"
                type="checkbox"
                {...register('emailSubscription')}
              />

              <span className="text-sm">Email</span>
            </label>
          </div>
          <div className={`flex flex-row items-center w-full mb-4`}>
            <label className="font-bold">
              <input
                className="flex-shrink-0 mr-2 leading-tight"
                type="checkbox"
                {...register('pushSubscription')}
              />

              <span className="text-sm">Push</span>
            </label>
          </div>
          <Button
            type="primary"
            classes="w-full"
            buttonType="submit"
            disabled={loading}
          >
            {t('help.send')}
          </Button>
        </form>
      </div>
    </>
  );
};

export default NewsletterSubscriptionUpdateForm;
