import React, { useEffect } from 'react';
import { GetLastReceipts } from '../services/graphql/operations';
import LastOrdersListItem from '../components/lastOrdersListItem';
import Alert from '../components/alert';
import { useTranslation } from 'react-i18next';
import Heading from '../components/heading';
import Spinner from '../components/spinner';
import { useQuery } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { userIsAuthenticatedState } from '../store/user';
import { Title } from 'react-head';
import { ReactComponent as Icon } from './../assets/icons/empty_cart.svg';
import { getAppIdFromUserAgent } from '../helpers/app';

const LastOrders: React.FC = () => {
  const { t } = useTranslation();
  const userIsAuthenticated = useRecoilValue(userIsAuthenticatedState);

  const appId = getAppIdFromUserAgent();
  const isWhiteLabel =
    (appId && !appId.startsWith('by.gets.clientapp')) || false;

  const { data, loading, error, refetch } = useQuery<
    GetLastReceiptsQuery,
    GetLastReceiptsQueryVariables
  >(GetLastReceipts, {
    variables: {
      params: {
        app_id: appId && isWhiteLabel ? appId : undefined,
        status: [
          'ERROR',
          'PENDING_RECEIVED',
          'RECEIVED',
          'PENDING_ACCEPTED',
          'ACCEPTED',
          'READY',
          'COMPLETED'
        ]
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    refetch();
  }, [userIsAuthenticated]);

  return (
    <div className="w-full p-4">
      <Title>getsby - deine Bestellungen</Title>
      <>
        <Heading text={t('navigation.myOrders')} />
        {error && (
          <Alert
            type="error"
            title="Fehler"
            message={error.name + ' ' + error.message}
          />
        )}
        {loading ? (
          <Spinner center={true} />
        ) : (
          <React.Fragment>
            {data &&
              (data.getClientReceipts.length > 0 ? (
                data.getClientReceipts.map((receipt) => (
                  <LastOrdersListItem receipt={receipt} key={receipt.uuid} />
                ))
              ) : (
                <>
                  <Alert
                    type="info"
                    title={t('alerts.info')}
                    message={t('invoice.listEmpty')}
                  />
                  <div className="flex items-center justify-center">
                    <Icon className="w-64 h-auto m-8 text-primary-500" />
                  </div>
                </>
              ))}
          </React.Fragment>
        )}
      </>
    </div>
  );
};

export default LastOrders;
