import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GetBonusProgramPoints } from '../services/graphql/operations';
import { gastronomyState } from '../store/gastronomy';
import {
  availableBonusProgramPointsState,
  userIsAuthenticatedState
} from '../store/user';
import BonusProgramBadge from './bonusProgramBadge';
import {
  getAppIdFromUserAgent,
  getUrlPathIdFromAppId,
  isWhitelabelApp
} from '../helpers/app';
import GetsbyLink from './getsbyLink';

interface Props {
  className?: string;
  renderAsText?: boolean;
}

const BonusProgramBadgeWrapper: React.FC<Props> = ({
  className,
  renderAsText = false
}) => {
  const gastronomy = useRecoilValue(gastronomyState);
  const isAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const [availableBonusProgramPoints, setAvailableBonusProgramPoints] =
    useRecoilState(availableBonusProgramPointsState);

  const appId = getAppIdFromUserAgent();
  const isWhiteLabel = isWhitelabelApp();
  const whiteLabelUrlPathId =
    appId && isWhiteLabel ? getUrlPathIdFromAppId(appId) : null;

  const { data, loading, refetch } = useQuery<
    GetBonusProgramPointsQuery,
    GetBonusProgramPointsQueryVariables
  >(GetBonusProgramPoints, {
    variables: {
      params: { user_uuid: gastronomy?.uuid, app_id: appId || undefined }
    },
    skip: !gastronomy?.options.hasBonusProgram && !appId,
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      setAvailableBonusProgramPoints(
        data.getBonusProgramPoints ? data.getBonusProgramPoints.points : null
      ),
    onError: () => setAvailableBonusProgramPoints(null)
  });

  useEffect(() => {
    refetch();
  }, [isAuthenticated]);

  if ((!isWhiteLabel && !gastronomy?.options.hasBonusProgram) || loading) {
    return null;
  }

  if (renderAsText) {
    return (
      <>
        {availableBonusProgramPoints}{' '}
        {data?.getBonusProgramPoints?.bonus_program.bonus_program_name}
      </>
    );
  }

  return (
    <GetsbyLink
      to={
        whiteLabelUrlPathId ? '/bonus/app/:appUrlPathId' : '/:urlPathId/bonus'
      }
      params={{
        urlPathId: gastronomy?.url_path_id,
        appUrlPathId: whiteLabelUrlPathId
      }}
      className="cursor-pointer"
    >
      <BonusProgramBadge
        className={className}
        points={availableBonusProgramPoints}
        bonusProgram={data?.getBonusProgramPoints?.bonus_program}
      />
    </GetsbyLink>
  );
};

export default BonusProgramBadgeWrapper;
