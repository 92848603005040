import React from 'react';

interface Props {
  gastronomy: GastronomyFragment;
}

const GastronomyListHtmlHeader: React.FC<Props> = ({ gastronomy }: Props) => {
  return null;
};

export default GastronomyListHtmlHeader;
