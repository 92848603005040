import React from 'react';
import { useBreakpoint } from '../hooks/useBreakpoint';

const GastronomyCardLoader: React.FC = () => {
  const { breakpoint } = useBreakpoint();
  const amountOfItems = breakpoint === 'sm' ? [0, 1, 2] : [0, 1, 2, 3, 4, 5];

  const getCardLoader = (): JSX.Element => {
    return (
      <div className="relative flex flex-col justify-start w-full overflow-hidden border rounded">
        <div className="flex flex-col animate-pulse">
          <div className="w-full h-40 bg-gray-200"></div>
          <div className="flex flex-col justify-between p-4 leading-normal bg-white rounded">
            <div className="flex mb-4">
              <div className="w-12 h-12 mr-4 bg-gray-200 rounded-full"></div>
              <div className="flex flex-col">
                <div className="w-32 h-4 mb-2 bg-gray-200 rounded"></div>
                <div className="w-16 h-4 bg-gray-200 rounded"></div>
              </div>
            </div>
            <div className="w-40 h-8 mb-2 bg-gray-200 rounded"></div>
            <div className="w-32 h-8 bg-gray-200 rounded"></div>
            <div className="flex flex-wrap items-start my-4">
              <span className="inline-flex w-16 h-6 mr-2 bg-gray-200 rounded-full"></span>
              <span className="inline-flex w-16 h-6 mr-2 bg-gray-200 rounded-full"></span>
              <span className="inline-flex w-16 h-6 mr-2 bg-gray-200 rounded-full"></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {amountOfItems.map((i) => (
        <React.Fragment key={i}>{getCardLoader()}</React.Fragment>
      ))}
    </div>
  );
};

export default GastronomyCardLoader;
