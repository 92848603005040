import React from 'react';
import Heading from '../components/heading';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from './../assets/icons/page_not_found.svg';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full p-4">
      <Heading text={t('general.pageNotFound')} />
      <div className="flex items-center justify-center w-full mx-auto md:w-1/3">
        <Icon className="text-primary-500" />
      </div>
    </div>
  );
};

export default PageNotFound;
