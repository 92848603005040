import React from 'react';
import { useRecoilValue } from 'recoil';
import { selectedTableState } from '../store/gastronomy';
import Modal from './modal';
import { useRecoilState } from 'recoil';
import { preorderModalState } from '../store/cart';
import PreorderDateSelectWidget from './preorderDateSelectWidget';

const PreorderDateSelectModal: React.FC = () => {
  const selectedTable = useRecoilValue(selectedTableState);
  const [preorderModal, setPreorderModal] = useRecoilState(preorderModalState);

  if (
    selectedTable &&
    selectedTable.allow_preorder &&
    preorderModal.showModal
  ) {
    return (
      <Modal
        onClose={() => {
          setPreorderModal((modal) => ({
            showModal: false
          }));
        }}
      >
        <PreorderDateSelectWidget />
      </Modal>
    );
  }
  return null;
};

export default PreorderDateSelectModal;
