import { add, format, isFuture } from 'date-fns';
import { PREORDER_DAYS_DEFAULT } from '../constants';
import { AvailabilityMixin } from './availability';

const days = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
  'hol'
] as OpeningDay[];

export function getReadableOpeningTimes(
  openingTimes: Record<string, any>
): ReadableOpeningTimes[] {
  const combinedTimes: ReadableOpeningTimes[] = [];
  for (const day of days) {
    if (openingTimes[day] && openingTimes[day].length > 0) {
      const index = combinedTimes.findIndex((times) =>
        areOpeningTimesTheSame(times.times, openingTimes[day])
      );
      if (index > -1) {
        combinedTimes[index].days.push(day);
      } else {
        combinedTimes.push({ days: [day], times: openingTimes[day] });
      }
    }
  }
  return combinedTimes;
}

export function getReadableTimesFromMixin(
  mixin: AvailabilityMixin
): ReadableOpeningTimes[] {
  const day = new Date();
  const result: any = {};
  for (let i = 0; i < 7; i++) {
    const date = add(day, { days: i });
    const ranges = mixin.getOpeningRangesForDate(date);
    if (ranges.length > 0 && ranges[0].length > 0) {
      result[mixin.dayIndex(date)] = ranges.map((range) => {
        return {
          time_from: format(range[0], 'HHmm'),
          time_to: format(range[1], 'HHmm')
        };
      });
    } else {
      result[mixin.dayIndex(date)] = [];
    }
  }
  result['hol'] = [];
  return getReadableOpeningTimes(result);
}

function areOpeningTimesTheSame(
  a: AvailabilityTimeElement[],
  b: AvailabilityTimeElement[]
) {
  if (a && b && a.length === b.length) {
    return (
      a.findIndex(
        (value, index) =>
          value.time_from !== b[index].time_from ||
          value.time_to !== b[index].time_to
      ) === -1
    );
  }
  return false;
}

// function checkServiceTime(serviceTime?: AvailabilityTimeElement[]) {
//   return (serviceTime && serviceTime.length > 0 && serviceTime) || undefined;
// }

export function hasServiceTimes(table: TableFragment): boolean {
  if (table.effective_service_times) {
    for (const day of days) {
      const serviceTimesOfDay = table.effective_service_times[day];
      if (serviceTimesOfDay && serviceTimesOfDay.length > 0) {
        return true;
      }
    }
  }
  return false;
}

export function getLeadTime(table: {
  tableConfig?: { leadTime?: number | null };
}): number {
  if (
    table.tableConfig &&
    table.tableConfig.leadTime !== null &&
    table.tableConfig.leadTime !== undefined
  ) {
    return table.tableConfig.leadTime;
  }
  return 0;
}

export function canOrderAt(
  mixin: AvailabilityMixin,
  preorderDays = PREORDER_DAYS_DEFAULT
): 'NOW' | boolean {
  const date = new Date();
  let canOrder = false;
  for (let i = 0; i <= preorderDays; i++) {
    if (i === 0 && mixin.nowOpen().length > 0) {
      return 'NOW';
    }
    const ranges = mixin.getOpeningRangesForDate(add(date, { days: i }));
    if (ranges.length > 0 && ranges[0].length > 0) {
      if (i === 0) {
        for (const range of ranges) {
          if (range[1] && isFuture(range[1])) {
            canOrder = true;
          }
        }
      } else {
        canOrder = true;
      }
    }
  }
  return canOrder;
}

export function getEffectiveAvailability(
  effective_availability: EffectiveProductGroupAvailability[],
  selectedTable?: TableFragment | null
): AvailabilityTimes | undefined {
  if (effective_availability) {
    let index = -1;
    if (selectedTable) {
      index = effective_availability.findIndex(
        (av) => av.table_uuid === selectedTable.uuid
      );
    }
    if (index === -1) {
      index = effective_availability.findIndex((av) => av.table_uuid === 'all');
    }
    if (index !== -1) {
      const availabilityTimes = effective_availability[index].times;
      return availabilityTimes;
    }
  }
}
