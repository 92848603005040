import React from 'react';
import { useTranslation } from 'react-i18next';
import BonusProgramTransactionLineItem from './bonusProgramTransactionLineItem';
import Heading from './heading';
import Spinner from './spinner';

interface Props {
  bonusProgramTransactions?: BonusProgramTransactionFragment[];
  isLoading?: boolean;
}

const BonusProgramTransactions: React.FC<Props> = ({
  bonusProgramTransactions = [],
  isLoading = false
}) => {
  const { t } = useTranslation();

  if (!bonusProgramTransactions || bonusProgramTransactions.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Spinner center={true} />;
  }

  return (
    <>
      <Heading text={t('bonusProgram.bonusTransactions')} classes="mt-16" />
      {bonusProgramTransactions.map((bonusProgramTransaction, i) => (
        <BonusProgramTransactionLineItem
          bonusProgramTransaction={bonusProgramTransaction}
          key={i}
        />
      ))}
    </>
  );
};

export default BonusProgramTransactions;
