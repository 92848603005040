import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  cartFormErrorsState,
  isAsapOrderPossibleSelector,
  preorderRadioState,
  preorderSlotState
} from '../store/cart';
import { gastronomyState, selectedTableState } from '../store/gastronomy';
import PreorderDateSelectWidget from './preorderDateSelectWidget';

export enum PreorderRadioValue {
  'asap',
  'preorder',
  undefined
}

const PreorderDateSelectRadios: React.FC = () => {
  const selectedTable = useRecoilValue(selectedTableState);
  const gastronomy = useRecoilValue(gastronomyState);
  const [preorderSlot, setPreorderSlot] = useRecoilState(preorderSlotState);
  const cartFormErrors = useRecoilValue(cartFormErrorsState);
  const isAsapPossible = useRecoilValue(isAsapOrderPossibleSelector);
  const [selectedRadio, setSelectedRadio] = useRecoilState(preorderRadioState);
  const { t } = useTranslation();

  if (gastronomy && selectedTable && selectedTable.allow_preorder) {
    return (
      <div className="flex flex-col p-4 border-b">
        <div
          className={`font-bold text-secondary-500 mb-4 ${
            cartFormErrors.includes('preorderRadios') ? 'text-red-500' : ''
          }`}
        >
          {t('preorder.preorderTitle')}*
        </div>
        <label className="cursor-pointer">
          <input
            className="mr-4"
            id={'preorder-asap'}
            type="radio"
            name="preorderRadios"
            checked={selectedRadio === PreorderRadioValue.asap}
            onChange={({ currentTarget }) => {
              if (currentTarget.checked === true) {
                setPreorderSlot(null);
                setSelectedRadio(PreorderRadioValue.asap);
              }
            }}
            disabled={!isAsapPossible}
            required={true}
          />
          <span className={`${isAsapPossible ? '' : 'text-gray-400'}`}>
            {t('asap')}
          </span>
        </label>
        <label
          className={
            'flex flex-row items-center cursor-pointer ' +
            (cartFormErrors.includes('preorderRadios') ? 'text-red-500' : '')
          }
        >
          <input
            className="mr-4"
            id={'preorder-time'}
            type="radio"
            name="preorderRadios"
            checked={selectedRadio === PreorderRadioValue.preorder}
            onChange={({ currentTarget }) => {
              if (currentTarget.checked === true) {
                setSelectedRadio(PreorderRadioValue.preorder);
              }
            }}
            required={true}
          />

          <span>
            {t('preorder.preorder')}{' '}
            {preorderSlot &&
              `${t('for')} ${format(preorderSlot.from, 'dd.MM.yyyy H:mm')}`}
          </span>
        </label>
        {selectedRadio === PreorderRadioValue.preorder && (
          <PreorderDateSelectWidget onlyPreorder={true} />
        )}
      </div>
    );
  }
  return null;
};

export default PreorderDateSelectRadios;
