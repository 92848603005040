import i18n, { BackendModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUserLanguage } from './helpers/language';
import { loadWithRetries } from './helpers/lazyRetry';

const backend: BackendModule = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {
    /* use services and options */
  },
  read: function (language, namespace, callback) {
    console.log('requesting language', language);
    /* return resources */
    if (language.startsWith('de')) {
      loadWithRetries(import('./locales/de/translation.json'))
        .then((value) => {
          callback(null, value);
        })
        .catch((error) => {
          callback(error, false);
        });
    } else {
      loadWithRetries(import('./locales/en/translation.json'))
        .then((value) => {
          callback(null, value);
        })
        .catch((error) => {
          callback(error, false);
        });
    }
  },

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(backend)
  .init({
    // fallbackLng: {
    //   'de-AT': ['de'],
    //   'de-DE': ['de'],
    //   'en-US': ['en'],
    //   'en-UK': ['en'],
    //   default: ['de']
    // },
    fallbackLng: 'en',
    lng: getUserLanguage(),
    supportedLngs: ['de', 'en'],
    load: 'languageOnly',
    debug: false,
    keySeparator: '.', // we do not use keys in form messages.welcome
    // detection: {},
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
