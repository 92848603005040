import { useApolloClient } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deliveryAddressState, userLocationState } from '../store/user';
import {
  getAddressElement,
  isInDeliveryRadius,
  setLastSelectedLocation
} from '../helpers/location';
import { selectedTableState } from '../store/gastronomy';
import PlacesAutocomplete, {
  geocodeByAddress,
  Suggestion
} from 'react-places-autocomplete';
import useScript from 'react-script-hook';

interface Props {
  user_uuid?: string;
  onSubmit: (place?: google.maps.GeocoderResult) => void;
  checkDeliveryRadius: boolean;
  label?: string;
}

const DeliveryAddressInput: React.FC<Props> = ({
  user_uuid,
  onSubmit,
  checkDeliveryRadius,
  label
}) => {
  const { t } = useTranslation();

  const [deliveryAddress, setDeliveryAddress] =
    useRecoilState(deliveryAddressState);
  const setLocationState = useSetRecoilState(userLocationState);
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const [address, setAddress] = useState<string>(
    (deliveryAddress && deliveryAddress.formatted_address) || ''
  );
  const [lastSelectedSuggestion, setLastSelectedSuggestion] =
    useState<Suggestion>();
  const [error, setError] = useState<string>();
  const apolloClient = useApolloClient();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [googleMapsLoading, googleMapsError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=placesAutocompleteLoaded`,
    checkForExisting: true
  });

  const getCoordinatesFromPlace = (placeResult: google.maps.GeocoderResult) => {
    if (placeResult.geometry) {
      const lat: number =
        typeof placeResult.geometry.location.lat === 'function'
          ? placeResult.geometry.location.lat()
          : (placeResult.geometry.location.lat as any);
      const lng: number =
        typeof placeResult.geometry.location.lng === 'function'
          ? placeResult.geometry.location.lng()
          : (placeResult.geometry.location.lng as any);
      return { lat, lng };
    }
  };

  const getSelectedLocationFromPlace = (
    placeResult: google.maps.GeocoderResult
  ) => {
    const coordinates = getCoordinatesFromPlace(placeResult);
    if (coordinates) {
      const street = getAddressElement(placeResult, 'route');
      const streetNumber = getAddressElement(placeResult, 'street_number');
      const city = getAddressElement(placeResult, 'locality');
      const addressString =
        street && city && streetNumber
          ? street + ' ' + streetNumber + ', ' + city
          : placeResult.formatted_address;

      getAddressElement(placeResult, 'locality');
      return {
        lat: coordinates.lat,
        lng: coordinates.lng,
        formatted_address: addressString,
        selected: true
      };
    }
  };

  const handleSelect = async (
    address: string,
    placeId: string,
    suggestion: Suggestion
  ) => {
    console.log('selected item', address, placeId, suggestion);
    try {
      if (suggestion) {
        setLastSelectedSuggestion(suggestion);
        setError(undefined);
        setAddress(suggestion.formattedSuggestion.mainText);
        const geocodeResult = await geocodeByAddress(suggestion.description);
        console.log('geocode result', geocodeResult);
        if (geocodeResult.length > 0) {
          await handleGeocodingResult(geocodeResult[0]);
        }
      } else {
        const geocodeResult = await geocodeByAddress(
          address +
            (lastSelectedSuggestion
              ? ', ' + lastSelectedSuggestion.formattedSuggestion.secondaryText
              : '')
        );
        console.log('geocode from address', geocodeResult);
        if (geocodeResult.length > 0) {
          await handleGeocodingResult(geocodeResult[0]);
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGeocodingResult = async (
    geocodeResult: google.maps.GeocoderResult
  ) => {
    const strNumber = getAddressElement(geocodeResult, 'street_number');
    const coordinates = getCoordinatesFromPlace(geocodeResult);
    console.log('coordinates', coordinates);
    if (strNumber) {
      if (user_uuid && coordinates && checkDeliveryRadius) {
        const tableInRadius = await isInDeliveryRadius(
          apolloClient,
          coordinates.lat,
          coordinates.lng,
          user_uuid
        );
        if (tableInRadius) {
          setSelectedTable(tableInRadius);
        } else {
          setError('notInRadius');
          return;
        }
      }
      const selectedLocation = getSelectedLocationFromPlace(geocodeResult);
      console.log('selectedLocation', selectedLocation);
      if (selectedLocation) {
        setLastSelectedLocation(selectedLocation);
        setLocationState(selectedLocation);
        setDeliveryAddress((current) => {
          return { ...current, ...geocodeResult };
        });
        if (onSubmit !== undefined) {
          onSubmit(geocodeResult);
        }
      }
    } else {
      setError('enterAddressInclStreetNumber');
      if (inputRef.current) {
        if (
          inputRef.current.value.substring(
            inputRef.current.value.length - 2
          ) !== ' '
        ) {
          inputRef.current.value = inputRef.current.value + ' ';
        }
        inputRef.current.focus();
      }
    }
  };

  const handleManualSubmit = async () => {
    if (inputRef.current && inputRef.current.value.length > 2) {
      try {
        // setLoading(true);
        setError(undefined);
        const geocodeResult = await geocodeByAddress(inputRef.current.value);
        console.log('geocode from address', geocodeResult);
        if (geocodeResult.length > 0) {
          await handleGeocodingResult(geocodeResult[0]);
          // setLoading(false);
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const getGeoPosition = () => {
    if (navigator.geolocation) {
      // setLoading(true);
      setError(undefined);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('geo position', position);
          // setPositionToState(position);
          if (window.google && window.google.maps) {
            const geocoder = new window.google.maps.Geocoder();
            const latLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            geocoder.geocode({ location: latLng }, async (result, status) => {
              console.log('reverse geocoding result', result, status);
              if (result && result.length > 0) {
                await handleGeocodingResult(result[0]);
                // setLoading(false);
              }
            });
          }
        },
        (error) => {
          console.log('getcurrrentposition error', error);
          // setLoading(false);
          setError('geoLocationFailed');
          // setPositionError(error);
        },
        { enableHighAccuracy: true, maximumAge: 3000, timeout: 10000 }
      );
    }
  };

  const searchOptions = {
    types: ['address'],
    componentRestrictions: { country: 'at' }
  };

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        //@ts-ignore
        onSelect={handleSelect}
        googleCallbackName="placesAutocompleteLoaded"
        searchOptions={searchOptions}
        highlightFirstSuggestion={true}
        shouldFetchSuggestions={address.length > 2}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="relative inline-flex flex-col justify-center w-full">
              <div className="flex">
                <label
                  className="font-bold text-secondary-500"
                  htmlFor="maps-input-field"
                >
                  {label}
                </label>
                <div className="relative w-full">
                  <input
                    id="maps-input-field"
                    ref={inputRef}
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: `location-search-input block w-full px-4 h-10 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500`
                    })}
                  />
                  <button
                    className="absolute top-0 right-0 px-1 py-2"
                    onClick={(evt) => {
                      evt.preventDefault();
                      if (inputRef.current) {
                        inputRef.current.value = '';
                        inputRef.current.focus();
                      }
                    }}
                  >
                    <i className="material-icons text-secondary-200">close</i>
                  </button>
                </div>

                <button
                  className="flex items-center justify-center w-10 h-10 ml-2 border rounded-sm cursor-pointer border-grey-300"
                  onClick={(evt) => {
                    evt.preventDefault();
                    handleManualSubmit();
                  }}
                >
                  <span className="text-base material-icons">done</span>
                </button>
                <button
                  className="flex items-center justify-center w-10 h-10 ml-2 bg-white border rounded"
                  onClick={(evt) => {
                    evt.preventDefault();
                    getGeoPosition();
                  }}
                >
                  <span className="text-base material-icons">gps_fixed</span>
                </button>
              </div>
              <div className={''}>
                {error && (
                  <div className="px-2 pt-1 pb-1 text-xs bg-red-500">
                    {t(['location.errors.' + error, 'errorWithMessage'], {
                      error
                    })}
                  </div>
                )}
                <ul
                  className={
                    'absolute w-full ' +
                    (suggestions && suggestions.length > 0
                      ? 'pt-2 bg-white border-l border-r border-secondary-100'
                      : '')
                  }
                >
                  {loading && (
                    <li className="py-2 pl-2 text-xs text-secondary-200">
                      Loading...
                    </li>
                  )}
                  {suggestions.map((suggestion) => {
                    const className =
                      'cursor-pointer hover:bg-secondary-100 hover:text-secondary-500' +
                      suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';

                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <li
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                        key={suggestion.index.toString()}
                      >
                        <span className="flex items-center py-2 border-b border-secondary-100">
                          <i
                            className={
                              'material-icons ' +
                              (suggestion.active
                                ? 'text-primary-500'
                                : 'text-secondary-100')
                            }
                          >
                            place
                          </i>

                          <span className="text-sm font-bold">
                            {suggestion.formattedSuggestion.mainText},&nbsp;
                          </span>
                          <span className="text-xs text-secondary-300">
                            {suggestion.formattedSuggestion.secondaryText}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};

export default DeliveryAddressInput;
