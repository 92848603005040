const envProd = {
  stage: 'production',
  graphqlEndpoint: '/api/v3/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:6c1bf450-cfad-4cdb-aa01-20bf80b9bd1a',
  userPoolId: 'eu-central-1_ZRmQQzc4G',
  userPoolWebClientId: 'b0ogqp1jve5dnbfoamhrjkiqv',
  s3UploadBucket: 'getsby-app-production',
  sentryDsn:
    'https://98eaa8265ed942c0bdca8209da3fba57@o220993.ingest.sentry.io/5378053',
  matomoSiteId: 3,
  host: 'https://getsby.at',
  oauthDomain: 'getsby.auth.eu-central-1.amazoncognito.com',
  dashboardHost: 'https://admin.gets.by',
  rnUserPoolWebClientId: '2ci7cs9cphb3pk447339q21b9r',
  appleSignInClientId: 'by.gets.clientapp.auth',
  appleSignInReturnUrl: 'https://getsby.at/auth/signin',
  primaryColor: '#f0c414',
  secondaryColor: '#919090'
};

const envDev = {
  stage: 'dev',
  graphqlEndpoint: '/api/v3/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:541ebaf6-bbd2-4383-81be-ff58904831a1',
  userPoolId: 'eu-central-1_RETO432t8',
  userPoolWebClientId: 'tvdmgll5t21pmlpvmhaa84f2p',
  s3UploadBucket: 'getsby-app-dev',
  sentryDsn:
    'https://98eaa8265ed942c0bdca8209da3fba57@o220993.ingest.sentry.io/5378053',
  matomoSiteId: 1,
  host: 'https://dev.web.gets.by',
  oauthDomain: 'getsby-dev.auth.eu-central-1.amazoncognito.com',
  dashboardHost: 'https://dev.admin.gets.by',
  rnUserPoolWebClientId: '3u315asmva3n601cbpoimlq73k',
  appleSignInClientId: 'by.gets.clientapp.dev.auth',
  appleSignInReturnUrl: 'https://dev.web.gets.by/auth/signin',
  primaryColor: '#f0c414',
  secondaryColor: '#919090'
};

const envDemo = {
  stage: 'demo',
  graphqlEndpoint: '/api/v3/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:dece8f0a-07f4-4135-bf56-ce8188b29494',
  userPoolId: 'eu-central-1_6PvnyTilK',
  userPoolWebClientId: 'ungqfrqca7024lkuvm024l0b0',
  s3UploadBucket: 'getsby-app-demo',
  sentryDsn:
    'https://98eaa8265ed942c0bdca8209da3fba57@o220993.ingest.sentry.io/5378053',
  matomoSiteId: 2,
  host: 'https://demo.web.gets.by',
  oauthDomain: 'getsby-demo.auth.eu-central-1.amazoncognito.com',
  dashboardHost: 'https://demo.admin.gets.by',
  rnUserPoolWebClientId: '3ch401vmvnjpnaeff42e366dvp',
  appleSignInClientId: 'by.gets.clientapp.demo.auth',
  appleSignInReturnUrl: 'https://demo.web.gets.by/auth/signin',
  primaryColor: '#22c55e',
  secondaryColor: '#919090'
};

const envLocal = {
  ...envDev,
  stage: 'local',
  graphqlEndpoint: 'http://localhost:8080/api/v3/graphql'
};

let Config = envLocal;

switch (process.env.REACT_APP_ENV) {
  case 'LOCAL':
    Config = envLocal;
    break;
  case 'DEV':
    Config = envDev;
    break;
  case 'DEMO':
    Config = envDemo;
    break;
  case 'PROD':
    Config = envProd;
    break;
  default:
    Config = envLocal;
}

export default Config;
