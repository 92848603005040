import React from 'react';
import { Link, Meta, Title } from 'react-head';

interface Props {
  gastronomy: GastronomyFragment;
}

const GastronomyHtmlHeader: React.FC<Props> = ({ gastronomy }: Props) => {
  return (
    <>
      <Title>{`Speisekarte ${gastronomy.name}`}</Title>
      <Link
        rel="canonical"
        href={`${window.location.origin}/${gastronomy.url_path_id}/speisekarte`}
      />
      <Meta property="og:title" content={'Speisekarte ' + gastronomy.name} />
      <Meta
        property="og:description"
        content={'Jetzt bei ' + gastronomy.name + ' online bestellen!'}
      />
    </>
  );
};

export default GastronomyHtmlHeader;
