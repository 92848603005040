import { TFunction } from 'i18next';

/**
 * Returns a translated Error message from a given receipt.
 *
 * @param {!FullReceiptFragment} receipt - The receipt object.
 * @param {!TFunction} t - The i18next TFunction.
 * @returns {string}
 */
export function translateReceiptError(
  receipt: FullReceiptFragment,
  t: TFunction
): string {
  let message = '';

  if (receipt.error && receipt.error.code) {
    switch (receipt.error.code) {
      case 'OUT_OF_STOCK':
        if (!receipt.orderItems) {
          message = t('error');
          break;
        }

        if (receipt.error.productUuids) {
          const productUuids = receipt.error.productUuids as string[];
          if (productUuids.length === 1) {
            const orderItem = receipt.orderItems.find(
              (value) => value.product && value.product_uuid === productUuids[0]
            );

            if (orderItem && orderItem.product) {
              if (orderItem.product_title) {
                message = t('orders.errors.outOfStock', {
                  product: orderItem.product_title
                });
              }
            } else {
              message = t('orders.errors.outOfStock', {
                product: t('product')
              });
            }
          } else {
            let productNames = '';

            for (let i = 0; i < productUuids.length; i++) {
              const orderItem = receipt.orderItems.find(
                (value) =>
                  value.product && value.product_uuid === productUuids[i]
              );

              let suffix = ', ';

              if (productUuids.length - 1 === i + 1) {
                suffix = ' ' + t('orders.errors.andJoin') + ' ';
              } else if (productUuids.length === i + 1) {
                suffix = '';
              }

              if (orderItem && orderItem.product_title) {
                if (orderItem.product_title) {
                  productNames += orderItem.product_title + suffix;
                }
              } else {
                productNames += t('product') + suffix;
              }
            }

            message = t('orders.errors.outOfStockPlural', {
              products: productNames.length ? productNames : t('someProducts')
            });
          }
        }
        break;
      case 'DECLINED_COMMENT':
        message = t('orders.errors.specialWishNotPossible', {
          wish: receipt.error.message
        });
        break;
      case 'DECLINED_WITH_MESSAGE':
        message = receipt.error.message;
        break;
      case 'CLOSING_SOON':
        message = t('orders.errors.closingSoon');
        break;
      case 'EXPIRED':
        message = t('orders.errors.notAccepted');
        break;
      case 'RESERVATION_DECLINED':
        message = t('orders.errors.reservation', {
          message: receipt.error.message
        });
        break;
      case 'PAYMENT_ERROR':
      case 'GENERIC_PAYMENT_ERROR':
        message = t('shoppingCart.orderErrors.GENERIC_PAYMENT_ERROR');
        break;
      case 'USER_CANCELLED':
        message = t('shoppingCart.orderErrors.CANCELLED');
        break;
      case 'CANCELLED_BY_ADMIN':
      case 'CANCELLED':
        message = t('orders.errors.cancelled');
        break;
      case 'TARGET_UNREACHABLE':
        message = t('shoppingCart.orderErrors.TARGET_UNREACHABLE');
        break;
      default:
        message = t([`shoppingCart.orderErrors.${receipt.error.code}`, '']);
        break;
    }
  }

  return message;
}

export const hasReceiptTranslations = (
  receipt?: FullReceiptFragment
): boolean => {
  if (!receipt) {
    return false;
  }

  if (receipt.language_code === 'de') {
    return false;
  }

  for (const orderItem of receipt.orderItems) {
    if (orderItem.product_title_translation) {
      return true;
    }
  }

  return false;
};
