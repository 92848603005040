import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userLocationState } from '../store/user';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH } from '../constants';
import { getLastSelectedLocation } from '../helpers/location';
import DeliveryAddressInput from './deliveryAddressInput';
import Alert from './alert';

type ComponentState = 'Initial' | 'Loading' | 'Manual' | 'Fixed';

const LocationIndicator: React.FC = () => {
  const [userLocation, setUserLocation] = useRecoilState(userLocationState);
  const [componentState, setComponentState] =
    useState<ComponentState>('Initial');
  const [geoError, setGeoError] = useState<GeolocationPositionError>();
  const userLocationRef = useRef(userLocation);
  userLocationRef.current = userLocation;
  const componentStateRef = useRef(componentState);
  componentStateRef.current = componentState;
  const { t } = useTranslation();

  const editSvg = (
    <i className="ml-2 text-base cursor-pointer material-icons">create</i>
  );
  const locationSvg = <i className="text-base material-icons">gps_fixed</i>;

  useEffect(() => {
    try {
      const lastKnownLocation = getLastSelectedLocation();
      if (lastKnownLocation && lastKnownLocation.selected) {
        setUserLocation(lastKnownLocation);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setPositionToState(position);
            },
            (error) => {
              console.log('getcurrrentposition error', error);
              setPositionError(error);
            },
            { enableHighAccuracy: true, maximumAge: 30000, timeout: 10000 }
          );
          // watchId = navigator.geolocation.watchPosition(
          //   (position) => {
          //     setPositionToState(position);
          //   },
          //   (error) => {
          //     console.log('error from watchPosition', error);
          //     setPositionError(error);
          //   },
          //   { enableHighAccuracy: true, maximumAge: 3000, timeout: 10000 }
          // );
        } else {
          throw new Error('geolocation unavailable');
        }
      }
    } catch (error) {
      console.log('error getting position');
      setPositionError(error);
    }
    // return () => {
    //   if (watchId) {
    //     navigator.geolocation.clearWatch(watchId);
    //   }
    // };
  }, []);

  useEffect(() => {
    if (componentState === 'Initial') {
      setTimeout(() => {
        console.log('first timeout', componentStateRef.current);
        if (
          componentStateRef.current &&
          componentStateRef.current === 'Initial'
        ) {
          setComponentState('Loading');
        }
      }, 1000);
    } else if (componentState === 'Loading') {
      setTimeout(() => {
        console.log('second timeout', componentStateRef.current);
        if (
          componentStateRef.current &&
          componentStateRef.current === 'Loading'
        ) {
          setComponentState('Manual');
        }
      }, 10000);
    }
  }, [componentState]);

  const setPositionToState = (position: GeolocationPosition) => {
    console.log('got position', position);
    if (
      position.coords.accuracy < 100 &&
      (!userLocationRef.current ||
        userLocationRef.current.lat !== position.coords.latitude ||
        userLocationRef.current.lng !== position.coords.longitude)
    ) {
      console.log('set position to state');
      const userLoc = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        selected: false
      };
      setUserLocation(userLoc);
    }
  };

  const setPositionError = (positionError: GeolocationPositionError) => {
    console.log('position error', positionError);
    setGeoError(positionError);
    setComponentState('Manual');
  };

  useEffect(() => {
    if (userLocation && userLocation.lat) {
      setComponentState('Fixed');
    }
  }, [userLocation]);

  if (componentState === 'Loading') {
    return (
      <div className="flex flex-col items-center">
        <img src={`${IMG_BASE_PATH}/EggLocation3S.gif`} width={120} />
        <div className="my-4">Wir ermitteln deine Position</div>
      </div>
    );
  } else if (componentState === 'Fixed' && userLocation) {
    return (
      <div
        className="flex flex-row items-center px-4 py-2 mb-4 text-gray-700 bg-gray-200 border border-gray-200 rounded cursor-pointer"
        onClick={() => {
          setComponentState('Manual');
          setUserLocation(null);
        }}
      >
        {!userLocation.formatted_address && locationSvg}
        <span className="">
          {userLocation.formatted_address &&
            t('location.around', { address: userLocation.formatted_address })}
          {!userLocation.formatted_address && t('location.choose')}
        </span>
        {editSvg}
      </div>
    );
  } else if (componentState === 'Manual') {
    return (
      <div className="mb-20">
        <div>{t('location.description')}</div>
        {geoError && (
          <Alert
            type="warning"
            title={t('location.error.title')}
            message={t('location.error.' + geoError.code)}
          />
        )}
        <div className="flex flex-row my-8">
          <Suspense fallback={<div>loading...</div>}>
            <DeliveryAddressInput
              onSubmit={() => null}
              checkDeliveryRadius={false}
            />
          </Suspense>
        </div>
        <div>{t('location.geolocationHint')}</div>
      </div>
    );
  } else {
    return <div className="mb-20" />;
  }
};

export default LocationIndicator;
