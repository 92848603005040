import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { emailIsValid } from '../helpers/login';
import {
  GetNewsletterSubscription,
  GetUserProfile,
  UpdateNewsletterSubscription
} from '../services/graphql/operations';
import { appConfigState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import Alert from './alert';
import Button from './button';

interface Props {
  className?: string;
}

const NewsletterSubscriptionAddForm: React.FC<Props> = ({ className }) => {
  const appConfig = useRecoilValue(appConfigState);
  const gastronomy = useRecoilValue(gastronomyState);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [userHasNoEmail, setUserHasNoEmail] = useState<boolean>(false);
  const [subscriberEmail, setSubscribrerEmail] = useState<string>('');
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const app = appConfig || gastronomy?.app;
  const app_uuid = app?.uuid;
  const user_uuid = !app_uuid ? gastronomy?.uuid : undefined;

  const hasNewsletter = useMemo(() => {
    if (app) {
      return !!app.newsletter;
    } else if (gastronomy) {
      return (
        !!gastronomy.options.enableNewsletter || !!gastronomy?.app?.newsletter
      );
    }
    return false;
  }, [gastronomy, app]);

  useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfile, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setUserHasNoEmail(!data.getUserProfile?.email)
  });

  const { loading: getNewsletterSubscriptionLoading } = useQuery<
    GetNewsletterSubscriptionQuery,
    GetNewsletterSubscriptionQueryVariables
  >(GetNewsletterSubscription, {
    variables: {
      params: {
        app_uuid,
        user_uuid
      }
    },
    skip: !hasNewsletter,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.getNewsletterSubscription) {
        return;
      }

      const subscribedEmail = data.getNewsletterSubscription.subscribedEmail;
      const isEmailSubscribed = !!data.getNewsletterSubscription.email;
      const isPushSubscribed = !!data.getNewsletterSubscription.push;
      const isEmailSubscriptionComplete = isEmailSubscribed && subscribedEmail;

      setIsChecked(
        app ? isEmailSubscribed || isPushSubscribed : isEmailSubscribed
      );

      setHideForm(
        !!(app
          ? isEmailSubscriptionComplete && isPushSubscribed
          : isEmailSubscriptionComplete)
      );
    }
  });

  const [
    updateSubscriptionMutation,
    { loading: updateNewsletterSubscriptionLoading }
  ] = useMutation<
    UpdateNewsletterSubscriptionMutation,
    UpdateNewsletterSubscriptionMutationVariables
  >(UpdateNewsletterSubscription, {
    refetchQueries: ['getUserProfile'],
    onCompleted: (data) => setIsSuccess(!!data)
  });

  const updateSubscription = async () => {
    setErrors([]);

    if (isChecked && userHasNoEmail && !emailIsValid(subscriberEmail)) {
      setErrors((e) => [...e, 'subscriber-email']);
      return;
    }

    await updateSubscriptionMutation({
      variables: {
        input: {
          app_uuid,
          user_uuid,
          email: isChecked,
          push: isChecked,
          subscribedEmail: userHasNoEmail ? subscriberEmail : undefined
        }
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  if (!hasNewsletter || hideForm) {
    return null;
  }

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {isSuccess && !updateNewsletterSubscriptionLoading && (
        <Alert
          type="success"
          title={t('alerts.success')}
          message={t('newsletter.subscriptionUpdated')}
        />
      )}
      <label className="mb-4 font-bold">
        <input
          className="flex-shrink-0 mr-2 leading-tight"
          type="checkbox"
          checked={isChecked}
          disabled={getNewsletterSubscriptionLoading}
          onChange={handleChange}
        />

        <span className="text-sm">
          {app?.newsletter_checkbox_label ||
            t('newsletter.checkboxLabel', {
              name: app ? app.name : gastronomy?.name
            })}
        </span>
      </label>
      {userHasNoEmail && (
        <label
          className={`block font-bold w-full mb-4 ${
            errors.includes('subscriber-email') ? 'text-red-500' : ''
          }`}
        >
          {t('auth.email')}
          <input
            autoFocus={true}
            type="email"
            name="email"
            v-model="email"
            id="subscriber-email"
            required={true}
            onChange={(evt) => setSubscribrerEmail(evt.target.value)}
            value={subscriberEmail}
            placeholder={t('newsletter.emailPlaceholder')}
            className={`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500 ${
              errors.includes('subscriber-email') ? 'border-red-500' : ''
            }`}
          />
        </label>
      )}
      <Button
        type="primary"
        classes="w-full"
        buttonType="submit"
        onClick={updateSubscription}
        disabled={!isChecked || updateNewsletterSubscriptionLoading}
      >
        {t('help.send')}
      </Button>
    </div>
  );
};

export default NewsletterSubscriptionAddForm;
