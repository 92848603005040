import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isWhitelabelApp } from '../helpers/app';
import { goBackShouldUseHistoryState, isMenuPageState } from '../store/app';
import { gastronomyState, tableTypeState } from '../store/gastronomy';
import GetsbyLink from './getsbyLink';

const NavBackButton: React.FC = () => {
  const history = useHistory();

  const goBackShouldUseHistory = useRecoilValue(goBackShouldUseHistoryState);
  const isMenuPage = useRecoilValue(isMenuPageState);
  const isWhiteLabel = isWhitelabelApp();

  const selectedTableType = useRecoilValue(tableTypeState);
  const gastronomy = useRecoilValue(gastronomyState);

  const navigateBack = () => {
    history.goBack();
  };

  const getNavigationPath = () => {
    if (gastronomy && !isMenuPage) {
      if (isWhiteLabel) {
        return '/';
      }

      return `/:urlPathId/speisekarte${
        selectedTableType ? `?tt=${selectedTableType}` : ''
      }`;
    }

    return '/';
  };

  if (goBackShouldUseHistory) {
    return (
      <span
        className={`w-6 h-6 block`}
        onClick={navigateBack}
        id="top-nav-back-button"
      >
        <i className="material-icons">arrow_back</i>
      </span>
    );
  } else {
    return (
      <GetsbyLink
        className={`w-6 h-6 block`}
        to={getNavigationPath()}
        params={{ urlPathId: gastronomy?.url_path_id }}
        id="top-nav-back-button"
      >
        <i className="material-icons">arrow_back</i>
      </GetsbyLink>
    );
  }
};

export default NavBackButton;
