import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH } from '../constants';
import Button from './button';
import Heading from './heading';
import Modal from './modal';
import { ReactComponent as Icon } from './../assets/icons/to_do_list.svg';
import Alert from './alert';
import { calculateCentsFromBonusProgram } from '../helpers/bonusProgramHelper';
import ScanInvoiceButton from './scanInvoiceButton';
import RedeemBonusProgramPointsForm from './redeemBonusProgramPointsForm';
import Spinner from './spinner';
import Picture from './Picture';
import GetsbyLink from './getsbyLink';
import GetsbyRedirect from './getsbyRedirect';

interface Props {
  bonusProgramPoints?: BonusProgramPointsFragment;
  groupedBonusProgramPoints?: BonusProgramPointsFragment[];
  isLoading?: boolean;
}

const BonusProgramPoints: React.FC<Props> = ({
  isLoading = false,
  bonusProgramPoints,
  groupedBonusProgramPoints
}) => {
  const { t } = useTranslation();
  const [selectedBonusProgramPoints, setSelectedBonusProgramPoints] =
    useState<BonusProgramPointsFragment | null>(null);
  const [bonusProgramPointsToRedeem, setBonusProgramPointsToRedeem] =
    useState<BonusProgramPointsFragment | null>(null);
  const [actionText, setActionText] = useState<string>(
    t('bonusProgram.collectPoints')
  );
  const [redirectTo, setRedirectTo] = useState<GetsbyRouter | null>(null);

  const handleClick =
    (bonusProgramPoints: BonusProgramPointsFragment) =>
    (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const passedThreshold =
        bonusProgramPoints.points >=
        bonusProgramPoints.bonus_program.bonus_threshold;

      if (
        !passedThreshold &&
        bonusProgramPoints.bonus_program.gastronomies.length === 1
      ) {
        setRedirectTo({
          to: '/:urlPathId/speisekarte',
          params: {
            urlPathId:
              bonusProgramPoints.bonus_program.gastronomies[0].url_path_id
          }
        });
      } else {
        setSelectedBonusProgramPoints(bonusProgramPoints);
        setActionText(
          bonusProgramPoints.points >=
            bonusProgramPoints.bonus_program.bonus_threshold
            ? t('bonusProgram.redeemNow')
            : t('bonusProgram.collectPoints')
        );
      }
    };

  const getEmptyText = (): React.ReactNode => (
    <>
      <Alert
        type="info"
        title={t('alerts.info')}
        message={t('bonusProgram.noBonusPointsCollected')}
      />
      <div className="flex items-center justify-center">
        <Icon className="w-64 h-auto m-8 text-primary-500" />
      </div>

      {bonusProgramPoints && (
        <Button
          classes="w-full flex items-center justify-center "
          type="default"
          onClick={handleClick({
            bonus_program: bonusProgramPoints.bonus_program,
            points: 0
          })}
        >
          <i className="mr-4 material-icons">shopping_cart</i>
          <span>{t('bonusProgram.collectPoints')}</span>
        </Button>
      )}
    </>
  );

  const getBonusProgramItem = (
    bonusProgramPoints: BonusProgramPointsFragment
  ): React.ReactNode => (
    <>
      <div className="p-4 mb-4 bg-white border border-gray-200 rounded select-none">
        <div className="flex flex-col items-center justify-between w-full sm:flex-row">
          <div className="flex flex-row w-full mb-4 sm:w-3/4 sm:mb-0">
            <div className="flex items-center justify-center w-12 h-12 mr-4 overflow-hidden rounded-full md:w-16 md:h-16">
              {bonusProgramPoints.bonus_program.icon ? (
                <Picture
                  imageSource={bonusProgramPoints.bonus_program.icon}
                  className="w-full h-full mx-auto"
                />
              ) : (
                <img
                  className="w-full h-full mx-auto"
                  src={`${IMG_BASE_PATH}/coin.svg`}
                  alt=""
                />
              )}
            </div>
            <div className="flex flex-col justify-center w-full">
              <div className="flex items-center text-xl font-bold">
                {bonusProgramPoints.points}{' '}
                {bonusProgramPoints.bonus_program.bonus_program_name ||
                  t('bonusProgram.genericName')}
              </div>
              <div className="text-secondary-200">
                {t('bonusProgram.inValueOf', {
                  value:
                    (
                      calculateCentsFromBonusProgram({
                        points: bonusProgramPoints.points,
                        bonusProgram: bonusProgramPoints.bonus_program
                      }) / 100
                    ).toFixed(2) + ' €'
                })}{' '}
                {t('bonusProgram.bonusThreshold')}{' '}
                {bonusProgramPoints.bonus_program.bonus_threshold}{' '}
                {t('bonusProgram.points')}
                {bonusProgramPoints.bonus_program.gastronomies && (
                  <>
                    {' '}
                    {t('bonusProgram.at')}{' '}
                    {bonusProgramPoints.bonus_program.gastronomies
                      .map((g) => g.name)
                      .join(', ')}
                    {'.'}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center w-full font-bold sm:w-1/4">
            <div className="flex flex-col w-full">
              <Button
                onClick={handleClick(bonusProgramPoints)}
                type="primary"
                classes="w-full flex items-center justify-center "
              >
                <i className="mr-4 material-icons">
                  {bonusProgramPoints.points >=
                  bonusProgramPoints.bonus_program.bonus_threshold
                    ? 'redeem'
                    : 'shopping_cart'}
                </i>
                <span>
                  {t(
                    bonusProgramPoints.points >=
                      bonusProgramPoints.bonus_program.bonus_threshold
                      ? 'bonusProgram.redeemNow'
                      : 'bonusProgram.collectPoints'
                  )}
                </span>
              </Button>
              {bonusProgramPoints.points <
                bonusProgramPoints.bonus_program.bonus_threshold && (
                <div className="w-full mt-4">
                  <div className="w-full bg-gray-200 rounded">
                    <div
                      className="py-1 rounded bg-primary-500"
                      style={{
                        width: `${
                          (bonusProgramPoints.points /
                            bonusProgramPoints.bonus_program.bonus_threshold) *
                          100
                        }%`
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (!bonusProgramPoints && !groupedBonusProgramPoints) {
    return null;
  }

  if (isLoading) {
    return <Spinner center={true} />;
  }

  if (redirectTo) {
    return <GetsbyRedirect {...redirectTo} />;
  }

  return (
    <>
      {selectedBonusProgramPoints && (
        <Modal onClose={() => setSelectedBonusProgramPoints(null)}>
          <Heading text={actionText} />
          <div className="flex flex-col w-full">
            {selectedBonusProgramPoints.points >=
              selectedBonusProgramPoints.bonus_program.bonus_threshold && (
              <p>{t('bonusProgram.redeemOnline')}</p>
            )}
            {selectedBonusProgramPoints.bonus_program.gastronomies.map(
              (g, i) => (
                <GetsbyLink
                  key={i}
                  to="/:urlPathId/speisekarte"
                  params={{ urlPathId: g.url_path_id }}
                >
                  <Button classes="w-full my-2" type="default">
                    {g.name}
                  </Button>
                </GetsbyLink>
              )
            )}
            {selectedBonusProgramPoints.points >=
              selectedBonusProgramPoints.bonus_program.bonus_threshold &&
              selectedBonusProgramPoints.bonus_program
                .enable_manual_redemption && (
                <>
                  <p className="mb-2">{t('auth.or')}</p>
                  <Button
                    type="default"
                    classes="w-full flex items-center justify-center"
                    onClick={() => {
                      setSelectedBonusProgramPoints(null);
                      setBonusProgramPointsToRedeem(selectedBonusProgramPoints);
                    }}
                  >
                    <i className="mr-4 material-icons">face</i>
                    <span>{t('bonusProgram.redeemOffline')}</span>
                  </Button>
                </>
              )}
          </div>
        </Modal>
      )}
      {bonusProgramPointsToRedeem && (
        <Modal>
          <RedeemBonusProgramPointsForm
            bonusProgramPoints={bonusProgramPointsToRedeem}
            onClose={() => setBonusProgramPointsToRedeem(null)}
          />
        </Modal>
      )}
      {groupedBonusProgramPoints ? (
        <>
          {groupedBonusProgramPoints?.length === 0 ? (
            getEmptyText()
          ) : (
            <>
              {groupedBonusProgramPoints?.map((groupedBonusProgram, i) => (
                <div key={i}>{getBonusProgramItem(groupedBonusProgram)}</div>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {bonusProgramPoints
            ? getBonusProgramItem(bonusProgramPoints)
            : getEmptyText()}
          {bonusProgramPoints?.bonus_program?.enable_invoice_scan && (
            <ScanInvoiceButton
              bonus_program_uuid={bonusProgramPoints.bonus_program.uuid}
              user_uuid={
                bonusProgramPoints.bonus_program.gastronomies.length === 1
                  ? bonusProgramPoints.bonus_program.gastronomies[0].uuid
                  : undefined
              }
              urlPathId={
                bonusProgramPoints.bonus_program.gastronomies.length === 1
                  ? bonusProgramPoints.bonus_program.gastronomies[0].url_path_id
                  : undefined
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default BonusProgramPoints;
