import React, { useEffect, useMemo, useState } from 'react';
import { GetReceiptFull } from '../services/graphql/operations';
import Spinner from '../components/spinner';
import { add, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  hasReceiptTranslations,
  translateReceiptError
} from '../helpers/ReceiptHelper';
import OrderProgress from '../components/orderProgress';
import Alert from '../components/alert';
import PaymentDetails from '../components/paymentDetails';
import Heading from '../components/heading';
import { useQuery } from '@apollo/client';
import GastronomyBanner from '../components/gastronomyBanner';
import { useHistory } from 'react-router-dom';
import { tableTypeDisplay } from '../helpers/gastronomy';
import ReceiptInvoices from '../components/receiptInvoices';
import getsby from '@getsby-devs/getsby-commons';
import OrderItem from '../components/orderItem';
import { Title } from 'react-head';
import ToggleSwitch from '../components/toggleSwitch';
import ReceiptBonusProgram from '../components/receiptBonusProgram';

interface Props {
  receiptUuid?: string;
}

const Order: React.FC<Props> = (props: Props) => {
  const [receipt, setReceipt] = useState<FullReceiptFragment>();
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const history = useHistory();
  const hasTranslations = useMemo(
    () => hasReceiptTranslations(receipt),
    [receipt]
  );

  const [showTranslations, setShowTranslations] = useState<boolean>(false);

  const receiptResult = useQuery<GetReceiptFullQuery>(GetReceiptFull, {
    variables: { uuid: props.receiptUuid }
  });

  const tableType = receipt && receipt.table_type;
  const phone =
    receipt && receipt.gastronomy && receipt.gastronomy.phone
      ? receipt.gastronomy.phone.replace(/\s/g, '')
      : null;

  useEffect(() => {
    if (!props.receiptUuid) {
      setError('Missing ID');
    } else {
      receiptResult.startPolling(5000);
    }
    return () => receiptResult.stopPolling();
  }, []);

  useEffect(() => {
    if (receiptResult.data && receiptResult.data.getReceipt) {
      const receipt = receiptResult.data.getReceipt;
      setReceipt(receipt);
      if (receipt.status === 'ERROR' || receipt.status === 'COMPLETED') {
        receiptResult.stopPolling();
      }
    }
  }, [receiptResult.data]);

  useEffect(() => {
    setShowTranslations(hasTranslations);
  }, [hasTranslations]);

  const getStatusMessage = (): React.ReactNode => (
    <>
      {receipt && (
        <div
          className={`justify-center flex flex-col py-4 text-center mb-4 -mx-4 ${
            receipt.status === 'READY'
              ? 'bg-primary-500 text-secondary-500'
              : 'bg-gray-200'
          }`}
        >
          <span className="text-4xl font-bold">
            {receipt.status ? (
              <>
                {receipt.status.startsWith('PENDING') &&
                  t(`orders.status.${receipt.status.toLowerCase()}`)}

                {receipt.status === 'RECEIVED' &&
                  t('orders.waitingForConfirmation')}

                {receipt.status === 'ACCEPTED' && t('orders.inProcess')}

                {(['READY', 'COMPLETED', 'ERROR'] as ReceiptStatus[]).includes(
                  receipt.status
                ) &&
                  `${
                    tableType &&
                    tableType !== 'DINEIN' &&
                    tableType !== 'DELIVERY'
                      ? t('pickupCode')
                      : t('order')
                  }${receipt.pickup_code ? ': ' + receipt.pickup_code : ''}`}
              </>
            ) : (
              <>
                {receipt.payment_status === 'OPEN' &&
                  t('payment.processingPayment')}
              </>
            )}
          </span>

          {receipt.requested_pickup_time && (
            <div className="flex flex-wrap items-center justify-center mt-4 font-bold">
              {tableType === 'DINEIN' &&
              receipt.gastronomy &&
              (receipt.gastronomy.options.tableOrderIsRoomService ||
                receipt.gastronomy.options.tableOrderIsEvent) ? (
                <>
                  {t('orders.preorderedFor')}:
                  <br />
                </>
              ) : (
                <>
                  {tableType === 'DELIVERY'
                    ? t('orders.estimatedDeliveryTime')
                    : t('orders.estimatedPickupTime')}
                  :<br />
                </>
              )}
              {format(
                new Date(receipt.requested_pickup_time),
                'dd.MM.yyyy HH:mm'
              )}
              {receipt.table &&
              receipt.table.tableConfig &&
              receipt.table.tableConfig.slotLength &&
              receipt.table.tableConfig.slotLength > 15
                ? format(
                    add(new Date(receipt.requested_pickup_time), {
                      minutes: receipt.table.tableConfig.slotLength
                    }),
                    ' - HH:mm'
                  )
                : ''}
            </div>
          )}

          {receipt.estimated_pickup_time && (
            <div className="flex flex-wrap items-center justify-center mt-4 font-bold">
              {tableType === 'DELIVERY'
                ? t('orders.estimatedDeliveryTime')
                : t('orders.estimatedPickupTime')}
              :<br />
              {format(
                new Date(receipt.estimated_pickup_time),
                'dd.MM.yyyy HH:mm'
              )}
            </div>
          )}
        </div>
      )}
    </>
  );

  const handleCouponCodeClick =
    (code: string) => (_event: React.MouseEvent) => {
      if (!code || location.search.includes('c=')) {
        return;
      }

      history.push({
        pathname: location.pathname,
        search: location.search
          ? location.search.concat(`&c=${code}`)
          : `?c=${code}`
      });
    };

  const groupedOrderItems = useMemo(() => {
    if (receipt?.orderItems) {
      const translatedOrderitems = showTranslations
        ? receipt.orderItems.map((oi) => {
            return {
              ...oi,
              product_title: oi.product_title_translation || oi.product_title,
              modifierItems: oi.modifierItems?.map((mi) => {
                return { ...mi, title: mi.title_translation || mi.title };
              })
            };
          })
        : receipt.orderItems;

      return getsby.groupOrderItems(
        //@ts-ignore
        translatedOrderitems
      );
    }
  }, [receipt, showTranslations]);

  const orderItemsSum = useMemo(() => {
    if (groupedOrderItems) {
      return groupedOrderItems
        .filter((oi) => !oi.is_canceled)
        .reduce((prev, oi) => prev + oi.amount * oi.price, 0);
    }
    return 0;
  }, [groupedOrderItems]);

  const tipAmount = useMemo(() => {
    if (receipt) {
      return (receipt.tip && receipt.tip.amount) || 0;
    }
    return 0;
  }, [receipt]);

  const creditAmount = useMemo(() => {
    if (receipt && receipt.receiptCredits && receipt.receiptCredits.length) {
      return receipt.receiptCredits[0].value || 0;
    }
    return 0;
  }, [receipt]);

  const bonusAmount = useMemo(() => {
    if (receipt && receipt.redeemed_bonus_amount) {
      return receipt.redeemed_bonus_amount || 0;
    }
    return 0;
  }, [receipt]);

  const totalInclTip = useMemo(() => {
    const calculatedEndSum =
      orderItemsSum + tipAmount - creditAmount - bonusAmount;

    return calculatedEndSum < 0 ? 0 : calculatedEndSum;
  }, [receipt, orderItemsSum, tipAmount, creditAmount, bonusAmount]);

  return (
    <div className="w-full p-4">
      <Title>
        Deine getsby Bestellung bei{' '}
        {receipt && receipt.gastronomy ? receipt.gastronomy.name : ''}
      </Title>
      <Heading
        text={`${t('order')}${
          receipt && receipt.pickup_code ? ': ' + receipt.pickup_code : ''
        }`}
      />
      {receiptResult.loading ? (
        <Spinner center={true} className="w-full" />
      ) : (
        <>
          {error ? (
            <Alert type="error" title={t('alerts.error')} message={error} />
          ) : (
            <>
              {receipt && (
                <div
                  className={
                    'flex flex-col justify-between leading-normal bg-white rounded' +
                    (receipt.error ? ' text-secondary-100' : '')
                  }
                >
                  {receipt.gastronomy && (
                    <div className="flex items-center">
                      <GastronomyBanner
                        gastronomy={receipt.gastronomy as GastronomyFragment}
                        showAdress={true}
                      />
                    </div>
                  )}
                  {tableType && (
                    <div className="flex flex-row items-center mb-4">
                      <span className="mr-4 text-base material-icons">
                        info
                      </span>
                      {t(
                        `tableType.${tableTypeDisplay(
                          receipt.gastronomy,
                          tableType
                        )}`
                      )}
                      {tableType === 'DINEIN' &&
                        receipt.table &&
                        receipt.gastronomy &&
                        (receipt.gastronomy.options.tableOrderIsRoomService ||
                          receipt.gastronomy.options.tableOrderIsEvent) && (
                          <> - {receipt.table.table_number}</>
                        )}
                    </div>
                  )}
                  {receipt.gastronomy &&
                    receipt.gastronomy.options.orderInformation && (
                      <div className="flex flex-row items-center mb-4">
                        <span className="mr-4 text-base material-icons">
                          info
                        </span>
                        {receipt.gastronomy.options.orderInformation}
                      </div>
                    )}
                  {tableType && tableType === 'DELIVERY' && (
                    <div className="flex flex-col mb-4">
                      {t('orders.willBeDeliveredTo')}
                      {receipt.delivery_address && (
                        <>
                          <div>{receipt.delivery_address.firstname}</div>
                          <div>{receipt.delivery_address.street}</div>
                          <div>
                            {receipt.delivery_address.zip +
                              ' ' +
                              receipt.delivery_address.city}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {receipt.error && (
                    <>
                      <Alert
                        type="error"
                        title={t('orders.status.cancelled')}
                        message={
                          <>
                            {translateReceiptError(receipt, t)}
                            <p className="mt-4 font-bold">
                              {t('orders.refundInfo')}
                            </p>
                          </>
                        }
                      />
                      {receipt.error.couponCode && (
                        <Alert
                          type="info"
                          title={t('credits.credit')}
                          message={
                            <div className="">
                              <p className="">{t('orders.couponCode')}</p>
                              <div className="my-2 text-2xl font-bold text-center">
                                {receipt.error.couponCode.code}
                              </div>
                              <span
                                onClick={handleCouponCodeClick(
                                  receipt.error.couponCode.code
                                )}
                                className="mt-2 underline cursor-pointer"
                              >
                                {t('credits.redeemNow')}
                              </span>
                            </div>
                          }
                        />
                      )}
                    </>
                  )}

                  {!receipt.error && receipt.hasCanceledOrderItems && (
                    <Alert
                      type="info"
                      title={t('orders.hasChanged')}
                      message={
                        <div>
                          <p>{t('orders.hasCanceledOrderItemsInfo')}</p>
                          <p className="mt-4 font-bold">
                            {t('orders.refundInfo')}
                          </p>
                        </div>
                      }
                    />
                  )}

                  {getStatusMessage()}

                  <OrderProgress status={receipt.status} />

                  <ReceiptBonusProgram receipt={receipt} />

                  {hasTranslations && (
                    <ToggleSwitch
                      onChange={() =>
                        setShowTranslations((current) => !current)
                      }
                      checked={showTranslations}
                      title={t('orders.showTranslations', {
                        code: receipt.language_code
                          ? `(${receipt.language_code.toUpperCase()})`
                          : ''
                      })}
                      classes="mt-8 mb-4 px-2"
                    />
                  )}

                  <table
                    id="cartItems"
                    className="w-full text-sm lg:text-base"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr className="h-12 text-base lg:text-xl">
                        <th className="hidden md:table-cell"></th>
                        <th className="text-left">{t('product')}</th>
                        <th className="text-center">
                          {t('shoppingCart.quantity')}
                        </th>
                        <th className="hidden text-right md:table-cell">
                          {t('shoppingCart.price')}
                        </th>
                        <th className="text-right">
                          {t('shoppingCart.total')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedOrderItems?.map((orderItem, index) => (
                        <OrderItem
                          orderItem={orderItem}
                          key={index.toString()}
                        />
                      ))}
                    </tbody>
                  </table>

                  <hr className="my-6" />

                  <div className="flex items-center justify-end mb-4">
                    <table className="text-right border-current table-auto w-max">
                      <tbody>
                        {tipAmount > 0 && (
                          <>
                            <tr>
                              <td className="p-2 font-bold">
                                {t('payment.subTotal') + ':'}
                              </td>
                              <td className="p-2">
                                {(orderItemsSum / 100).toFixed(2)} €
                              </td>
                            </tr>
                            <tr>
                              <td className="p-2 font-bold ">
                                {t('tip') + ':'}
                              </td>
                              <td className="p-2">
                                {(tipAmount / 100).toFixed(2)} €
                              </td>
                            </tr>
                          </>
                        )}

                        {creditAmount > 0 && (
                          <tr>
                            <td className="p-2 font-bold ">
                              {t('credits.credit') + ':'}
                            </td>
                            <td className="p-2">
                              -{(creditAmount / 100).toFixed(2)} €
                            </td>
                          </tr>
                        )}

                        {bonusAmount > 0 && (
                          <tr className="border-t-2">
                            <td className="p-2 font-bold ">
                              {t('payment.subTotal') + ':'}
                            </td>
                            <td className="p-2">
                              {(
                                (orderItemsSum + tipAmount - creditAmount) /
                                100
                              ).toFixed(2)}{' '}
                              €
                            </td>
                          </tr>
                        )}

                        {bonusAmount > 0 && (
                          <tr>
                            <td className="p-2 font-bold">
                              {receipt.redeemed_bonus_points}{' '}
                              {t('bonusProgram.genericName') + ':'}
                            </td>
                            <td className="p-2">
                              -{(bonusAmount / 100).toFixed(2)} €
                            </td>
                          </tr>
                        )}

                        <tr
                          className={`font-bold ${
                            bonusAmount || creditAmount || tipAmount
                              ? 'border-t-2'
                              : ''
                          }`}
                        >
                          <td className="p-2">
                            {t('payment.totalInclVat') + ':'}
                          </td>
                          <td className="p-2">
                            {(totalInclTip / 100).toFixed(2)} €
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {receipt.payment_type !== 'NONE' && (
                    <div className="flex flex-wrap items-center justify-end mb-4">
                      <div className="mr-2 font-bold lg:mr-4">
                        {t('invoice.paymentMethodUsed') + ':'}
                      </div>
                      <PaymentDetails receipt={receipt} />
                    </div>
                  )}

                  {receipt.gastronomy && phone && (
                    <div className="my-4">
                      <div className="mb-4">
                        {t('orders.contactInfo', {
                          gastronomy: receipt.gastronomy.name
                        })}
                      </div>
                      <a
                        href={`tel:${phone}`}
                        className="inline-flex items-center justify-center w-full px-4 py-2 mb-4 font-bold bg-white border border-gray-400 rounded shadow text-secondary-500"
                      >
                        <i className="material-icons">call</i>
                        {phone}
                      </a>
                    </div>
                  )}

                  <ReceiptInvoices receipt={receipt} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Order;
