import { useState, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';

/**
 * @typedef FontFace
 * @property {string} family The font-family: Roboto, Inter, Open Sans, etc
 * @property {string|number} weight The font-weight: normal, bold, 800, etc
 * @property {string} style The font-style: normal, italic, oblique
 * @property {string} stretch The font stretch: normal, condensed, expanded, etc
 */
interface FontFace {
  family: string;
  weight?:
    | `light`
    | `normal`
    | `bold`
    | `bolder`
    | `100`
    | `200`
    | `300`
    | `400`
    | `500`
    | `600`
    | `700`
    | `800`
    | `900`;
  style?: `normal` | `italic` | `oblique`;
  stretch?:
    | `normal`
    | `ultra-condensed`
    | `extra-condensed`
    | `condensed`
    | `semi-condensed`
    | `semi-expanded`
    | `expanded`
    | `extra-expanded`
    | `ultra-expanded`;
}

interface Options {
  timeout?: number;
}

function useFontFaceObserver(
  fontFaces: FontFace[] = [],
  { timeout }: Options = {}
): { isFontListLoaded: boolean; hasErrors: boolean } {
  const [isFontListLoaded, setIsFontListLoaded] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const fontFacesString = JSON.stringify(fontFaces);

  useEffect(() => {
    const promises = JSON.parse(fontFacesString).map(
      ({ family, weight, style, stretch }: FontFace) =>
        new FontFaceObserver(family, {
          weight,
          style,
          stretch
        }).load(null, timeout)
    );

    Promise.all(promises)
      .then(() => setIsFontListLoaded(true))
      .catch(() => setHasErrors(true));
  }, [fontFacesString, timeout]);

  return { isFontListLoaded, hasErrors };
}

export default useFontFaceObserver;
