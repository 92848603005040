import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AvailabilityMixin } from '../helpers/availability';
import { calculateTimeOfNextUpdate } from '../helpers/gastronomy';
import { canOrderAt, getEffectiveAvailability } from '../helpers/serviceTimes';
import { useInterval } from '../helpers/useInterval';
import { enhanceProduct } from '../helpers/utilities';
import {
  selectedTablePreorderDaysSelector,
  selectedTableState
} from '../store/gastronomy';
import Product from './product';
import ProductGroupHeader from './productGroupHeader';

interface Props {
  productGroup: ProductGroupInclProductFragment;
  initCollapsed: boolean;
}

const ProductGroupItem: React.FC<Props> = ({ productGroup, initCollapsed }) => {
  const selectedTable = useRecoilValue(selectedTableState);
  const preorderDays = useRecoilValue(selectedTablePreorderDaysSelector);
  // const [selectedProductGroup, setSelectedProductGroup] = useRecoilState(
  //   selectedProductGroupState
  // );
  const [availability, setAvailability] = useState<AvailabilityMixin>();
  const [canOrder, setCanOrder] = useState<boolean>();
  const [nextUpdate, setNextUpdate] =
    useState<{
      time: number;
      isOpen: boolean;
    }>();
  const productGroupRef = React.useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState<boolean>(initCollapsed);
  // const { breakpoint } = useBreakpoint();
  // const isApp = useRecoilValue(isAppState);

  // useLayoutEffect(() => {
  //   if (selectedProductGroup === productGroup.uuid && productGroupRef.current) {
  //     window.scrollTo({
  //       behavior: 'smooth',
  //       top:
  //         productGroupRef.current.offsetTop -
  //         (breakpoint !== 'sm' || isApp ? 80 : 15)
  //     });
  //   }
  // }, [selectedProductGroup]);

  useEffect(() => {
    let mCanOrder = true;
    if (productGroup.effective_availability) {
      const availabilityTimes = getEffectiveAvailability(
        productGroup.effective_availability,
        selectedTable
      );
      if (availabilityTimes) {
        const mixin = new AvailabilityMixin(availabilityTimes);
        if (mixin) {
          mCanOrder = canOrderAt(mixin, preorderDays) !== false;
          const update = calculateTimeOfNextUpdate(mixin);
          if (update) {
            setNextUpdate(update);
          }
        }
        setAvailability(mixin);
      }
    }
    setCanOrder(mCanOrder);
  }, [productGroup, selectedTable]);

  useInterval(
    () => {
      if (nextUpdate && nextUpdate.time < new Date().getTime()) {
        if (productGroup.effective_availability) {
          const availabilityTimes = getEffectiveAvailability(
            productGroup.effective_availability,
            selectedTable
          );
          if (availabilityTimes) {
            const mixin = new AvailabilityMixin(availabilityTimes);
            setCanOrder(mixin.nowOpen().length > 0);
            const followingUpdate = calculateTimeOfNextUpdate(mixin);
            if (followingUpdate) {
              setNextUpdate(followingUpdate);
              return;
            }
          }
        }
        setNextUpdate(undefined);
      }
    },
    nextUpdate ? 1000 : null
  );

  const onClickHandler = () => {
    // setSelectedProductGroup((prev) =>
    //   prev === productGroup.uuid ? null : productGroup.uuid
    // );
    setCollapsed((current) => !current);
  };

  if (canOrder !== undefined) {
    return (
      <div
        key={productGroup.uuid}
        id={productGroup.url_path_id}
        className={`mx-2 border-b product-group-list-item`}
        ref={productGroupRef}
      >
        <div
          onClick={onClickHandler}
          className={`flex py-4 cursor-pointer w-full`}
        >
          <ProductGroupHeader
            productGroup={productGroup}
            availability={availability}
            canOrder={canOrder}
            isActive={!collapsed}
          />
        </div>
        {!collapsed && (
          <div className="pb-4 product-group-list-item-content">
            {productGroup.description && (
              <div className="px-2 mb-4 text-sm md:mb-8 lg:text-base text-secondary-200 product-group-item-description">
                {productGroup.description}
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:gap-y-8 md:grid-cols-2 product-list">
              {productGroup.products &&
                productGroup.products.map((product) => (
                  <Product
                    key={product.uuid}
                    product={enhanceProduct(product, productGroup)}
                    availability={availability}
                    canOrder={canOrder}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
  return <div className="h-10" />;
};

export default ProductGroupItem;
