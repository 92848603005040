import React, { ChangeEventHandler } from 'react';

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  title?: string;
  classes?: string;
}

const ToggleSwitch: React.FC<Props> = ({
  classes = '',
  onChange,
  checked = false,
  title
}) => {
  return (
    <div className={classes}>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="flex-shrink-0 sr-only"
            checked={checked}
            onChange={onChange}
          />
          <div
            className={`w-10 h-4 bg-gray-400 rounded-full shadow-inner`}
          ></div>
          <div
            className={`absolute w-6 h-6 transition bg-white rounded-full shadow -left-1 -top-1  ${
              checked ? 'transform bg-primary-500 translate-x-full' : ''
            }`}
          ></div>
        </div>
        {title && <div className="ml-3 text-sm font-medium">{title}</div>}
      </label>
    </div>
  );
};

export default ToggleSwitch;
