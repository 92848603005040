import { useApolloClient } from '@apollo/client';
import Auth from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import Config from '../config';
import { captureError, sendToSentry } from '../helpers/error';
import { isStorageAvailable } from '../helpers/localStorage';
import {
  IsAuthenticated,
  SetGuestUuid,
  SocialLogin
} from '../services/graphql/operations';
import { addSnackbar, snackbarState } from '../store/snackbar';
import { userIsAuthenticatedState } from '../store/user';

interface Props {
  children: any;
}

const UserMigration: React.FC<Props> = ({ children }) => {
  const [migrated, setMigrated] = useState<boolean>();
  const apolloClient = useApolloClient();
  const setIsAuthenticated = useSetRecoilState(userIsAuthenticatedState);
  const setSnackbars = useSetRecoilState(snackbarState);

  useEffect(() => {
    const socialLogin = async (
      token: string,
      provider: AuthorizerType,
      uuid: string
    ) => {
      return apolloClient.mutate<
        SocialLoginMutation,
        SocialLoginMutationVariables
      >({
        mutation: SocialLogin,
        variables: {
          token,
          provider
        }
      });
    };

    const stripUuid = (uuid: string) => {
      return uuid.substring(uuid.indexOf(':') + 1);
    };

    const migration = async () => {
      if (isStorageAvailable()) {
        const identityId = localStorage.getItem(
          'CognitoIdentityId-' + Config.identityPoolId
        );
        if (identityId) {
          try {
            const credentials = await Auth.currentCredentials();
            const poolUuid = stripUuid(credentials.identityId);
            console.log('credentials', credentials);
            if (credentials.authenticated) {
              const info = await Auth.currentUserInfo();
              console.log('info', info);
              const lastAuthUser = localStorage.getItem(
                `CognitoIdentityServiceProvider.${Config.userPoolWebClientId}.LastAuthUser`
              );
              if (lastAuthUser) {
                const session = await Auth.currentSession();
                const idToken = session.getIdToken().getJwtToken();
                console.log('idToken', idToken);
                if (idToken) {
                  //its a cognito user, migrate
                  await socialLogin(idToken, 'COGNITO', poolUuid);
                }
              } else {
                const federatedInfoString = localStorage.getItem(
                  'aws-amplify-federatedInfo'
                );
                if (federatedInfoString) {
                  const federatedInfo = JSON.parse(federatedInfoString);
                  const provider =
                    federatedInfo.provider === 'appleid.apple.com'
                      ? 'apple'
                      : federatedInfo.provider;
                  await socialLogin(
                    federatedInfo.token,
                    provider.toUpperCase(),
                    poolUuid
                  );
                }
              }
            } else {
              if (poolUuid) {
                await apolloClient.mutate<
                  SetGuestUuidMutation,
                  SetGuestUuidMutationVariables
                >({
                  mutation: SetGuestUuid,
                  variables: { identityId: poolUuid }
                });
              }
            }
            localStorage.setItem(
              '_CognitoIdentityId-' + Config.identityPoolId,
              identityId
            );
            localStorage.removeItem(
              'CognitoIdentityId-' + Config.identityPoolId
            );
          } catch (err) {
            console.log('error migrating user', err);
            if (sendToSentry(err)) {
              captureError(err);
            }
            addSnackbar(
              {
                type: 'ERROR',
                title:
                  'Sie wurden ausgelogged. Bitte melden sie sich erneut an. (' +
                  err.message +
                  ')'
              },
              setSnackbars
            );
          }
        }
        await checkIsAuthenticated();
      }
      setMigrated(true);
    };

    const checkIsAuthenticated = async (uuid?: string) => {
      try {
        const result = await apolloClient.mutate<IsAuthenticatedMutation>({
          mutation: IsAuthenticated
        });
        if (result.data) {
          setIsAuthenticated(result.data.isAuthenticated);
        }
      } catch (error) {
        if (sendToSentry(error)) {
          captureError(error);
        }
        setIsAuthenticated(false);
        addSnackbar(
          {
            type: 'ERROR',
            title:
              'Nutzerinformationen nicht verfügbar. Bitte lade die Seite neu.'
          },
          setSnackbars
        );
      }
    };

    migration();
  }, []);

  if (migrated) {
    return children;
  }
  return null;
};

export default UserMigration;
