import {
  add,
  differenceInMilliseconds,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameSecond,
  isWithinInterval,
  startOfDay,
  sub
} from 'date-fns';
import * as Joi from 'joi';

enum AvailabilityIndex {
  'mon' = 'mon',
  'tue' = 'tue',
  'wed' = 'wed',
  'thu' = 'thu',
  'fri' = 'fri',
  'sat' = 'sat',
  'sun' = 'sun',
  'hol' = 'hol',
  'e' = 'e'
}
const availabilityKeys = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
  'hol',
  'e'
];

export class AvailabilityMixin {
  private static holidays: Date[][] = [];

  /**
   * Opening hours are considered to be in localtime with respect to the restaurant.
   * Furthermore, the user is considered to be in the same timezone as the restaurant.
   * That's why no timezone conversions are needed.
   */
  opening: AvailabilityTimes;

  constructor(opening: AvailabilityTimes) {
    this.opening = opening;
    // this.checkOpeningFormatValidity();
  }

  checkOpeningFormatValidity(): void {
    const time_string = Joi.string().regex(/^[0-9]{4}$/);
    const time_item = Joi.object({
      __typename: Joi.string().optional(),
      time_from: time_string,
      time_to: time_string,
      limit: Joi.number().optional().allow(null)
    });
    const schema_line = Joi.array().max(10).items(time_item);
    const schema = Joi.object().keys({
      __typename: Joi.string().optional(),
      mon: schema_line,
      tue: schema_line,
      wed: schema_line,
      thu: schema_line,
      fri: schema_line,
      sat: schema_line,
      sun: schema_line,
      hol: schema_line,
      e: Joi.array().items(
        Joi.object({
          __typename: Joi.string().optional(),
          dateRange: Joi.object({
            __typename: Joi.string().optional(),
            begin_date: Joi.string(),
            end_date: Joi.string()
          }),
          timeRanges: Joi.array().max(10).items(time_item)
        })
      ),
      cc: Joi.string(),
      opt: Joi.object({
        overwrite: Joi.boolean().optional()
      }).optional()
    });
    Joi.assert(this.opening, schema);
  }

  /**
   * Begin of the interval is open (inclusive), end is closed (inclusive)
   * Reasoning: If Restaurant opens at 11:00, you would want people to be able
   * to order once the clock turns exactly 11:00.
   * If the Restaurant closes at 23:00, you want people to be able to order
   * until 23:00.
   */
  timerangeAt(date_time: Date): Date[] {
    for (const [begin, end /*, max*/] of this.getOpeningRangesForDate(
      date_time
    )) {
      if (
        (isSameSecond(date_time, begin) || isAfter(date_time, begin)) &&
        isBefore(date_time, end)
      ) {
        return [begin, end /*, max*/];
      }
    }
    return [];
  }

  getOpeningRangesForDate(date_time: Date): Date[][] {
    date_time = new Date(date_time.getTime());

    // today's opening ranges
    let ranges = this.getMatchingExceptionsForDate(date_time);
    if (!ranges.length) {
      ranges = this.getMatchingRulesForDate(date_time);
    }

    // for opening hours that wrap midnight, we also need to consider yesterday's opening ranges
    const yesterday = sub(new Date(date_time.getTime()), { days: 1 });
    let yesterday_ranges = this.getMatchingExceptionsForDate(yesterday);
    if (!yesterday_ranges.length) {
      yesterday_ranges = this.getMatchingRulesForDate(yesterday);
    }

    for (const yesterday_range of yesterday_ranges) {
      // todo: check if this works
      if (isSameDay(yesterday_range[1], date_time)) {
        ranges.push(yesterday_range);
      }
    }

    if (ranges.length === 1 && ranges[0].length === 0) {
      return [];
    }

    return ranges.sort((a, b) => {
      if (!a[0] || !b[0]) {
        return 0;
      }
      return differenceInMilliseconds(a[0], b[0]);
    });
  }

  getMatchingExceptionsForDate(date_time: Date): Date[][] {
    const rules: Date[][] = [];
    const exceptions = this.opening['e'] || [];
    for (const {
      dateRange: { begin_date, end_date },
      timeRanges
    } of exceptions) {
      const exception_range = [new Date(begin_date), new Date(end_date)];
      if (this.containsDay(date_time, exception_range)) {
        if (timeRanges.length) {
          for (const { time_from, time_to } of timeRanges) {
            const date_begin = this.combine_date_and_time(date_time, time_from);
            let date_end = this.combine_date_and_time(date_time, time_to);
            if (time_to < time_from) {
              date_end = add(date_end, { days: 1 });
            }
            rules.push([date_begin, date_end]);
          }
        } else {
          rules.push([]);
        }
      }
    }
    return rules;
  }

  getMatchingRulesForDate(date_time: Date): Date[][] {
    date_time = new Date(date_time.getTime());
    const rules: Date[][] = [];
    const ranges = (this.opening[this.dayIndex(date_time)] ||
      []) as AvailabilityTimeElement[];
    for (const { time_from, time_to /*, max*/ } of ranges) {
      const date_begin = this.combine_date_and_time(date_time, time_from);
      let date_end = this.combine_date_and_time(date_time, time_to);
      if (time_to < time_from) {
        date_end = add(date_end, { days: 1 });
      }
      rules.push([date_begin, date_end /*, max*/]);
    }
    return rules;
  }

  dayIndex(date_time: Date): AvailabilityIndex {
    if (this.isHoliday(date_time)) {
      return 'hol' as AvailabilityIndex;
    }
    return format(date_time, 'ccc').toLowerCase() as AvailabilityIndex;
  }

  isHoliday(date_time: Date): boolean {
    const countryCode = this.opening.cc;
    if (AvailabilityMixin.holidays[countryCode as any]) {
      for (const holiday of AvailabilityMixin.holidays[countryCode as any]) {
        if (isSameDay(date_time, holiday)) {
          return true;
        }
      }
    }
    return false;
  }

  nowOpen(): Date[] {
    return this.timerangeAt(new Date());
  }

  combine_date_and_time(date_time: Date, time: string): Date {
    date_time = new Date(date_time.getTime());
    const hours = parseInt(time.slice(0, 2), 10);
    const minutes = parseInt(time.slice(2, 4), 10);
    date_time.setHours(hours);
    date_time.setMinutes(minutes, 0, 0);
    return date_time;
  }

  containsDay(date_time: Date, range: Date[]): boolean {
    const [begin, end] = range;
    // if (begin && end) {
    return isWithinInterval(date_time, {
      start: startOfDay(begin),
      end: endOfDay(end)
    });
    // } else {
    //   return false;
    // }
  }
  static setHolidays(countryCode: string, holidays: string[]): void {
    AvailabilityMixin.holidays[countryCode as any] = holidays.map(
      (holiday) => new Date(holiday)
    );
  }
}

export function momentRangesToString(
  ranges: Date[][]
): AvailabilityTimeElement[] {
  return ranges.map((range) => ({
    time_from: format(range[0], 'HHmm'),
    time_to: format(range[1], 'HHmm')
  }));
}

export function combineAvailabilityRanges(
  a: Date[],
  b: Date[],
  strict = false
): Date[] {
  if (a.length > 0) {
    if (b.length > 0) {
      const start = isWithinInterval(b[0], { start: a[0], end: a[1] })
        ? b[0]
        : a[0];
      const end = isWithinInterval(b[1], { start: a[0], end: a[1] })
        ? b[1]
        : a[1];
      return [start, end];
    } else if (strict) {
      return b;
    }
  }
  return a;
}

export function availabilityHasEntries(
  availability: AvailabilityTimes
): boolean {
  let hasEntries = false;
  if (availability) {
    for (const key of Object.keys(availability)) {
      if (availabilityKeys.includes(key)) {
        const day = key as AvailabilityIndex;
        const daysAvailability = availability[day];
        if (daysAvailability && daysAvailability.length > 0) {
          hasEntries = true;
        }
      }
    }
  }
  return hasEntries;
}
