import { add } from 'date-fns';
import queryString from 'query-string';
import {
  PERSISTED_TABLE_STATE_KEY,
  PERSISTED_TABLE_TYPE_STATE_KEY
} from '../constants';

export function isStorageAvailable(
  type: 'localStorage' | 'sessionStorage' = 'localStorage'
): boolean | undefined {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function setTableInLocalStorage({
  user_uuid,
  table_type,
  table
}: {
  user_uuid: string;
  table_type: TableType;
  table?: TableFragment;
}): void {
  if (isStorageAvailable()) {
    let stateValue: Record<
      string,
      { table_type: TableType; table?: TableFragment; expires: number }
    > = {};

    // clean up legacy
    localStorage.removeItem(PERSISTED_TABLE_TYPE_STATE_KEY);

    // get existing
    const existing = localStorage.getItem(PERSISTED_TABLE_STATE_KEY);
    if (existing) {
      stateValue = JSON.parse(existing);
      if (stateValue.uuid) {
        stateValue = {};
      }
    }

    // clean out all expired values
    const now = new Date().getTime();
    for (const key of Object.keys(stateValue)) {
      if (stateValue[key].expires < now) {
        delete stateValue[key];
      }
    }

    // keep table if the type_type has not changed, but no new table is passed
    if (!table && stateValue[user_uuid]) {
      const tableFromStorage = stateValue[user_uuid];
      if (tableFromStorage.table?.table_type === table_type) {
        table = tableFromStorage.table;
      }
    }

    stateValue[user_uuid] = {
      table_type,
      table,
      expires: add(new Date(), { hours: 12 }).getTime()
    };

    localStorage.setItem(PERSISTED_TABLE_STATE_KEY, JSON.stringify(stateValue));
  }
}

export function getTableFromLocalStorage(
  user_uuid: string
): { table_type: TableType; table?: TableFragment } | null {
  if (isStorageAvailable()) {
    const existing = localStorage.getItem(PERSISTED_TABLE_STATE_KEY);
    if (existing) {
      const params = queryString.parse(window.location.search);
      const stateValue: Record<
        string,
        { table_type: TableType; table?: TableFragment; expires: number }
      > = JSON.parse(existing);
      if (
        stateValue &&
        stateValue[user_uuid] &&
        stateValue[user_uuid].expires > new Date().getTime() &&
        (!params.tt || stateValue[user_uuid].table_type === params.tt)
      ) {
        return stateValue[user_uuid];
      }
    }
  }
  return null;
}

export function removeTableFromLocalStorage(user_uuid: string): void {
  if (isStorageAvailable()) {
    const existing = localStorage.getItem(PERSISTED_TABLE_STATE_KEY);
    if (existing) {
      const stateValue: Record<string, TableFragment> = JSON.parse(existing);
      if (stateValue && stateValue[user_uuid]) {
        delete stateValue[user_uuid];
        localStorage.setItem(
          PERSISTED_TABLE_STATE_KEY,
          JSON.stringify(stateValue)
        );
      }
    }
  }
}
