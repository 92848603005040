import { useApolloClient } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { GraphQLError } from 'graphql';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useRecoilState } from 'recoil';
import { IMG_BASE_PATH } from '../constants';
import useQueryParams from '../helpers/useQueryParams';
import { CheckCouponCode } from '../services/graphql/operations';
import { preliminaryCreditState } from '../store/cart';
import { restaurantFiltersState } from '../store/user';
import Button from './button';
import CcBadge from './ccBadge';
import Heading from './heading';
import Modal from './modal';

const RedeemCouponPopup: React.FC = () => {
  const apolloClient = useApolloClient();
  const [t] = useTranslation();
  const [error, setError] = useState<GraphQLError>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [credit, setCredit] = useRecoilState(preliminaryCreditState);
  const params = useQueryParams();
  const setRestaurantFilters = useSetRecoilState(restaurantFiltersState);
  const { trackEvent } = useMatomo();

  useEffect(() => {
    if (params) {
      let couponCode: string | null = null;

      if (params.has('c')) {
        couponCode = params.get('c');
      } else if (params.has('cc')) {
        couponCode = params.get('cc');
      }

      if (typeof couponCode === 'string' && couponCode.length > 4) {
        processCouponCode(couponCode);
      }
    }
  }, [location.search]);

  const processCouponCode = async (couponCode: string) => {
    const result = await apolloClient.query<
      CheckCouponCodeQuery,
      CheckCouponCodeQueryVariables
    >({
      query: CheckCouponCode,
      variables: { code: couponCode },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      setShowModal(true);
      result.errors.map((error) => {
        setError(error);
      });
    } else if (result.data && result.data.checkCouponCode) {
      setShowModal(true);
      const credit = result.data.checkCouponCode;
      setCredit(credit);

      if (credit.requires_cc_products) {
        setRestaurantFilters((current) => [...current, 'HAS_CC_PRODUCTS']);
      }
      trackEvent({
        category: 'Coupon',
        action: 'Checked',
        name: credit.campaign_coupon_code,
        value: credit.value / 100
      });
    }
  };

  const ModalBody: React.ReactNode = (
    <>
      <Heading text={t('credits.coupon')} />
      <div className="flex flex-col justify-center">
        {error && (
          <>
            <span className="mb-4">{t('credits.errors.unspecific')}</span>
            <span className="mb-4 text-red-500">
              {error.extensions && error.extensions.code
                ? t(
                    [
                      'credits.errors.' + error.extensions.code,
                      'errorWithMessage'
                    ],
                    { error: error.message }
                  )
                : error.message}
            </span>
            <div className="flex items-center justify-center">
              <img
                src={`${IMG_BASE_PATH}/undraw/sad.svg`}
                alt=""
                className="w-2/3 my-8"
              />
            </div>
            <Button
              type="default"
              title={t('cancel')}
              onClick={() => {
                setError(undefined);
                setShowModal(false);
              }}
            />
          </>
        )}
        {credit && (
          <>
            <span className="mb-4">
              {t('credits.urlSuccessMessage', {
                code: credit.campaign_coupon_code
              })}
            </span>
            <div className="text-sm text-secondary-300">
              {credit.value > 0 && (
                <div className="flex flex-row items-start mb-2">
                  <span className="mt-1 mr-4 text-sm material-icons">
                    attach_money
                  </span>
                  {t('credits.couponValue')}: {(credit.value / 100).toFixed(2)}{' '}
                  €
                </div>
              )}
              {credit.min_order_amount > 0 && (
                <div className="flex flex-row items-start mb-2">
                  <span className="mt-1 mr-4 text-sm material-icons">
                    attach_money
                  </span>
                  {t('delivery.minOrderAmount')}:{' '}
                  {(credit.min_order_amount / 100).toFixed(2)} €
                </div>
              )}
              {credit.only_first_order && (
                <div className="flex flex-row items-start mb-2">
                  <span className="mt-1 mr-4 text-sm material-icons">
                    new_releases
                  </span>
                  {t('credits.newCustomer')}
                </div>
              )}
              {credit.gastronomies && credit.gastronomies.length > 0 && (
                <div className="flex flex-row items-start mb-2">
                  <span className="mt-1 mr-4 text-sm material-icons">info</span>
                  {t('credits.onlyForRestaurants')}:{' '}
                  {credit.gastronomies.map((g) => g.name).join(', ')}
                </div>
              )}
              {credit.requires_cc_products && (
                <>
                  <div className="flex flex-row items-start mb-2">
                    <span className="mt-1 mr-4 text-sm material-icons">
                      info
                    </span>
                    {t('credits.requireCcProducts')}
                  </div>
                  <span className="flex justify-center">
                    <CcBadge />
                  </span>
                </>
              )}
            </div>
            <div className="flex items-center justify-center">
              <img
                src={`${IMG_BASE_PATH}/undraw/gift.svg`}
                alt=""
                className="w-2/3 my-8"
              />
            </div>
            <Button
              type="primary"
              title={t('ok')}
              onClick={() => setShowModal(false)}
            />
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>{ModalBody}</Modal>
      )}
    </>
  );
};

export default RedeemCouponPopup;
