const MAX_RETRIES = 100;
const RETRY_INTERVAL_IN_MS = 200;

export function lazyRetry<T>(fn: () => Promise<T>, retryCount = 1): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retryCount > MAX_RETRIES) {
          reject(error);
          return;
        }

        setTimeout(() => {
          return lazyRetry(fn, retryCount + 1).then(resolve, reject);
        }, RETRY_INTERVAL_IN_MS * (retryCount * 1.5));
      });
  });
}

export function loadWithRetries<T>(
  functionToExecute: T,
  retryCount = 1,
  lastError?: Error
): T {
  if (retryCount > MAX_RETRIES && lastError) {
    throw lastError;
  }

  try {
    return functionToExecute;
  } catch (error) {
    setTimeout(() => {
      return loadWithRetries(functionToExecute, retryCount + 1, error);
    }, RETRY_INTERVAL_IN_MS * (retryCount * 1.5));
  }

  throw lastError;
}
