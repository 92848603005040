import React from 'react';
import { useRecoilValue } from 'recoil';
import { snackbarState } from '../store/snackbar';
import SimpleSnackbar from './simpleSnackbar';

const SnackbarContainer: React.FC = () => {
  const snackbars = useRecoilValue(snackbarState);

  return (
    <div className="fixed right-0 z-30 flex flex-col items-start justify-center mt-4 mr-4">
      {snackbars.map((n, i) => (
        <SimpleSnackbar snackbar={n} key={i} />
      ))}
    </div>
  );
};

export default SnackbarContainer;
