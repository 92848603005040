import React, { useEffect, useState } from 'react';
import Button from '../components/button';
import {
  AddGuestRegistrations,
  GetGuestRegistrationFields
} from '../services/graphql/operations';
import nacl from 'tweetnacl';
import { encrypt, userIsRegistered } from '../helpers/guestRegistrationHelper';
import Spinner from '../components/spinner';
import Heading from '../components/heading';
import Alert from '../components/alert';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { guestRegistrationState } from '../store/user';
import {
  STORAGE_KEY_GUEST_REGISTRATION,
  STORAGE_KEY_GUEST_REGISTRATION_DATA
} from '../constants';
import { captureError } from '../helpers/error';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import GuestRegistrationData from '../components/guestRegistrationData';
import { emailIsValid } from '../helpers/login';
import { gastronomyState, selectedTableState } from '../store/gastronomy';
import GastronomyBanner from '../components/gastronomyBanner';
import { isStorageAvailable } from '../helpers/localStorage';
import { useTranslation } from 'react-i18next';
import GetsbyLink from '../components/getsbyLink';
import GetsbyRedirect from '../components/getsbyRedirect';

const keyPair = nacl.box.keyPair();
const pubKey = keyPair.publicKey;
const privKey = keyPair.secretKey;

interface Props {
  urlPathId?: string;
  gastronomy?: GastronomyFragment;
  onSuccess?: (registration: EncryptedGuestRegistration) => void;
  buttonText?: string;
}

const GuestRegistration: React.FC<Props> = (props: Props) => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const gastronomy = useRecoilValue(gastronomyState);
  const [guestRegistrations, setGuestRegistrations] = useState<
    Record<string, unknown>[]
  >([]);
  const [error, setError] = useState<Error>();
  const [errors, setErrors] = useState<string[][]>([]);
  const [acceptedError, setAcceptedError] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<GetsbyRouter | null>(null);
  const setGuestRegistration = useSetRecoilState(guestRegistrationState);
  const [remember, setRemember] = useState<boolean>(false);
  const selectedTable = useRecoilValue(selectedTableState);

  const [guestRegistrationFieldsQuery, guestRegistrationFieldsResult] =
    useLazyQuery<
      GetGuestRegistrationFieldsQuery,
      GetGuestRegistrationFieldsQueryVariables
    >(GetGuestRegistrationFields, {
      onCompleted: (data) => {
        if (data.getGuestRegistrationFields) {
          addFieldSet(data.getGuestRegistrationFields, true);
        }
      }
    });

  useEffect(() => {
    if (props.urlPathId) {
      guestRegistrationFieldsQuery({
        variables: { params: { url_path_id: props.urlPathId } }
      });
    }
    if (props.gastronomy) {
      guestRegistrationFieldsQuery({
        variables: { params: { user_uuid: props.gastronomy.uuid } }
      });
    }
  }, []);

  useEffect(() => {
    if (gastronomy && isStorageAvailable()) {
      const guestRegistrationJson = localStorage.getItem(
        STORAGE_KEY_GUEST_REGISTRATION
      );

      if (guestRegistrationJson) {
        const guestRegistration = JSON.parse(guestRegistrationJson);
        const isRegistered = userIsRegistered(guestRegistration, gastronomy);

        if (isRegistered) {
          setSuccess(true);
        }
      }
    }
  }, [gastronomy]);

  const addFieldSet = (fields: GuestRegistrationField[], prefill = false) => {
    const guestRegistrationObject: any = {};
    const rememberedValues = prefill ? getValuesFromLocalStorage() : null;

    for (const field of fields) {
      guestRegistrationObject[field.name] =
        rememberedValues && rememberedValues[field.name]
          ? rememberedValues[field.name]
          : '';
      if (
        prefill &&
        field.name === 'table' &&
        selectedTable &&
        selectedTable.table_number
      ) {
        guestRegistrationObject[field.name] = selectedTable.table_number;
      }
    }
    setGuestRegistrations((current) => [...current, guestRegistrationObject]);
    if (prefill && rememberedValues) {
      setAccepted(true);
      setRemember(true);
    }
  };

  const getValuesFromLocalStorage = () => {
    const rememberedValues = isStorageAvailable()
      ? localStorage.getItem(STORAGE_KEY_GUEST_REGISTRATION_DATA)
      : null;

    if (rememberedValues) {
      const data = JSON.parse(rememberedValues);
      if (data) {
        return data;
      }
    }
  };

  const validateInput = (
    data: Record<string, any>,
    validateTable: boolean
  ): string[] => {
    const fieldErrors: string[] = [];
    if (
      guestRegistrationFieldsResult.data &&
      guestRegistrationFieldsResult.data.getGuestRegistrationFields
    ) {
      for (const field of guestRegistrationFieldsResult.data
        .getGuestRegistrationFields) {
        if (field.required) {
          if (!data[field.name]) {
            fieldErrors.push(field.name);
          } else if (
            data[field.name].length < 1 ||
            (field.html_type === 'email' && !emailIsValid(data[field.name]))
          ) {
            fieldErrors.push(field.name);
          }
        }
      }
    }
    return fieldErrors;
  };

  const onChange = (index: number, field: string, value: string) => {
    setGuestRegistrations((current) => [
      ...current.slice(0, index),
      { ...current[index], [field]: value },
      ...current.slice(index + 1)
    ]);
  };

  const onDelete = (index: number) => {
    setGuestRegistrations((current) => {
      return [...current.slice(0, index), ...current.slice(index + 1)];
    });
  };

  const onSave = async () => {
    if (gastronomy && gastronomy.uuid && gastronomy.options.public_key) {
      try {
        const currentErrors: string[][] = [];

        for (let i = 0; i < guestRegistrations.length; i++) {
          const errors = validateInput(
            guestRegistrations[i],
            i === 0 ? true : false
          ).filter((err) => err !== 'table' || i === 0);

          if (errors.length) {
            currentErrors[i] = errors;
          }
        }

        setErrors(currentErrors);

        for (const cErrors of currentErrors) {
          if (cErrors && cErrors.length > 0) {
            return;
          }
        }

        if (!accepted) {
          setAcceptedError(true);
          return;
        } else {
          setAcceptedError(false);
        }

        if (isStorageAvailable()) {
          if (remember) {
            localStorage.setItem(
              STORAGE_KEY_GUEST_REGISTRATION_DATA,
              JSON.stringify(guestRegistrations[0])
            );
          } else {
            localStorage.removeItem(STORAGE_KEY_GUEST_REGISTRATION_DATA);
          }
        }

        const gastroPubKey = Buffer.from(gastronomy.options.public_key, 'hex');
        const pubKeyHex = Buffer.from(pubKey).toString('hex');

        const table = guestRegistrations[0]['table'];

        const input: AddGuestRegistrationsMutationVariables['input'] =
          guestRegistrations.map((gr) => {
            const jsonString = JSON.stringify({
              firstname: gr.firstname,
              lastname: gr.lastname,
              email: gr.email,
              phone: gr.phone,
              table: table
            });

            const encrypted = encrypt(jsonString, gastroPubKey, privKey);

            return {
              user_uuid: gastronomy.uuid as string,
              data: encrypted,
              public_key: pubKeyHex
            };
          });

        await apolloClient.mutate<AddGuestRegistrationMutation>({
          mutation: AddGuestRegistrations,
          variables: { input }
        });

        const guestRegistration = {
          userUuid: gastronomy.uuid,
          timestamp: new Date().getTime(),
          data: input[0].data
        };

        if (isStorageAvailable()) {
          localStorage.setItem(
            STORAGE_KEY_GUEST_REGISTRATION,
            JSON.stringify(guestRegistration)
          );
        }
        setGuestRegistration(guestRegistration);

        if (props.onSuccess) {
          props.onSuccess(guestRegistration);
        }

        if (gastronomy.status !== 'DISABLED' && gastronomy.options.canOrder) {
          onRedirect();
        } else {
          setSuccess(true);
        }
      } catch (error) {
        console.log('error sending guest registration', error);
        captureError(error);
        setError(error);
      }
    } else {
      const err = new Error('Kein Public Key!');
      setError(err);
      captureError(err);
    }
  };

  const onRedirect = async () => {
    let tableType = null;

    if (selectedTable) {
      tableType = selectedTable.table_type;
    } else if (
      gastronomy &&
      gastronomy.options.tableTypes &&
      gastronomy.options.tableTypes.includes('DINEIN')
    ) {
      tableType = 'DINEIN';
    }

    setRedirectTo({
      to: `/:urlPathId/speisekarte${tableType ? `?tt=${tableType}` : ''}`,
      params: { urlPathId: props.urlPathId }
    });
  };

  if (guestRegistrationFieldsResult.loading) {
    return (
      <div className="min-h-screen">
        <Spinner center={true} />
      </div>
    );
  } else if (error) {
    return <div>Error: {error.message}</div>;
  } else if (redirectTo) {
    return <GetsbyRedirect {...redirectTo} />;
  } else if (
    gastronomy &&
    guestRegistrationFieldsResult.data &&
    guestRegistrationFieldsResult.data.getGuestRegistrationFields
  ) {
    return (
      <div className="w-full p-4">
        <Heading text={'Corona Gästeregistrierung'} />
        {success ? (
          <>
            <div className="flex items-center mb-8">
              <GastronomyBanner
                gastronomy={gastronomy}
                useLink={gastronomy.options.canOrder}
              />
            </div>
            <Alert
              type="success"
              title={t('guestRegistration.thanks')}
              message={
                <div className="mb-4">
                  {t('guestRegistration.success', {
                    gastronomy: gastronomy.name
                  })}
                </div>
              }
            />

            <Button
              type="default"
              title={t('guestRegistration.newRegistration')}
              classes="w-full mb-4"
              onClick={() => setSuccess(false)}
            />

            {gastronomy.status === 'ENABLED' && (
              <Button
                type="primary"
                title={t('guestRegistration.goToMenu')}
                classes="w-full"
                disabled={!gastronomy.options.canOrder}
                onClick={() => onRedirect()}
              />
            )}
            {gastronomy.status === 'MENUONLY' && (
              <Button
                type="primary"
                title={t('guestRegistration.goToMenu')}
                classes="w-full"
                onClick={() => onRedirect()}
              />
            )}
          </>
        ) : (
          <>
            <div className="flex items-center mb-8">
              <GastronomyBanner
                gastronomy={gastronomy}
                useLink={gastronomy.options.canOrder}
              />
            </div>
            <div className="w-full max-w-lg">
              {guestRegistrations.map((gr, i) => (
                <GuestRegistrationData
                  key={i.toString()}
                  index={i}
                  onChange={onChange}
                  onDelete={onDelete}
                  showTable={i === 0}
                  showHeader={i > 0}
                  showDivider={guestRegistrations.length > 1}
                  values={gr}
                  errors={errors[i]}
                  fields={
                    (guestRegistrationFieldsResult.data &&
                      guestRegistrationFieldsResult.data
                        .getGuestRegistrationFields) ||
                    []
                  }
                />
              ))}
              <div className="mb-4 text-center">
                <div
                  onClick={() => {
                    if (
                      guestRegistrationFieldsResult.data &&
                      guestRegistrationFieldsResult.data
                        .getGuestRegistrationFields
                    ) {
                      addFieldSet(
                        guestRegistrationFieldsResult.data
                          .getGuestRegistrationFields
                      );
                    }
                  }}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <span className="mr-2 material-icons">person_add</span>
                  <span className="underline">Weitere Person hinzufügen</span>
                </div>
              </div>
              <div className="mb-3">
                <label
                  className={`text-sm ${acceptedError ? 'text-red-500' : ''}`}
                >
                  <input
                    className="flex-shrink-0 mr-2 leading-tight"
                    type="checkbox"
                    checked={accepted}
                    onChange={(evt) => {
                      setAccepted(evt.target.checked);
                    }}
                  />
                  Ich habe die{' '}
                  <GetsbyLink
                    to="/corona-datenschutz"
                    className="text-primary-500"
                  >
                    Datenschutzerklärung
                  </GetsbyLink>{' '}
                  gelesen und stimme dieser und der Verarbeitung meiner Daten zu
                </label>
              </div>
              <div>
                <label className={`text-sm`}>
                  <input
                    className="flex-shrink-0 mr-2 leading-tight"
                    type="checkbox"
                    checked={remember}
                    onChange={(evt) => {
                      setRemember(evt.target.checked);
                    }}
                  />
                  Meine Daten fürs nächste Mal merken
                </label>
              </div>
              <div className="mt-4">
                <Button
                  onClick={onSave}
                  type="primary"
                  title={props.buttonText || 'Abschicken'}
                  classes="w-full"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  return <div>Invalid Request</div>;
};

export default GuestRegistration;
