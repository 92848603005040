import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { WEB_APP_ID } from './constants';
import { BrowserRouter } from 'react-router-dom';
import queryString from 'query-string';
import AppInit from './containers/appInit';
import ScrollToTop from './components/scrollToTop';
import Config from './config';
import { ErrorBoundary, init } from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  // Supress console output
  console.log = () => null;
  console.debug = () => null;
  console.info = () => null;
  console.warn = () => null;
}

init({
  dsn: Config.sentryDsn,
  release: process.env.REACT_APP_VERSION,
  environment: Config.stage,
  enabled: process.env.NODE_ENV === 'production'
});

const queryParams = queryString.parse(window.location.search);

const app = (
  <React.StrictMode>
    <ErrorBoundary
      fallback={'Ein Fehler ist aufgetreten. Bitte neu laden.'}
      showDialog={true}
    >
      <BrowserRouter>
        <ScrollToTop />
        {queryParams.isApp === '1' ? <AppInit /> : <App />}
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById(WEB_APP_ID));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
