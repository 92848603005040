interface RgbColor {
  /** Values are in percent (ex: 255,127,0,255 => 1,0.5,0,1) */
  r: number;
  g: number;
  b: number;
  a: number;
}

export function getMatrixFromHex(hexColor: string): string | null {
  const rgbColor = hexToRgb(hexColor);
  return rgbColor ? computeMatrixColor(rgbColor) : null;
}

export function getMatrixFromRGBA(rgbaString: string): string | null {
  const rgba = rgbaStringToRgbColor(rgbaString);
  if (rgba) {
    return computeMatrixColor(rgba);
  }
  return null;
}

export function getMatrixFromStyle(classOrId: string): string | null {
  const colorProp = getProperty(classOrId, 'color');
  if (colorProp) {
    if (colorProp.startsWith('#')) {
      return getMatrixFromHex(colorProp);
    } else if (colorProp.startsWith('rgb')) {
      return getMatrixFromRGBA(colorProp);
    }
  }
  return null;
}

function rgbaStringToRgbColor(colorProp: string) {
  const rgb = colorProp.match(/[.?\d]+/g);
  if (rgb) {
    const base = 1 / 255;
    return {
      r: +rgb[0] * base,
      g: +rgb[1] * base,
      b: +rgb[2] * base,
      a: rgb.length > 3 ? +rgb[3] * base : 1
    };
  }
  return null;
}

// Inspired by this answer : https://stackoverflow.com/a/5624139/11480016
function hexToRgb(hex3or6: string): RgbColor | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex6 = hex3or6.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex6
  );
  const base = 1 / 255;
  return result
    ? {
        r: parseInt(result[1], 16) * base,
        g: parseInt(result[2], 16) * base,
        b: parseInt(result[3], 16) * base,
        a: 1
      }
    : null;
}

function computeMatrixColor(rgbColor: RgbColor): string {
  let matrix;
  if (rgbColor) {
    // Ignore original colors and apply the new one
    matrix =
      `0 0 0 0 ${rgbColor.r} ` + // Red
      `0 0 0 0 ${rgbColor.g} ` + // Green
      `0 0 0 0 ${rgbColor.b} ` + // Blue
      `0 0 0 ${rgbColor.a} 0`; // Alpha
  } else {
    // Identity (keep orignal colors)
    matrix =
      `1 0 0 0 0 ` + // Red
      `0 1 0 0 0 ` + // Green
      `0 0 1 0 0 ` + // Blue
      `0 0 0 1 0`; // Alpha
  }
  return matrix;
}

function getProperty(classString: string, property: string): string | null {
  const elem = document.getElementsByClassName(classString)[0];

  console.log('elem', elem);
  return elem
    ? window.getComputedStyle(elem, null).getPropertyValue(property)
    : null;
}
