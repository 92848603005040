import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import GetsbyLink from './getsbyLink';

const TopNavPartnerDropdown: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen((current) => !current);
  };

  const menuItemClass =
    'block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item';

  return (
    <>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <div className="relative mx-1">
          <div
            className="flex items-center p-2 mr-2 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded cursor-pointer focus:outline-none text-primary-500 hover:bg-primary-500 hover:text-secondary-500"
            onClick={toggleMenu}
          >
            Features
            <i className="material-icons">expand_more</i>
          </div>
          {isMenuOpen && (
            <div
              className="absolute left-0 z-30 w-48 mt-2 origin-top-left rounded-md shadow-lg"
              id="top-nav-menu"
            >
              <div className="py-1 bg-white rounded-md" role="menu">
                <GetsbyLink
                  to="/partner/features"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Übersicht
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/online-bestellsystem"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Online-Bestellsystem
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/sichtbarkeit"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Sichtbarkeit & Online-Präsenz
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/kommunikation"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Kommunikation
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/gaesteregistrierung"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Corona Gästeregistrierung
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/technik"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Dashboard & Technik
                </GetsbyLink>
                <GetsbyLink
                  to="/partner/bonuspunkte-club"
                  className={menuItemClass}
                  role="menuitem"
                >
                  Bonuspunkte-Club
                </GetsbyLink>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default TopNavPartnerDropdown;
