import React, { useEffect, useMemo, useState } from 'react';
import TopNav from './topNav';
import Footer from './footer';
import { useRecoilState } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { Link, Meta, Style } from 'react-head';
import { generateTheme } from '../helpers/theme';
import { GetGastronomy } from '../services/graphql/operations';
import AppInitOptionsService from '../services/appInitOptionsService';
import { currentThemeState } from '../store/app';
import useFontFaceObserver from '../hooks/useFontFaceObserver';
import { DEFAULT_GOOGLE_FONTS } from '../constants';
import PageNotFound from '../containers/pageNotFound';
import { useParams } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  route: GetsbyRoute;
}

const Layout: React.FC<Props> = ({
  children,
  route: {
    showFooter = true,
    showContainer = true,
    showNavigation = true,
    applyCustomTheme,
    useGastronomyTheme,
    isPartnerPage,
    params
  }
}) => {
  const apolloClient = useApolloClient();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const routerParams: {
    urlPathId?: string;
    receiptUuid?: string;
    postUuid?: string;
  } = useParams();
  const [initComplete, setInitComplete] = useState(false);
  const [redirectTo404, setRedirectTo404] = useState(false);
  const appInitOptions = AppInitOptionsService.getInstance().getOptions();
  const [currentTheme, setCurrentTheme] = useRecoilState(currentThemeState);

  const urlPathId = params?.urlPathId || routerParams.urlPathId;
  const postUuid = params?.postUuid || routerParams.postUuid;
  const receiptUuid = params?.receiptUuid || routerParams.receiptUuid;

  const fontList = useMemo(() => {
    if (currentTheme?.waitForFonts) {
      return [
        ...DEFAULT_GOOGLE_FONTS,
        ...currentTheme.waitForFonts.map((font) => {
          return { family: font };
        })
      ];
    }
    return DEFAULT_GOOGLE_FONTS;
  }, [currentTheme]);

  const { isFontListLoaded, hasErrors } = useFontFaceObserver(fontList);
  const renderContent = isFontListLoaded || hasErrors;

  useEffect(() => {
    if (
      receiptUuid ||
      postUuid ||
      (urlPathId && (!gastronomy || gastronomy?.url_path_id !== urlPathId)) ||
      useGastronomyTheme
    ) {
      apolloClient
        .query<GetGastronomyQuery, GetGastronomyQueryVariables>({
          query: GetGastronomy,
          variables: {
            params: {
              uuid: useGastronomyTheme,
              url_path_id: urlPathId,
              receipt_uuid: receiptUuid,
              newsletter_uuid: postUuid
            },
            includeCategories: true
          }
        })
        .then(({ data }) => {
          if (data.getGastronomy) {
            if (data.getGastronomy.custom_theme && applyCustomTheme) {
              setCurrentTheme({
                ...data.getGastronomy.custom_theme,
                type: 'GASTRONOMY'
              });
            } else {
              setCurrentTheme(null);
            }
            setGastronomy(data.getGastronomy);
          }
        })
        .catch((error) => {
          if (!postUuid) {
            console.log('getGastronomy in layout error', error);
            setRedirectTo404(true);
          }
        })
        .finally(() => setInitComplete(true));
    } else if (
      (!urlPathId && gastronomy) ||
      (urlPathId && gastronomy?.url_path_id === urlPathId)
    ) {
      if (gastronomy.custom_theme && applyCustomTheme) {
        setCurrentTheme({ ...gastronomy.custom_theme, type: 'GASTRONOMY' });
      } else {
        setCurrentTheme(null);
      }
      setInitComplete(true);
    } else {
      setCurrentTheme(null);
      setInitComplete(true);
    }
  }, [urlPathId, postUuid, receiptUuid]);

  const themeCss = useMemo(() => {
    if (currentTheme) {
      return generateTheme(currentTheme);
    }
  }, [currentTheme]);

  if (!initComplete) {
    return null;
  }

  const inset =
    typeof appInitOptions?.insets?.top === 'number'
      ? appInitOptions?.insets?.top
      : 0;

  return (
    <div className={showContainer ? 'bg-gray-100' : ''} id="layout">
      {currentTheme && (
        <>
          {currentTheme.type === 'GASTRONOMY' &&
            currentTheme.head?.map((tag, i) => (
              <React.Fragment key={i}>
                {tag.tagType === 'LINK' && <Link {...tag.attributes} />}
                {tag.tagType === 'META' && <Meta {...tag.attributes} />}
                {tag.tagType === 'STYLE' && (
                  <Style {...tag.attributes}>{tag.children}</Style>
                )}
              </React.Fragment>
            ))}
          {currentTheme?.colors?.primary?._500 && (
            <Meta
              name="theme-color"
              content={currentTheme.colors.primary._500}
            />
          )}
          {currentTheme?.enableZoom && (
            <Meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=3"
            />
          )}
          <Style>{themeCss}</Style>
        </>
      )}

      {renderContent && showNavigation && (
        <TopNav
          isPartnerPage={isPartnerPage}
          theme={currentTheme || undefined}
          inset={inset}
        />
      )}

      <main
        id="content"
        className={`${
          showContainer ? 'container bg-white shadow-lg mx-auto' : 'w-full'
        } flex flex-col justify-between h-full`}
        style={{ marginTop: appInitOptions ? inset + 56 : 0 + 'px' }}
      >
        {renderContent && (
          <>
            {redirectTo404 ? <PageNotFound /> : children}
            {showFooter && <Footer isCustomTheme={!!currentTheme} />}
          </>
        )}
      </main>
    </div>
  );
};

export default Layout;
