import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './layout';

const MakeRoute: React.FC<
  {
    path: string;
  } & GetsbyRoute
> = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => {
        if (route.applyLayout === false) {
          return <route.component {...props} />;
        }

        return (
          <Layout route={route}>
            <route.component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default MakeRoute;
