import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH } from '../constants';
import { tableTypeDisplay } from '../helpers/gastronomy';

interface Props {
  gastronomy: {
    is_in_radius?: boolean;
    options: { tableOrderIsRoomService?: boolean };
  };
  type: TableType;
}

const OrderTypeBadge: React.FC<Props> = ({ gastronomy, type }) => {
  const { t } = useTranslation();

  const disabled = type === 'DELIVERY' && gastronomy.is_in_radius === false;

  const title = disabled ? t('delivery.notInRadius') : '';

  const materialIconId = disabled ? 'wrong_location' : '';

  return (
    <span
      className={`inline-flex items-center px-3 py-1 mb-2 mr-2 text-sm font-semibold ${
        disabled
          ? 'bg-gray-100 text-secondary-200'
          : 'bg-gray-200 text-secondary-500'
      } rounded-full`}
      title={title}
    >
      {materialIconId ? (
        <i className="mr-2 text-sm material-icons">{materialIconId}</i>
      ) : (
        <img
          className="w-3 h-auto mr-2"
          src={`${IMG_BASE_PATH}/order_type/${type.toLowerCase()}.svg`}
          alt=""
        />
      )}
      {t(`tableType.${tableTypeDisplay(gastronomy, type)}`)}
    </span>
  );
};

export default OrderTypeBadge;
