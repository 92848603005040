import React from 'react';
import { useTranslation } from 'react-i18next';

const allergyInfo = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'L',
  'M',
  'N',
  'O',
  'P',
  'R'
];
const AllergyInformation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ul className="m-4 mt-20 text-sm text-secondary-200" id="allergen-list">
      <label
        className="font-semibold text-secondary-300"
        htmlFor="allergen-list"
      >
        {t('allergens.label')}
      </label>
      {allergyInfo.map((letter) => (
        <li key={letter} className="flex flex-row allergen-list-item">
          <div className="w-6">{letter}:</div>
          <div>{t('allergens.' + letter)}</div>
        </li>
      ))}
    </ul>
  );
};

export default AllergyInformation;
