import { add, isBefore, isFuture } from 'date-fns';
import { atom, SetterOrUpdater, selector, DefaultValue } from 'recoil';
import { PreorderRadioValue } from '../components/preorderDateSelectRadios';
import {
  LEAD_TIME_ASAP_END,
  STORAGE_KEY_PRELIMINARY_CREDIT
} from '../constants';
import { AvailabilityMixin } from '../helpers/availability';
import { DEFAULT_TIP_PERCENTAGE } from '../constants';
import { calculateDeliveryFee } from '../helpers/order';
import {
  gastronomyState,
  selectedTableLeadTime,
  selectedTableState
} from './gastronomy';
import { hasServiceTimes } from '../helpers/serviceTimes';
import { getDisabledUntilFromGastronomy } from '../helpers/gastronomy';
import { isStorageAvailable } from '../helpers/localStorage';
import {
  availableBonusProgramPointsState,
  useBonusProgramPointsState
} from './user';
import { calculateCentsFromBonusProgram } from '../helpers/bonusProgramHelper';

export const cartItemsAtom = atom<CartItem[]>({
  key: 'cartItems',
  default: []
});

export const cartItemsState = selector<CartItem[]>({
  key: 'cartItemsSelector',
  get: ({ get }) => {
    return get(cartItemsAtom);
  },
  set: ({ set, reset }, cartItems) => {
    set(cartItemsAtom, cartItems);
    if (Array.isArray(cartItems) && cartItems.length === 0) {
      reset(preorderSlotState);
      reset(redeemCreditState);
    }
  }
});

export const tipPercentageAtom = atom<number | null>({
  key: 'tipPercentage',
  default: null
});

export const tipPercentageState = selector<number>({
  key: 'tipPercentageSelector',
  get: ({ get }) => {
    const tipPercentage = get(tipPercentageAtom);
    if (tipPercentage !== null) {
      return tipPercentage;
    }
    const gastro = get(gastronomyState);
    if (
      gastro &&
      gastro.options.tipPercentage !== undefined &&
      gastro.options.tipPercentage !== null
    ) {
      return gastro.options.tipPercentage;
    }
    return DEFAULT_TIP_PERCENTAGE;
  },
  set: ({ set }, value) => {
    set(tipPercentageAtom, value);
  }
});

export const cartTotal = selector<number>({
  key: 'cartTotal',
  get: ({ get }) => {
    const cartItems = get(cartItemsState);

    let total = 0;

    for (const cartItem of cartItems) {
      let itemTotal = cartItem.product.gross_price || 0;

      if (cartItem.modifiers) {
        for (const modifier of cartItem.modifiers) {
          itemTotal += modifier.price_modifier || 0;
        }
      }

      total += itemTotal * cartItem.quantity;
    }

    return total;
  }
});

export const positiveCartTotal = selector<number>({
  key: 'positiveCartTotal',
  get: ({ get }) => {
    const cartItems = get(cartItemsState);

    let total = 0;

    for (const cartItem of cartItems) {
      let itemTotal = cartItem.product.gross_price || 0;

      if (cartItem.modifiers) {
        for (const modifier of cartItem.modifiers) {
          itemTotal += modifier.price_modifier || 0;
        }
      }

      if (itemTotal >= 0) {
        total += itemTotal * cartItem.quantity;
      }
    }

    return total;
  }
});

export const cartItemsCount = selector<number>({
  key: 'cartItemsCount',
  get: ({ get }) => {
    const items = get(cartItemsState);
    return items.reduce((prev, i) => prev + i.quantity, 0);
  }
});

export const tipState = selector<number>({
  key: 'tip',
  get: ({ get }) => {
    const total = get(positiveCartTotal);
    const percentage = get(tipPercentageState);
    const paymentMethod = get(paymentMethodState);

    if (paymentMethod === 'CASH') {
      return 0;
    }

    return total && total > 0 ? Math.round(total * (percentage / 100)) : 0;
  }
});

export const cartTotalInclTip = selector<number>({
  key: 'cartTotalInclTip',
  get: ({ get }) => {
    const total = get(cartTotal);
    const tip = get(tipState);
    const table = get(selectedTableState);

    const deliveryFee = table && total ? calculateDeliveryFee(table, total) : 0;

    const value = total ? total + tip + deliveryFee : 0;
    return value;
  }
});

export const cartTotalInclTipAndCredit = selector<number>({
  key: 'cartTotalInclTipAndCredit',
  get: ({ get }) => {
    const total = get(cartTotalInclTip);
    const credit = get(redeemCreditState);
    const hasCcProductsInCart = get(hasCcProductsInCartSelector);

    let value = total;

    if (credit) {
      value -=
        !credit.requires_cc_products ||
        (credit.requires_cc_products && hasCcProductsInCart)
          ? credit.value || 0
          : 0;
    }

    return value;
  }
});

export const cartEndSum = selector<number>({
  key: 'cartEndSum',
  get: ({ get }) => {
    const total = get(cartTotalInclTipAndCredit);
    const useBonusProgramPoints = get(useBonusProgramPointsState);
    const availableBonusProgramPoints = get(availableBonusProgramPointsState);
    const gastronomy = get(gastronomyState);

    let value = total;

    if (
      useBonusProgramPoints &&
      typeof availableBonusProgramPoints === 'number'
    ) {
      value -= calculateCentsFromBonusProgram({
        bonusProgram: gastronomy?.bonusProgram,
        points: useBonusProgramPoints
      });
    }

    return value > 0 ? value : 0;
  }
});

export const minimumOrderValueReachedSelector = selector<boolean>({
  key: 'minimumOrderValueReached',
  get: ({ get }) => {
    const table = get(selectedTableState);
    const total = get(positiveCartTotal);

    if (
      table &&
      total &&
      table.delivery_config &&
      table.delivery_config.minOrderAmount &&
      table.delivery_config.minOrderAmount >= total
    ) {
      return false;
    }
    return true;
  }
});

export const addCartItem = (
  cartItemToAdd: CartItem,
  cartItems: CartItem[],
  setCartItems: SetterOrUpdater<CartItem[]>
): void => {
  const index = cartItems.findIndex((item) => item.id === cartItemToAdd.id);
  console.log('add to cart: ', index, cartItemToAdd, cartItems);

  if (index === -1) {
    setCartItems([...cartItems, cartItemToAdd]);
  } else {
    setCartItems([
      ...cartItems.slice(0, index),
      {
        ...cartItemToAdd,
        quantity: cartItems[index].quantity + cartItemToAdd.quantity
      },
      ...cartItems.slice(index + 1)
    ]);
  }
};

// todo change this to pure cartitem
export const getLineItemIndex = (
  lineItems: LineItem[],
  lineItem: {
    product_uuid?: string;
    title?: string;
    comment?: string;
    price?: number;
    modifiers?: CartModifierItem[];
    is_canceled?: boolean;
  }
): number => {
  const index = lineItems.findIndex((item) => {
    return (
      item.title === lineItem.title &&
      item.price === lineItem.price &&
      item.comment === lineItem.comment &&
      item.is_canceled === lineItem.is_canceled &&
      areModifiersTheSame(item.modifiers, lineItem.modifiers)
    );
  });
  return index;
};

export const isProductInCart = (
  cartItems: CartItem[],
  product_uuid?: string
): boolean => {
  if (!product_uuid) {
    return false;
  }

  return cartItems.find((item) => item.product.uuid === product_uuid)
    ? true
    : false;
};

const areModifiersTheSame = (
  modifiersA?: CartModifierItem[],
  modifiersB?: CartModifierItem[]
): boolean => {
  if (modifiersA === modifiersB) {
    return true;
  } else if (modifiersA && modifiersB) {
    const aUuids = modifiersA.map((item) => item.uuid).sort();
    const bUuids = modifiersB.map((item) => item.uuid).sort();
    return JSON.stringify(aUuids) === JSON.stringify(bUuids);
  } else {
    return false;
  }
};

export const redeemCreditState = atom<{
  uuid?: string;
  value: number;
  code?: string;
  requires_otp?: boolean;
  valid_from?: string;
  valid_to?: string;
  combinable?: boolean;
  min_order_amount: number;
  requires_cc_products: boolean;
} | null>({
  key: 'redeemCredit',
  default: null
});

const getPreliminaryCreditDefault = () => {
  if (isStorageAvailable()) {
    const credit = localStorage.getItem(STORAGE_KEY_PRELIMINARY_CREDIT);

    if (credit) {
      return JSON.parse(credit);
    }
  }

  return null;
};

export const preliminaryCreditState = atom<PreliminaryCreditFragment | null>({
  key: 'preliminaryCredit',
  default: getPreliminaryCreditDefault(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newCredit) => {
        if (isStorageAvailable()) {
          if (newCredit) {
            localStorage.setItem(
              STORAGE_KEY_PRELIMINARY_CREDIT,
              JSON.stringify(newCredit)
            );
          } else {
            localStorage.removeItem(STORAGE_KEY_PRELIMINARY_CREDIT);
          }
        }
      });
    }
  ]
});

export const cartToLineItem = (cartItems: CartItem[]): LineItem[] => {
  const lineItems: LineItem[] = cartItems.map((cartItem) => ({
    id: cartItem.id,
    product_uuid: cartItem.product.uuid,
    title: cartItem.product.title,
    price: cartItem.product.gross_price,
    quantity: cartItem.quantity,
    comment: cartItem.comment,
    modifiers: cartItem.modifiers,
    image: cartItem.product.image_full,
    availability: cartItem.product.effective_availability
  }));

  return lineItems;
};

export const preorderSlotState = atom<{
  from: Date;
  to: Date;
  table_uuid: string;
  expires: number;
} | null>({
  key: 'preorderSlot',
  default: null,
  effects_UNSTABLE: [
    ({ resetSelf, onSet }) => {
      let timeout: NodeJS.Timeout | null = null;
      onSet((newSlot, oldSlot) => {
        if (timeout) clearTimeout(timeout);
        if (newSlot) {
          if (!(newSlot instanceof DefaultValue)) {
            timeout = setInterval(() => {
              if (newSlot.expires < new Date().getTime()) {
                console.log('clearing preorder slot because it expired');
                if (timeout) {
                  clearInterval(timeout);
                }
                resetSelf();
              }
            }, 1000);
          }
        }
      });
      return () => {
        if (timeout) {
          clearInterval(timeout);
        }
      };
    }
  ]
});

export const preorderRadioAtom = atom<PreorderRadioValue>({
  key: 'preorderRadio',
  default: PreorderRadioValue.undefined
});

export const preorderRadioState = selector<PreorderRadioValue>({
  key: 'preorderRadioState',
  get: ({ get }) => {
    const radio = get(preorderRadioAtom);
    if (radio === PreorderRadioValue.undefined) {
      const isAsapPossible = get(isAsapOrderPossibleSelector);
      const preorderSlot = get(preorderSlotState);
      if (isAsapPossible && !preorderSlot) {
        return PreorderRadioValue.asap;
      }
      if (preorderSlot) {
        return PreorderRadioValue.preorder;
      }
    }
    return radio;
  },
  set: ({ set }, value) => {
    set(preorderRadioAtom, value);
  }
});

export const preorderModalState = atom<{
  showModal: boolean;
  title?: string | React.ReactNode;
  closeOnSelect?: boolean;
  availability?: AvailabilityMixin;
  productGroupUuid?: string;
  actionButton?: () => void;
}>({
  key: 'preorderModal',
  default: { showModal: false }
});

export const cartFormErrorsState = atom<CartFormFieldNames[]>({
  key: 'cartFormErrors',
  default: []
});

export const isAsapOrderPossibleSelector = selector<boolean>({
  key: 'isAsapOrderPossibleSelector',
  get: ({ get }) => {
    let isPossible = false;
    const gastronomy = get(gastronomyState);
    const table = get(selectedTableState);

    if (gastronomy && table && table.table_type) {
      const disabledUntil = getDisabledUntilFromGastronomy(
        table.table_type,
        gastronomy
      );

      if (disabledUntil && isFuture(new Date(disabledUntil))) {
        return false;
      }
    }

    if (gastronomy && gastronomy.isOpen) {
      if (table && table.effective_service_times && hasServiceTimes(table)) {
        const range = new AvailabilityMixin(
          table.effective_service_times
        ).timerangeAt(new Date());
        const leadTime = get(selectedTableLeadTime);
        if (
          range.length > 0 &&
          (!leadTime ||
            (leadTime <= LEAD_TIME_ASAP_END &&
              isBefore(add(new Date(), { minutes: leadTime }), range[1])))
        ) {
          isPossible = true;
        }
      } else {
        isPossible = true;
      }
    }
    return isPossible;
  }
});

export const hasCcProductsInCartSelector = selector<boolean>({
  key: 'hasCcProductsInCartSelector',
  get: ({ get }) => {
    const items = get(cartItemsState);

    return items.filter((i) => i.product.is_cc_product).length ? true : false;
  }
});

export const showCcProductsModalState = atom({
  key: 'showCcProductsModal',
  default: false
});

export const hasCcMealDealActiveSelector = selector<boolean>({
  key: 'hasCcMealDealActiveSelector',
  get: ({ get }) => {
    const preliminaryCredit = get(preliminaryCreditState);
    const credit = get(redeemCreditState);

    if (
      (credit && credit.requires_cc_products) ||
      (preliminaryCredit && preliminaryCredit.requires_cc_products)
    ) {
      return true;
    }

    return false;
  }
});

export const paymentMethodState = atom<PaymentMethodType | null>({
  key: 'paymentMethod',
  default: null
});
