import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import {
  GetCategories,
  GetProductGroupsInclProducts
} from '../services/graphql/operations';
import CategoryList from './categoryList';
import RestaurantMenuHtmlHeader from './restaurantMenuHtmlHeader';
import RestaurantViewLoader from './restaurantViewLoader';
import ServiceTimesNote from './serviceTimesNote';
import ProductGroupList from './productGroupList';
import Alert from './alert';
import Fuse from 'fuse.js';
import { enhanceProduct, filterEmptyCategories } from '../helpers/utilities';
import RestaurantMenuInfoCards from './restaurantMenuInfoCards';
import { useRecoilValue } from 'recoil';
import {
  isMenuOnlySelector,
  selectedCategoryState,
  selectedTableState
} from '../store/gastronomy';
import FeaturedProduct from './featuredProduct';
import { canOrderAt, getEffectiveAvailability } from '../helpers/serviceTimes';
import { AvailabilityMixin } from '../helpers/availability';
import { flatMap } from 'lodash';
import { selectedLanguageCodeState } from '../store/app';

interface Props {
  gastronomy: GastronomyFragment;
  tableType: TableType | null;
}

export type EnhancedProduct = ProductFragment &
  Pick<ProductGroup, 'effective_availability'> & {
    productGroupUuid?: string;
  };

const RestaurantMenu: React.FC<Props> = ({ gastronomy, tableType }) => {
  const selectedCategory = useRecoilValue(selectedCategoryState);
  const selectedTable = useRecoilValue(selectedTableState);
  const selectedLanguageCode = useRecoilValue(selectedLanguageCodeState);
  const isMenuOnly = useRecoilValue(isMenuOnlySelector);
  const { loading, error, data } = useQuery<
    GetProductGroupsInclProductsQuery,
    GetProductGroupsInclProductsQueryVariables
  >(GetProductGroupsInclProducts, {
    skip: !isMenuOnly && !tableType,
    variables: {
      params: {
        user_uuid: gastronomy.uuid,
        orderType: tableType || undefined,
        languageCode: selectedLanguageCode
      }
    },
    fetchPolicy: 'network-only'
  });

  const productGroups = data && data.getProductGroups;
  const fuse = useMemo(() => {
    if (productGroups) {
      const options = {
        includeScore: false,
        threshold: 0.3,
        keys: ['title']
      };
      const products = flatMap(productGroups, (pg) => {
        return pg.products ? pg.products.map((p) => enhanceProduct(p, pg)) : [];
      });
      return new Fuse(products, options);
    }
  }, [productGroups]);

  const featuredProduct = useMemo(() => {
    if (gastronomy.categories) {
      const selCategory = gastronomy.categories.find(
        (cat) => cat.uuid === selectedCategory
      );
      if (selCategory?.featured_product_uuid && productGroups) {
        for (const productGroup of productGroups) {
          const product = productGroup.products?.find(
            (prod) => prod.uuid === selCategory.featured_product_uuid
          );
          if (product) {
            let availability;
            if (productGroup.effective_availability) {
              const availabilityTimes = getEffectiveAvailability(
                productGroup.effective_availability,
                selectedTable
              );
              if (availabilityTimes) {
                availability = new AvailabilityMixin(availabilityTimes);
              }
            }
            return {
              product,
              availability,
              canOrder: availability ? canOrderAt(availability) !== false : true
            };
          }
        }
      }
    }
    return undefined;
  }, [productGroups, gastronomy, selectedCategory, selectedTable]);

  const [getCategoriesQuery, { data: categoriesData }] =
    useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
      GetCategories
    );

  useEffect(() => {
    if (gastronomy && selectedLanguageCode) {
      getCategoriesQuery({
        variables: {
          params: {
            user_uuid: gastronomy.uuid,
            languageCode: selectedLanguageCode
          }
        }
      });
    }
  }, [selectedLanguageCode]);

  return (
    <>
      {loading ? (
        <RestaurantViewLoader />
      ) : (
        <>
          {error && (
            <Alert type="error" title="Fehler" message={error.message} />
          )}
          {fuse && productGroups && (
            <>
              <RestaurantMenuHtmlHeader
                productGroups={productGroups}
                gastronomy={gastronomy}
              />
              <ServiceTimesNote />
              <RestaurantMenuInfoCards />
              <CategoryList
                categories={filterEmptyCategories({
                  categories:
                    categoriesData?.getCategories || gastronomy.categories,
                  productGroups
                })}
              />
              {featuredProduct && <FeaturedProduct {...featuredProduct} />}
              <ProductGroupList productGroups={productGroups} fuse={fuse} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default RestaurantMenu;
