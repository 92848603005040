import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import MakeRoute from './components/MakeRoute';
import BonusProgram from './containers/bonusProgram';
import CustomPage from './containers/customPage';
import DownloadApp from './containers/downloadApp';
import GuestRegistration from './containers/guestRegistration';
import PageNotFound from './containers/pageNotFound';
import RestaurantView from './containers/restaurantView';
import { GetPages } from './services/graphql/operations';
import { appConfigState } from './store/app';

const SubRouter: React.FC<RouteComponentProps<{ urlPathId: string }>> = ({
  match
}) => {
  const [routes, setRoutes] = useState<GetsbyRoutes>({});
  const urlPathId = match.params.urlPathId;
  const appConfig = useRecoilValue(appConfigState);

  useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPages, {
    variables: { params: { gastronomyUrlPathId: urlPathId } },
    skip: !urlPathId,
    onCompleted: (data) => getSubRoutes(data.getPages),
    onError: () => getSubRoutes()
  });

  const getSubRoutes = async (pages: PageFragment[] = []) => {
    /* eslint react/display-name: 0 */
    const subRoutes: GetsbyRoutes = {
      '/:urlPathId': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <Redirect to={`/${routeProps.match.params.urlPathId}/speisekarte`} />
        )
      },
      '/:urlPathId/speisekarte': {
        exact: true,
        applyCustomTheme: true,
        component: RestaurantView
      },
      '/:urlPathId/corona': {
        exact: true,
        applyCustomTheme: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <GuestRegistration urlPathId={routeProps.match.params.urlPathId} />
        )
      },
      '/:urlPathId/bonus': {
        exact: true,
        applyCustomTheme: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <BonusProgram urlPathId={routeProps.match.params.urlPathId} />
        )
      },
      '/:urlPathId/download-app': {
        exact: true,
        applyCustomTheme: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <DownloadApp
            gastronomyUrlPathId={routeProps.match.params.urlPathId}
          />
        )
      }
    };

    for (const page of pages) {
      subRoutes[`/:urlPathId${page.url_path}`] = {
        exact: true,
        showNavigation: page.show_navigation,
        showContainer: page.show_container,
        showFooter: page.show_footer,
        useGastronomyTheme: page.user_uuid,
        applyCustomTheme: true,
        component: () => <CustomPage html={page.html} css={page.css} />
      };
    }

    if (appConfig?.router) {
      for (const route of appConfig.router) {
        const oldRoute = subRoutes[route.path];

        if (!oldRoute) {
          continue;
        }

        subRoutes[route.overwrite] = {
          ...oldRoute,
          params: route.params
        };
      }
    }

    subRoutes['*'] = {
      exact: false,
      component: PageNotFound
    };

    setRoutes(subRoutes);
  };

  return (
    <>
      <Switch>
        {Object.keys(routes).map((key, index) => (
          <MakeRoute key={index} path={key} {...routes[key]} />
        ))}
      </Switch>
    </>
  );
};

export default SubRouter;
