import React from 'react';
import PressBanner from './pressBanner';
import WavesTop from './wavesTop';
interface Props {
  title: string;
  subTitle?: React.ReactNode;
  keywords?: string;
  callToAction?: JSX.Element;
  customLeft?: React.ReactNode;
  right?: React.ReactNode;
  renderWaves?: boolean;
  renderPressBanner?: boolean;
}

const Hero: React.FC<Props> = ({
  title,
  subTitle,
  keywords,
  callToAction,
  customLeft,
  right,
  renderWaves = true,
  renderPressBanner = true
}: Props) => {
  return (
    <>
      <div className="text-white bg-primary-500">
        <div className="py-8">
          <div className="container flex flex-col flex-wrap items-center justify-between px-6 mx-auto md:flex-row">
            <div className="flex flex-col items-start justify-center w-full text-center md:w-1/2 lg:w-2/5 md:text-left">
              {customLeft ? (
                <React.Fragment>{customLeft}</React.Fragment>
              ) : (
                <React.Fragment>
                  {keywords && (
                    <p className="w-full uppercase tracking-loose">
                      {keywords}
                    </p>
                  )}
                  {title && (
                    <h1 className="my-4 text-4xl font-bold leading-tight md:text-5xl">
                      {title}
                    </h1>
                  )}
                  {subTitle && (
                    <div className="mb-8 text-xl leading-normal md:text-2xl text-secondary-500">
                      {subTitle}
                    </div>
                  )}
                  {callToAction}
                </React.Fragment>
              )}
            </div>

            <div className="items-center w-full text-center md:w-1/2 lg:w-3/5">
              {right && <React.Fragment>{right}</React.Fragment>}
            </div>
          </div>
        </div>
        {renderWaves && <WavesTop />}
      </div>
      {renderPressBanner && (
        <PressBanner showHeading={false} classes="bg-white" />
      )}
    </>
  );
};

export default Hero;
