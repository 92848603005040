import React from 'react';

interface Props {
  text: string;
  classes?: string;
}

const Heading: React.FC<Props> = ({ text, classes }: Props) => {
  return (
    <div className={`mb-4 border-b ${classes}`}>
      <h1 className="text-3xl font-semibold">{text}</h1>
    </div>
  );
};

export default Heading;
