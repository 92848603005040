import React from 'react';
import GetsbyLink from './getsbyLink';

interface Props {
  title: string;
  path: string;
  isActiveFn?: () => boolean;
  mobileButton?: boolean;
}

const TopNavButton: React.FC<Props> = ({
  title,
  path,
  isActiveFn,
  mobileButton = false
}) => {
  const isActive = (): boolean => {
    if (isActiveFn) {
      return isActiveFn();
    }

    return location.pathname.startsWith(path);
  };

  if (mobileButton) {
    return (
      <GetsbyLink
        to={path}
        className={`block px-3 py-2 my-1 text-base font-medium leading-5 transition duration-150 ease-in-out rounded-md focus:outline-none hover:bg-primary-500 hover:text-secondary-500 ${
          isActive() ? 'bg-primary-500 text-secondary-500' : 'text-primary-500'
        }`}
      >
        {title}
      </GetsbyLink>
    );
  }

  return (
    <GetsbyLink
      to={path}
      className={`flex items-center p-2 mr-2 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded focus:outline-none hover:bg-primary-500 hover:text-secondary-500 ${
        isActive() ? 'bg-primary-500 text-secondary-500' : 'text-primary-500'
      }`}
    >
      {title}
    </GetsbyLink>
  );
};

export default TopNavButton;
