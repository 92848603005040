import React, { useMemo } from 'react';
import Picture from './Picture';
import getsby from '@getsby-devs/getsby-commons';

interface GroupedOrderItem {
  id: string;
  orderItemsUuids: string[];
  amount: number;
  price: number;
  productTitle: string;
  productPrintTitle?: string | null;
  productUuid?: string | null;
  productImage?: string | null;
  comment?: string | null;
  discount: number;
  getsby_discount: number;
  is_canceled: boolean;
  productGroupType?: string;
  productGroupTitle?: string;
  order?: string;
  modifierItems?: FullReceiptModifierItemFragment[];
}

interface Props {
  orderItem: GroupedOrderItem;
}

const OrderItem: React.FC<Props> = ({ orderItem }) => {
  const groupedModifiers = useMemo(() => {
    return orderItem.modifierItems
      ? getsby.groupOrderItemModifierItems(orderItem.modifierItems)
      : [];
  }, [orderItem]);

  return (
    <tr
      className={`items-center ${
        orderItem.is_canceled ? 'line-through text-red-500' : ''
      }`}
    >
      <td className="hidden py-2 md:table-cell">
        {orderItem.productImage && orderItem.productImage.length > 0 && (
          <Picture
            imageSource={orderItem.productImage}
            className="flex items-center w-12 h-12 mx-auto overflow-hidden rounded-full md:w-16 md:h-16"
            width={64}
          />
        )}
      </td>
      <td className="">
        <p className="font-bold">{orderItem.productTitle}</p>
        {groupedModifiers.map((mod, index) => (
          <div className="mb-2" key={index}>
            <span className="mr-2 font-semibold md:ml-4">{mod.title}:</span>
            <span>{mod.items.map((item) => item.title).join(', ')}</span>
          </div>
        ))}
        {orderItem.comment && (
          <div className="flex flex-row mb-2 align-middle md:ml-4">
            <i className="material-icons">message</i>
            <div className="ml-2">{orderItem.comment}</div>
          </div>
        )}
      </td>
      <td className="text-center">{orderItem.amount}</td>
      <td className="hidden text-right md:table-cell">
        <span className="text-sm font-medium lg:text-base">
          {(orderItem.price / 100).toFixed(2)} €
        </span>
      </td>
      <td className="text-right">
        <span className="text-sm font-medium lg:text-base">
          {((orderItem.price * orderItem.amount) / 100).toFixed(2)} €
        </span>
      </td>
    </tr>
  );
};

export default OrderItem;
