import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

export const emailIsValid = (input: string): boolean => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(input);
};

export const getCognitoUserAttributes = (
  user: CognitoUser
): Promise<CognitoUserAttribute[]> => {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((error, result) => {
      if (error) {
        reject(error);
      }
      if (result) {
        resolve(result);
      }
    });
  });
};

export const getUserUuid = async (
  user: CognitoUser
): Promise<string | undefined> => {
  const attributes = await getCognitoUserAttributes(user);
  for (const attribute of attributes) {
    if (attribute.getName() === 'sub') {
      return attribute.getValue();
    }
  }
};

export const getUserEmail = async (
  user: CognitoUser
): Promise<string | undefined> => {
  const attributes = await getCognitoUserAttributes(user);
  for (const attribute of attributes) {
    if (attribute.getName() === 'email') {
      return attribute.getValue();
    }
  }
};

export const getUserIdentityProvider = async (
  user: CognitoUser
): Promise<string | undefined> => {
  const attributes = await getCognitoUserAttributes(user);
  for (const attribute of attributes) {
    if (attribute.getName() === 'identities') {
      const identities = JSON.parse(attribute.getValue());
      if (identities && identities[0] && identities[0].providerName) {
        return identities[0].providerName;
      }
    }
  }
};

export const refreshToken = (
  provider: 'google' | 'facebook' | 'apple'
): Promise<{ token: string; expires_at: number }> => {
  return new Promise((resolve, reject) => {
    window.refreshSignInResult = (result) => {
      if (result && result.token) {
        resolve({ token: result.token, expires_at: result.expires_at });
      } else {
        reject();
      }
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'refreshSignIn', provider })
    );
  });
};

export const refreshFBWebToken = ():
  | { token: string; expires_at: number }
  | undefined => {
  if (FB) {
    const authResponse = FB.getAuthResponse();

    return authResponse
      ? {
          token: authResponse.accessToken,
          expires_at: new Date().getTime() + authResponse.expiresIn
        }
      : undefined;
  }
};
export const refreshGoogleWebToken = async (): Promise<
  { token: string; expires_at: number } | undefined
> => {
  if (window.gapi && window.gapi.auth2) {
    console.log('google api is available');
    try {
      const auth =
        window.gapi.auth2.getAuthInstance() ||
        window.gapi.auth2.init({
          client_id:
            '816600227972-4f3i8v4hk9r5cuo0ns2fmil84cs769s6.apps.googleusercontent.com',
          cookie_policy: 'single_host_origin',
          scope: 'profile email openid'
        });
      const user = await auth.signIn();
      const { id_token, expires_at } = user.getAuthResponse();
      return { token: id_token, expires_at };
    } catch (err) {
      console.log('google refresh error', err);
    }
  }
};
