import React from 'react';
import { useTranslation } from 'react-i18next';

const MenuOnlyBadge: React.FC = () => {
  const { t } = useTranslation();

  return (
    <span
      className={`inline-flex items-center px-3 py-1 mb-2 mr-2 text-sm font-semibold bg-gray-200 text-secondary-500 rounded-full`}
      title={t('tableType.MENUONLY')}
    >
      <i className="mr-2 text-sm material-icons">menu_book</i>
      {t('tableType.MENUONLY')}
    </span>
  );
};

export default MenuOnlyBadge;
