import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from './button';
import Modifier from './modifier';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  cartItemsState,
  addCartItem,
  isProductInCart,
  preorderSlotState,
  preorderModalState,
  cartTotal
} from '../store/cart';
import { GetProductModifiers } from '../services/graphql/operations';
import Spinner from './spinner';
import Alert from './alert';
import {
  canOrderAtSelectedTimeOnTableSelector,
  gastronomyState,
  isMenuOnlySelector,
  selectedTableState
} from '../store/gastronomy';
import { useTranslation } from 'react-i18next';
import Picture from './Picture';
import { useQuery } from '@apollo/client';
import Modal from './modal';
import {
  checkForIOS,
  getModifierByUuid,
  getModifierItemByUuid,
  getProductPriceFromModifiers
} from '../helpers/utilities';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import OpeningTimes from './openingTimes';
import { getReadableOpeningTimes } from '../helpers/serviceTimes';
import { AvailabilityMixin } from '../helpers/availability';
import { isFuture } from 'date-fns';
import { getDisabledUntilFromGastronomy } from '../helpers/gastronomy';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Heading from './heading';
import generateCartItemHash from '../helpers/orderItem';
import { selectedLanguageCodeState } from '../store/app';
import ProductPlusMinus from './productPlusMinus';
import useQueryParams from '../helpers/useQueryParams';

interface Props {
  product: ProductListItemData;
  availability?: AvailabilityMixin;
  canOrder: boolean;
  featured?: boolean;
}

const Product: React.FC<Props> = ({
  product,
  availability,
  canOrder,
  featured = false
}: Props) => {
  const [cartItems, setCartItems] = useRecoilState(cartItemsState);
  const isMenuOnly = useRecoilValue(isMenuOnlySelector);
  const gastronomy = useRecoilValue(gastronomyState);
  const selectedTable = useRecoilValue(selectedTableState);
  const preorderSlot = useRecoilValue(preorderSlotState);
  const setShowPreorderModal = useSetRecoilState(preorderModalState);
  const canOrderAtSelectedTimeOnTable = useRecoilValue(
    canOrderAtSelectedTimeOnTableSelector
  );

  const [t] = useTranslation();
  const formMethods = useForm<ProductCartFormData>();
  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = formMethods;
  const formChanges = watch();
  const isIOS = useMemo(() => checkForIOS(), []);
  const { pushInstruction } = useMatomo();
  const total = useRecoilValue(cartTotal);
  const selectedLanguageCode = useRecoilValue(selectedLanguageCodeState);

  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [productPrice, setProductPrice] = useState<number>(0);
  const [showModifierModal, setShowModifierModal] = useState<boolean>(false);

  const queryParams = useQueryParams();

  const imageDivRef = useRef<HTMLDivElement>(null);

  const { data, error, loading } = useQuery<
    GetProductModifiersQuery,
    GetProductModifiersQueryVariables
  >(GetProductModifiers, {
    variables: { uuid: product.uuid, languageCode: selectedLanguageCode },
    skip: !showModifierModal
  });

  const modifiers = useMemo(() => {
    if (data && data.getProduct && data.getProduct.modifier) {
      return data.getProduct.modifier;
    }
    return [];
  }, [data]);

  const nowAvailable = useMemo(
    () =>
      !availability ||
      availability.timerangeAt(preorderSlot ? preorderSlot.from : new Date())
        .length > 0,
    [availability, preorderSlot]
  );

  const isInCart = useMemo(() => {
    return isProductInCart(cartItems, product.uuid);
  }, [cartItems, product]);

  const showPreorder = useMemo(() => {
    if (gastronomy?.isActive) {
      if (canOrderAtSelectedTimeOnTable && nowAvailable) {
        return false;
      }
      if (selectedTable && selectedTable.canPreorderNow) {
        return true;
      }
    }
    return false;
  }, [gastronomy, selectedTable, canOrderAtSelectedTimeOnTable, nowAvailable]);

  const [submitButtonText, buttonEnabled] = useMemo(() => {
    let text = 'error';
    let buttonEnabled = false;
    const amount = (productPrice / 100).toFixed(2);

    if (gastronomy?.isActive) {
      if (canOrder === false) {
        text = t('shoppingCart.cannotOrder');
      } else if (
        product.out_of_stock_until &&
        isFuture(new Date(product.out_of_stock_until))
      ) {
        text = t('shoppingCart.outOfStock');
      } else if (canOrderAtSelectedTimeOnTable && nowAvailable) {
        text = `${t('shoppingCart.addToCart')} (${amount} €)`;
        buttonEnabled = true;
      } else {
        if (selectedTable?.canPreorderNow) {
          text = t('shoppingCart.submitButton.preOrder') + ` (${amount} €)`;
          buttonEnabled = true;
        } else if (selectedTable && selectedTable.table_type) {
          const disabledUntil = getDisabledUntilFromGastronomy(
            selectedTable.table_type,
            gastronomy
          );

          if (disabledUntil && isFuture(new Date(disabledUntil))) {
            text = t(`tableType.disabled`, {
              tableType: selectedTable.table_type
            });
          } else {
            text = t('preorder.noPreorder');
          }
        } else {
          text = t('shoppingCart.cannotOrder');
        }
      }
    } else {
      text = t('gastronomies.closed');
    }

    return [text, buttonEnabled];
  }, [
    gastronomy,
    canOrder,
    canOrderAtSelectedTimeOnTable,
    nowAvailable,
    selectedTable,
    productPrice
  ]);

  useEffect(() => {
    const priceChange = getProductPriceFromModifiers(formChanges, modifiers);

    if (product && typeof product.gross_price === 'number') {
      setProductPrice(product.gross_price + priceChange);
    }
  }, [formChanges]);

  useEffect(() => {
    if (isIOS && errors) {
      const firstError = Object.keys(errors)[0];

      if (!firstError) {
        return;
      }

      const elements = document.getElementsByName(firstError);

      if (!elements) {
        return;
      }
      const first = elements.item(0);

      if (!first) {
        return;
      }

      first.scrollIntoView({ block: 'center' });
      first.focus();
    }
  }, [errors, isIOS]);

  const onSubmit =
    (additionalData: {
      skipPreorderModal: boolean;
    }): SubmitHandler<ProductCartFormData> =>
    (data, e) => {
      const { skipPreorderModal } = additionalData;
      console.log('data on submit', data, skipPreorderModal);

      if (!skipPreorderModal && showPreorder) {
        setShowPreorderModal({
          showModal: true,
          title: (
            <div className="flex flex-col">
              <div>
                {t('preorder.productAvailability', {
                  title: product.title
                })}
              </div>
              {availability && (
                <OpeningTimes
                  containerClassName="text-xs mt-2"
                  times={getReadableOpeningTimes(availability.opening)}
                />
              )}
            </div>
          ),
          availability,
          productGroupUuid: product.productGroupUuid,
          actionButton: () => {
            handleSubmit(onSubmit({ skipPreorderModal: true }))();
          }
        });

        return;
      }

      if (
        gastronomy &&
        gastronomy.isActive &&
        (gastronomy.isOpen || gastronomy.options.hasPreOrder)
      ) {
        let cartModifiers: CartModifierItem[] = [];

        for (const key of Object.keys(data)) {
          if (Array.isArray(data[key])) {
            const mod = getModifierByUuid(modifiers, key);
            const currentValue = data[key];

            if (mod && Array.isArray(currentValue)) {
              const newModifiers = [];

              for (const newModUuid of currentValue) {
                const item = getModifierItemByUuid(mod, newModUuid);

                if (item) {
                  newModifiers.push({
                    ...item,
                    modifier_title: mod.title,
                    modifier_order: mod.order
                  });
                }
              }

              cartModifiers = [...cartModifiers, ...newModifiers];
            }
          } else {
            const mod = getModifierByUuid(modifiers, key);
            const currentValue = data[key];

            if (mod && typeof currentValue === 'string') {
              const item = getModifierItemByUuid(mod, currentValue);

              if (item) {
                cartModifiers.push({
                  ...item,
                  modifier_title: mod.title,
                  modifier_order: mod.order
                });
              }
            }
          }
        }

        const itemToAdd: CartItem = {
          id: generateCartItemHash({
            product_uuid: product.uuid,
            modifiers: cartModifiers
          }),
          product,
          quantity: 1,
          modifiers: cartModifiers,
          gastronomy: {
            uuid: gastronomy.uuid as string,
            name: gastronomy.name as string
          }
        };

        addCartItem(itemToAdd, cartItems, setCartItems);
        setShowModifierModal(false);
        reset();
        const gross_price = product.gross_price || 0;
        pushInstruction(
          'addEcommerceItem',
          product.uuid,
          product.title,
          gross_price / 100
        );
        pushInstruction(
          'trackEcommerceCartUpdate',
          total ? (total + gross_price) / 100 : 0
        );
      }
    };

  const handleClickOnProduct = () => {
    if (isMenuOnly) {
      return;
    }
    if (product.hasModifier) {
      setShowModifierModal(true);
    } else {
      if (buttonEnabled) {
        handleSubmit(onSubmit({ skipPreorderModal: false }))();
      }
    }
  };

  const openImageModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  if (!gastronomy) {
    return null;
  }

  const submitButtonRender = (onClick?: () => void) => {
    return (
      <Button
        type={buttonEnabled ? 'primary' : 'default'}
        disabled={!buttonEnabled}
        buttonType={'submit'}
        classes={'w-full'}
        onClick={onClick}
      >
        {submitButtonText}
      </Button>
    );
  };

  const getImageWidth = () => {
    if (imageDivRef.current) {
      if (imageDivRef.current.offsetWidth < 120) {
        return 120;
      }
      if (imageDivRef.current.offsetWidth < 200) {
        return 200;
      }
    }
    return 600;
  };

  const hideAddButton = queryParams.has('isMenuOnly')
    ? queryParams.get('isMenuOnly') === '1'
    : false;

  const productDescription: React.ReactNode = (
    <p className="text-sm break-words lg:text-base text-secondary-200 product-description">
      {product.description}
      {Array.isArray(product.allergenList) &&
        product.allergenList.length > 0 && (
          <span> ({product.allergenList.join(', ')})</span>
        )}
    </p>
  );

  const imageModal = (
    <Modal onClose={closeImageModal}>
      <Heading text={product.alias || product.title} />
      {product.image_full && (
        <Picture
          imageSource={product.image_full}
          className="w-full overflow-hidden rounded-md"
          width={480}
          quality={35}
          showSpinner={true}
        />
      )}
      {productDescription}
      {!isMenuOnly && (
        <div className="mt-4">
          {submitButtonRender(() => {
            handleClickOnProduct();
            setShowImageModal(false);
          })}
        </div>
      )}
    </Modal>
  );

  const modifierModal = (
    <Modal
      onClose={() => {
        console.log('closing');
        setShowModifierModal(false);
      }}
    >
      <Heading text={product.alias || product.title} />
      {productDescription}
      {loading ? (
        <Spinner size="small" center={true} />
      ) : (
        <>
          {error ? (
            <Alert
              type="error"
              title={t('alerts.error')}
              message={error.message}
            />
          ) : (
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(onSubmit({ skipPreorderModal: false }))}
              >
                <div className="mb-4">
                  {modifiers &&
                    modifiers.map(
                      (modifier, index) =>
                        modifier && (
                          <Modifier
                            modifier={modifier}
                            key={index.toString()}
                            isProductAvailable={nowAvailable || false}
                          />
                        )
                    )}
                  {submitButtonRender()}
                </div>
              </form>
            </FormProvider>
          )}
        </>
      )}
    </Modal>
  );

  const landscape =
    (product.image_metadata &&
      product.image_metadata.dimensions.width >
        product.image_metadata.dimensions.height) ||
    false;

  return (
    <>
      {showImageModal && imageModal}
      <div
        className={`p-2 ${
          isMenuOnly
            ? ''
            : buttonEnabled
            ? 'cursor-pointer'
            : 'cursor-not-allowed'
        } product-list-item ${
          isInCart ? ' border-primary-500 border-l-2' : ''
        }`}
        onClick={handleClickOnProduct}
      >
        {product.out_of_stock_until &&
          isFuture(new Date(product.out_of_stock_until)) && (
            <div
              className="absolute top-0 right-0 w-32 text-xs font-semibold text-center text-white transform rotate-45 bg-red-500 shadow-lg whitespace-nowrap product-list-item-ribbon"
              style={{ marginTop: '10px', marginRight: '-45px' }}
            >
              {t('soldOut').toUpperCase()}
            </div>
          )}
        <div
          className={`flex ${
            landscape && featured ? 'flex-col sm:flex-row' : 'flex-row'
          } w-full h-full select-none`}
        >
          {product.image_full && product.image_full.length > 0 && (
            <div
              ref={imageDivRef}
              className={`mr-4 ${
                featured
                  ? landscape
                    ? 'w-full sm:w-1/2 mb-2'
                    : 'w-1/2 sm:w-1/3'
                  : 'w-1/4'
              } product-list-item-image-container`}
              onClick={openImageModal}
            >
              {imageDivRef.current && product.image_full && (
                <Picture
                  imageSource={product.image_full}
                  className="overflow-hidden rounded cursor-pointer product-list-item-image"
                  width={featured && !landscape ? undefined : getImageWidth()}
                  height={featured && landscape ? undefined : getImageWidth()}
                  quality={featured ? 50 : 35}
                  fit={featured ? 'fill' : 'crop'}
                />
              )}
              {/*showDetails && (
                    <span className="absolute bottom-0 right-0 flex items-center justify-center px-2 py-1 rounded bg-primary-500">
                      <i className="text-sm text-white material-icons">
                        search
                      </i>
                    </span>
                  )*/}
            </div>
          )}
          <div className="flex flex-col flex-1 w-full">
            <div className="flex flex-row justify-between">
              <h3
                className={`font-semibold flex-wrap break-words product-list-item-title ${
                  nowAvailable && buttonEnabled
                    ? 'text-secondary-500'
                    : 'text-secondary-200'
                }`}
              >
                {product.alias || product.title}
                <span className="ml-2 text-green-500 product-list-item-v-type">
                  {product.type === 'VEGETARIAN'
                    ? 'V'
                    : product.type === 'VEGAN'
                    ? 'V+'
                    : ''}
                </span>
              </h3>
              <div className="">
                <div
                  className={`flex font-bold justify-end flex-nowrap ml-2 whitespace-nowrap ${
                    nowAvailable && buttonEnabled
                      ? 'text-primary-500'
                      : 'text-secondary-200'
                  } product-list-item-price`}
                >
                  {product.hasPriceModifier ? t('menu.fromPrice') + ' ' : ''}
                  {((product.gross_price || 0) / 100).toFixed(2)} €
                </div>
                {typeof product.discount === 'number' &&
                  product.discount > 0 && (
                    <div className="flex justify-end text-sm text-red-500 line-through product-list-item-discount">
                      {((product.gross_price + product.discount) / 100).toFixed(
                        2
                      )}{' '}
                      €
                    </div>
                  )}
              </div>
            </div>
            {productDescription}
            {!buttonEnabled && !isMenuOnly && (
              <div className="flex items-center mt-4 text-sm font-bold text-secondary-200">
                <span className="mr-2 text-base material-icons">warning</span>
                {submitButtonText}
              </div>
            )}
          </div>
          {canOrder &&
            buttonEnabled &&
            !isMenuOnly &&
            hideAddButton !== true && (
              <ProductPlusMinus
                productUuid={product.uuid}
                onIncrease={handleClickOnProduct}
                className="ml-2"
              />
            )}
        </div>
      </div>
      {showModifierModal && modifierModal}
    </>
  );
};

export default Product;
