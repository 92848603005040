import { atom, SetterOrUpdater } from 'recoil';

export const snackbarState = atom<Snackbar[]>({
  key: 'snackbars',
  default: []
});

export const addSnackbar = (
  snackbar: Snackbar,
  setSnackbars: SetterOrUpdater<Snackbar[]>
): void => {
  if (!snackbar) {
    return;
  }

  setSnackbars((oldNotifications) => [...oldNotifications, snackbar]);
};

export const removeSnackbar = (
  snackbar: Snackbar,
  setSnackbars: SetterOrUpdater<Snackbar[]>
): void => {
  if (!snackbar) {
    return;
  }

  setSnackbars((currentSnackbars) =>
    currentSnackbars.filter(
      (n) => JSON.stringify(n) !== JSON.stringify(snackbar)
    )
  );
};
