import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { EnhancedProduct } from '../components/restaurantMenu';
import {
  NEW_CUSTOMER_CC_COUPON_CODE,
  NEW_CUSTOMER_COUPON_CODE,
  ORDER_TYPES
} from '../constants';

type CheckForBackButtonOptions = {
  preSetGastronomyUrlPath: string | null;
};

export const checkForBackButton = (
  pathname: string,
  { preSetGastronomyUrlPath }: CheckForBackButtonOptions
): boolean => {
  if (
    pathname === '/' ||
    (preSetGastronomyUrlPath && pathname.startsWith('/speisekarte')) ||
    pathname.startsWith('/partner') ||
    pathname.startsWith('/gaesteregistrierung')
  ) {
    return false;
  }

  return true;
};

export const validateModifier = (
  mod: ProductModifier,
  selectedModifierItems: ProductModifierItem[]
): boolean => {
  const modifierItemCount = mod.modifierItems ? mod.modifierItems.length : 0;

  let constraintsSatisfied = true;
  if (modifierItemCount > 0 && mod.min && mod.min > 0) {
    const selectedItemsForThisModifier = selectedModifierItems.filter(
      (selectedMod) => selectedMod.modifier_uuid === mod.uuid
    );
    console.log('modifier: ', mod.min, selectedItemsForThisModifier.length);
    if (selectedItemsForThisModifier.length < mod.min) {
      constraintsSatisfied = false;
    }
  }

  return constraintsSatisfied;
};

export async function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
export const getOrderTypesByGastronomyOptions = (
  options: GastronomyOptions
): TableType[] => {
  return options && options.tableTypes
    ? options.tableTypes.filter((t) => ORDER_TYPES.includes(t))
    : [];
};

export const getGastronomyLinkTarget = (
  gastronomy: GastronomyFragment | null
): string => {
  if (!gastronomy || gastronomy.status === 'DISABLED') {
    return '#';
  }

  const orderTypes = getOrderTypesByGastronomyOptions(gastronomy.options);

  if (orderTypes.length === 1) {
    return `/${gastronomy.url_path_id}/speisekarte?tt=${orderTypes[0]}`;
  }

  return `/${gastronomy.url_path_id}/speisekarte`;
};

export const checkForIOS = (): boolean => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const getModifierByUuid = (
  mods: ModifierFragment[] | null,
  uuid: string | null
): ModifierFragment | null => {
  if (!uuid || !mods) {
    return null;
  }

  return mods.find((m) => m.uuid === uuid) || null;
};

export const getModifierItemByUuid = (
  mod: ModifierFragment,
  uuid: string
): ModifierItemFragment | null => {
  if (!mod.modifierItems) {
    return null;
  }

  return mod.modifierItems.find((m) => m.uuid === uuid) || null;
};

export const getProductPriceFromModifiers = (
  data: ProductCartFormData,
  modifiers: ModifierFragment[] | null
): number => {
  let priceChange = 0;

  if (!modifiers || modifiers.length === 0) {
    return priceChange;
  }

  for (const key of Object.keys(data)) {
    const mod = getModifierByUuid(modifiers, key);
    if (Array.isArray(data[key])) {
      const currentValue = data[key] as string[];
      if (mod?.modifierItems) {
        priceChange =
          priceChange +
          currentValue.reduce((prev: number, uuid: string) => {
            const item = getModifierItemByUuid(mod, uuid);

            return item?.price_modifier ? prev + item.price_modifier : prev;
          }, 0);
      }
    } else {
      const currentValue = data[key] as string;

      if (mod) {
        const item = getModifierItemByUuid(mod, currentValue);

        priceChange = item?.price_modifier
          ? priceChange + item.price_modifier
          : priceChange;
      }
    }
  }

  return priceChange;
};

export const getMinMaxTextForModifier = (
  t: TFunction,
  min: number,
  max: number,
  length: number
): string => {
  let text = '';

  if (min === max) {
    text = t('productModifier.chooseExact', { max });
  } else if (min === 0 && max > 0) {
    text = t('productModifier.chooseOptional', {
      max: max === 99 ? length : max
    });
  } else {
    text = t('productModifier.chooseMinMax', { min, max });
  }

  return text;
};

export const focusOnError = (errors: CartFormFieldNames[]): void => {
  if (!Array.isArray(errors) || errors.length === 0) {
    return;
  }

  const firstError = errors[0];

  if (!firstError) {
    return;
  }

  const elements = document.getElementsByName(
    firstError === 'cardPaymentMethod' ? 'paymentMethod' : firstError
  );

  if (elements && elements.length > 0) {
    const first = elements.item(0);

    if (!first) {
      return;
    }

    first.scrollIntoView({ block: 'center' });
    first.focus();
  } else {
    const element = document.getElementById(firstError);
    if (element) {
      element.scrollIntoView({ block: 'center' });
      element.focus();
    }
  }
};

export const isPhoneValid = (input?: string): boolean => {
  return typeof input === 'string' && input.length > 5;
};

export const enhanceProduct = (
  product: ProductFragment,
  productGroup: ProductGroupFragment
): EnhancedProduct => {
  return {
    ...product,
    effective_availability: productGroup.effective_availability,
    productGroupUuid: productGroup.uuid
  };
};

export const getKeyValue =
  <T, U extends keyof T>(key: U) =>
  (obj: T) =>
    obj[key];

export const isNewCustomerCoupon = (couponCode?: string): boolean => {
  if (!couponCode) {
    return false;
  }
  return [NEW_CUSTOMER_COUPON_CODE, NEW_CUSTOMER_CC_COUPON_CODE].includes(
    couponCode
  );
};

export const filterEmptyCategories = ({
  categories = [],
  productGroups = []
}: {
  categories?: CategoryFragment[];
  productGroups?: ProductGroupFragment[];
}): CategoryFragment[] => {
  const categoriesWithProductGroups: string[] = [];

  productGroups.forEach((pg) => {
    if (pg.category_uuid) {
      categoriesWithProductGroups.push(pg.category_uuid);
    }
  });

  return categories.filter((c) => categoriesWithProductGroups.includes(c.uuid));
};

export const getTimeAgo = (date: Date, t: TFunction): string => {
  const MINUTE = 60,
    HOUR = MINUTE * 60,
    DAY = HOUR * 24,
    WEEK = DAY * 7,
    MONTH = DAY * 30,
    YEAR = DAY * 365;

  const secondsAgo = Math.round((+new Date() - +date) / 1000);
  let divisor: number | null = null;
  let unit: string | null = null;

  if (secondsAgo < 10) {
    return t('relativeDate.rightNow');
  } else if (secondsAgo < MINUTE) {
    return t('relativeDate.unitAgo', {
      count: secondsAgo,
      unit: t('relativeDate.seconds')
    });
  } else if (secondsAgo < HOUR) {
    [divisor, unit] = [MINUTE, 'minute'];
  } else if (secondsAgo < DAY) {
    [divisor, unit] = [HOUR, 'hour'];
  } else if (secondsAgo < WEEK) {
    [divisor, unit] = [DAY, 'day'];
  } else if (secondsAgo < MONTH) {
    [divisor, unit] = [WEEK, 'week'];
  } else if (secondsAgo < YEAR) {
    [divisor, unit] = [MONTH, 'month'];
  } else if (secondsAgo > YEAR) {
    [divisor, unit] = [YEAR, 'year'];
  }

  if (divisor && unit) {
    const count = Math.floor(secondsAgo / divisor);
    const unitKey = count > 1 ? unit + 's' : unit;

    return t('relativeDate.unitAgo', {
      count,
      unit: t('relativeDate.' + unitKey)
    });
  }

  return format(date, 'dd.MM.yyyy HH:mm');
};
