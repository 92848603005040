import React from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import useUri from '../hooks/useUri';

const GetsbyRedirect: React.FC<GetsbyRouter & RedirectProps> = ({
  to,
  params,
  ...rest
}) => {
  const uri = useUri(to, params);

  return <Redirect to={uri} {...rest} />;
};

export default GetsbyRedirect;
