import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';

export const captureError = (error: Error): void => {
  console.log('error', error);
  if (error) {
    if (!error.message || !error.message.startsWith('[GraphQL:')) {
      console.log('send error to sentry');
      Sentry.captureException(error);
    }
  }
};

const excludeErrorCodes = [
  'GASTRONOMY_NOT_OPEN',
  'TARGET_UNREACHABLE',
  'OUTSIDE_OF_AVAILABILITY_TIME',
  'ORDER_AMOUNT_LOW',
  'USER_NOT_NEW',
  'CODE_ALREADY_USED',
  'INVALID_COUPON_CODE',
  'OWN_REFERRAL_FORBIDDEN',
  'CODE_TYPE_ALREADY_USED',
  'REFER_BACK_FORBIDDEN'
];

const excludeStripeErrorCodes = ['card_declined'];

export const sendToSentry = (error: GraphQLError): boolean => {
  let send = true;
  if (error.extensions) {
    if (error.extensions.code) {
      if (excludeErrorCodes.includes(error.extensions.code)) {
        send = false;
      }
    }
    if (error.extensions.exception && error.extensions.exception.code) {
      if (excludeStripeErrorCodes.includes(error.extensions.exception.code)) {
        send = false;
      }
    }
  }
  return send;
};
