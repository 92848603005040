import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from './spinner';

interface Props {
  status?: ReceiptStatus;
}

const OrderProgress: React.FC<Props> = ({ status }: Props) => {
  const { t } = useTranslation();

  const statusTransition: ReceiptStatus[] = [
    'ERROR',
    'RECEIVED',
    'ACCEPTED',
    'READY',
    'COMPLETED'
  ];
  const currentIndex = statusTransition.findIndex((s) => s === status);

  if (currentIndex < 0) {
    return <Spinner center={true} className="w-full" />;
  }

  if (currentIndex === 0) {
    return <></>;
  }

  return (
    <div className="w-full py-6">
      <div className="flex">
        <div className="w-1/4">
          <div className="relative mb-2">
            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg flex items-center justify-center ${
                currentIndex < 1
                  ? 'bg-transparent border-2 border-gray-200 text-gray-200'
                  : 'bg-primary-500 text-white'
              }`}
            >
              {currentIndex >= 1 && <i className="material-icons">done</i>}
            </div>
          </div>
          <div className="text-xs text-center md:text-base">
            {t('orders.status.received')}
          </div>
        </div>

        <div className="w-1/4">
          <div className="relative mb-2">
            <div
              className="absolute flex items-center content-center align-middle align-center"
              style={{
                width: 'calc(100% - 2.5rem - 1rem)',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <div className="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
                <div
                  className="w-0 py-1 rounded bg-primary-300"
                  style={{
                    width:
                      currentIndex < 0
                        ? '0%'
                        : currentIndex < 2
                        ? '33%'
                        : '100%'
                  }}
                ></div>
              </div>
            </div>
            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg flex items-center justify-center ${
                currentIndex < 2
                  ? 'bg-transparent border-2 border-gray-200 text-gray-200'
                  : 'bg-primary-500 text-white'
              }`}
            >
              {currentIndex >= 2 && <i className="material-icons">done</i>}
            </div>
          </div>
          <div className="text-xs text-center md:text-base">
            {t('orders.status.accepted')}
          </div>
        </div>

        <div className="w-1/4">
          <div className="relative mb-2">
            <div
              className="absolute flex items-center content-center align-middle align-center"
              style={{
                width: 'calc(100% - 2.5rem - 1rem)',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <div className="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
                <div
                  className="w-0 py-1 rounded bg-primary-300"
                  style={{
                    width:
                      currentIndex === 2
                        ? '33%'
                        : currentIndex < 2
                        ? '0%'
                        : '100%'
                  }}
                ></div>
              </div>
            </div>
            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg flex items-center justify-center ${
                currentIndex < 3
                  ? 'bg-transparent border-2 border-gray-200 text-gray-200'
                  : 'bg-primary-500 text-white'
              }`}
            >
              {currentIndex >= 3 && <i className="material-icons">done</i>}
            </div>
          </div>
          <div className="text-xs text-center md:text-base">
            {t('orders.status.ready')}
          </div>
        </div>

        <div className="w-1/4">
          <div className="relative mb-2">
            <div
              className="absolute flex items-center content-center align-middle align-center"
              style={{
                width: 'calc(100% - 2.5rem - 1rem)',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <div className="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
                <div
                  className="w-0 py-1 rounded bg-primary-300"
                  style={{
                    width:
                      currentIndex === 3
                        ? '33%'
                        : currentIndex < 3
                        ? '0%'
                        : '100%'
                  }}
                ></div>
              </div>
            </div>
            <div
              className={`w-10 h-10 mx-auto rounded-full text-lg flex items-center justify-center ${
                currentIndex < 4
                  ? 'bg-transparent border-2 border-gray-200 text-gray-200'
                  : 'bg-primary-500 text-white'
              }`}
            >
              {currentIndex >= 4 && <i className="material-icons">done</i>}
            </div>
          </div>
          <div className="text-xs text-center md:text-base">
            {t('orders.status.completed')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProgress;
