import { USER_AGENT_MARKER } from '../constants';

export const completeApplePayInApp = async (
  clientSecret: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    window.completeApplePayResult = () => {
      window.completeApplePayResult = undefined;
      resolve();
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'completeApplePay', clientSecret })
    );
  });
};

export async function checkMessagingPermission(): Promise<
  [boolean, string | undefined]
> {
  return new Promise((resolve, reject) => {
    window.messagingPermissionCheckResult = (hasPermission, token) => {
      console.log('Messaging permission', hasPermission);
      window.messagingPermissionCheckResult = undefined;
      resolve([hasPermission, token]);
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'checkMessagingPermission' })
    );
  });
}

export async function requestMessagingPermission(): Promise<string> {
  return new Promise((resolve, reject) => {
    window.messagingPermissionResult = (result, token) => {
      console.log('messaging permission result', result, token);
      window.messagingPermissionResult = undefined;
      if (token) {
        resolve(token);
      } else {
        reject();
      }
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'requestMessagingPermission' })
    );
  });
}

export function getAppIdFromUserAgent(): string | null {
  const userAgent = window.navigator.userAgent;
  const gIndex = userAgent.indexOf(USER_AGENT_MARKER);

  if (gIndex > -1) {
    const getsbyAppStr = userAgent.substring(gIndex);

    if (getsbyAppStr) {
      return getsbyAppStr.substring(
        getsbyAppStr.indexOf('(') + 1,
        getsbyAppStr.indexOf(')')
      );
    }
  }

  return null;
}

export function isWhitelabelApp(): boolean {
  const appId = getAppIdFromUserAgent();
  return (appId && !appId.startsWith('by.gets.clientapp')) || false;
}

export function getUrlPathIdFromAppId(appId: string): string {
  return appId.substring(appId.lastIndexOf('.') + 1);
}

export function isGetsbyDomain(): boolean {
  const domain = window.location.hostname;

  return !!(
    domain.match('gets.by') ||
    domain === 'getsby.at' ||
    domain === 'localhost'
  );
}

export function replacePathParams(
  inputUri: string,
  params?: Record<string, string | undefined | null> | null
): string {
  if (params) {
    for (const key in params) {
      if (typeof params[key] === 'string') {
        inputUri = inputUri.replace(
          new RegExp(`:${key}`, 'gi'),
          params[key] as string
        );
      }
    }
  }

  return inputUri;
}

export function getRouteOverwrite(
  inputUri: string,
  router?: Router[] | null
): string {
  let routeOverwrite = inputUri;

  if (router) {
    const currentPath = router.find((route) => route.path === inputUri);

    if (currentPath) {
      routeOverwrite = currentPath.overwrite;

      routeOverwrite = replacePathParams(
        routeOverwrite,
        currentPath.params as Record<string, string | undefined | null> | null
      );
    }
  }

  return routeOverwrite;
}
