import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userIsAuthenticatedState } from '../store/user';
import { useTranslation } from 'react-i18next';
import { preSetGastronomyUrlPathState } from '../store/gastronomy';
import { cartItemsCount } from '../store/cart';
import { useBreakpoint } from '../hooks/useBreakpoint';
import Config from '../config';
import { checkForBackButton } from '../helpers/utilities';
import NavBackButton from './navBackButton';
import HelpModal from './HelpModal';
import { ReactComponent as GetsbyLogo } from './../assets/icons/getsby_logo.svg';
import LanguageSelect from './languageSelect';
import OutsideClickHandler from 'react-outside-click-handler';
import { getAppIdFromUserAgent, getUrlPathIdFromAppId } from '../helpers/app';
import AppInitOptionsService from '../services/appInitOptionsService';
import GetsbyLink from './getsbyLink';
import TopNavButton from './topNavButton';
import TopNavPartnerDropdown from './topNavPartnerDropdown';

interface Props {
  theme?: CustomThemeFragmentFragment;
  isPartnerPage?: boolean;
  inset?: number;
}

const TopNav: React.FC<Props> = ({ theme, isPartnerPage, inset = 0 }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const preSetGastronomyUrlPath = useRecoilValue(preSetGastronomyUrlPathState);
  const userIsAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const cartValue = useRecoilValue(cartItemsCount);
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const { breakpoint } = useBreakpoint();
  const isApp = AppInitOptionsService.getInstance().isApp();
  const showBackButton = checkForBackButton(location.pathname, {
    preSetGastronomyUrlPath
  });

  const devicePixelRatio = useMemo(() => {
    return Math.min(2, Math.round(window.devicePixelRatio));
  }, [window.devicePixelRatio]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const closeUserMenu = () => {
    setUserMenuOpen(false);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen((current) => !current);
  };

  const onHelpModalClose = () => {
    setShowHelpModal(false);
  };

  const onShowHelpModalClick = () => {
    setShowHelpModal(true);
  };

  const appId = getAppIdFromUserAgent();

  const whiteLabelUrlPathId =
    appId && !appId.startsWith('by.gets.clientapp')
      ? getUrlPathIdFromAppId(appId)
      : null;

  return (
    <>
      {showHelpModal && <HelpModal onClose={onHelpModalClose} />}
      <nav
        id="top-nav"
        className={`z-30 w-full bg-white border-b items-center ${
          isApp ? 'fixed top-0' : ''
        }`}
        style={{ paddingTop: inset + 'px' }}
      >
        <div className="w-full px-2 py-1 mx-auto sm:px-4 sm:px-6">
          <div
            className="relative flex items-center justify-between h-12"
            id="top-nav-container"
          >
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded text-primary-500 focus:outline-none"
                role="menu"
              >
                {showBackButton ? (
                  <NavBackButton />
                ) : (
                  <>
                    {!isApp && (
                      <>
                        <span
                          className={`w-6 h-6 ${menuOpen ? 'block' : 'hidden'}`}
                          onClick={closeMenu}
                        >
                          <i className="material-icons">close</i>
                        </span>
                        {!theme && (
                          <span
                            className={`w-6 h-6 ${
                              menuOpen ? 'hidden' : 'block'
                            }`}
                            onClick={openMenu}
                          >
                            <i className="material-icons">menu</i>
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
            <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
              <div
                className="flex items-center flex-shrink-0"
                id="top-nav-logo-container"
              >
                <GetsbyLink to="/">
                  {theme && theme.appBarLogo ? (
                    <img
                      src={`${theme.appBarLogo}?h=48&q=75&auto=format&dpr=${devicePixelRatio}`}
                      alt="getsby Logo"
                      className="w-auto h-auto max-h-12"
                      style={{ maxWidth: '100px' }}
                      id="top-nav-logo"
                    />
                  ) : (
                    <GetsbyLogo
                      className="w-auto h-12 text-primary-500"
                      id="top-nav-logo"
                    />
                  )}
                </GetsbyLink>
              </div>
              <div className="items-center hidden sm:flex sm:ml-6">
                <div className="flex">
                  {!theme && (
                    <>
                      {isPartnerPage && (
                        <>
                          <TopNavButton
                            title="Startseite"
                            path="/partner"
                            isActiveFn={() =>
                              location.pathname.startsWith('/partner') &&
                              !location.pathname.startsWith('/partner/')
                            }
                          />
                          <TopNavPartnerDropdown />
                          <TopNavButton title="Preise" path="/partner/preise" />
                        </>
                      )}
                      {location.pathname === '/' && (
                        <TopNavButton
                          path="/partner"
                          title={t(`general.forPartners`)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
              id="top-nav-menu-icons"
            >
              {!isPartnerPage && (
                <>
                  <button
                    className="flex mx-1 text-sm border-2 border-transparent rounded top-nav-menu-icon focus:outline-none text-primary-500"
                    onClick={onShowHelpModalClick}
                  >
                    <i className="material-icons">contact_support</i>
                  </button>
                  {breakpoint !== 'sm' && (
                    <GetsbyLink
                      to="/cart"
                      className="relative flex mx-1 text-sm border-2 border-transparent rounded focus:outline-none text-primary-500 top-nav-menu-icon"
                      target={!preSetGastronomyUrlPath ? '_top' : undefined}
                    >
                      <i className="material-icons">shopping_cart</i>
                    </GetsbyLink>
                  )}
                </>
              )}
              <OutsideClickHandler onOutsideClick={closeUserMenu}>
                <div className="relative mx-1">
                  <button
                    className="flex text-sm border-2 border-transparent rounded focus:outline-none text-primary-500 top-nav-menu-icon"
                    onClick={toggleUserMenu}
                  >
                    <i className="material-icons">account_circle</i>
                  </button>

                  {userMenuOpen && (
                    <div
                      className="absolute right-0 z-30 w-48 mt-2 origin-top-right rounded-md shadow-lg"
                      id="top-nav-menu"
                    >
                      <div className="py-1 bg-white rounded-md" role="menu">
                        {isPartnerPage ? (
                          <>
                            <a
                              href={Config.dashboardHost}
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                            >
                              {t(`general.partnerLogin`)}
                            </a>
                            <a
                              href={`${Config.dashboardHost}?signup`}
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                            >
                              {t(`general.partnerSignup`)}
                            </a>
                          </>
                        ) : (
                          <>
                            {userIsAuthenticated ? (
                              <GetsbyLink
                                to="/auth/:action"
                                params={{ action: 'logout' }}
                                className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                                role="menuitem"
                                onClick={closeUserMenu}
                              >
                                {t(`profile.logout`)}
                              </GetsbyLink>
                            ) : (
                              <>
                                <GetsbyLink
                                  to="/auth/:action"
                                  params={{ action: 'signin' }}
                                  className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                                  role="menuitem"
                                  onClick={closeUserMenu}
                                >
                                  {t(`profile.login`)}
                                </GetsbyLink>
                                <GetsbyLink
                                  to="/auth/:action"
                                  params={{ action: 'signup' }}
                                  className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                                  role="menuitem"
                                  onClick={closeUserMenu}
                                >
                                  {t(`profile.signup`)}
                                </GetsbyLink>
                              </>
                            )}
                            <GetsbyLink
                              to="/cart"
                              target={
                                !preSetGastronomyUrlPath ? '_top' : undefined
                              }
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                              onClick={closeUserMenu}
                            >
                              {t('shoppingCart.cart')}{' '}
                              {cartValue > 0 ? `(${cartValue})` : ''}
                            </GetsbyLink>
                            <GetsbyLink
                              to="/orders"
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                              onClick={closeUserMenu}
                            >
                              {t(`navigation.myOrders`)}
                            </GetsbyLink>
                            <GetsbyLink
                              to={
                                whiteLabelUrlPathId
                                  ? '/bonus/app/:appUrlPathId'
                                  : '/bonus'
                              }
                              params={{ appUrlPathId: whiteLabelUrlPathId }}
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                              onClick={closeUserMenu}
                            >
                              {t(`navigation.bonus`)}
                            </GetsbyLink>
                            <GetsbyLink
                              to="/news"
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                              onClick={closeUserMenu}
                            >
                              {t(`newsletter.feed`)}
                            </GetsbyLink>
                            <GetsbyLink
                              to="/profile"
                              className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                              role="menuitem"
                              onClick={closeUserMenu}
                            >
                              {t('navigation.userProfile')}
                            </GetsbyLink>

                            {!isApp && !whiteLabelUrlPathId && (
                              <GetsbyLink
                                to="/partner"
                                className="block px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out text-secondary-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 top-nav-menu-item"
                                role="menuitem"
                                onClick={closeUserMenu}
                              >
                                {t(`general.signUpRestaurant`)}
                              </GetsbyLink>
                            )}
                            <hr />
                            <LanguageSelect style="unstyled" />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
        <OutsideClickHandler onOutsideClick={closeMenu}>
          <div className={`${menuOpen ? 'block' : 'hidden'}`}>
            <div className="px-2 pt-2 pb-3">
              {isPartnerPage && (
                <>
                  <TopNavButton
                    title="Startseite"
                    path="/partner"
                    isActiveFn={() =>
                      location.pathname.startsWith('/partner') &&
                      !location.pathname.startsWith('/partner/')
                    }
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Features"
                    path="/partner/features"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Online-Bestellsystem"
                    path="/partner/online-bestellsystem"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Sichtbarkeit & Online-Präsenz"
                    path="/partner/sichtbarkeit"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Kommunikation"
                    path="/partner/kommunikation"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Corona Gästeregistrierung"
                    path="/partner/gaesteregistrierung"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Dashboard & Technik"
                    path="/partner/technik"
                    mobileButton={true}
                  />
                  <TopNavButton
                    title="Bonuspunkte-Club"
                    path="/partner/bonuspunkte-club"
                    mobileButton={true}
                  />
                </>
              )}
              {location.pathname === '/' && (
                <TopNavButton
                  path="/partner"
                  title={t(`general.forPartners`)}
                  mobileButton={true}
                />
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </nav>
    </>
  );
};

export default TopNav;
