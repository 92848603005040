import { format, isSameDay } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isAsapOrderPossibleSelector,
  preorderModalState,
  preorderSlotState
} from '../store/cart';
import { selectedTableState } from '../store/gastronomy';

const PreorderDateSelectButton: React.FC = () => {
  const selectedTable = useRecoilValue(selectedTableState);
  const preorderSlot = useRecoilValue(preorderSlotState);
  const setPreorderModal = useSetRecoilState(preorderModalState);
  const isAsapPossible = useRecoilValue(isAsapOrderPossibleSelector);
  const { t } = useTranslation();

  const buttonRender = (slot: { from: Date; to: Date } | null) => {
    if (slot) {
      const now = new Date();

      return (
        <span>
          {isSameDay(now, slot.from)
            ? 'Heute'
            : format(slot.from, 'dd.MM.yyyy')}{' '}
          {format(slot.from, 'H:mm')}
          {' - '}
          {format(slot.to, 'H:mm')}
        </span>
      );
    } else if (isAsapPossible) {
      return t('asap');
    } else {
      return (
        <div className="flex items-center">
          <span>Zeit wählen</span>
          <span className="ml-2 text-xl text-red-700 material-icons">
            error_outline
          </span>
        </div>
      );
    }
  };

  if (selectedTable && selectedTable.allow_preorder) {
    return (
      <div
        className="flex items-center w-full h-full cursor-pointer"
        onClick={() =>
          setPreorderModal((_modal) => ({
            showModal: true,
            closeOnSelect: true
          }))
        }
      >
        {buttonRender(preorderSlot)}
        <div className="ml-2 text-sm material-icons">edit</div>
      </div>
    );
  }
  return null;
};

export default PreorderDateSelectButton;
