import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { captureError } from '../helpers/error';
import { GetInvoiceUrl } from '../services/graphql/operations';
import Alert from './alert';
import Button from './button';
import Heading from './heading';

interface Props {
  receipt: FullReceiptFragment;
}
const ReceiptInvoices: React.FC<Props> = ({ receipt }) => {
  const invoices: InvoiceFragmentFragment[] = receipt
    ? receipt.invoices || []
    : [];
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const downloadFile = (invoice: InvoiceFragmentFragment) => async () => {
    if (invoice) {
      try {
        if (!invoice.url) {
          return;
        }

        let url = '#';

        if (
          invoice.url.startsWith('http://') ||
          invoice.url.startsWith('https://')
        ) {
          url = invoice.url;
        } else {
          const { data } = await apolloClient.mutate<
            GetInvoiceUrlQuery,
            GetInvoiceUrlQueryVariables
          >({
            mutation: GetInvoiceUrl,
            variables: { uuid: invoice.uuid }
          });

          if (data) {
            url = data.getInvoiceUrl;
          }
        }

        console.log('url', url);

        const link = document.createElement('a');
        link.download = `Beleg ${invoice.uuid}`;
        link.href = url;
        link.click();
      } catch (error) {
        console.log(error);
        captureError(error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full my-4">
      <Heading text={t('invoice.invoices')} />

      {receipt.pos_type === 'GETSBY' && !receipt.pos_invoice_url ? (
        <Alert
          type="info"
          title={t('invoice.invoiceInfo')}
          message={t('invoice.requestInvoice', {
            gastronomy: receipt.gastronomy ? receipt.gastronomy.name : ''
          })}
        />
      ) : (
        <>
          {invoices.length === 0 && (
            <Alert
              type="info"
              title={t('invoice.invoiceInfo')}
              message={t('invoice.receiptAvailableShortly')}
            />
          )}

          <div className="flex flex-col items-center w-full">
            {invoices.map((invoice, index) => (
              <div key={index} className="w-full">
                {invoice.url && (
                  <Button
                    type="default"
                    onClick={downloadFile(invoice)}
                    classes="w-full mb-2 flex items-center justify-center"
                  >
                    <i className="material-icons">file_download</i>
                    {t('invoice.download', { index: index + 1 })}
                  </Button>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReceiptInvoices;
