import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';
import { ReactComponent as Icon } from './../assets/icons/make_it_rain.svg';
import { calculateCentsFromBonusProgram } from '../helpers/bonusProgramHelper';
import { useMutation } from '@apollo/client';
import Heading from './heading';
import { RedeemBonusProgramPoints } from '../services/graphql/operations';
import RedemptionQrCode from './redemptionQrCode';

interface Props {
  bonusProgramPoints: BonusProgramPointsFragment;
  onClose: () => void;
}

const RedeemBonusProgramPointsForm: React.FC<Props> = ({
  bonusProgramPoints,
  onClose
}) => {
  const maxPoints = bonusProgramPoints.points || 0;
  const { t } = useTranslation();
  const [usePoints, setUsePoints] = useState(maxPoints);
  const bonusProgramName =
    bonusProgramPoints.bonus_program.bonus_program_name ||
    t('bonusProgram.genericName');

  const [redeemBonusProgramPointsMutation, { data }] = useMutation<
    RedeemBonusProgramPointsMutation,
    RedeemBonusProgramPointsMutationVariables
  >(RedeemBonusProgramPoints);

  const redemption = data?.redeemBonusProgramPoints;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsePoints((current) =>
      +event.target.value > maxPoints ? current : +event.target.value
    );
  };

  const handleIncrement = () =>
    setUsePoints((current) => {
      const currentNumber = +current || 0;

      return currentNumber + 1 > maxPoints ? maxPoints : currentNumber + 1;
    });

  const handleDecrement = () =>
    setUsePoints((current) => {
      const currentNumber = +current || 0;

      return currentNumber === 0 ? 0 : currentNumber - 1;
    });

  const handleSubmit = async () => {
    if (confirm(t('bonusProgram.confirmRedeem'))) {
      if (confirm(t('areYouSure'))) {
        await redeemBonusProgramPointsMutation({
          variables: {
            input: {
              points: +usePoints,
              bonus_program_uuid: bonusProgramPoints.bonus_program.uuid,
              user_uuid:
                bonusProgramPoints.bonus_program.gastronomies.length === 1
                  ? bonusProgramPoints.bonus_program.gastronomies[0].uuid
                  : undefined
            }
          },
          refetchQueries: [
            'getGroupedBonusProgramPoints',
            'getBonusProgramTransactions'
          ]
        });
      }
    }
  };

  const handleClose = () => {
    if (confirm(t('bonusProgram.confirmOk'))) {
      onClose();
    }
  };

  if (redemption) {
    return (
      <RedemptionQrCode redemption={redemption} handleClose={handleClose} />
    );
  }

  return (
    <>
      <Heading text={t('bonusProgram.redeemNow')} />
      <p>{t('bonusProgram.redeemOfflineInfo1')}</p>
      <div className="flex items-center justify-center">
        <Icon className="w-64 h-auto m-8 text-primary-500" />
      </div>
      <p className="text-base font-bold">
        {t('bonusProgram.maxRedeemablePoints', {
          points: maxPoints,
          name: bonusProgramName
        })}
      </p>
      <div className="flex items-center justify-center w-full my-4">
        <div className="relative flex flex-row w-full h-10 my-4 bg-transparent rounded">
          <button
            data-action="decrement"
            className="w-1/3 h-full bg-gray-200 rounded-l outline-none appearance-none cursor-pointer focus:outline-none text-secondary-500"
            onClick={handleDecrement}
          >
            <span className="m-auto text-2xl">−</span>
          </button>
          <input
            type="number"
            min={0}
            max={maxPoints}
            className="flex items-center w-1/3 font-semibold text-center bg-gray-200 outline-none text-md"
            name="custom-input-number"
            value={usePoints}
            onChange={handleChange}
          ></input>
          <button
            data-action="increment"
            className="w-1/3 h-full bg-gray-200 rounded-r outline-none appearance-none cursor-pointer focus:outline-none text-secondary-500"
            onClick={handleIncrement}
          >
            <span className="m-auto text-2xl">+</span>
          </button>
        </div>
      </div>
      <p className="mb-8 text-xl font-bold text-center text-primary-500">
        {usePoints} {bonusProgramName} ={' '}
        {(
          calculateCentsFromBonusProgram({
            points: +usePoints || 0,
            bonusProgram: bonusProgramPoints.bonus_program
          }) / 100
        ).toFixed(2) + ' €'}
      </p>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <Button
          type="default"
          classes="w-full flex items-center justify-center"
          onClick={onClose}
        >
          <i className="mr-4 material-icons">close</i>
          <span>{t('cancel')}</span>
        </Button>
        <Button
          type="primary"
          classes="w-full flex items-center justify-center"
          onClick={handleSubmit}
          disabled={+usePoints < 1}
        >
          <i className="mr-4 material-icons">redeem</i>
          <span>{t('bonusProgram.redeemNow')}</span>
        </Button>
      </div>
    </>
  );
};

export default RedeemBonusProgramPointsForm;
