import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  classes?: string;
}

const GuestRegistrationBadge: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();

  return (
    <span
      className={`guest-registration-badge inline-flex items-center px-3 py-1 text-sm font-semibold bg-blue-500 text-white rounded-full ${classes}`}
      title={t('guestRegistration.goTo')}
    >
      <i className="mr-2 text-sm material-icons">how_to_reg</i>
      {t('guestRegistration.goTo')}
    </span>
  );
};

export default GuestRegistrationBadge;
