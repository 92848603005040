import { useQuery } from '@apollo/client';
import { isBefore } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { calculateCentsFromBonusProgram } from '../helpers/bonusProgramHelper';
import {
  GetBonusProgramPoints,
  GetBonusProgramTransactions
} from '../services/graphql/operations';
import { userIsAuthenticatedState } from '../store/user';
import BonusProgramBadge from './bonusProgramBadge';
import GetsbyLink from './getsbyLink';
import Heading from './heading';

interface Props {
  receipt?: FullReceiptFragment;
}

const ReceiptBonusProgram: React.FC<Props> = ({ receipt }) => {
  const { t } = useTranslation();
  const isAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const [bonusProgramPoints, setBonusProgramPoints] =
    useState<BonusProgramPointsFragment | null>(null);
  const [bonusProgramTransaction, setBonusProgramTransaction] =
    useState<BonusProgramTransactionFragment | null>(null);

  useQuery<
    GetBonusProgramTransactionsQuery,
    GetBonusProgramTransactionsQueryVariables
  >(GetBonusProgramTransactions, {
    skip: !receipt || receipt.status !== 'COMPLETED',
    variables: { params: { receipt_uuid: receipt ? receipt.uuid : 'NOT_SET' } },
    onCompleted: (data) =>
      setBonusProgramTransaction(
        data.getBonusProgramTransactions.length
          ? data.getBonusProgramTransactions[0]
          : null
      )
  });

  useQuery<GetBonusProgramPointsQuery, GetBonusProgramPointsQueryVariables>(
    GetBonusProgramPoints,
    {
      fetchPolicy: 'network-only',
      skip: !receipt || !receipt.user_uuid,
      variables: {
        params: { user_uuid: receipt ? receipt.user_uuid : 'NOT_SET' }
      },
      onCompleted: (data) =>
        setBonusProgramPoints(data.getBonusProgramPoints || null)
    }
  );

  const isBeforeBonusProgram = useMemo(() => {
    if (receipt && bonusProgramPoints) {
      return isBefore(
        new Date(receipt.created_at),
        new Date(bonusProgramPoints.bonus_program.created_at)
      );
    }

    return true;
  }, [receipt, bonusProgramPoints]);

  const collectedBonusProgramPoints = useMemo(() => {
    if (bonusProgramTransaction) {
      return bonusProgramTransaction.points;
    } else if (receipt) {
      const sum = receipt.orderItems
        .filter((oi) => !oi.is_canceled && oi.product_price > 0)
        .reduce((current, orderItem) => current + orderItem.product_price, 0);

      return Math.floor(sum / 100);
    }

    return 0;
  }, [receipt, bonusProgramTransaction]);

  const bonusProgramPointsBalance = useMemo(() => {
    const currentBalance = bonusProgramPoints ? bonusProgramPoints.points : 0;

    if (!bonusProgramTransaction) {
      return currentBalance + collectedBonusProgramPoints;
    }

    return currentBalance;
  }, [
    bonusProgramTransaction,
    collectedBonusProgramPoints,
    bonusProgramPoints
  ]);

  if (
    !receipt ||
    receipt.status === 'ERROR' ||
    !bonusProgramPoints ||
    isBeforeBonusProgram
  ) {
    return <></>;
  }

  return (
    <>
      <Heading text={t('bonusProgram.genericName')} />
      <div className="flex flex-col w-full md:flex-row">
        <div
          className={`flex items-center w-full text-sm md:text-base p-4 ${
            isAuthenticated ? 'md:w-1/2' : 'md:w-1/3'
          }`}
        >
          <div className="flex flex-col flex-wrap items-center justify-center w-full mb-4">
            <Trans
              i18nKey="bonusProgram.withThisOrderCollected"
              components={[
                <BonusProgramBadge
                  points={collectedBonusProgramPoints}
                  bonusProgram={bonusProgramPoints.bonus_program}
                  className="my-2 cursor-default"
                  key={0}
                />,
                (
                  calculateCentsFromBonusProgram({
                    points: collectedBonusProgramPoints,
                    bonusProgram: bonusProgramPoints.bonus_program
                  }) / 100
                ).toFixed(2) + ' €'
              ]}
            />
          </div>
        </div>
        <div
          className={`flex flex-col items-center w-full text-sm md:text-base p-4 ${
            isAuthenticated ? 'md:w-1/2' : 'md:w-1/3'
          }`}
        >
          <Trans
            i18nKey={
              bonusProgramTransaction
                ? 'bonusProgram.currentBalance'
                : 'bonusProgram.futureBalance'
            }
            components={[
              <BonusProgramBadge
                points={bonusProgramPointsBalance}
                bonusProgram={bonusProgramPoints.bonus_program}
                className="my-2 cursor-default"
                key={0}
              />,
              (
                calculateCentsFromBonusProgram({
                  points: bonusProgramPointsBalance,
                  bonusProgram: bonusProgramPoints.bonus_program
                }) / 100
              ).toFixed(2) + ' €'
            ]}
          />
        </div>
        {!isAuthenticated && (
          <div className="flex flex-col items-center w-full p-4 text-sm md:text-base md:w-1/3">
            <p>{t('bonusProgram.registerDontLoosePoints')}</p>
            <GetsbyLink
              to={`/auth/:action${
                receipt.gastronomy?.url_path_id ? '/:urlPathId' : ''
              }`}
              params={{
                action: 'signup',
                urlPathId: receipt.gastronomy?.url_path_id
              }}
              className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 font-bold rounded shadow cursor-pointer bg-primary-500 text-secondary-500"
            >
              <i className="mr-4 material-icons">person_add</i>
              <span>{t(`bonusProgram.signupNow`)}</span>
            </GetsbyLink>
          </div>
        )}
      </div>

      <hr className="my-6" />
    </>
  );
};

export default ReceiptBonusProgram;
