import React, { useEffect, useState } from 'react';
import GastronomyList from './containers/gastronomyList';
import {
  Switch,
  Redirect,
  RouteComponentProps,
  useHistory
} from 'react-router-dom';
import LastOrders from './containers/lastOrders';
import Order from './containers/order';
import PaymentConfirm from './containers/paymentConfirm';
import queryString from 'query-string';
import QueryParamsProcessor from './containers/queryParamsProcessor';
import Bonus from './containers/static/bonus';
import OpenInBrowser from './containers/static/openInBrowser';
import RestaurantView from './containers/restaurantView';
import BonusProgram from './containers/bonusProgram';
import MakeRoute from './components/MakeRoute';
import { lazyRetry } from './helpers/lazyRetry';
import { useApolloClient } from '@apollo/client';
import { GetApp, GetHolidays } from './services/graphql/operations';
import CustomPage from './containers/customPage';
import { getAppIdFromUserAgent, isGetsbyDomain } from './helpers/app';
import Config from './config';
import Spinner from './components/spinner';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { appConfigState, locationHistoryState } from './store/app';
import NewsletterSubscription from './containers/newsletterSubscription';
import NewsletterPost from './containers/newsletterPost';
import DownloadApp from './containers/downloadApp';
import { Link, Meta, Style } from 'react-head';
import { AvailabilityMixin } from './helpers/availability';
import { captureException } from '@sentry/minimal';
import NewsletterFeed from './containers/newsletterFeed';
import SubRouter from './SubRouter';
import GuestRegistration from './containers/guestRegistration';

const Cart = React.lazy(() => lazyRetry(() => import('./containers/cart')));
const Profile = React.lazy(() =>
  lazyRetry(() => import('./containers/profile'))
);
const Help = React.lazy(() => lazyRetry(() => import('./containers/help')));
const Faq = React.lazy(() => lazyRetry(() => import('./containers/faq')));
const Gtc = React.lazy(() =>
  lazyRetry(() => import('./containers/static/gtc'))
);
const PrivacyPolicy = React.lazy(() =>
  lazyRetry(() => import('./containers/static/privacyPolicy'))
);
const Imprint = React.lazy(() =>
  lazyRetry(() => import('./containers/static/imprint'))
);
const Referral = React.lazy(() =>
  lazyRetry(() => import('./containers/static/referral'))
);
const Corona = React.lazy(() =>
  lazyRetry(() => import('./containers/static/corona'))
);
const CoronaPrivacyPolicy = React.lazy(() =>
  lazyRetry(() => import('./containers/static/coronaPrivacyPolicy'))
);
const Partner = React.lazy(() =>
  lazyRetry(() => import('./containers/static/partnerHome'))
);
const Features = React.lazy(() =>
  lazyRetry(() => import('./containers/static/features'))
);
const OnlineOrderSystem = React.lazy(() =>
  lazyRetry(() => import('./containers/static/onlineOrderSystem'))
);
const WebPresence = React.lazy(() =>
  lazyRetry(() => import('./containers/static/webPresence'))
);
const TableService = React.lazy(() =>
  lazyRetry(() => import('./containers/static/tableService'))
);
const TakeAway = React.lazy(() =>
  lazyRetry(() => import('./containers/static/takeAway'))
);
const RoomService = React.lazy(() =>
  lazyRetry(() => import('./containers/static/roomService'))
);
const Events = React.lazy(() =>
  lazyRetry(() => import('./containers/static/events'))
);
const ReusablePackaging = React.lazy(() =>
  lazyRetry(() => import('./containers/static/reusablePackaging'))
);
const Webshop = React.lazy(() =>
  lazyRetry(() => import('./containers/static/webshop'))
);
const Technic = React.lazy(() =>
  lazyRetry(() => import('./containers/static/technic'))
);
const Communication = React.lazy(() =>
  lazyRetry(() => import('./containers/static/communication'))
);
const App = React.lazy(() =>
  lazyRetry(() => import('./containers/static/app'))
);
const Pricing = React.lazy(() =>
  lazyRetry(() => import('./containers/static/pricing'))
);
const Authentication = React.lazy(() =>
  lazyRetry(() => import('./containers/authentication'))
);
const Maintenance = React.lazy(() =>
  lazyRetry(() => import('./containers/maintenance'))
);

interface Props {
  gastronomyUrlPath?: string;
}

const Router: React.FC<Props> = ({ gastronomyUrlPath }) => {
  const [routes, setRoutes] = useState<GetsbyRoutes>({});
  const [appConfig, setAppConfig] = useRecoilState(appConfigState);
  const setLocationHistory = useSetRecoilState(locationHistoryState);
  const apolloClient = useApolloClient();
  const history = useHistory();

  const appId = getAppIdFromUserAgent();
  const domain = window.location.hostname;
  const isGetsby = isGetsbyDomain();

  useEffect(() => {
    apolloClient
      .query<GetHolidaysQuery>({ query: GetHolidays })
      .then((result) => {
        if (result.data.getHolidays) {
          for (const holidayObj of result.data.getHolidays) {
            AvailabilityMixin.setHolidays(
              holidayObj.countryCode,
              holidayObj.holidays
            );
          }
        }
      })
      .catch((error) => captureException(error));
    getRoutes();
  }, []);

  useEffect(() => {
    if (!history.location) {
      return;
    }

    if (
      history.action === 'PUSH' &&
      !history.location.pathname.startsWith('/q/')
    ) {
      setLocationHistory((prev) => [...prev, history.location]);
    } else if (history.action === 'POP') {
      setLocationHistory((prev) => [...prev.slice(0, prev.length - 1)]);
    }
  }, [history.location]);

  const getRoutes = async () => {
    /* eslint react/display-name: 0 */

    const routeObj: GetsbyRoutes = {
      '/': () => {
        const params = queryString.parse(window.location.search);
        const hasTOrGParam = !!(params.g || params.t);
        return {
          exact: true,
          showFooter: !hasTOrGParam,
          showNavigation: !hasTOrGParam,
          showContainer: !hasTOrGParam,
          component: (routeProps: RouteComponentProps) => {
            if (hasTOrGParam) {
              return <QueryParamsProcessor queryParams={params} />;
            } else {
              return <RestaurantView />;
            }
          }
        };
      },
      '/speisekarte/:urlPathId': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <Redirect to={`/${routeProps.match.params.urlPathId}/speisekarte`} />
        )
      },
      '/cart': {
        exact: true,
        component: Cart,
        showFooter: false,
        applyCustomTheme: true
      },
      '/payment/confirm/:receiptUuid': {
        exact: true,
        component: (
          routeProps: RouteComponentProps<{ receiptUuid: string }>
        ) => (
          <PaymentConfirm receiptUuid={routeProps.match.params.receiptUuid} />
        )
      },
      '/orders': {
        exact: true,
        component: LastOrders
      },
      '/bonus': {
        exact: true,
        component: BonusProgram
      },
      '/bonus/:urlPathId': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <Redirect to={`/${routeProps.match.params.urlPathId}/bonus`} />
        )
      },
      '/bonus/app/:appUrlPathId': {
        exact: true,
        component: (
          routeProps: RouteComponentProps<{ appUrlPathId: string }>
        ) => (
          <BonusProgram appUrlPathId={routeProps.match.params.appUrlPathId} />
        )
      },
      '/order/:receiptUuid': {
        exact: true,
        component: (
          routeProps: RouteComponentProps<{ receiptUuid: string }>
        ) => <Order receiptUuid={routeProps.match.params.receiptUuid} />,
        showFooter: false,
        applyCustomTheme: true
      },
      '/q/:code': {
        exact: true,
        showFooter: false,
        showNavigation: false,
        showContainer: false,
        component: (routeProps: RouteComponentProps<{ code: string }>) => (
          <QueryParamsProcessor code={routeProps.match.params.code} />
        )
      },
      '/corona/:urlPathId': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <Redirect to={`/${routeProps.match.params.urlPathId}/corona`} />
        )
      },
      '/profile': {
        exact: true,
        component: Profile
      },
      '/faq': {
        exact: true,
        component: Faq
      },
      '/help': {
        exact: true,
        component: Help
      },
      '/agb': {
        exact: true,
        component: Gtc
      },
      '/privacy-policy': {
        exact: true,
        component: PrivacyPolicy
      },
      '/imprint': {
        exact: true,
        component: Imprint
      },
      '/corona-datenschutz': {
        exact: true,
        component: CoronaPrivacyPolicy
      },
      '/referral': {
        exact: true,
        component: Referral
      },
      '/gtc': {
        exact: true,
        component: () => {
          window.location.href = Config.dashboardHost + '/gtc';
          return <Spinner center={true} />;
        }
      },
      '/datenschutzerklaerung': {
        exact: true,
        component: () => <Redirect to="/privacy-policy" />
      },
      '/impressum': {
        exact: true,
        component: () => <Redirect to="/imprint" />
      },
      '/open-in-browser': {
        exact: true,
        component: OpenInBrowser
      },
      '/auth': {
        exact: true,
        component: Authentication
      },
      '/auth/:action': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ action: any }>) => (
          <Authentication action={routeProps.match.params.action} />
        )
      },
      '/auth/:action/:urlPathId': {
        exact: true,
        component: (
          routeProps: RouteComponentProps<{ action: any; urlPathId: string }>
        ) => (
          <Authentication
            action={routeProps.match.params.action}
            urlPathId={routeProps.match.params.urlPathId}
          />
        ),
        applyCustomTheme: true
      },
      '/download-app/:urlPathId': {
        exact: true,
        component: (routeProps: RouteComponentProps<{ urlPathId: string }>) => (
          <Redirect to={`/${routeProps.match.params.urlPathId}/download-app`} />
        )
      },
      '/download/:appUrlPathId': {
        exact: true,
        component: (
          routeProps: RouteComponentProps<{ appUrlPathId: string }>
        ) => (
          <DownloadApp appUrlPathId={routeProps.match.params.appUrlPathId} />
        ),
        applyCustomTheme: true
      },
      '/maintenance': {
        exact: true,
        component: Maintenance
      },
      '/news/post/:postUuid': {
        exact: true,
        applyCustomTheme: true,
        component: (routeProps: RouteComponentProps<{ postUuid?: string }>) => (
          <NewsletterPost postUuid={routeProps.match.params.postUuid} />
        )
      },
      '/news/subscription/:subscriptionUuid': {
        exact: true,
        showNavigation: appId ? true : false,
        showFooter: false,
        component: (
          routeProps: RouteComponentProps<{ subscriptionUuid?: string }>
        ) => (
          <NewsletterSubscription
            subscriptionUuid={routeProps.match.params.subscriptionUuid}
          />
        )
      },
      '/news': {
        exact: true,
        component: NewsletterFeed
      }
    };

    if ((appId && !appId.startsWith('by.gets.clientapp')) || !isGetsby) {
      const params: AppSearchParams = {};

      if (appId) {
        params.app_id = appId;
      } else if (!isGetsby) {
        params.domain = domain;
      }

      const { data } = await apolloClient.query<
        GetAppQuery,
        GetAppQueryVariables
      >({
        query: GetApp,
        variables: {
          params
        }
      });

      if (data.getApp) {
        setAppConfig(data.getApp);

        routeObj['/:urlPathId/speisekarte'] = {
          exact: true,
          applyCustomTheme: true,
          component: () => <RestaurantView />
        };
        routeObj['/:urlPathId/corona'] = {
          exact: true,
          applyCustomTheme: true,
          component: (
            routeProps: RouteComponentProps<{ urlPathId: string }>
          ) => (
            <GuestRegistration urlPathId={routeProps.match.params.urlPathId} />
          )
        };
        routeObj['/:urlPathId/bonus'] = {
          exact: true,
          applyCustomTheme: true,
          component: (
            routeProps: RouteComponentProps<{ urlPathId: string }>
          ) => <BonusProgram urlPathId={routeProps.match.params.urlPathId} />
        };
        routeObj['/:urlPathId/download-app'] = {
          exact: true,
          applyCustomTheme: true,
          component: (
            routeProps: RouteComponentProps<{ urlPathId: string }>
          ) => (
            <DownloadApp
              gastronomyUrlPathId={routeProps.match.params.urlPathId}
            />
          )
        };

        if (data.getApp.pages) {
          for (const page of data.getApp.pages) {
            routeObj[page.url_path] = {
              exact: true,
              showNavigation: page.show_navigation,
              showContainer: page.show_container,
              showFooter: page.show_footer,
              useGastronomyTheme: page.user_uuid,
              applyCustomTheme: !!page.user_uuid,
              component: () => <CustomPage html={page.html} css={page.css} />
            };
          }
        }

        if (data.getApp.router) {
          for (const route of data.getApp.router) {
            const oldRoute = routeObj[route.path];

            if (!oldRoute) {
              continue;
            }

            routeObj[route.overwrite] = {
              ...oldRoute,
              params: route.params
            };

            if (!route.params) {
              delete routeObj[route.path];
            }
          }

          routeObj['*'] = {
            exact: false,
            component: () => {
              const url = new URL(window.location.href);
              window.location.href = url.toString();
              return <></>;
            }
          };
        }
      }
    } else {
      if (!gastronomyUrlPath) {
        routeObj['/'] = () => {
          const params = queryString.parse(window.location.search);
          const hasTOrGParam = !!(params.g || params.t);
          return {
            exact: true,
            showFooter: !hasTOrGParam,
            showNavigation: !hasTOrGParam,
            showContainer: !hasTOrGParam,
            component: (routeProps: RouteComponentProps) => {
              if (hasTOrGParam) {
                return <QueryParamsProcessor queryParams={params} />;
              } else {
                return <GastronomyList />;
              }
            }
          };
        };
        routeObj['/restaurants/:location'] = {
          exact: true,
          component: (
            routeProps: RouteComponentProps<{ location: string }>
          ) => (
            <GastronomyList locationString={routeProps.match.params.location} />
          )
        };
        routeObj['/partner'] = {
          exact: true,
          component: Partner,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/features'] = {
          exact: true,
          component: Features,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/online-bestellsystem'] = {
          exact: true,
          component: OnlineOrderSystem,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/sichtbarkeit'] = {
          exact: true,
          component: WebPresence,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/kommunikation'] = {
          exact: true,
          component: Communication,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/gaesteregistrierung'] = {
          exact: true,
          component: Corona,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/technik'] = {
          exact: true,
          component: Technic,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/bonuspunkte-club'] = {
          exact: true,
          component: Bonus,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/app'] = {
          exact: true,
          component: App,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/preise'] = {
          exact: true,
          component: Pricing,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/tischservice'] = {
          exact: true,
          component: TableService,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/take-away'] = {
          exact: true,
          component: TakeAway,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/zimmerservice'] = {
          exact: true,
          component: RoomService,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/veranstaltungen'] = {
          exact: true,
          component: Events,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/mehrweg'] = {
          exact: true,
          component: ReusablePackaging,
          showContainer: false,
          isPartnerPage: true
        };
        routeObj['/partner/webshop'] = {
          exact: true,
          component: Webshop,
          showContainer: false,
          isPartnerPage: true
        };
      }

      // workaround because martin printed the wrong qr codes
      routeObj['/krazy-kitchen/corona'] = {
        exact: true,
        component: () => (
          <Redirect to={`/krazy-kitchen-strandbar-herrmann/speisekarte`} />
        )
      };

      routeObj['/:urlPathId'] = {
        exact: false,
        applyLayout: false,
        component: SubRouter
      };
    }

    setRoutes(routeObj);
  };

  if (Object.entries(routes).length === 0) {
    return null;
  }

  return (
    <>
      {appConfig?.custom_theme?.head?.map((tag, i) => (
        <React.Fragment key={i}>
          {tag.tagType === 'LINK' && <Link {...tag.attributes} />}
          {tag.tagType === 'META' && <Meta {...tag.attributes} />}
          {tag.tagType === 'STYLE' && (
            <Style {...tag.attributes}>{tag.children}</Style>
          )}
        </React.Fragment>
      ))}
      <Switch>
        {Object.keys(routes).map((key, index) => {
          const routeObj = routes[key];
          const routeProps =
            typeof routeObj === 'function' ? routeObj() : routeObj;
          return <MakeRoute key={index} path={key} {...routeProps} />;
        })}
      </Switch>
    </>
  );
};

export default Router;
