import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH, ORDER_TYPES } from '../constants';
import { useSetRecoilState } from 'recoil';
import { tableTypeState } from '../store/gastronomy';
import {
  getDisabledUntilFromGastronomy,
  tableTypeDisplay
} from '../helpers/gastronomy';
import { format, isFuture } from 'date-fns';
import GetsbyLink from './getsbyLink';

interface Props {
  gastronomy: GastronomyFragment;
}

const isIframe = window.self !== window.top;

const TableTypeInitialSelect: React.FC<Props> = ({ gastronomy }: Props) => {
  const { t } = useTranslation();
  const setTableType = useSetRecoilState(tableTypeState);

  return (
    <div className="max-w-md p-4 mx-auto text-secondary-500">
      <h3 className="mb-6 text-xl font-semibold lg:text-2xl">
        {t('gastronomies.howDoYouLikeToOrder')}
      </h3>

      {gastronomy.options.tableTypes &&
        gastronomy.options.tableTypes
          .filter((tableType) => ORDER_TYPES.includes(tableType))
          .map((tableType, index) => {
            const disabledUntil = getDisabledUntilFromGastronomy(
              tableType,
              gastronomy
            );

            return (
              <div key={index}>
                {disabledUntil && isFuture(new Date(disabledUntil)) && (
                  <div className="flex flex-row items-center mb-2 text-sm text-red-500">
                    <span className="mr-2 text-sm material-icons">warning</span>
                    {t(`tableType.disabledUntil`, {
                      tableType,
                      date: format(new Date(disabledUntil), 'dd.MM.yyyy HH:mm')
                    })}
                  </div>
                )}
                <GetsbyLink
                  to={`/:urlPathId/speisekarte?tt=${tableType}`}
                  params={{ urlPathId: gastronomy.url_path_id }}
                  className={
                    'inline-flex items-center w-full px-4 py-2 mb-4 font-bold bg-white border border-gray-400 rounded shadow text-secondary-500 hover:bg-gray-100'
                  }
                  target={isIframe ? '_top' : undefined}
                  onClick={() => {
                    setTableType(tableType);
                  }}
                >
                  <img
                    className="w-4 h-auto mr-4"
                    src={`${IMG_BASE_PATH}/order_type/${tableType.toLowerCase()}.svg`}
                    alt=""
                  />
                  <span>
                    {t(`tableType.${tableTypeDisplay(gastronomy, tableType)}`)}
                  </span>
                </GetsbyLink>
              </div>
            );
            // }
          })}
    </div>
  );
};

export default TableTypeInitialSelect;
