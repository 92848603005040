import React from 'react';
import { IMG_BASE_PATH } from '../../../constants';
import Config from '../../../config';
import { ReactComponent as GrowthIcon } from './../../../assets/icons/growth.svg';
import { ReactComponent as IdeaIcon } from './../../../assets/icons/idea.svg';
import { ReactComponent as TasksIcon } from './../../../assets/icons/tasks.svg';
import { ReactComponent as QuestionsIcon } from './../../../assets/icons/questions.svg';
import { ReactComponent as ConfirmedIcon } from './../../../assets/icons/confirmed.svg';
import Feature from './feature';
import SectionHeading from './sectionHeading';
import FeatureSection from './featureSection';
import SectionSubHeading from './sectionSubHeading';

const BonusFeatures: React.FC = () => {
  return (
    <FeatureSection>
      <SectionHeading title="Bonuspunkte-Club" />

      <SectionSubHeading text="Sorgen Sie für mehr wiederkehrende Gäste durch Ihren eigenen Bonuspunkte-Club. Ihre Gäste sammeln bei jeder Konsumation in Ihrem Lokal Treuepunkte, die sie beim nächsten Besuch einlösen können. Geben Sie Ihren Gästen einen Anreiz, Sie bald wieder zu besuchen." />

      <Feature
        textOrientation="left"
        title="Steigern Sie die Gäste-Loyalität mit ihrem individuellen Bonuspunkte-Club"
        text={
          <p>
            Gäste können bei jeder Konsumation Treuepunkte sammeln und beim
            nächsten Besuch einlösen. Der perfekte Anreiz, Ihr Lokal bald wieder
            aufzusuchen. Dank unseres Newsletter-Tools bleiben Sie immer mit
            Ihren Gästen in Kontakt und könne diese über Neuigkeiten, Angebote
            und Veranstaltungen informieren.
          </p>
        }
        image={<GrowthIcon className="w-auto h-48 text-primary-500" />}
      />

      <Feature
        textOrientation="right"
        title="Wie funktioniert der Bonuspunkte-Club?"
        text={
          <p>
            <ol className="ml-4 list-decimal">
              <li>
                Ihre Gäste scannen den QR-Code am Tisch ein und registrieren
                sich, um Bonuspunkte sammeln zu können. Wenn Sie eine eigene
                Whitelabel Smartphone-App bei uns bestellt haben, kann der Gast
                diese nun installieren. Sonst findet alles Weitere einfach im
                Browser statt.
              </li>
              <li>
                Ihre Gäste können unabhängig vom online Bestellsystem im Browser
                oder der App ihre Konsumations-Rechnung einscannen, um
                Bonuspunkte gutgeschrieben zubekommen. Wird das online
                Bestellsystem verwendet, funktioniert das gleich direkt bei der
                Bestellung. Für jeden konsumierten Euro erhalten Gäste 1
                Bonuspunkt.
              </li>
              <li>
                Beim nächsten Besuch können Ihre Gäste die gesammelten
                Bonuspunkte durch Vorzeigen des Smartphone-Bildschirms beim
                Personal einlösen oder (sofern das online Bestellsystem
                verwendet wird) digital entwerten und erhalten den durch Sie
                festgelegten Rabatt.
              </li>
            </ol>
          </p>
        }
        image={
          <img
            src={`${IMG_BASE_PATH}/qrcode.png`}
            width="300px"
            height="300px"
            className="m-auto"
            alt=""
          />
        }
      />

      <Feature
        textOrientation="left"
        title="Warum einen Bonuspunkte-Club?"
        text={
          <p>
            <ul className="ml-4 list-disc">
              <li>Steigern Sie Ihre Kundenbindung</li>
              <li>
                Geben Sie Ihren Gästen einen zusätzlichen Anreiz, möglichst bald
                wiederzukommen
              </li>
              <li>Gewinnen Sie mehr Stammgäste</li>
              <li>Belohnen Sie Ihre bestehenden Stammgäste für ihre Treue</li>
              <li>
                Bleiben Sie mit Ihren Gästen in Kontakt, indem Sie unser
                Newsletter- & Notification-Tool verwenden
              </li>
              <li>
                Erinnern Sie Ihre Gäste daran, dass sie noch Bonuspunkte
                einlösen können und bewegen Sie sie zueinem Besuch in Ihrem
                Lokal
              </li>
              <li>
                Sehen Sie, welche Gäste schon länger nicht mehr bei Ihnen waren
                und spielen Sie automatisiert Angebote aus
              </li>
              <li>
                Erhöhen Sie Ihre Markenwahrnehmung und Markenloyalität durch
                Ihre eigene App in Apple AppStore und Google Play Store
              </li>
              <li>
                Nutzen Sie die zusätzlichen Features: Digitale mehrsprachige
                Speisekarte, Corona-Gästeregistrierung, Tischbestellung und
                Umsatzsteigerung durch Takeaway-/Liefer-Bestellungen
              </li>
            </ul>
          </p>
        }
        image={<IdeaIcon className="w-auto h-48 text-primary-500" />}
      />

      <Feature
        textOrientation="right"
        title="Wie kann ich den Bonusclub einrichten?"
        text={
          <p>
            <ol className="ml-4 list-decimal">
              <li>
                Registrieren Sie sich{' '}
                <a
                  href={`${Config.dashboardHost}?signup`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-yellow-500"
                >
                  hier
                </a>{' '}
                und Sie erhalten sofort Zugang zu Ihrem persönlichen
                Admin-Bereich, wo Sie alle gewünschten Einstellungen vornehmen
                können. Sobald Sie eingelogged sind, clicken Sie auf
                „Bonuspunkte Programm“. Dort kann festgelegt werden, wieviel
                Rabatt Ihre Gäste für jeden Bonuspunkt erhalten sollen. Gerne
                helfen wir Ihnen bei der Konfiguration.
              </li>
              <li>
                Kleben Sie die QR-Code Tischsticker, die Sie von uns erhalten,
                gut sichtbar auf alle Tische und verknüpfen Sie diese durch ein
                erstmaliges Einscannen mit Ihrem Restaurant. Wenn Sie möchten,
                können Sie auch eigene QR-Codes in Ihrem eigenen Design
                produzieren lassen.
              </li>
              <li>
                Bewerben Sie den Bonuspunkte-Club bei Ihren Gästen, um
                sicherzustellen, dass möglichst viele Gäste daran teilnehmen.
              </li>
            </ol>
          </p>
        }
        image={<QuestionsIcon className="w-auto h-48 text-primary-500" />}
      />

      <Feature
        textOrientation="left"
        title="Wie mache ich den Bonuspunkte-Club bei meinen Gästen erfolgreich?"
        text={
          <p>
            <ul className="ml-4 list-disc">
              <li>
                Instruieren Sie Ihr Servicepersonal, die Gäste auf das
                Bonusprogramm aufmerksam zumachen und zu erklären wie es
                funktioniert
              </li>
              <li>
                Weisen Sie Ihre Gäste bei Überreichung des Konsumationsbelegs
                darauf hin, dass sie durch Einscannen des Belegs Bonuspunkte
                sammeln können und beim nächsten Restaurantbesuch einen Rabatt
                erhalten
              </li>
              <li>
                Drucken Sie einen Hinweis zum Bonusprogramm auf Ihre Kaufbelege
              </li>
            </ul>
          </p>
        }
        image={<TasksIcon className="w-auto h-48 text-primary-500" />}
      />
    </FeatureSection>
  );
};

export default BonusFeatures;
