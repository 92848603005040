import React from 'react';
import { useRecoilState } from 'recoil';
import { userLocationState } from '../store/user';

const LocationButton: React.FC = () => {
  const [location, setLocation] = useRecoilState(userLocationState);

  return (
    <div
      className="flex items-center w-full h-full cursor-pointer"
      onClick={() => setLocation(null)}
    >
      <div className="truncate" style={{ maxWidth: '10rem' }}>
        {location && location.formatted_address
          ? location.formatted_address
          : 'not selected'}
      </div>
      <div className="ml-2 text-sm material-icons">edit</div>
    </div>
  );
};

export default LocationButton;
