import { useState, useEffect } from 'react';
import { WEB_APP_ID } from '../constants';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

interface UseBreakpointReturnType {
  breakpoint: Breakpoint;
  width: number;
  height: number;
}

export const useBreakpoint = (): UseBreakpointReturnType => {
  const initWidth = () => {
    if (document) {
      const root = document.getElementById(WEB_APP_ID);

      return root ? root.clientWidth : 0;
    }

    return 0;
  };

  const initHeight = () => {
    if (document) {
      const root = document.getElementById(WEB_APP_ID);

      return root ? root.clientHeight : 0;
    }

    return 0;
  };

  const [width, setWidth] = useState<number>(() => initWidth());
  const [height, setHeight] = useState<number>(() => initHeight());

  const handleResize = () => {
    const root = document.getElementById(WEB_APP_ID);

    setWidth(root ? root.clientWidth : 0);
    setHeight(root ? root.clientHeight : 0);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    breakpoint:
      width >= 1280 ? 'xl' : width >= 1024 ? 'lg' : width >= 768 ? 'md' : 'sm',
    width,
    height
  };
};
