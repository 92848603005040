import React from 'react';

interface Props {
  title: string;
  id?: string;
}

const SectionHeading: React.FC<Props> = ({ title, id }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full px-4 mb-16">
      <h2
        className="w-full my-2 text-5xl font-bold leading-tight text-center text-secondary-500"
        id={id}
      >
        {title}
      </h2>
      <div className="w-full mb-4">
        <div className="w-64 h-1 py-0 mx-auto my-0 rounded-t opacity-25 bg-primary-500 gradient" />
      </div>
    </div>
  );
};

export default SectionHeading;
