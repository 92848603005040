import gql from 'graphql-tag';
export const BonusProgramGastronomy = gql`
  fragment BonusProgramGastronomy on BonusProgramGastronomy {
    uuid
    name
    url_path_id
    status
  }
`;
export const BonusProgram = gql`
  fragment BonusProgram on BonusProgram {
    uuid
    title
    bonus_program_name
    bonus_threshold
    bonus_exchange_rate
    max_validity_days
    enable_invoice_scan
    enable_manual_redemption
    created_at
    icon
    gastronomies {
      ...BonusProgramGastronomy
    }
  }
  ${BonusProgramGastronomy}
`;
export const BonusProgramPoints = gql`
  fragment BonusProgramPoints on BonusProgramPoints {
    points
    bonus_program {
      ...BonusProgram
    }
  }
  ${BonusProgram}
`;
export const BonusProgramTransaction = gql`
  fragment BonusProgramTransaction on BonusProgramTransaction {
    uuid
    app_user_uuid
    user_uuid
    points
    redeemable_points
    redeemed_points
    receipt_uuid
    created_at
    expires_at
    type
    gastronomy {
      uuid
      name
    }
    bonus_program {
      ...BonusProgram
    }
  }
  ${BonusProgram}
`;
export const BonusProgramRedemption = gql`
  fragment BonusProgramRedemption on BonusProgramRedemption {
    uuid
    app_user_uuid
    redeemed_bonus_points
    redeemed_bonus_amount
    qrcode_text
    expires_at
    gastronomy {
      uuid
      name
    }
    bonus_program {
      ...BonusProgram
    }
  }
  ${BonusProgram}
`;
export const Credit = gql`
  fragment Credit on Credit {
    uuid
    campaign_coupon_code
    type
    valid_from
    valid_to
    combinable
    min_order_amount
    app_user_uuid
    value
    available_value
    created_at
    updated_at
    gastronomies {
      uuid
      name
    }
    requires_cc_products
  }
`;
export const PreliminaryCredit = gql`
  fragment PreliminaryCredit on PreliminaryCredit {
    type
    campaign_coupon_code
    valid_from
    valid_to
    combinable
    min_order_amount
    value
    only_first_order
    gastronomies {
      uuid
      name
    }
    requires_otp
    requires_cc_products
  }
`;
export const Event = gql`
  fragment Event on BookableEvent {
    uuid
    title
    begin_time
  }
`;
export const AvailabilityTime = gql`
  fragment AvailabilityTime on AvailabilityTimeElement {
    time_from
    time_to
    limit
  }
`;
export const Availability = gql`
  fragment Availability on AvailabilityTimes {
    mon {
      ...AvailabilityTime
    }
    tue {
      ...AvailabilityTime
    }
    wed {
      ...AvailabilityTime
    }
    thu {
      ...AvailabilityTime
    }
    fri {
      ...AvailabilityTime
    }
    sat {
      ...AvailabilityTime
    }
    sun {
      ...AvailabilityTime
    }
    hol {
      ...AvailabilityTime
    }
    e {
      dateRange {
        begin_date
        end_date
      }
      timeRanges {
        ...AvailabilityTime
      }
    }
    cc
  }
  ${AvailabilityTime}
`;
export const Category = gql`
  fragment Category on Category {
    uuid
    name
    icon
    featured_product_uuid
  }
`;
export const CustomThemeFragment = gql`
  fragment CustomThemeFragment on CustomTheme {
    colors {
      appBarColors {
        backgroundColor
        primaryColor
        secondaryColor
      }
      primary {
        _50
        _100
        _200
        _300
        _400
        _500
        _600
        _700
        _800
        _900
      }
      secondary {
        _50
        _100
        _200
        _300
        _400
        _500
        _600
        _700
        _800
        _900
      }
    }
    appBarLogo
    customCss
    enableZoom
    head {
      tagType
      attributes
      children
    }
    waitForFonts
  }
`;
export const Page = gql`
  fragment Page on Page {
    uuid
    app_uuid
    user_uuid
    url_path
    link_text
    html
    css
    show_navigation
    show_container
    show_footer
    created_at
    gastronomy {
      uuid
      name
    }
  }
`;
export const App = gql`
  fragment App on App {
    uuid
    name
    app_id
    custom_theme {
      ...CustomThemeFragment
    }
    pages {
      ...Page
    }
    facebook_app_id
    google_signin_client_id
    apple_signin_client_id
    apple_signin_return_url
    newsletter
    newsletter_checkbox_label
    app_store_id
    bonusProgram {
      uuid
      app_install_reward
      bonus_program_name
    }
    router {
      path
      overwrite
      params
    }
  }
  ${CustomThemeFragment}
  ${Page}
`;
export const Gastronomy = gql`
  fragment Gastronomy on Gastronomy {
    name
    uuid
    street
    zip
    city
    type
    header_image
    logo_image
    distance_in_km
    status
    isOpen
    isActive
    created_at
    phone
    lat
    lng
    place_id
    opening_times {
      ...Availability
    }
    options {
      hasTakeAway
      hasTableOrder
      hasSelfService
      hasPreOrder
      hasDriveIn
      hasDelivery
      hasDiscounts
      tipPercentage
      serviceInformation
      deliveryInformation
      enableCashPaymentForType
      tableTypes
      public_key
      enableGuestRegistration
      maxPreorderDays
      hideGastronomyNameInHeader
      disabledPaymentTypes {
        origin
        type
      }
      tableOrderIsRoomService
      tableOrderIsEvent
      canOrder
      disableNewCustomerCoupon
      hasBonusProgram
      enableNewsletter
      qrCodeRoute
    }
    url_path_id
    dinein_disabled_until
    takeaway_disabled_until
    selfservice_disabled_until
    drivein_disabled_until
    reservation_disabled_until
    delivery_disabled_until
    has_cc_products
    is_in_radius
    categories @include(if: $includeCategories) {
      ...Category
    }
    custom_theme {
      ...CustomThemeFragment
    }
    bonusProgram {
      ...BonusProgram
    }
    app {
      ...App
    }
  }
  ${Availability}
  ${Category}
  ${CustomThemeFragment}
  ${BonusProgram}
  ${App}
`;
export const GastronomyFeeModel = gql`
  fragment GastronomyFeeModel on GastronomyFeeModel {
    title
    description
    pp_var
    pp_fix
    pnp_var
    pnp_fix
    monthly_fix
    created_at
    includes_bonus_program
    includes_app
    dine_in
    take_away
    self_service
    drive_in
    delivery
  }
`;
export const Language = gql`
  fragment Language on Language {
    code
    title
    native_title
  }
`;
export const NewsletterSubscription = gql`
  fragment NewsletterSubscription on NewsletterSubscription {
    uuid
    app_user_uuid
    user_uuid
    app_uuid
    email
    push
    name
    subscribedEmail
  }
`;
export const Newsletter = gql`
  fragment Newsletter on Newsletter {
    uuid
    user_uuid
    app_uuid
    type
    subject
    body_push
    body_html
    body_text
    sent_at
    owner
  }
`;
export const OrderErrorLogFragment = gql`
  fragment OrderErrorLogFragment on OrderErrorLog {
    uuid
    app_user_uuid
    user_uuid
    request_input
    error
    origin {
      type
      version
      userAgent
      url
      host
    }
    created_at
  }
`;
export const Card = gql`
  fragment Card on Card {
    id
    brand
    country
    cvcCheck
    expMonth
    expYear
    funding
    last4
    name
    isDefault
  }
`;
export const ModifierItem = gql`
  fragment ModifierItem on ProductModifierItem {
    uuid
    price_modifier
    title
    allergenList
    type
    order
    modifier_uuid
    description
  }
`;
export const Modifier = gql`
  fragment Modifier on ProductModifier {
    uuid
    title
    description
    min
    max
    order
    modifierItems {
      ...ModifierItem
    }
  }
  ${ModifierItem}
`;
export const ProductGroup = gql`
  fragment ProductGroup on ProductGroup {
    uuid
    title
    alias
    description
    url_path_id
    effective_availability {
      table_uuid
      times {
        ...Availability
      }
    }
    category_uuid
  }
  ${Availability}
`;
export const Product = gql`
  fragment Product on Product {
    uuid
    user_uuid
    title
    alias
    type
    gross_price
    description
    image_full
    allergenList
    order_limit
    discount
    hasModifier
    hasPriceModifier
    out_of_stock_until
    is_cc_product
    image_metadata {
      dimensions {
        width
        height
      }
    }
  }
`;
export const ProductGroupInclProduct = gql`
  fragment ProductGroupInclProduct on ProductGroup {
    ...ProductGroup
    products {
      ...Product
    }
  }
  ${ProductGroup}
  ${Product}
`;
export const Receipt = gql`
  fragment Receipt on Receipt {
    uuid
    user_uuid
    created_at
    updated_at
    app_user_uuid
    status
    pickup_code
    total_price
    table_type
    error {
      code
      message
      productUuids
      couponCode {
        code
      }
    }
    gastronomy {
      uuid
      name
      logo_image
      url_path_id
      phone
      zip
      city
      street
      lat
      lng
      place_id
    }
    estimated_pickup_time
    requested_pickup_time
    hasCanceledOrderItems
    language_code
  }
`;
export const FullReceiptModifierItem = gql`
  fragment FullReceiptModifierItem on OrderItemModifierItem {
    modifier_item_uuid
    title
    title_translation
    price_modifier
    order
    modifier {
      uuid
      title
      order
    }
  }
`;
export const FullReceiptOrderItem = gql`
  fragment FullReceiptOrderItem on OrderItem {
    product {
      image
    }
    product_title
    product_title_translation
    product_price
    product_uuid
    comment
    discount
    user_uuid
    uuid
    is_canceled
    modifierItems {
      ...FullReceiptModifierItem
    }
  }
  ${FullReceiptModifierItem}
`;
export const InvoiceFragment = gql`
  fragment InvoiceFragment on Invoice {
    uuid
    receipt_uuid
    pos_invoice_nr
    url
  }
`;
export const FullReceipt = gql`
  fragment FullReceipt on Receipt {
    uuid
    user_uuid
    created_at
    payment_status
    payment_type
    updated_at
    payment_provider_json
    app_user_uuid
    status
    pickup_code
    pos_invoice_url
    total_price
    discount
    table_type
    error {
      code
      message
      productUuids
      couponCode {
        code
      }
    }
    gastronomy {
      uuid
      name
      logo_image
      url_path_id
      phone
      zip
      city
      street
      lat
      lng
      place_id
      options {
        orderInformation
        tableOrderIsRoomService
        tableOrderIsEvent
      }
      custom_theme {
        ...CustomThemeFragment
      }
    }
    orderItems {
      ...FullReceiptOrderItem
    }
    tip {
      amount
    }
    receiptCredits {
      value
    }
    estimated_pickup_time
    requested_pickup_time
    table {
      table_type
      table_number
      tableConfig {
        slotLength
      }
    }
    delivery_address {
      firstname
      lastname
      note
      street
      zip
      city
      phone
      default
    }
    hasCanceledOrderItems
    invoices {
      ...InvoiceFragment
    }
    pos_type
    language_code
    redeemed_bonus_amount
    redeemed_bonus_points
  }
  ${CustomThemeFragment}
  ${FullReceiptOrderItem}
  ${InvoiceFragment}
`;
export const Table = gql`
  fragment Table on Table {
    uuid
    status
    table_number
    user_uuid
    allow_preorder
    table_type
    service_information
    effective_service_times {
      ...Availability
    }
    tableConfig {
      leadTime
      slotLength
      preorderDays
    }
    delivery_config {
      minOrderAmount
      deliveryFee
      freeDeliveryFrom
    }
    canPreorderNow
  }
  ${Availability}
`;
export const UserProfile = gql`
  fragment UserProfile on UserProfile {
    uuid
    email
    referral_code
    addresses {
      firstname
      lastname
      note
      street
      zip
      city
      phone
      default
    }
  }
`;
export const GetApp = gql`
  query getApp($params: AppSearchParams!) {
    getApp(params: $params) {
      ...App
    }
  }
  ${App}
`;
export const Login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      uuid
      email
    }
  }
`;
export const SocialLogin = gql`
  mutation socialLogin($token: String!, $provider: AuthorizerType!) {
    socialLogin(token: $token, provider: $provider) {
      uuid
      email
    }
  }
`;
export const Signup = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      userProfile {
        uuid
        email
      }
      app {
        name
        app_id
        app_store_id
        url_path_id
      }
    }
  }
`;
export const Logout = gql`
  mutation logout {
    logout
  }
`;
export const IsAuthenticated = gql`
  mutation isAuthenticated {
    isAuthenticated
  }
`;
export const SetGuestUuid = gql`
  mutation setGuestUuid($identityId: ID!) {
    setGuestUuid(identityId: $identityId)
  }
`;
export const ResetPassword = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
export const ResetPasswordConfirm = gql`
  mutation resetPasswordConfirm(
    $email: String!
    $code: String!
    $password: String!
  ) {
    resetPasswordConfirm(email: $email, code: $code, password: $password)
  }
`;
export const GetBonusProgramPoints = gql`
  query getBonusProgramPoints($params: BonusProgramPointsSearchParams!) {
    getBonusProgramPoints(params: $params) {
      ...BonusProgramPoints
    }
  }
  ${BonusProgramPoints}
`;
export const GetGroupedBonusProgramPoints = gql`
  query getGroupedBonusProgramPoints($app_user_uuid: ID) {
    getGroupedBonusProgramPoints(app_user_uuid: $app_user_uuid) {
      ...BonusProgramPoints
    }
  }
  ${BonusProgramPoints}
`;
export const GetBonusProgramTransactions = gql`
  query getBonusProgramTransactions(
    $params: BonusProgramTransactionsSearchParams!
  ) {
    getBonusProgramTransactions(params: $params) {
      ...BonusProgramTransaction
    }
  }
  ${BonusProgramTransaction}
`;
export const AddBonusInvoice = gql`
  mutation addBonusInvoice($input: BonusInvoiceAddInput!) {
    addBonusInvoice(input: $input) {
      ...BonusProgramTransaction
    }
  }
  ${BonusProgramTransaction}
`;
export const GetBonusProgramRedemptions = gql`
  query getBonusProgramRedemptions(
    $params: BonusProgramRedemptionsSearchParams!
  ) {
    getBonusProgramRedemptions(params: $params) {
      ...BonusProgramRedemption
    }
  }
  ${BonusProgramRedemption}
`;
export const RedeemBonusProgramPoints = gql`
  mutation redeemBonusProgramPoints($input: RedeemBonusProgramPointsInput!) {
    redeemBonusProgramPoints(input: $input) {
      ...BonusProgramRedemption
    }
  }
  ${BonusProgramRedemption}
`;
export const GetCategories = gql`
  query getCategories($params: CategorySearchParams!) {
    getCategories(params: $params) {
      ...Category
    }
  }
  ${Category}
`;
export const GetCredits = gql`
  query getCredits($params: CreditSearchParams!) {
    getCredits(params: $params) {
      ...Credit
    }
  }
  ${Credit}
`;
export const CheckCouponCode = gql`
  query checkCouponCode($code: ID!, $phone: String) {
    checkCouponCode(code: $code, phone: $phone) {
      ...PreliminaryCredit
    }
  }
  ${PreliminaryCredit}
`;
export const ActivateCouponCode = gql`
  mutation activateCouponCode($input: ActivateCouponCodeInput!) {
    activateCouponCode(input: $input) {
      ...Credit
    }
  }
  ${Credit}
`;
export const SendOtp = gql`
  mutation sendOTP($emailOrPhone: String!, $type: OtpType!) {
    sendOTP(emailOrPhone: $emailOrPhone, type: $type)
  }
`;
export const GetEvents = gql`
  query getEvents($params: EventSearchParams!) {
    getEvents(params: $params) {
      ...Event
    }
  }
  ${Event}
`;
export const GetGastronomy = gql`
  query getGastronomy(
    $params: GastronomySearchParams!
    $includeCategories: Boolean! = false
  ) {
    getGastronomy(params: $params) {
      ...Gastronomy
      productGroupsInCart
    }
  }
  ${Gastronomy}
`;
export const GetGastronomyUrlPath = gql`
  query getGastronomyUrlPath($params: GastronomySearchParams!) {
    getGastronomy(params: $params) {
      uuid
      url_path_id
      options {
        enableGuestRegistration
        canOrder
        tableTypes
        qrCodeRoute
      }
    }
  }
`;
export const GetGastronomies = gql`
  query getGastronomies(
    $params: GastronomiesSearchParams!
    $includeCategories: Boolean! = false
  ) {
    getGastronomies(params: $params) {
      ...Gastronomy
    }
  }
  ${Gastronomy}
`;
export const Search = gql`
  query search($params: SearchParams!, $includeCategories: Boolean! = false) {
    search(params: $params) {
      rank
      type
      gastronomy {
        ...Gastronomy
      }
    }
  }
  ${Gastronomy}
`;
export const IsGastronomyActive = gql`
  query isGastronomyActive($params: GastronomySearchParams!) {
    getGastronomy(params: $params) {
      uuid
      isActive
      isOpen
    }
  }
`;
export const GetGuestRegistrationFields = gql`
  query getGuestRegistrationFields($params: GuestRegistrationFieldsParams!) {
    getGuestRegistrationFields(params: $params) {
      name
      html_type
      translation_key
      required
      sort
    }
  }
`;
export const CheckDeliveryRadius = gql`
  query checkDeliveryRadius($params: CheckDeliveryRadiusParams!) {
    checkDeliveryRadius(params: $params) {
      result
      table_uuid
      meters
      minutes
    }
  }
`;
export const GetCurrentGastronomyFeeModels = gql`
  query getCurrentGastronomyFeeModels {
    getCurrentGastronomyFeeModels {
      ...GastronomyFeeModel
    }
  }
  ${GastronomyFeeModel}
`;
export const GetGastronomyOpeningTimes = gql`
  query getGastronomyOpeningTimes($params: GastronomySearchParams!) {
    getGastronomy(params: $params) {
      opening_times {
        ...Availability
      }
    }
  }
  ${Availability}
`;
export const GetHolidays = gql`
  query getHolidays {
    getHolidays {
      countryCode
      holidays
    }
  }
`;
export const GetInvoiceUrl = gql`
  query getInvoiceUrl($uuid: ID!) {
    getInvoiceUrl(uuid: $uuid)
  }
`;
export const GetLanguages = gql`
  query getLanguages {
    getLanguages {
      ...Language
    }
  }
  ${Language}
`;
export const GetNewsletter = gql`
  query getNewsletter($uuid: ID!) {
    getNewsletter(uuid: $uuid) {
      ...Newsletter
    }
  }
  ${Newsletter}
`;
export const GetNewsletterSubscription = gql`
  query getNewsletterSubscription($params: NewsletterSubscriptionParams!) {
    getNewsletterSubscription(params: $params) {
      ...NewsletterSubscription
    }
  }
  ${NewsletterSubscription}
`;
export const UpdateNewsletterSubscription = gql`
  mutation updateNewsletterSubscription($input: NewsletterSubscriptionInput!) {
    updateNewsletterSubscription(input: $input) {
      status
    }
  }
`;
export const GetNewsletters = gql`
  query getNewsletters($params: NewsletterSearchParams) {
    getClientNewsletters(params: $params) {
      ...Newsletter
    }
  }
  ${Newsletter}
`;
export const AddOrder = gql`
  mutation addOrder($input: OrderItemsAddInput!) {
    addOrder(input: $input) {
      user_uuid
      app_user_uuid
      table_uuid
      receipt_uuid
      useCredits {
        campaign_coupon_code
      }
      orderItems {
        __typename
        app_user_uuid
        product_title
        product_price
        product_tax
        product_uuid
        comment
        created_at
        receipt_uuid
        table_uuid
        updated_at
        user_uuid
        uuid
      }
      paymentIntent
    }
  }
`;
export const CompleteOrder = gql`
  mutation completeOrder($input: CompleteOrderInput!) {
    completeOrder(input: $input) {
      message
    }
  }
`;
export const AddOrderErrorLog = gql`
  mutation addOrderErrorLog($input: OrderErrorLogAddInput!) {
    addOrderErrorLog(input: $input) {
      ...OrderErrorLogFragment
    }
  }
  ${OrderErrorLogFragment}
`;
export const GetPages = gql`
  query getPages($params: PagesSearchParams!) {
    getPages(params: $params) {
      ...Page
    }
  }
  ${Page}
`;
export const GetPaymentProviderToken = gql`
  query getPaymentProviderToken {
    getPaymentProviderToken {
      token
    }
  }
`;
export const GetSavedCards = gql`
  query getSavedCards {
    getAllCardsOfCustomer {
      ...Card
    }
  }
  ${Card}
`;
export const DeleteCardOfCustomer = gql`
  mutation deleteCardOfCustomer($cardId: String!) {
    deleteCardOfCustomer(cardId: $cardId) {
      id
      deleted
      errorMessage
    }
  }
`;
export const GetProduct = gql`
  query getProduct($uuid: ID!) {
    getProduct(uuid: $uuid) {
      ...Product
    }
  }
  ${Product}
`;
export const GetProducts = gql`
  query getProducts($params: ProductSearchParams!) {
    getProducts(params: $params) {
      ...Product
    }
  }
  ${Product}
`;
export const GetProductModifiers = gql`
  query getProductModifiers($uuid: ID, $languageCode: String) {
    getProduct(uuid: $uuid, languageCode: $languageCode) {
      uuid
      modifier {
        ...Modifier
      }
    }
  }
  ${Modifier}
`;
export const GetProductGroups = gql`
  query getProductGroups($params: ProductGroupSearchParams!) {
    getProductGroups(params: $params) {
      ...ProductGroup
    }
  }
  ${ProductGroup}
`;
export const GetProductGroupsInclProducts = gql`
  query getProductGroupsInclProducts($params: ProductGroupSearchParams!) {
    getProductGroups(params: $params) {
      ...ProductGroupInclProduct
    }
  }
  ${ProductGroupInclProduct}
`;
export const HasProductGroups = gql`
  query hasProductGroups($params: ProductGroupSearchParams!) {
    getProductGroups(params: $params) {
      uuid
    }
  }
`;
export const GetProductGroup = gql`
  query getProductGroup($uuid: ID, $languageCode: String) {
    getProductGroup(uuid: $uuid, languageCode: $languageCode) {
      ...ProductGroupInclProduct
    }
  }
  ${ProductGroupInclProduct}
`;
export const LookupQrCode = gql`
  query lookupQrCode($url: String!, $includeCategories: Boolean! = false) {
    lookupQrCode(url: $url) {
      url
      table {
        ...Table
      }
      gastronomy {
        ...Gastronomy
      }
    }
  }
  ${Table}
  ${Gastronomy}
`;
export const GetReceiptFull = gql`
  query getReceiptFull($uuid: ID!, $languageCode: String) {
    getReceipt(uuid: $uuid, languageCode: $languageCode) {
      ...FullReceipt
    }
  }
  ${FullReceipt}
`;
export const GetReceiptAfterOrder = gql`
  query getReceiptAfterOrder($uuid: ID!, $languageCode: String) {
    getReceipt(uuid: $uuid, languageCode: $languageCode) {
      uuid
      status
      error {
        code
        message
        productUuids
        couponCode {
          code
        }
      }
      estimated_pickup_time
    }
  }
`;
export const GetLastReceipts = gql`
  query getLastReceipts($params: ReceiptSearchParams!) {
    getClientReceipts(params: $params) {
      ...Receipt
    }
  }
  ${Receipt}
`;
export const UpdateReceipt = gql`
  mutation updateReceipt($input: ReceiptUpdateInput!) {
    adminUpdateReceipt(input: $input) {
      uuid
    }
  }
`;
export const AddSupportMessage = gql`
  mutation addSupportMessage($input: SupportMessageAddInput!) {
    addSupportMessage(input: $input) {
      __typename
    }
  }
`;
export const GetTableType = gql`
  query getTableType($uuid: ID) {
    getTable(uuid: $uuid) {
      uuid
      table_type
    }
  }
`;
export const GetTable = gql`
  query getTable($uuid: ID) {
    getTable(uuid: $uuid) {
      ...Table
    }
  }
  ${Table}
`;
export const GetTables = gql`
  query getTables($params: TableSearchParams!) {
    getTables(params: $params) {
      ...Table
    }
  }
  ${Table}
`;
export const GetPreorderSlots = gql`
  query getPreorderSlots($params: PreorderSlotsParams!) {
    getPreorderSlots(params: $params) {
      date
      slots {
        from
        to
        limit
      }
    }
  }
`;
export const AddGuestRegistration = gql`
  mutation addGuestRegistration($input: GuestRegistrationInput!) {
    addGuestRegistration(input: $input) {
      uuid
      created_at
      user_uuid
    }
  }
`;
export const AddGuestRegistrations = gql`
  mutation addGuestRegistrations($input: [GuestRegistrationInput!]!) {
    addGuestRegistrations(input: $input) {
      uuid
      created_at
      user_uuid
    }
  }
`;
export const GetUserProfile = gql`
  query getUserProfile {
    getUserProfile {
      ...UserProfile
    }
  }
  ${UserProfile}
`;
export const AddNotificationToken = gql`
  mutation addNotificationToken($input: NotificationTokenAddInput!) {
    addNotificationToken(input: $input) {
      status
    }
  }
`;
export const DeleteNotificationToken = gql`
  mutation deleteNotificationToken($input: NotificationTokenDeleteInput!) {
    deleteNotificationToken(input: $input) {
      status
    }
  }
`;
