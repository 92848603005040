import { ApolloClient } from '@apollo/client';
import { STORAGE_KEY_PERSISTED_LOCATION } from '../constants';
import { CheckDeliveryRadius, GetTable } from '../services/graphql/operations';
import { isStorageAvailable } from './localStorage';
import { delay } from './utilities';

export const requestGeolocation = async (): Promise<GeolocationPosition> => {
  return requestGeolocationWithRetry(3);
};

const requestGeolocationWithRetry = async (
  retryCount = 3
): Promise<GeolocationPosition> => {
  console.log('requsting geolocation retry', retryCount);
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position: GeolocationPosition) => {
          console.log('accuracy', position.coords.accuracy);
          if (position.coords.accuracy > 100 && retryCount > 0) {
            await delay(100);
            resolve(requestGeolocationWithRetry(retryCount - 1));
          } else {
            resolve(position);
          }
        },
        (error) => {
          reject(error);
        },
        {
          enableHighAccuracy: true
        }
      );
    } else {
      reject('Geolocation unavailable');
    }
  });
};

const getLocationFromStorage = (): UserLocation | undefined => {
  if (isStorageAvailable()) {
    const locationFromStorageJson = localStorage.getItem(
      STORAGE_KEY_PERSISTED_LOCATION
    );
    if (locationFromStorageJson && locationFromStorageJson !== 'undefined') {
      const locationFromStorage = JSON.parse(locationFromStorageJson);
      if (locationFromStorage) {
        return locationFromStorage;
      }
    }
  }
};

export const getLastSelectedLocation = (): UserLocation | undefined => {
  const lastLocation = getLocationFromStorage();
  if (lastLocation) {
    return lastLocation;
  }
};

export const setLastSelectedLocation = (
  location: UserLocation | undefined
): void => {
  if (isStorageAvailable()) {
    if (location) {
      localStorage.setItem(
        STORAGE_KEY_PERSISTED_LOCATION,
        JSON.stringify(location)
      );
    } else {
      localStorage.removeItem(STORAGE_KEY_PERSISTED_LOCATION);
    }
  }
};

export const getAddressFromPlace = (
  deliveryData: DeliveryData,
  phone?: string
): AddressInput | undefined => {
  if (deliveryData.address_components) {
    let zip = '';
    let city = '';
    let street = '';
    let streetNumber = '';
    for (const component of deliveryData.address_components) {
      if (component.types.includes('postal_code')) {
        zip = component.short_name;
      } else if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('route')) {
        street = component.long_name;
      } else if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
    }
    const apt =
      ('/' + deliveryData.entrance || '') +
      ('/' + deliveryData.floor || '') +
      ('/' + deliveryData.appartment || '');
    const addressInput: AddressInput = {
      firstname: deliveryData.name || '',
      lastname: deliveryData.name || '',
      zip,
      city,
      street: street + ' ' + streetNumber + apt,
      note: deliveryData.note,
      phone: phone || deliveryData.phone || '',
      default: false
    };

    return addressInput;
  }
};

export const isInDeliveryRadius = async (
  apolloClient: ApolloClient<any>,
  lat: number,
  lng: number,
  user_uuid: string
): Promise<TableFragment | null> => {
  try {
    const result = await apolloClient.query<
      CheckDeliveryRadiusQuery,
      CheckDeliveryRadiusQueryVariables
    >({
      query: CheckDeliveryRadius,
      fetchPolicy: 'network-only',
      variables: { params: { coordinates: { lat, lng }, user_uuid } }
    });
    if (result.data && result.data.checkDeliveryRadius) {
      console.log('checkDeliverRadius result', result.data.checkDeliveryRadius);
      for (const tableResult of result.data.checkDeliveryRadius) {
        if (tableResult.result === true) {
          const table = await apolloClient.query<
            GetTableQuery,
            GetTableQueryVariables
          >({ query: GetTable, variables: { uuid: tableResult.table_uuid } });
          console.log('delivery table', table);
          if (table.data && table.data.getTable) {
            return table.data.getTable;
          }
        }
      }
    }
  } catch (error) {
    console.log('error checking radius');
  }
  return null;
};

export const placeToFormattedAddress = (
  place: google.maps.places.PlaceResult,
  streetNumber?: string
): string => {
  return (
    getAddressElement(place, 'route') +
    ' ' +
    (streetNumber || getAddressElement(place, 'street_number') || '') +
    (', ' + getAddressElement(place, 'postal_code') || '') +
    (', ' + getAddressElement(place, 'locality') || '')
  );
};

export const getAddressElement = (
  place: { address_components?: google.maps.GeocoderAddressComponent[] },
  element: 'route' | 'locality' | 'postal_code' | 'street_number'
): string | undefined => {
  if (place.address_components) {
    for (const component of place.address_components) {
      if (component.types.includes(element)) {
        return component.long_name;
      }
    }
  }
};
