import React, { useEffect } from 'react';
import Heading from './heading';
import HelpForm from './helpForm';
import Modal from './modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
  onClose?: () => void;
}

type UnregisterCallback = () => void;

const HelpModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  let historyListener: UnregisterCallback | null = null;

  useEffect(() => {
    historyListener = history.listen(() => {
      if (onClose) {
        onClose();
      }
    });

    return () => {
      if (historyListener) {
        historyListener();
      }
    };
  }, [history]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal onClose={handleClose}>
        <Heading text={t('navigation.help')} />
        <HelpForm />
      </Modal>
    </>
  );
};

export default HelpModal;
