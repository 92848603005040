import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import useUri from '../hooks/useUri';

const GetsbyLink: React.FC<GetsbyRouter & LinkProps> = ({
  to,
  params,
  className,
  children,
  ...rest
}) => {
  const uri = useUri(to, params);

  return (
    <Link to={uri} className={className} {...rest}>
      {children}
    </Link>
  );
};

export default GetsbyLink;
