import { atom, selector } from 'recoil';
import { STORAGE_KEY_SELECTED_LANGUAGE_CODE } from '../constants';
import { isStorageAvailable } from '../helpers/localStorage';
import { getUserLanguage } from '../helpers/language';
import Config from '../config';
import { Location } from 'history';

const currentThemeAtom = atom<CustomThemeState | null>({
  key: 'currentThemeAtom',
  default: null
});

export const currentThemeState = selector<CustomThemeState | null>({
  key: 'currentThemeState',
  get: ({ get }) => {
    const currentTheme = get(currentThemeAtom);
    const appConfig = get(appConfigState);

    if (currentTheme) {
      return currentTheme;
    }

    if (appConfig?.custom_theme) {
      return { ...appConfig.custom_theme, type: 'APP' as CustomThemeType };
    }

    return null;
  },
  set: ({ set }, newCustomTheme) => set(currentThemeAtom, newCustomTheme)
});

export const currentColorsState = selector<
  | {
      primary?: string;
      secondary?: string;
    }
  | undefined
>({
  key: 'currentColorsSelector',
  get: ({ get }) => {
    const currentTheme = get(currentThemeState);
    if (!currentTheme?.colors) {
      return {
        primary: Config.primaryColor,
        secondary: Config.secondaryColor
      };
    }
    return {
      primary:
        typeof currentTheme?.colors?.primary?._500 === 'string'
          ? currentTheme?.colors?.primary?._500
          : Config.primaryColor,
      secondary:
        typeof currentTheme?.colors?.secondary?._500 === 'string'
          ? currentTheme?.colors?.secondary?._500
          : Config.secondaryColor
    };
  }
});

export const languagesState = atom<Language[]>({
  key: 'langugages',
  default: []
});

const getSelectedLanguageCodeDefault = (): string | undefined => {
  if (isStorageAvailable()) {
    const persistedLanguageCode = localStorage.getItem(
      STORAGE_KEY_SELECTED_LANGUAGE_CODE
    );
    if (persistedLanguageCode) {
      return persistedLanguageCode;
    }
  }
  return getUserLanguage() || undefined;
};

const selectedLanguageCodeAtom = atom<string | undefined>({
  key: 'selectedLanguageCodeAtom',
  default: getSelectedLanguageCodeDefault()
});

export const selectedLanguageCodeState = selector<string | undefined>({
  key: 'selectedLanguageCodeState',
  get: ({ get }) => get(selectedLanguageCodeAtom),
  set: ({ get, set }, newLanguage) => {
    const languages = get(languagesState);

    if (isStorageAvailable()) {
      if (newLanguage) {
        localStorage.setItem(
          STORAGE_KEY_SELECTED_LANGUAGE_CODE,
          newLanguage as string
        );
      } else {
        localStorage.removeItem(STORAGE_KEY_SELECTED_LANGUAGE_CODE);
      }
    }

    set(
      selectedLanguageCodeAtom,
      languages.find((l) => l.code === newLanguage) ? newLanguage : 'en'
    );
  }
});

export const appConfigState = atom<AppFragment | null>({
  key: 'appConfigState',
  default: null
});

export const locationHistoryState = atom<Array<Location<unknown>>>({
  key: 'locationHistoryState',
  default: []
});

export const goBackShouldUseHistoryState = selector<boolean>({
  key: 'goBackShouldUseHistoryState',
  get: ({ get }) => {
    const locationHistory = get(locationHistoryState);

    if (!locationHistory || locationHistory.length <= 1) {
      return false;
    }

    const lastElementIndex = locationHistory.length - 1;
    const beforeLastElementIndex = locationHistory.length - 2;

    if (
      locationHistory[lastElementIndex].pathname.startsWith('/order/') &&
      (locationHistory[beforeLastElementIndex]?.pathname.startsWith(
        '/payment/confirm'
      ) ||
        locationHistory[beforeLastElementIndex]?.pathname.startsWith('/cart'))
    ) {
      return false;
    }

    return true;
  }
});

export const isMenuPageState = selector<boolean>({
  key: 'isMenuPageState',
  get: ({ get }) => {
    const locationHistory = get(locationHistoryState);

    const lastElementIndex = locationHistory.length - 1;

    if (
      locationHistory &&
      locationHistory.length &&
      locationHistory[lastElementIndex].pathname.includes('/speisekarte')
    ) {
      return true;
    }

    return false;
  }
});
