import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { trimLanguageCode } from '../helpers/language';
import { languagesState, selectedLanguageCodeState } from '../store/app';

interface Props {
  style?: 'unstyled' | 'primary';
}

const LanguageSelect: React.FC<Props> = ({ style = 'primary' }) => {
  const languages = useRecoilValue(languagesState);
  const [selectedLanguageCode, setSelectedLanguageCode] = useRecoilState(
    selectedLanguageCodeState
  );
  const { i18n } = useTranslation();

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const languageCode = event.target.value;

    setSelectedLanguageCode(languageCode);
    i18n.changeLanguage(languageCode);
  };

  const classes =
    style === 'primary'
      ? 'bg-primary-500 text-secondary-500'
      : 'bg-transparent';

  return (
    <div
      className="relative flex items-center w-48 cursor-pointer hover:bg-gray-100"
      id="top-nav-language-select"
    >
      <select
        className={`block px-3 py-2 w-full mx-1 cursor-pointer overflow-hidden text-sm font-medium leading-5 rounded appearance-none overflow-ellipsis focus:outline-none ${classes}`}
        onChange={onChangeHandler}
        value={trimLanguageCode(selectedLanguageCode) || ''}
      >
        {languages.map((language, index) => (
          <option key={index.toString()} value={language.code}>
            {language.title} ({language.native_title})
          </option>
        ))}
      </select>
      <div className={`flex items-center justify-center pr-2 ${classes}`}>
        <i className="material-icons">expand_more</i>
      </div>
    </div>
  );
};

export default LanguageSelect;
