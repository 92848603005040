import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AvailabilityMixin } from '../helpers/availability';
import { canOrderAt, getEffectiveAvailability } from '../helpers/serviceTimes';
import { selectedCategoryState, selectedTableState } from '../store/gastronomy';
import Alert from './alert';
import AllergyInformation from './allergyInformation';
import Product from './product';
import ProductGroupItem from './productGroupItem';
import { EnhancedProduct } from './restaurantMenu';
import Fuse from 'fuse.js';
import { productSearchState } from '../store/products';

interface Props {
  productGroups: ProductGroupInclProductFragment[];
  fuse: Fuse<EnhancedProduct>;
}

const ProductGroupList: React.FC<Props> = ({ productGroups, fuse }: Props) => {
  const search = useRecoilValue(productSearchState);
  const [filteredProducts, setFilteredProducts] = useState<
    {
      product: ProductFragment;
      availability?: AvailabilityMixin;
      canOrder: boolean;
    }[]
  >([]);
  const selectedTable = useRecoilValue(selectedTableState);
  const selectedCategory = useRecoilValue(selectedCategoryState);

  const { t } = useTranslation();

  useEffect(() => {
    if (search.search.length > 0) {
      const results = fuse.search(search.search);
      const items = results.map(
        (x: Fuse.FuseResult<EnhancedProduct>) => x.item
      );
      const filteredProducts = items.map((p: EnhancedProduct) => {
        const mixin = getAvailability(p.effective_availability);
        return {
          product: p,
          availability: mixin,
          canOrder: mixin ? canOrderAt(mixin) !== false : true
        };
      });
      setFilteredProducts(filteredProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [search]);

  const getAvailability = (
    effective_availability?: EffectiveProductGroupAvailability[]
  ) => {
    if (effective_availability) {
      const availabilityTimes = getEffectiveAvailability(
        effective_availability,
        selectedTable
      );
      if (availabilityTimes) {
        return new AvailabilityMixin(availabilityTimes);
      }
    }
  };

  const filteredProductGroups = useMemo(() => {
    return productGroups.filter((pg) => pg.category_uuid === selectedCategory);
  }, [productGroups, selectedCategory]);

  return (
    <div id="product-group-list">
      {search.search.length > 0 ? (
        <>
          {filteredProducts.length > 0 ? (
            <>
              <div className="flex-col mx-2 mb-4 sm:mx-4">
                <h2
                  className={`text-xl lg:text-2xl font-semibold text-secondary-500`}
                >
                  {t('gastronomies.searchResults', {
                    amount: filteredProducts.length
                  })}
                </h2>
              </div>
              <div className="grid grid-cols-1 gap-8 p-4 sm:grid-cols-2">
                {filteredProducts.map((item) => (
                  <Product
                    key={item.product.uuid}
                    product={item.product}
                    availability={item.availability}
                    canOrder={item.canOrder}
                  />
                ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              title={t('alerts.info')}
              message={t('gastronomies.noSearchResults')}
              classes="m-4"
            />
          )}
        </>
      ) : (
        <>
          {filteredProductGroups.map((productGroup) => (
            <ProductGroupItem
              productGroup={productGroup}
              key={productGroup.uuid}
              initCollapsed={filteredProductGroups.length > 1}
            />
          ))}
        </>
      )}

      <AllergyInformation />
    </div>
  );
};

export default ProductGroupList;
