export const calculateCentsFromBonusProgram = ({
  bonusProgram,
  points = 0
}: {
  bonusProgram?: BonusProgramFragment;
  points?: number;
}): number => {
  if (!bonusProgram || !points) {
    return 0;
  }

  return points * bonusProgram.bonus_exchange_rate;
};
