import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Picture from './Picture';
import GetsbyLink from './getsbyLink';

interface Props {
  receipt: ReceiptFragment;
}

const LastOrdersListItem: React.FC<Props> = ({ receipt }: Props) => {
  const gastronomy = receipt.gastronomy;
  const { t } = useTranslation();

  if (gastronomy) {
    return (
      <GetsbyLink
        to="/order/:receiptUuid"
        params={{ receiptUuid: receipt.uuid }}
        className="no-underline"
      >
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded">
          <div className="flex items-center justify-between w-full cursor-pointer select-none">
            <div className="flex items-center">
              <div className="flex items-center w-12 h-12 mr-4 overflow-hidden rounded-full md:w-16 md:h-16">
                {gastronomy.logo_image && (
                  <Picture
                    className="w-full h-auto"
                    imageSource={gastronomy.logo_image}
                    width={64}
                  />
                )}
              </div>
              <div>
                <div className="text-xl font-bold text-secondary-500">
                  {gastronomy.name}
                </div>
                {receipt.created_at && (
                  <p className="text-base text-secondary-200">
                    {format(
                      new Date(
                        receipt.requested_pickup_time ||
                          receipt.estimated_pickup_time ||
                          receipt.created_at
                      ),
                      'dd.MM.yyyy HH:mm'
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="text-right">
                {receipt.status && (
                  <div
                    className={`font-bold ${
                      receipt.status === 'ERROR'
                        ? 'text-red-500'
                        : receipt.status === 'COMPLETED'
                        ? 'text-green-500'
                        : 'text-primary-500'
                    }`}
                  >
                    {t(`orders.status.${receipt.status.toLowerCase()}`)}
                  </div>
                )}
                <div>
                  {t('order')}
                  {receipt && receipt.pickup_code
                    ? ': ' + receipt.pickup_code
                    : ''}
                </div>
                <div
                  className={`${
                    receipt.status === 'ERROR' ? 'line-through' : ''
                  }`}
                >
                  {((receipt.total_price || 0) / 100).toFixed(2)} €
                </div>
              </div>
              {receipt.status !== 'ERROR' && receipt.hasCanceledOrderItems && (
                <i
                  className="ml-4 text-red-500 material-icons"
                  title={t('orders.hasChanged')}
                >
                  warning
                </i>
              )}
            </div>
          </div>
        </div>
      </GetsbyLink>
    );
  } else {
    return null;
  }
};

export default LastOrdersListItem;
