import React from 'react';
import GetsbyLink from './getsbyLink';

interface Props {
  to?: string | null;
  children?: React.ReactNode;
  classes?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const CustomLink: React.FC<Props> = ({
  children,
  to,
  classes,
  onClick
}: Props) => {
  return (
    <React.Fragment>
      {to ? (
        <GetsbyLink to={to} className={classes} onClick={onClick}>
          {children}
        </GetsbyLink>
      ) : (
        <div className={`${classes} cursor-not-allowed`}>{children}</div>
      )}
    </React.Fragment>
  );
};

export default CustomLink;
