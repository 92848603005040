import React from 'react';
import { IMG_BASE_PATH } from '../constants';
import { useTranslation } from 'react-i18next';
import { getAppIdFromUserAgent } from '../helpers/app';
import AppInitOptionsService from '../services/appInitOptionsService';
import GetsbyLink from './getsbyLink';

interface Props {
  isCustomTheme: boolean;
}

const Footer: React.FC<Props> = ({ isCustomTheme }) => {
  const isApp = AppInitOptionsService.getInstance().isApp();
  const { t } = useTranslation();

  const appId = getAppIdFromUserAgent();
  const isWhiteLabel =
    (appId && !appId.startsWith('by.gets.clientapp')) || false;

  if (isApp) {
    return <div className="flex"></div>;
  }

  return (
    <footer className="container px-8 mx-auto my-20" id="footer">
      <div className="flex flex-col w-full py-6 md:flex-row">
        <div className="items-center flex-1 mb-6 footer-col">
          {isCustomTheme ||
            (isWhiteLabel && (
              <div className="text-sm text-center text-gray-500">
                powered by
              </div>
            ))}
          <img
            id="getsby-footer-logo"
            className="mx-auto"
            src={`${IMG_BASE_PATH}/getsby_logo_black.svg`}
            alt=""
            style={{ width: 'auto', height: '56px' }}
          />
          <div
            className="mb-4 text-sm text-center text-gray-500"
            id="getsby-application-version"
          >
            v{process.env.REACT_APP_VERSION}
          </div>
        </div>
        <div className="flex-1 footer-col">
          <p className="text-gray-500 uppercase md:mb-6 footer-col-heading">
            {t(`navigation.help`)}
          </p>
          <ul className="mb-6 list-reset footer-col-list">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/faq"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t(`navigation.faq`)}
              </GetsbyLink>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/help"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t(`navigation.help`)}
              </GetsbyLink>
            </li>
          </ul>
        </div>
        <div className="flex-1 footer-col">
          <p className="text-gray-500 uppercase md:mb-6 footer-col-heading">
            {t('info.legal')}
          </p>
          <ul className="mb-6 list-reset footer-col-list">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/agb"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t('general.gtc')}
              </GetsbyLink>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/privacy-policy"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t('general.privacyStatement')}
              </GetsbyLink>
            </li>
            {/* <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/referral"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t('referral.CardHeader')}
              </GetsbyLink>
            </li> */}
          </ul>
        </div>
        <div className="flex-1 footer-col">
          <p className="text-gray-500 uppercase md:mb-6 footer-col-heading">
            Social Media
          </p>
          <ul className="mb-6 list-reset footer-col-list">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a
                href="https://www.facebook.com/getsbyapp.austria/"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a
                href="https://www.instagram.com/getsbyapp/"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a
                href="https://www.linkedin.com/company/getsbyapp/"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1 footer-col">
          <p className="text-gray-500 uppercase md:mb-6 footer-col-heading">
            {t('info.company')}
          </p>
          <ul className="mb-6 list-reset footer-col-list">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <GetsbyLink
                to="/imprint"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t('info.imprint')}
              </GetsbyLink>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a
                href="mailto:office@gets.by"
                className="no-underline text-secondary-500 hover:underline hover:text-primary-500"
              >
                {t('info.contact')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="py-4 text-center text-gray-500">
        © {new Date().getFullYear()} getsby
      </p>
    </footer>
  );
};

export default Footer;
