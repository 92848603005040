import { useQuery } from '@apollo/client';
import React from 'react';
import { AvailabilityMixin } from '../helpers/availability';
import { getReadableTimesFromMixin } from '../helpers/serviceTimes';
import { GetGastronomyOpeningTimes } from '../services/graphql/operations';
import OpeningTimes from './openingTimes';

interface Props {
  gastronomyUuid: string;
}

const OpeningTimesWidget: React.FC<Props> = ({ gastronomyUuid }) => {
  const { data } = useQuery<
    GetGastronomyOpeningTimesQuery,
    GetGastronomyOpeningTimesQueryVariables
  >(GetGastronomyOpeningTimes, {
    variables: { params: { uuid: gastronomyUuid } }
  });

  if (data?.getGastronomy.opening_times) {
    const mixin = new AvailabilityMixin(data.getGastronomy.opening_times);
    return <OpeningTimes times={getReadableTimesFromMixin(mixin)} />;
  }

  return null;
};

export default OpeningTimesWidget;
