import React from 'react';

interface Props {
  bgColor?: 'gray' | 'white';
  className?: string;
  id?: string;
}

const FeatureSection: React.FC<Props> = ({
  bgColor = 'white',
  className = '',
  id,
  children
}) => {
  return (
    <section
      className={`py-8 border-b ${
        bgColor === 'white' ? 'bg-white' : 'bg-gray-100'
      } ${className}`}
      id={id}
    >
      <div className="container max-w-5xl m-8 mx-auto mt-0">{children}</div>
    </section>
  );
};

export default FeatureSection;
