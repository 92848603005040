import { useQuery } from '@apollo/client';
import format from 'date-fns/format';
import React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Alert from '../components/alert';
import GetsbyLink from '../components/getsbyLink';
import Heading from '../components/heading';
import Spinner from '../components/spinner';
import { getTimeAgo } from '../helpers/utilities';
import { GetNewsletters } from '../services/graphql/operations';
import { appConfigState } from '../store/app';
import { ReactComponent as Icon } from './../assets/icons/subscriber.svg';

const NewsletterFeed: React.FC = () => {
  const appConfig = useRecoilValue(appConfigState);
  const { t } = useTranslation();

  const app_uuid = appConfig?.uuid;

  const { data, loading } = useQuery<
    GetNewslettersQuery,
    GetNewslettersQueryVariables
  >(GetNewsletters, {
    variables: { params: { app_uuid } }
  });

  return (
    <div className="w-full p-4">
      <Title>getsby - Dein Newsfeed</Title>
      <Heading text={t('newsletter.feed')} />
      {loading && <Spinner center={true} />}
      {data?.getClientNewsletters.map((newsletter, i) => (
        <GetsbyLink
          to="/news/post/:postUuid"
          params={{ postUuid: newsletter.uuid }}
          key={i}
        >
          <div className="py-4">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="font-bold text-primary-500">
                  {newsletter.owner}
                </div>
                <div className="">{newsletter.subject}</div>
              </div>
              {newsletter.sent_at && (
                <div
                  className="self-start text-sm text-gray-500"
                  title={format(
                    new Date(newsletter.sent_at),
                    'dd.MM.yyyy HH:mm'
                  )}
                >
                  {getTimeAgo(new Date(newsletter.sent_at), t)}
                </div>
              )}
            </div>
          </div>
          <hr className="mb-4" />
        </GetsbyLink>
      ))}
      {data?.getClientNewsletters.length === 0 && (
        <>
          <Alert
            type="info"
            title={t('alerts.info')}
            message={t('newsletter.noPosts')}
          />
          <div className="flex items-center justify-center">
            <Icon className="w-64 h-auto m-8 text-primary-500" />
          </div>
        </>
      )}
    </div>
  );
};

export default NewsletterFeed;
