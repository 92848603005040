import { format, isFuture } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';
import GetsbyLink from './getsbyLink';
import GetsbyRedirect from './getsbyRedirect';
import Heading from './heading';
import Modal from './modal';

interface Props {
  bonusProgramTransaction: BonusProgramTransactionFragment;
}

const BonusProgramTransactionLineItem: React.FC<Props> = ({
  bonusProgramTransaction
}) => {
  const { t } = useTranslation();
  const [selectedBonusProgram, setSelectedBonusProgram] =
    useState<BonusProgramFragment | null>(null);
  const [redirectTo, setRedirectTo] = useState<GetsbyRouter | null>(null);

  const isValid = useMemo(() => {
    if (bonusProgramTransaction.redeemable_points === 0) {
      return false;
    }

    return isFuture(new Date(bonusProgramTransaction.expires_at));
  }, [bonusProgramTransaction]);

  const handleClick = (_event: React.MouseEvent) => {
    if (!isValid) {
      return;
    }
    if (bonusProgramTransaction.bonus_program.gastronomies.length === 1) {
      setRedirectTo({
        to: '/:urlPathId/speisekarte',
        params: {
          urlPathId:
            bonusProgramTransaction.bonus_program.gastronomies[0].url_path_id
        }
      });
    } else {
      setSelectedBonusProgram(bonusProgramTransaction.bonus_program);
    }
  };

  if (redirectTo) {
    return <GetsbyRedirect {...redirectTo} />;
  }

  return (
    <>
      {selectedBonusProgram && (
        <Modal onClose={() => setSelectedBonusProgram(null)}>
          <Heading text={t('bonusProgram.redeemNow')} />
          <div className="flex flex-col w-full">
            {t('bonusProgram.bonusThreshold')}{' '}
            {selectedBonusProgram.bonus_threshold} {t('bonusProgram.points')}{' '}
            {t('bonusProgram.at')}{' '}
            {selectedBonusProgram.gastronomies.map((g, i) => (
              <GetsbyLink
                key={i}
                to="/:urlPathId/speisekarte"
                params={{ urlPathId: g.url_path_id }}
              >
                <Button classes="w-full my-2" type="default">
                  {g.name}
                </Button>
              </GetsbyLink>
            ))}
          </div>
        </Modal>
      )}
      <div
        className={`py-2 px-4 mb-4 bg-white border border-gray-200 rounded w-full ${
          isValid ? 'cursor-pointer' : 'text-secondary-200'
        }`}
        onClick={handleClick}
      >
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col">
            <div className="font-bold">
              {bonusProgramTransaction.points}{' '}
              {bonusProgramTransaction.bonus_program.bonus_program_name ||
                t('bonusProgram.genericName')}
            </div>
            <div className="text-sm text-secondary-200">
              <p>
                {t(
                  'bonusProgram.transactionType.' + bonusProgramTransaction.type
                )}
              </p>
              <p>
                {t('bonusProgram.collectedOn')}{' '}
                {format(
                  new Date(bonusProgramTransaction.created_at),
                  'dd.MM.yyyy'
                )}{' '}
                {bonusProgramTransaction.gastronomy && (
                  <>
                    {t('bonusProgram.at')}{' '}
                    {bonusProgramTransaction.gastronomy.name}
                  </>
                )}
              </p>
              <p>
                {t('bonusProgram.redeemableUntil')}{' '}
                {format(
                  new Date(bonusProgramTransaction.expires_at),
                  'dd.MM.yyyy'
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <div
              className={`font-bold text-right ${
                isValid ? 'text-green-500' : ''
              }`}
            >
              <p>{t(`bonusProgram.${isValid ? 'valid' : 'invalid'}`)}</p>
            </div>
            <div
              className={`font-bold text-right ${
                isValid ? 'text-primary-500' : ''
              }`}
            >
              <p>
                {t('bonusProgram.redeemed')}:
                <span>
                  {' '}
                  {bonusProgramTransaction.redeemed_points}/
                  {bonusProgramTransaction.points}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BonusProgramTransactionLineItem;
