import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { Trans, useTranslation } from 'react-i18next';
import {
  AddBonusInvoice,
  GetGastronomy,
  GetTable,
  LookupQrCode
} from '../services/graphql/operations';
import Alert from './alert';
import BonusProgramBadge from './bonusProgramBadge';
import Button from './button';
import Heading from './heading';
import Modal from './modal';
import QrReaderWrapper from './qrReaderWrapper';
import { ReactComponent as GiftIcon } from './../assets/icons/gift.svg';
import { calculateCentsFromBonusProgram } from '../helpers/bonusProgramHelper';
import NewsletterSubscriptionAddForm from './newsletterSubscriptionAddForm';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userIsAuthenticatedState } from '../store/user';
import shortUuid from 'short-uuid';
import { gastronomyState, selectedTableState } from '../store/gastronomy';
import { appConfigState } from '../store/app';
import GetsbyLink from './getsbyLink';
import GetsbyRedirect from './getsbyRedirect';

type ScanResult = {
  table?: TableFragment;
  gastronomy?: GastronomyFragment;
  invoiceString?: string;
  raw: string;
  url?: URL;
};

type ExpectedResult = 'table' | 'invoice' | 'any';

interface Props {
  user_uuid?: string;
  urlPathId?: string;
  bonus_program_uuid?: string;
  expectedResult: ExpectedResult;
  title: string;
  subtitle: string;
  buttonText: string;
  children: any;
}

const ScanQrModal: React.FC<Props> = ({
  user_uuid,
  urlPathId,
  bonus_program_uuid,
  expectedResult,
  title,
  subtitle,
  buttonText,
  children
}) => {
  const isAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const [bonusProgramTransaction, setBonusProgramTransaction] =
    useState<BonusProgramTransactionFragment | null>(null);
  const gastronomy = useRecoilValue(gastronomyState);
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const appConfig = useRecoilValue(appConfigState);

  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [scanResult, setScanResult] = useState<ScanResult>();
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localExpectedResult, setLocalExpectedResult] =
    useState<ExpectedResult>(expectedResult);
  const [redirectTo, setRedirectTo] = useState<GetsbyRouter | null>(null);

  useEffect(() => {
    console.log('useEffect scanResult', localExpectedResult, scanResult);
    if (!scanResult) {
      return;
    }
    if (scanResult.table) {
      if (localExpectedResult === 'table') {
        if (gastronomy && scanResult.table.user_uuid === gastronomy.uuid) {
          setSelectedTable(scanResult.table);
          return;
        } else {
          // show info to user
          setShowInfo(true);
          return;
        }
      } else if (localExpectedResult === 'any') {
        const url = new URL(scanResult.raw);
        let redirectPath = url.pathname;
        setSelectedTable(scanResult.table);
        if (url.pathname === '' || url.pathname === '/') {
          redirectPath = scanResult.gastronomy?.url_path_id + '/speisekarte';
        }
        setRedirectTo({ to: redirectPath });
        return;
      } else {
        // show info to user
        setShowInfo(true);
        return;
      }
    } else if (scanResult.gastronomy) {
      if (
        localExpectedResult === 'table' ||
        localExpectedResult === 'invoice'
      ) {
        // show info to user
        setShowInfo(true);
        return;
      } else {
        const url = new URL(scanResult.raw);
        let redirectPath = url.pathname;
        if (url.pathname === '' || url.pathname === '/') {
          redirectPath = scanResult.gastronomy?.url_path_id + '/speisekarte';
        }
        setRedirectTo({ to: redirectPath });
        return;
      }
    }
  }, [scanResult, localExpectedResult]);

  async function handleScan(data: string | null) {
    if (!data) {
      return;
    }
    setShowInfo(false);
    setLoading(true);
    console.log('handleScan', localExpectedResult, data);
    try {
      if (data.startsWith('_R1-AT')) {
        if (
          localExpectedResult === 'invoice' ||
          localExpectedResult === 'any'
        ) {
          await handleInvoiceScan(data);
        } else {
          // show info to user
          setScanResult({ invoiceString: data, raw: data });
          setShowInfo(true);
          return;
        }
      }
      if (data.startsWith('http')) {
        await handleUrlScan(data);
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  async function handleInvoiceScan(data: string) {
    const result = await apolloClient.mutate<
      AddBonusInvoiceMutation,
      AddBonusInvoiceMutationVariables
    >({
      mutation: AddBonusInvoice,
      variables: {
        input: {
          qrText: data,
          user_uuid: user_uuid || gastronomy?.uuid,
          bonus_program_uuid:
            bonus_program_uuid || appConfig?.bonusProgram?.uuid
        }
      },
      errorPolicy: 'all',
      refetchQueries: [
        'getBonusProgramPoints',
        'getGroupedBonusProgramPoints',
        'getBonusProgramTransactions'
      ]
    });
    setLoading(false);
    if (result.errors) {
      const err = result.errors[0];

      if (err) {
        setError(
          t(
            [
              'QRCodeScanner.errors.' + err.extensions?.code,
              'shoppingCart.generalOrderError'
            ],
            { error: err.message }
          )
        );
      }
    } else {
      setError(null);
      setBonusProgramTransaction(result.data?.addBonusInvoice || null);
    }
  }

  const handleUrlScan = async (data: string) => {
    const url = new URL(data);
    if (!url) {
      return;
    }
    console.log('scanned url', url);
    if (url.pathname.startsWith('/q/')) {
      const pathElements = url.pathname.split('/');
      if (pathElements.length > 1) {
        const code = pathElements[2];
        const result = await apolloClient.query<
          LookupQrCodeQuery,
          LookupQrCodeQueryVariables
        >({ query: LookupQrCode, variables: { url: code } });
        setLoading(false);
        console.log('qr lookup result', result);
        if (result.data.lookupQrCode) {
          setScanResult({
            table: result.data.lookupQrCode.table,
            gastronomy: result.data.lookupQrCode.gastronomy,
            raw: data,
            url
          });
          return;
        }
        setError(t('QRCodeScanner.errors.NotProgrammed'));
        return;
      }
    }
    if (url.searchParams?.has('t')) {
      let tableUuid = url.searchParams.get('t');
      if (tableUuid) {
        if (tableUuid.length < 36) {
          tableUuid = shortUuid().toUUID(tableUuid);
        }
        setLoading(true);
        const result = await apolloClient.query<
          GetTableQuery,
          GetTableQueryVariables
        >({
          query: GetTable,
          variables: { uuid: tableUuid }
        });
        if (result.data.getTable) {
          const gastronomyResult = await apolloClient.query<
            GetGastronomyQuery,
            GetGastronomyQueryVariables
          >({
            query: GetGastronomy,
            variables: { params: { uuid: result.data.getTable.user_uuid } }
          });
          setLoading(false);
          if (gastronomyResult.data.getGastronomy) {
            setScanResult({
              table: result.data.getTable,
              gastronomy: gastronomyResult.data.getGastronomy,
              raw: data,
              url
            });
            return;
          }
        }
        setLoading(false);
        setError(t('QRCodeScanner.errors.NotRecognized'));
        return;
      }
    }
    if (url.searchParams?.has('g')) {
      let gastronomyUuid = url.searchParams.get('g');
      if (gastronomyUuid) {
        if (gastronomyUuid.length < 36) {
          gastronomyUuid = shortUuid().toUUID(gastronomyUuid);
        }
        setLoading(true);
        const result = await apolloClient.query<
          GetGastronomyQuery,
          GetGastronomyQueryVariables
        >({
          query: GetGastronomy,
          variables: { params: { uuid: gastronomyUuid } }
        });
        setLoading(false);
        if (result.data.getGastronomy) {
          setScanResult({
            gastronomy: result.data.getGastronomy,
            raw: data,
            url
          });
          return;
        }
        setError(t('QRCodeScanner.errors.NotRecognized'));
        return;
      }
    }
  };

  const handleError = (error: Error) => {
    setError(error.name);
  };

  const onModalClose = () => {
    setIsOpen(false);
    setError(null);
    setBonusProgramTransaction(null);
    setScanResult(undefined);
    setShowInfo(false);
    setLoading(false);
  };

  const handleShowInfoOk = async () => {
    setLocalExpectedResult('any');
    await handleScan(scanResult?.raw || null);
  };

  if (redirectTo) {
    return <GetsbyRedirect {...redirectTo} />;
  }

  if (!isOpen) {
    return (
      <span onClick={() => setIsOpen(true)} className="cursor-pointer">
        {children}
      </span>
    );
  }

  if (showInfo) {
    let target;
    if (scanResult?.table) {
      target = `${scanResult.table.user_uuid} +
        ', ${t('QRCodeScanner.table')}: ' +
        ${scanResult.table.table_number}`;
    } else if (scanResult?.gastronomy) {
      target = scanResult.gastronomy.name;
    } else if (scanResult?.invoiceString) {
      target = t('QRCodeScanner.targetScanInvoice');
    }

    return (
      <>
        {children}
        <Modal onClose={onModalClose}>
          <Heading text={t('QRCodeScanner.youWillBeRedirected', { target })} />
          <div className="grid grid-cols-2 gap-4">
            <Button type="default" classes="w-full" onClick={onModalClose}>
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              classes="w-full"
              onClick={handleShowInfoOk}
              disabled={loading}
            >
              {t('ok')}
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  if (bonusProgramTransaction) {
    return (
      <>
        {children}
        <Modal onClose={onModalClose}>
          <Heading text={t('congratulations')} />
          <div className="flex items-center justify-center">
            <GiftIcon className="w-64 h-auto m-8 text-primary-500" />
          </div>
          <div className="flex flex-col flex-wrap items-center justify-center w-full mb-4">
            <Trans
              i18nKey="bonusProgram.withThisInvoiceCollected"
              components={[
                <BonusProgramBadge
                  points={bonusProgramTransaction.points}
                  bonusProgram={bonusProgramTransaction.bonus_program}
                  className="my-2 cursor-default"
                  key={0}
                />,
                (
                  calculateCentsFromBonusProgram({
                    points: bonusProgramTransaction.points,
                    bonusProgram: bonusProgramTransaction.bonus_program
                  }) / 100
                ).toFixed(2) + ' €'
              ]}
            />
          </div>
          <NewsletterSubscriptionAddForm className="mt-4 mb-2" />
          <div className="flex flex-col justify-end pt-2">
            {!isAuthenticated ? (
              <div className="flex flex-col justify-center w-full">
                <p>{t('bonusProgram.registerDontLoosePoints')}</p>
                <GetsbyLink
                  to={`/auth/:action${urlPathId ? '/:urlPathId' : ''}`}
                  params={{ action: 'signup', urlPathId }}
                  className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 font-bold rounded shadow cursor-pointer bg-primary-500 text-secondary-500"
                >
                  <i className="mr-4 material-icons">person_add</i>
                  <span>{t(`bonusProgram.signupNow`)}</span>
                </GetsbyLink>
                {/* <div
                  className="mt-4 text-xs text-center underline cursor-pointer"
                  onClick={onClose}
                >
                  {t('close')}
                </div> */}
              </div>
            ) : (
              <Button type="default" classes="w-full" onClick={onModalClose}>
                {t('close')}
              </Button>
            )}
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      {children}
      <Modal onClose={onModalClose}>
        <Heading text={title} />
        <p className="mb-4">{subtitle}</p>
        {error ? (
          <>
            <Alert type="error" title={t('alerts.error')} message={error} />
            <div className="grid grid-cols-2 gap-4 pt-2">
              <Button type="default" classes="w-full" onClick={onModalClose}>
                {buttonText}
              </Button>
              <Button
                type="primary"
                classes="w-full"
                onClick={() => setError(null)}
              >
                {t('retry')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <QrReaderWrapper
              onScan={handleScan}
              onError={handleError}
              showViewFinder={!loading}
            />
            <div className="flex justify-end pt-2">
              <Button
                type="default"
                classes="w-full"
                onClick={onModalClose}
                disabled={loading}
              >
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ScanQrModal;
