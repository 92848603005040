export function generateTheme(theme: CustomTheme): string {
  const { colors, customCss = '' } = theme;

  let cssText = '';

  if (colors) {
    const { primary, secondary, appBarColors } = colors;

    if (appBarColors) {
      if (appBarColors.backgroundColor) {
        cssText = cssText.concat(
          `#top-nav {background-color: ${appBarColors.backgroundColor}; border: none;}`
        );
        cssText = cssText.concat(
          `body {background-color: ${appBarColors.backgroundColor};}`
        );
      }
      if (appBarColors.primaryColor) {
        cssText = cssText.concat(
          `#top-nav .text-primary-500 {color: ${appBarColors.primaryColor};}`
        );
      }
      if (appBarColors.secondaryColor) {
        cssText = cssText.concat(
          `#top-nav .text-secondary-500 :not(#nav-menu) {color: ${appBarColors.secondaryColor};}`
        );
      }
    }

    if (primary) {
      for (const [key, value] of Object.entries(primary)) {
        if (key !== '__typename' && key.startsWith('_') && value) {
          const colorLevel = key.substring(1, key.length);

          cssText = cssText.concat(
            `.bg-primary-${colorLevel} {background-color: ${value};} .text-primary-${colorLevel} {color: ${value};} .border-primary-${colorLevel} {border-color: ${value};}`
          );
        }
      }
    }

    if (secondary) {
      for (const [key, value] of Object.entries(secondary)) {
        if (key !== '__typename' && key.startsWith('_') && value) {
          const colorLevel = key.substring(1, key.length);

          cssText = cssText.concat(
            `.bg-secondary-${colorLevel} {background-color: ${value};} .bg-primary-${colorLevel} .text-secondary-${colorLevel}, .bg-primary-${colorLevel}.text-secondary-${colorLevel} {color: ${value};} .border-secondary-${colorLevel} {border-color: ${value};}`
          );
        }
      }
    }
  }

  return cssText.concat(customCss || '');
}
