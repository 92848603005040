import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { IMG_BASE_PATH } from '../constants';
import useQueryParams from '../helpers/useQueryParams';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { hasCcMealDealActiveSelector } from '../store/cart';
import { gastronomyState } from '../store/gastronomy';
import BonusProgramBadgeWrapper from './bonusProgramBadgeWrapper';
import CcBadge from './ccBadge';
import GetsbyLink from './getsbyLink';
import GuestRegistrationBadge from './guestRegistrationBadge';
import ScanInvoiceButton from './scanInvoiceButton';

const GastronomyHeader: React.FC = ({ children }) => {
  const hasCcMealDealActive = useRecoilValue(hasCcMealDealActiveSelector);
  const queryParams = useQueryParams();
  const history = useHistory();
  const gastronomy = useRecoilValue(gastronomyState);
  const { breakpoint } = useBreakpoint();
  const { t } = useTranslation();
  const header = document.getElementById('restaurant-header');
  const headerHeight = header ? getComputedStyle(header).height : undefined;

  const isMenuOnly = queryParams.has('isMenuOnly')
    ? queryParams.get('isMenuOnly') === '1'
    : false;

  const imagePath = useMemo(
    () =>
      gastronomy?.header_image
        ? `${gastronomy.header_image}?w=${
            breakpoint === 'sm' ? '360' : '1280'
          }${
            headerHeight ? '&h=' + headerHeight : ''
          }&fit=crop&fm=jpeg&q=35&dpr=${Math.min(
            2,
            Math.round(window.devicePixelRatio)
          )}`
        : `${IMG_BASE_PATH}/food_pattern.svg`,
    [gastronomy, breakpoint]
  );

  const showName = useMemo(() => {
    if (gastronomy && gastronomy.options) {
      return !gastronomy.options.hideGastronomyNameInHeader;
    }

    return true;
  }, [gastronomy]);

  const handleOrderViaApp = () => {
    if (isMenuOnly) {
      queryParams.delete('isMenuOnly');
      history.push({
        search: queryParams.toString()
      });
    }
  };

  if (!gastronomy) {
    return null;
  }

  return (
    <div
      className={`relative flex flex-col items-center justify-center bg-center bg-cover overflow-hidden p-4 h-32 md:h-64`}
      style={{
        backgroundImage: showName
          ? `linear-gradient(
                      rgba(0, 0, 0, 0.6), 
                      rgba(0, 0, 0, 0.6)
                    ), url(${imagePath})`
          : `url(${imagePath})`,
        filter:
          gastronomy.isActive || gastronomy.status === 'MENUONLY'
            ? 'none'
            : 'grayscale(100%)'
      }}
      id="restaurant-header"
    >
      {showName && (
        <div className="flex flex-col" id="restaurant-header-content">
          <h1
            className="text-xl text-center text-white md:text-4xl"
            id="restaurant-header-name"
          >
            {gastronomy.name}
          </h1>
          {gastronomy.type && (
            <p
              className="text-sm italic leading-normal text-center text-gray-400 md:text-base justify-self-end"
              id="restaurant-header-description"
            >
              {gastronomy.type}
            </p>
          )}
        </div>
      )}
      <div
        className="absolute flex flex-col top-2 left-2"
        id="restaurant-header-top-left"
      >
        {isMenuOnly && gastronomy.status === 'ENABLED' && (
          <span
            className={`order-via-app-badge inline-flex items-center px-3 py-1 text-sm mb-2 font-semibold bg-primary-500 text-secondary-500 border border-secondary-500  rounded-full cursor-pointer`}
            title={t('orderViaApp')}
            onClick={handleOrderViaApp}
          >
            <i className="mr-2 text-sm material-icons">app_shortcut</i>
            {t('orderViaApp')}
          </span>
        )}
        {gastronomy.bonusProgram?.enable_invoice_scan && (
          <ScanInvoiceButton
            renderAsBadge={true}
            bonus_program_uuid={gastronomy.bonusProgram.uuid}
            user_uuid={gastronomy.uuid}
            urlPathId={gastronomy.url_path_id}
          />
        )}
      </div>
      <div
        className="absolute bottom-2 left-2"
        id="restaurant-header-bottom-left"
      >
        {gastronomy.options.enableGuestRegistration && (
          <GetsbyLink
            to="/:urlPathId/corona"
            params={{ urlPathId: gastronomy.url_path_id }}
          >
            <GuestRegistrationBadge />
          </GetsbyLink>
        )}
      </div>
      <div className="absolute top-2 right-2" id="restaurant-header-top-right">
        <BonusProgramBadgeWrapper />
      </div>
      <div
        className="absolute bottom-2 right-2"
        id="restaurant-header-bottom-right"
      >
        {hasCcMealDealActive && gastronomy.has_cc_products && <CcBadge />}
      </div>
      {children}
    </div>
  );
};

export default GastronomyHeader;
