import React from 'react';
import { IMG_BASE_PATH, ORDER_TYPES } from '../constants';
import { useTranslation } from 'react-i18next';
import { restaurantFiltersState } from '../store/user';
import { useRecoilState, useRecoilValue } from 'recoil';
import CcBadge from './ccBadge';
import { hasCcMealDealActiveSelector } from '../store/cart';

const RestaurantFilters: React.FC = () => {
  const { t } = useTranslation();
  const hasCcMealDealActive = useRecoilValue(hasCcMealDealActiveSelector);
  const [restaurantFilters, setRestaurantFilters] = useRecoilState(
    restaurantFiltersState
  );

  const handleClick = (type: RestaurantFilter) => {
    if (restaurantFilters.includes(type)) {
      setRestaurantFilters(restaurantFilters.filter((t) => t !== type));
    } else {
      setRestaurantFilters([...restaurantFilters, type]);
    }
  };

  return (
    <div>
      <p className="mb-4 text-base font-semibold">{t('filters')}</p>
      <div className="flex flex-wrap items-start mb-4">
        {ORDER_TYPES.map((type, index) => (
          <span
            className={`inline-flex items-center px-3 py-1 mb-2 mr-2 text-sm font-semibold border rounded-full cursor-pointer ${
              restaurantFilters.includes(type)
                ? 'bg-primary-500 border-primary-500'
                : 'bg-white hover:bg-gray-200 border-gray-200'
            }`}
            key={index}
            onClick={() => handleClick(type)}
          >
            <img
              className="w-3 h-auto mr-2"
              src={`${IMG_BASE_PATH}/order_type/${type.toLowerCase()}.svg`}
              alt=""
            />
            {t(`tableType.${type}`)}
          </span>
        ))}
        {hasCcMealDealActive && (
          <CcBadge
            onClick={() => handleClick('HAS_CC_PRODUCTS')}
            isActive={restaurantFilters.includes('HAS_CC_PRODUCTS')}
          />
        )}
      </div>
    </div>
  );
};

export default RestaurantFilters;
