import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  search: (searchValue: string) => void;
  clear: () => void;
  placeHolder?: string;
  searchValue: string;
  onChange: (value: string) => void;
  debounceDelay?: number;
  focusOnRender?: boolean;
}

const SearchInput: React.FC<Props> = ({
  search,
  clear,
  placeHolder,
  searchValue,
  onChange,
  debounceDelay = 1000,
  focusOnRender = false
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      search(searchValue);
    }, debounceDelay);

    return () => clearTimeout(debounceFn);
  }, [searchValue]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div
      className={`flex items-center justify-between w-full px-4 py-2 text-gray-700 bg-gray-200 border rounded border-gray-200 leading-tight search-input-container`}
    >
      <input
        className="w-full bg-transparent outline-none appearance-none focus:outline-none active:outline-none search-input"
        type="text"
        name="search"
        value={searchValue}
        onChange={change}
        placeholder={placeHolder || t('search')}
        ref={inputRef}
      />
      <div
        className={`flex items-center justify-center ml-1 text-gray-700 outline-none focus:outline-none active:outline-none`}
      >
        {searchValue.length > 1 ? (
          <i className="cursor-pointer material-icons" onClick={clear}>
            close
          </i>
        ) : (
          <i className="material-icons">search</i>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
