import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  gastronomyState,
  getAvailabilityMixinForSelectedTableSelector,
  selectedTableLeadTime,
  selectedTableState,
  tableTypeState
} from '../store/gastronomy';
import { canOrderAt, getReadableTimesFromMixin } from '../helpers/serviceTimes';
import OpeningTimes from './openingTimes';
import { LEAD_TIME_ASAP_END, PREORDER_DAYS_DEFAULT } from '../constants';
import { format, isFuture } from 'date-fns';
import { getDisabledUntilFromGastronomy } from '../helpers/gastronomy';

const ServiceTimesNote: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);
  const selectedTable = useRecoilValue(selectedTableState);
  const leadTime = useRecoilValue(selectedTableLeadTime);
  const tableType = useRecoilValue(tableTypeState);
  const mixin = useRecoilValue(getAvailabilityMixinForSelectedTableSelector);
  const { t } = useTranslation();
  const disabledUntil = getDisabledUntilFromGastronomy(tableType, gastronomy);
  const defaultNoteClasses = 'flex flex-row items-start my-1 px-4';

  const nowOpen = mixin?.nowOpen();
  const canOrder = useMemo(() => {
    if (!gastronomy || !gastronomy.isActive) {
      return false;
    }
    if (Array.isArray(nowOpen) && nowOpen.length) {
      return true;
    }
    if (selectedTable) {
      return selectedTable.canPreorderNow;
    }
    return false;
  }, [nowOpen, selectedTable]);

  const isDisabled = useMemo(() => {
    if (disabledUntil) {
      return isFuture(new Date(disabledUntil));
    }
    return false;
  }, [disabledUntil]);

  const hasServiceInformation = useMemo(() => {
    if (selectedTable) {
      return !!selectedTable.service_information;
    }
    return false;
  }, [selectedTable]);

  const hasLeadTime = useMemo(() => {
    if (leadTime) {
      return leadTime > LEAD_TIME_ASAP_END;
    }
    return false;
  }, [leadTime]);

  const hasDeliveryFee = useMemo(() => {
    if (selectedTable) {
      return !!(
        selectedTable.table_type === 'DELIVERY' &&
        selectedTable.delivery_config &&
        selectedTable.delivery_config.deliveryFee !== undefined &&
        selectedTable.delivery_config.deliveryFee > 0
      );
    }
    return false;
  }, [selectedTable]);

  if (
    !selectedTable ||
    !nowOpen ||
    (!isDisabled &&
      !hasServiceInformation &&
      canOrder &&
      !hasLeadTime &&
      !hasDeliveryFee)
  ) {
    return <></>;
  }

  return (
    <div className="flex flex-col w-full my-2 text-sm sm:flex-wrap text-secondary-300">
      {isDisabled && disabledUntil && (
        <div className={`${defaultNoteClasses} text-red-500`}>
          <span className="pt-1 mr-4 text-sm material-icons">warning</span>
          {t(`tableType.disabledUntil`, {
            tableType,
            date: format(new Date(disabledUntil), 'dd.MM.yyyy HH:mm')
          })}
        </div>
      )}
      {hasServiceInformation && (
        <div className={`${defaultNoteClasses}`}>
          <span className="pt-1 mr-4 text-sm material-icons">info</span>
          {selectedTable.service_information}
        </div>
      )}
      {selectedTable?.allow_preorder && mixin && (
        <div className={`${defaultNoteClasses}`}>
          <span className="pt-1 mr-4 text-sm material-icons">schedule</span>
          <div>
            {nowOpen.length > 0
              ? t('openingTimes.openNow', {
                  from: format(nowOpen[0], 'H:mm'),
                  to: format(nowOpen[1], 'H:mm')
                })
              : selectedTable.allow_preorder &&
                canOrderAt(
                  mixin,
                  selectedTable.tableConfig
                    ? selectedTable.tableConfig.preorderDays
                    : PREORDER_DAYS_DEFAULT
                ) !== false
              ? t('openingTimes.preorder')
              : t('gastronomies.closed')}
            <div className="text-xs">
              <OpeningTimes times={getReadableTimesFromMixin(mixin)} />
            </div>
          </div>
        </div>
      )}
      {hasLeadTime && selectedTable.tableConfig && (
        <div className={`${defaultNoteClasses}`}>
          <span className="pt-1 mr-4 text-sm material-icons">more_time</span>
          {t('delivery.leadTime', {
            minutes: selectedTable.tableConfig.leadTime
          })}
        </div>
      )}
      {hasDeliveryFee &&
        selectedTable &&
        selectedTable.delivery_config &&
        selectedTable.delivery_config.deliveryFee && (
          <div className={`${defaultNoteClasses}`}>
            <span className="mt-1 mr-4 text-sm material-icons">
              attach_money
            </span>
            {selectedTable.delivery_config.minOrderAmount &&
              t('delivery.minOrderAmount') +
                ' ' +
                (selectedTable.delivery_config.minOrderAmount / 100).toFixed(
                  2
                ) +
                '€, '}
            {selectedTable.delivery_config.freeDeliveryFrom
              ? t('delivery.freeFromFee', {
                  deliveryFee: (
                    selectedTable.delivery_config.deliveryFee / 100
                  ).toFixed(2),
                  freeDeliveryFrom: (
                    selectedTable.delivery_config.freeDeliveryFrom / 100
                  ).toFixed(2)
                })
              : t('delivery.fee') +
                ' ' +
                (selectedTable.delivery_config.deliveryFee / 100).toFixed(2) +
                '€'}
          </div>
        )}
    </div>
  );
};

export default ServiceTimesNote;
