import React from 'react';
import { FieldError, useFormContext, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMinMaxTextForModifier } from '../helpers/utilities';

interface Props {
  modifier: ModifierFragment;
  isProductAvailable: boolean;
}

const Modifier: React.FC<Props> = ({ modifier, isProductAvailable }) => {
  const { register, formState } = useFormContext();
  const { t } = useTranslation();
  const error: FieldError | null =
    modifier && modifier.uuid ? formState.errors[modifier.uuid] : null;

  const validate = (data: string | string[]): ValidateResult => {
    console.log('data', data);

    const min = modifier.min || 0;
    const max = modifier.max || 99;

    if (Array.isArray(data)) {
      const count = data.length;

      if (count >= min && count <= max) {
        return true;
      }
    } else if (typeof data === 'string') {
      return min > 0 ? !!data : true;
    } else if (typeof data === 'boolean') {
      return min > 0 ? !!data : true;
    }

    return false;
  };

  if (!modifier.modifierItems || modifier.modifierItems.length === 0) {
    return <></>;
  }

  return (
    <div
      className={`mb-4 md:flex md:items-center ${
        isProductAvailable ? 'text-secondary-500' : 'text-secondary-200'
      }`}
    >
      <div className="md:w-1/3">
        <label
          className={`block pr-4 mb-1 text-sm font-semibold lg:text-base lg:font-bold md:text-right md:mb-0 ${
            error ? 'text-red-500' : ''
          }`}
        >
          {modifier.title}
          {modifier.description && (
            <>
              <br />
              <span className="text-xs italic font-normal">
                {modifier.description}
              </span>
            </>
          )}
        </label>
      </div>
      <div className="md:w-2/3">
        <span
          className={`block mb-2 text-xs italic lg:text-sm w-full ${
            error ? 'text-red-500 font-bold' : ''
          }`}
        >
          {getMinMaxTextForModifier(
            t,
            modifier.min as number,
            modifier.max as number,
            modifier.modifierItems ? modifier.modifierItems.length : 0
          )}
        </span>
        {modifier.modifierItems &&
          modifier.modifierItems.map(
            (item, index) =>
              item && (
                <label
                  className="flex flex-row justify-between w-full mb-4 text-sm font-semibold lg:mb-2 lg:text-base lg:font-bold"
                  key={index.toString()}
                >
                  <div className="flex flex-row items-start">
                    <input
                      {...register(modifier.uuid, { validate: validate })}
                      value={item.uuid || undefined}
                      className="flex-shrink-0 mt-1 mr-2 leading-tight"
                      name={modifier.uuid}
                      type={
                        modifier.min === 1 && modifier.max === 1
                          ? 'radio'
                          : 'checkbox'
                      }
                    />

                    <div>
                      <div className="text-sm">
                        {item.title || ''}
                        {Array.isArray(item.allergenList) &&
                          item.allergenList.length > 0 && (
                            <span> ({item.allergenList.join(', ')})</span>
                          )}
                      </div>
                      {item.description && (
                        <div className="text-xs font-light">
                          {item.description}
                        </div>
                      )}
                    </div>
                  </div>
                  {typeof item.price_modifier === 'number' &&
                    item.price_modifier !== 0 && (
                      <div className="flex flex-none ml-2 text-sm flex-nowrap">
                        {item.price_modifier > 0 ? '+ ' : '- '}
                        {(Math.abs(item.price_modifier) / 100).toFixed(2)} €
                      </div>
                    )}
                </label>
              )
          )}
      </div>
    </div>
  );
};

export default Modifier;
