import React from 'react';
import { useSetRecoilState } from 'recoil';
import { userLocationState } from '../store/user';

//todo: This should come from the database!!!

const LocationLinks: React.FC = () => {
  const setLocation = useSetRecoilState(userLocationState);

  const LocationButton = ({
    lat,
    lng,
    formatted_address
  }: {
    lat: number;
    lng: number;
    formatted_address: string;
  }): React.ReactNode => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const onLocationClick = () => {
      scrollToTop();
      setLocation({
        lat,
        lng,
        formatted_address,
        selected: true
      });
    };

    return (
      <button
        className="inline-flex items-center px-3 py-1 mb-2 mr-2 text-sm font-semibold border border-gray-200 rounded-full outline-none cursor-pointer hover:bg-gray-200 focus:outline-none"
        onClick={onLocationClick}
      >
        Restaurants in {formatted_address}
      </button>
    );
  };

  return (
    <div className="flex flex-wrap items-center justify-center my-20">
      {LocationButton({
        lat: 48.210033,
        lng: 16.363449,
        formatted_address: 'Wien'
      })}

      {LocationButton({
        lat: 47.076668,
        lng: 15.421371,
        formatted_address: 'Graz'
      })}

      {LocationButton({
        lat: 47.2854337,
        lng: 11.3087503,
        formatted_address: 'Innsbruck'
      })}

      {LocationButton({
        lat: 46.6414215,
        lng: 14.2427157,
        formatted_address: 'Klagenfurt'
      })}
    </div>
  );
};
export default LocationLinks;
