import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH } from '../constants';
import Alert from './alert';
import DeliveryAddressInput from './deliveryAddressInput';

interface Props {
  checking: boolean;
  showAddressInput: boolean;
  showWarning: boolean;
}

const CheckDeliveryLocation: React.FC<Props> = ({
  checking,
  showAddressInput,
  showWarning
}: Props) => {
  const { t } = useTranslation();

  if (checking) {
    return (
      <div className="flex flex-col items-center mx-2 my-8">
        <img src={`${IMG_BASE_PATH}/EggLocation3S.gif`} width={120} />
        <div className="my-4">Wir ermitteln deine Position</div>
      </div>
    );
  } else if (showAddressInput) {
    return (
      <div className="flex flex-col items-center mx-2 my-8">
        {showWarning && (
          <Alert
            type="warning"
            title={t('tableType.DELIVERY')}
            message={<span>{t('delivery.notInRadius')}</span>}
          />
        )}
        <div className="my-4">{t('delivery.whereTo')}:</div>
        <div>
          <DeliveryAddressInput
            onSubmit={() => {
              /**/
            }}
            checkDeliveryRadius={true}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CheckDeliveryLocation;
