export function getUserLanguage(): string | undefined {
  return trimLanguageCode(
    (navigator.languages && navigator.languages[0]) || navigator.language
  );
}

export function trimLanguageCode(code?: string): string | undefined {
  if (!code) {
    return undefined;
  }
  return code.match('-') ? code.split('-')[0] : code;
}
