import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH, ORDER_TYPES } from '../constants';
import CustomLink from './customLink';
import { differenceInWeeks } from 'date-fns';
import GastronomyListHtmlHeader from './gastronomyListHtmlHeader';
import GastronomyBanner from './gastronomyBanner';
import { getReadableOpeningTimes } from '../helpers/serviceTimes';
import OpeningTimes from './openingTimes';
import {
  getGastronomyLinkTarget,
  getOrderTypesByGastronomyOptions
} from '../helpers/utilities';
import { useRecoilValue } from 'recoil';
import { userLocationState } from '../store/user';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { calculateTimeOfNextUpdate } from '../helpers/gastronomy';
import { useInterval } from '../helpers/useInterval';
import { AvailabilityMixin } from '../helpers/availability';
import CcBadge from './ccBadge';
import OrderTypeBadge from './orderTypeBadge';
import { hasCcMealDealActiveSelector } from '../store/cart';
import MenuOnlyBadge from './menuOnlyBadge';

interface Props {
  gastronomy: GastronomyFragment;
}
const GastronomyCard: React.FC<Props> = ({ gastronomy }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean | undefined>(gastronomy.isOpen);
  const userLocation = useRecoilValue(userLocationState);
  const hasCcMealDealActive = useRecoilValue(hasCcMealDealActiveSelector);
  const { breakpoint } = useBreakpoint();
  const [nextUpdate, setNextUpdate] =
    useState<{
      time: number;
      isOpen: boolean;
    }>();

  useEffect(() => {
    setIsOpen(gastronomy.isOpen);
    if (gastronomy.opening_times) {
      const update = calculateTimeOfNextUpdate(
        new AvailabilityMixin(gastronomy.opening_times)
      );
      if (update) {
        setNextUpdate(update);
      }
    }
  }, [gastronomy]);

  useInterval(
    () => {
      if (nextUpdate && nextUpdate.time < new Date().getTime()) {
        if (gastronomy.opening_times) {
          const mixin = new AvailabilityMixin(gastronomy.opening_times);
          setIsOpen(mixin.nowOpen().length > 0);
          const followingUpdate = calculateTimeOfNextUpdate(mixin);
          if (followingUpdate) {
            if (followingUpdate.time > new Date().getTime()) {
              setNextUpdate(followingUpdate);
              return;
            }
          }
        }
        setNextUpdate(undefined);
      }
    },
    nextUpdate ? 1000 : null
  );

  const openText = useMemo(() => {
    const gastro = gastronomy;

    if (isOpen) {
      if (
        gastro.isActive &&
        getOrderTypesByGastronomyOptions(gastro.options).length > 0
      ) {
        return (
          <span className="text-green-500 ">{t('openingTimes.open')}</span>
        );
      }
    } else if (gastro.options.hasPreOrder && gastro.isActive) {
      return (
        <span className="text-green-500 ">{t('openingTimes.preorder')}</span>
      );
    }

    return <span className="text-red-500 ">{t('gastronomies.closed')}</span>;
  }, [gastronomy, isOpen]);

  return (
    <CustomLink
      classes="flex flex-col justify-start w-full border rounded text-secondary-500 relative overflow-hidden hover:shadow-lg bg-white"
      to={getGastronomyLinkTarget(gastronomy)}
      onClick={() => {
        // setGastronomy(null);
        // setSelectedTableType(null);
      }}
    >
      <GastronomyListHtmlHeader gastronomy={gastronomy} />
      <div
        className="relative flex w-full h-40 overflow-hidden bg-center bg-cover"
        style={{
          backgroundImage: gastronomy.header_image
            ? `url(${gastronomy.header_image}?w=${
                breakpoint === 'sm' ? '326' : '403'
              }&h=160&fit=crop&fm=jpeg&q=35&dpr=${Math.min(
                2,
                Math.round(window.devicePixelRatio)
              )})`
            : `url(${IMG_BASE_PATH}/food_pattern.svg)`,
          filter: gastronomy.isActive ? 'none' : 'grayscale(100%)'
        }}
      >
        {hasCcMealDealActive && gastronomy.has_cc_products && (
          <div className="absolute bottom-2 right-2">
            <CcBadge />
          </div>
        )}
      </div>
      {gastronomy.created_at &&
        differenceInWeeks(new Date(), new Date(gastronomy.created_at)) < 3 && (
          <div
            className="absolute top-0 right-0 w-32 h-auto text-base font-semibold text-center transform rotate-45 shadow-lg whitespace-nowrap bg-primary-500 ribbon"
            style={{ marginTop: '10px', marginRight: '-40px' }}
          >
            {t('gastronomies.new').toUpperCase()}
          </div>
        )}
      <div className="flex flex-col justify-between p-4 leading-normal bg-white rounded">
        <div className="flex items-center mb-4">
          <GastronomyBanner
            gastronomy={gastronomy}
            useLink={false}
            showAdress={false}
          />
        </div>
        <div className="flex">
          <div className="w-full text-sm">
            <div className="flex">
              <div className="w-3/4">
                <p className="mb-1 leading-none text-secondary-800">
                  {gastronomy.street}
                </p>
                <p className="mb-2 leading-none text-secondary-800">
                  {gastronomy.zip} {gastronomy.city}
                </p>
              </div>
              {userLocation && typeof gastronomy.distance_in_km === 'number' && (
                <div className="flex justify-end w-1/4">
                  <div className="flex flex-col items-center justify-center text-xs text-secondary-800">
                    <i className="material-icons text-primary-500">place</i>
                    <span>{gastronomy.distance_in_km.toFixed(1)} km</span>
                  </div>
                </div>
              )}
            </div>
            {gastronomy.opening_times && (
              <div className="my-4">
                <p className="mb-1 font-bold leading-none text-secondary-800">
                  {t('openingTimes.openingTimes')}:
                </p>
                {
                  <OpeningTimes
                    times={getReadableOpeningTimes(gastronomy.opening_times)}
                  />
                }
              </div>
            )}
            <p className="mb-4 font-bold">{openText}</p>
          </div>
        </div>
        <div className="flex flex-wrap items-start">
          {gastronomy.status === 'MENUONLY' ? (
            <MenuOnlyBadge />
          ) : (
            <>
              {gastronomy.options.tableTypes &&
                gastronomy.options.tableTypes
                  .filter((type) => ORDER_TYPES.includes(type))
                  .map((type, index) => (
                    <OrderTypeBadge
                      gastronomy={gastronomy}
                      type={type}
                      key={index}
                    />
                  ))}
            </>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default GastronomyCard;
