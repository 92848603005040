import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GetsbyLink from '../components/getsbyLink';
import { IMG_BASE_PATH } from '../constants';
import useQueryParams from '../helpers/useQueryParams';
import { GetApp } from '../services/graphql/operations';
import { ReactComponent as Icon } from './../assets/icons/download.svg';

interface Props {
  gastronomyUrlPathId?: string;
  appUrlPathId?: string;
}

const DownloadApp: React.FC<Props> = ({
  gastronomyUrlPathId,
  appUrlPathId
}) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const referrer = queryParams.get('referrer') as AppDownloadReferrer | null;

  const { data, error, loading } = useQuery<GetAppQuery, GetAppQueryVariables>(
    GetApp,
    {
      variables: {
        params: {
          gastronomy_url_path_id: gastronomyUrlPathId,
          url_path_id: appUrlPathId
        }
      }
    }
  );

  const app = data?.getApp;

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4 align-middle sm:mt-8">
      <div className="md:w-80">
        {app && (
          <>
            {referrer === 'registration' && (
              <div className="mb-4">{t('app.thanksForRegistering')}</div>
            )}
            <div className="mb-8">
              {t('app.downloadNow', { name: app.name })}
              {referrer === 'registration' && (
                <div className="mb-4 font-bold">
                  {app.bonusProgram?.app_install_reward
                    ? t('app.loginInAppForReward', {
                        reward: app.bonusProgram.app_install_reward,
                        name: app.bonusProgram.bonus_program_name
                      })
                    : t('app.loginInApp')}
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center">
              <a
                href={`https://apps.apple.com/app/${app.app_store_id}`}
                className="mb-2"
              >
                <img
                  className="mx-auto mr-2"
                  src={`${IMG_BASE_PATH}/app-store-badge.svg`}
                  alt=""
                  style={{ width: 'auto', height: '60px' }}
                />
              </a>
              <a
                href={`https://play.google.com/store/apps/details?id=${app.app_id}`}
              >
                <img
                  className="mx-auto mr-2"
                  src={`${IMG_BASE_PATH}/google-play-badge.svg`}
                  alt=""
                  style={{ width: 'auto', height: '80px' }}
                />
              </a>
              <div className="flex flex-col items-center justify-center my-12">
                {gastronomyUrlPathId && (
                  <GetsbyLink
                    to="/:urlPathId/speisekarte"
                    params={{ urlPathId: gastronomyUrlPathId }}
                    className="mb-4 text-gray-600 underline"
                  >
                    {t('guestRegistration.goToMenu')}
                  </GetsbyLink>
                )}
                <GetsbyLink to="/profile" className="text-gray-600 underline">
                  {t('navigation.userProfile')}
                </GetsbyLink>
              </div>
              <div className="flex items-center justify-center">
                <Icon className="w-64 h-auto m-8 text-primary-500" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadApp;
