import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_BASE_PATH } from '../constants';
import Picture from './Picture';

interface Props {
  className?: string;
  points?: number | null;
  bonusProgram?: {
    bonus_program_name?: string | null;
    icon?: string | null;
  };
}

const BonusProgramBadge: React.FC<Props> = ({
  className,
  points = 0,
  bonusProgram
}) => {
  const { t } = useTranslation();

  const iconClasses = 'w-full h-full';

  return (
    <span
      className={`bonus-program-badge inline-flex items-center px-3 py-1 text-sm font-semibold bg-primary-500 text-secondary-500 border border-secondary-500 rounded-full ${className}`}
    >
      <div className="flex items-center justify-center w-4 h-4 max-w-sm mr-2 overflow-hidden bonus-program-badge-icon">
        {bonusProgram?.icon ? (
          <Picture imageSource={bonusProgram.icon} className={iconClasses} />
        ) : (
          <img
            className={iconClasses}
            src={`${IMG_BASE_PATH}/coin.svg`}
            alt=""
            style={{ height: '30px' }}
          />
        )}
      </div>
      {points}{' '}
      {bonusProgram?.bonus_program_name || t('bonusProgram.genericName')}
    </span>
  );
};

export default BonusProgramBadge;
