import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { cartItemsCount, cartTotal } from '../store/cart';
import { useTranslation } from 'react-i18next';
import usePrevious from '../hooks/usePrevious';
import { useBreakpoint } from '../hooks/useBreakpoint';
import AppInitOptionsService from '../services/appInitOptionsService';
import GetsbyLink from './getsbyLink';

const isIframe = window.self !== window.top;

const CartButton: React.FC = () => {
  const cartValue = useRecoilValue<number | undefined>(cartTotal || 0);
  const { t } = useTranslation();
  const cartItems = useRecoilValue<number>(cartItemsCount);
  const [buttonText, setButtonText] = useState<string>(t('shoppingCart.cart'));
  const previousCartValue = usePrevious<number | undefined>(cartItems);
  const { breakpoint } = useBreakpoint();
  const isApp = AppInitOptionsService.getInstance().isApp();

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (previousCartValue !== undefined && previousCartValue !== cartItems) {
      setButtonText(t('shoppingCart.addedArticleToCart'));

      timeout = setTimeout(() => {
        setButtonText(t('shoppingCart.cart'));
      }, 1500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [cartItems]);

  if (cartItems > 0) {
    return (
      <div
        className={`fixed z-20 h-16 ${
          !isApp && breakpoint === 'sm' ? 'bottom-12' : 'bottom-2'
        } left-4 right-4`}
        id="cart-button"
      >
        <GetsbyLink to="/cart" target={isIframe ? '_top' : undefined}>
          <div
            className={`flex items-center justify-between w-full max-w-lg p-2 font-bold text-center mx-auto rounded-md shadow-lg bg-primary-500 text-secondary-500`}
          >
            <div className="w-8 h-8 py-1 text-center rounded-full bg-secondary-500 text-primary-500">
              {cartItems}
            </div>
            <div>{buttonText}</div>
            <div>{cartValue ? (cartValue / 100).toFixed(2) : 0} €</div>
          </div>
        </GetsbyLink>
      </div>
    );
  }
  return null;
};

export default CartButton;
