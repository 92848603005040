import React from 'react';
import Hero from './components/hero';
import BonusFeatures from './components/bonusFeatures';
import WavesBottom from './components/wavesBottom';
import CallToAction from './components/callToAction';
import Config from '../../config';
import { Title } from 'react-head';
import Phone from './components/phone';

const Bonus: React.FC = () => {
  return (
    <React.Fragment>
      <Title>getsby - Bonuspunkte-Club</Title>

      <Hero
        keywords="Treuepunkte leicht gemacht"
        title="Ihr individueller Bonuspunkte-Club"
        subTitle="Erhöhen Sie Ihre Kundenloyalität, gewinnen Sie mehr Stammgäste und belohnen Sie sie für ihre Treue"
        callToAction={
          <a
            className="flex px-8 py-4 mx-auto mt-4 font-bold text-white rounded-full shadow-lg bg-secondary-500"
            href={`${Config.dashboardHost}?signup`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Jetzt anmelden und gleich loslegen!
          </a>
        }
        right={
          <Phone
            className="w-full py-8"
            imgSrc={`https://image.gets.by/general/misc/IMG_4E9AE8BE2713-1.jpeg?q=100&auto=format&dpr=${Math.min(
              2,
              Math.round(window.devicePixelRatio)
            )}`}
          />
        }
        renderPressBanner={false}
      />

      <div className="w-full">
        <BonusFeatures />
      </div>
      <WavesBottom fillColor="#ffffff" />
      <CallToAction
        title="Heben Sie Ihren Service auf das nächste Level!"
        subTitle="Der beste Service für Ihre Gäste - selbst bei großem Andrang!"
        callToAction={
          <a
            className="px-8 py-4 mx-auto font-bold text-white rounded-full shadow-lg bg-secondary-500 lg:mx-0"
            href={`${Config.dashboardHost}?signup`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Service anheben!
          </a>
        }
      />
    </React.Fragment>
  );
};

export default Bonus;
