import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from './heading';
import QRCode from 'qrcode.react';
import Countdown from './countdown';
import Button from './button';
import { isFuture, isPast } from 'date-fns';

interface Props {
  redemption: BonusProgramRedemptionFragment;
  handleClose?: () => void;
}

const RedemptionQrCode: React.FC<Props> = ({ redemption, handleClose }) => {
  const [allowClose, setAllowClose] = useState(false);

  useEffect(() => {
    const expiryDate = new Date(redemption.expires_at);
    let timeout: NodeJS.Timeout;
    if (isPast(expiryDate)) {
      setAllowClose(true);
    } else {
      timeout = setTimeout(() => {
        setAllowClose(true);
      }, expiryDate.getTime() - new Date().getTime());
    }
    return () => clearTimeout(timeout);
  }, [redemption]);

  const { t } = useTranslation();

  const expiryDate = new Date(redemption.expires_at);

  return (
    <>
      <Heading text={t('bonusProgram.successfullyRedeemed')} />
      <p>{t('bonusProgram.redeemOfflineInfo2')}</p>
      <p className="my-4 text-xl font-bold text-center text-primary-500">
        {redemption.bonus_program?.title}
      </p>
      {redemption && (
        <>
          <div className="flex flex-col items-center justify-center my-8">
            <QRCode
              id={redemption.uuid}
              value={redemption.qrcode_text}
              size={200}
            />
            <div className="flex items-center mt-4">
              {isFuture(expiryDate) ? (
                <>
                  {t('bonusProgram.expiresIn')}:{' '}
                  <Countdown
                    className="ml-1 font-bold"
                    milliSeconds={
                      new Date(redemption.expires_at).getTime() -
                      new Date().getTime()
                    }
                  />
                </>
              ) : (
                <span className="font-bold text-red-500">
                  {t('bonusProgram.invalid')}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      <p className="mb-8 text-xl font-bold text-center text-primary-500">
        {redemption.redeemed_bonus_points}{' '}
        {redemption.bonus_program?.bonus_program_name} ={' '}
        {(redemption.redeemed_bonus_amount / 100).toFixed(2) + ' €'}
      </p>
      {allowClose && (
        <Button
          type="primary"
          classes="w-full flex items-center justify-center"
          onClick={handleClose}
        >
          <i className="mr-4 material-icons">done</i>
          <span>{t('ok')}</span>
        </Button>
      )}
    </>
  );
};

export default RedemptionQrCode;
