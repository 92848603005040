import { atom, selector } from 'recoil';
import queryString from 'query-string';
import { STORAGE_KEY_DELIVERY_ADDRESS } from '../constants';
import { getLastSelectedLocation } from '../helpers/location';
import { isStorageAvailable } from '../helpers/localStorage';
import { preliminaryCreditState, redeemCreditState } from './cart';

const params = queryString.parse(window.location.search);

const getDefaultLocation = () => {
  if (params && params.lng && params.lat) {
    return {
      lat: parseFloat(params.lat as string),
      lng: parseFloat(params.lng as string),
      selected: false
    };
  }
  const lastKnownLocation = getLastSelectedLocation();
  if (lastKnownLocation) {
    return lastKnownLocation;
  }
  return null;
};

export const userLocationState = atom<UserLocation | null>({
  key: 'userLocationState',
  default: getDefaultLocation()
});

export const initialUserLocationLoadingState = atom<boolean>({
  key: 'initialUserLocationLoading',
  default: true
});

export const restaurantFiltersState = atom<RestaurantFilter[]>({
  key: 'restaurantFiltersState',
  default: []
});

export const guestRegistrationState = atom<EncryptedGuestRegistration | null>({
  key: 'guestRegistrationState',
  default: null
});

export const userIsAuthenticatedAtom = atom<boolean | null>({
  key: 'userIsAuthenticated',
  default: null
});

export const userIsAuthenticatedState = selector<boolean | null>({
  key: 'userIsAuthenticatedSelector',
  get: ({ get }) => get(userIsAuthenticatedAtom),
  set: ({ get, set, reset }, newValue) => {
    if (typeof newValue === 'boolean' || newValue === null) {
      const oldValue = get(userIsAuthenticatedAtom);

      if (newValue !== oldValue) {
        reset(availableBonusProgramPointsState);
        reset(useBonusProgramPointsState);
        reset(restaurantFiltersState);
        set(preliminaryCreditState, null);
        set(redeemCreditState, null);
      }
    }
    set(userIsAuthenticatedAtom, newValue);
  }
});

export const availableBonusProgramPointsState = atom<number | null>({
  key: 'availableBonusProgramPoints',
  default: null
});

export const useBonusProgramPointsState = atom<number>({
  key: 'useBonusProgramPoints',
  default: 0
});

const deliveryAddressFromLocalStorage =
  isStorageAvailable() && localStorage.getItem(STORAGE_KEY_DELIVERY_ADDRESS);
export const deliveryAddressState = atom<DeliveryData | null>({
  key: 'deliveryAddress',
  default:
    (deliveryAddressFromLocalStorage &&
      JSON.parse(deliveryAddressFromLocalStorage)) ||
    null
});

export const locationSelectModalState = atom<{ isOpen: boolean } | null>({
  key: 'locationSelectModalState',
  default: null
});

export const locationSelectModalSelector = selector<{ isOpen: boolean }>({
  key: 'locationSelectModalSelector',
  get: ({ get }) => {
    const isOpenState = get(locationSelectModalState);
    // const selectedTable = get(selectedTableState);
    return { isOpen: isOpenState ? isOpenState.isOpen : false };
  }
});
