import React from 'react';
import AppInitOptionsService from '../services/appInitOptionsService';

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
}

const Modal: React.FC<Props> = ({ children, onClose }: Props) => {
  const appInitOptions = AppInitOptionsService.getInstance().getOptions();

  return (
    <div
      className="fixed inset-0 z-30 overflow-y-auto"
      style={
        appInitOptions?.insets?.top
          ? { marginTop: appInitOptions.insets.top + 'px' }
          : undefined
      }
    >
      <div className="flex items-center justify-center min-h-screen pb-20 text-center">
        <div
          className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          onClick={onClose}
        ></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>

        <div className="inline-block w-full overflow-hidden text-left align-middle transition-all transform bg-white rounded shadow-xl sm:max-w-lg">
          {onClose && (
            <div
              className="absolute right-0 mt-2 mr-2 cursor-pointer text-secondary-300 material-icons"
              onClick={onClose}
            >
              close
            </div>
          )}
          <div className="p-4 bg-white">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
