import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getRouteOverwrite, replacePathParams } from '../helpers/app';
import { appConfigState } from '../store/app';

const useUri = (
  inputUri: string,
  params?: Record<string, string | undefined | null>
): string => {
  const appConfig = useRecoilValue(appConfigState);

  const uri = useMemo(() => {
    let routeOverwrite = inputUri;
    let searchParams = '';

    if (inputUri.indexOf('?') !== -1) {
      const indexOfSearchParams = routeOverwrite.indexOf('?');
      searchParams = routeOverwrite.substring(indexOfSearchParams);
      routeOverwrite = routeOverwrite.substring(0, indexOfSearchParams);
    }

    if (appConfig?.router) {
      routeOverwrite = getRouteOverwrite(routeOverwrite, appConfig.router);
    }

    routeOverwrite = replacePathParams(routeOverwrite, params);

    return routeOverwrite + searchParams;
  }, [appConfig, inputUri]);

  return uri;
};

export default useUri;
