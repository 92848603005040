import { STORAGE_KEY_APP_DATA } from '../constants';
import { isStorageAvailable } from '../helpers/localStorage';

class AppInitOptionsService {
  private static instance: AppInitOptionsService;
  private appInitOptions: AppInitOptions | null;

  private constructor() {
    this.appInitOptions = null;
    if (isStorageAvailable()) {
      const appInitJson = localStorage.getItem(STORAGE_KEY_APP_DATA);
      if (appInitJson) {
        this.appInitOptions = JSON.parse(appInitJson) as AppInitOptions;
      }
    }
  }

  public static getInstance(): AppInitOptionsService {
    if (!AppInitOptionsService.instance) {
      AppInitOptionsService.instance = new AppInitOptionsService();
    }

    return AppInitOptionsService.instance;
  }

  public getOptions(): AppInitOptions | null {
    return this.appInitOptions;
  }

  public isApp(): boolean {
    return this.appInitOptions !== null;
  }
}

export default AppInitOptionsService;
