import React from 'react';
import { IMG_BASE_PATH } from '../constants';

interface Props {
  showText?: boolean;
  text?: string;
  onClick?: () => void;
  isActive?: boolean;
  className?: string;
}

const CcBadge: React.FC<Props> = ({
  showText = true,
  text = 'Meal Deal',
  onClick,
  isActive = true,
  className
}) => {
  const ccColor = '#e61a27';
  return (
    <div
      className={`cc-badge inline-flex items-center px-3 py-1 text-sm font-semibold ${
        isActive ? 'text-white' : ccColor
      } rounded-full ${
        onClick ? 'cursor-pointer border hover:bg-gray-200 border-gray-200' : ''
      } ${className}`}
      style={{ backgroundColor: isActive ? ccColor : '' }}
      onClick={onClick}
    >
      <img
        src={`${IMG_BASE_PATH}/${
          isActive ? 'cc_logo.svg' : 'cc_logo_grey.svg'
        }`}
        alt=""
        className="h-4"
      />
      {showText && <span className="ml-2">{text}</span>}
    </div>
  );
};

export default CcBadge;
