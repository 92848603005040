import React from 'react';
import GetsbyLink from './getsbyLink';

interface Props {
  useLink: boolean;
  target?: GetsbyRouter;
  external?: boolean;
  className?: string;
  id?: string;
}

const LinkWrapper: React.FC<Props> = ({
  useLink = true,
  target,
  external = false,
  children,
  className,
  id
}) => {
  return (
    <>
      {useLink && target ? (
        <>
          {external ? (
            <a
              href={target.to || '#'}
              target="_blank"
              rel="noreferrer"
              className={className}
              id={id}
            >
              {children}
            </a>
          ) : (
            <GetsbyLink {...target} className={className} id={id}>
              {children}
            </GetsbyLink>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LinkWrapper;
