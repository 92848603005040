import React from 'react';
import { useBreakpoint } from '../hooks/useBreakpoint';

const RestaurantViewLoader: React.FC = () => {
  const { breakpoint } = useBreakpoint();
  const amountOfItems = breakpoint === 'sm' ? [0, 1, 2] : [0, 1, 2, 3, 4, 5];

  return (
    <>
      <div className="flex flex-col p-4 animate-pulse">
        <div className="w-full h-6 bg-gray-200 rounded"></div>
      </div>
      <nav className="relative flex bg-white shadow-lg animate-pulse">
        <ul className="flex items-center px-4 py-2 overflow-auto whitespace-nowrap">
          <li className={`mr-1`}>
            <div className="w-48 h-10 mr-2 bg-gray-200 rounded"></div>
          </li>
          {amountOfItems.map((i) => (
            <li className={`mr-1`} key={i}>
              <div className="w-32 h-6 mr-2 bg-gray-200 rounded"></div>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex flex-col p-4 animate-pulse">
        <div className="w-full h-12 my-2 bg-gray-200 rounded"></div>
        <div className="grid grid-cols-1 gap-8 p-4 sm:grid-cols-2">
          {amountOfItems.map((i) => (
            <div className="w-full py-2" key={i}>
              <div className="flex items-center p-4 bg-white border border-l-4 border-gray-200 rounded">
                <div className="w-12 h-12 mr-4 bg-gray-200 rounded md:w-16 md:h-16"></div>
                <div className="w-32 h-6 mr-2 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RestaurantViewLoader;
