import React from 'react';

interface Props {
  type: 'success' | 'info' | 'warning' | 'error';
  title: string;
  message?: string | React.ReactNode;
  classes?: string;
}

const Alert: React.FC<Props> = ({ type, title, message, classes }: Props) => {
  const headerStyles = {
    success: 'bg-green-500',
    info: 'bg-blue-500',
    warning: 'bg-yellow-500',
    error: 'bg-red-500'
  };

  const bodyStyles = {
    success: 'border-green-400 bg-green-100 text-green-700',
    info: 'border-blue-400 bg-blue-100 text-blue-700',
    warning: 'border-yellow-400 bg-yellow-100 text-yellow-700',
    error: 'border-red-400 bg-red-100 text-red-700'
  };

  return (
    <div role="alert" className={`mb-4 ${classes}`}>
      <div
        className={`text-white font-bold rounded-t ${
          message ? '' : 'rounded-b'
        } px-4 py-2 ${headerStyles[type]}`}
      >
        {title}
      </div>
      {message && (
        <div
          className={`border border-t-0 rounded-b px-4 py-3 ${bodyStyles[type]}`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default Alert;
