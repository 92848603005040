import React, { ReactElement } from 'react';

interface Props {
  icon: string;
  content: ReactElement;
}

const InfoCard: React.FC<Props> = ({ icon, content }) => {
  return (
    <div className="flex m-1">
      <span className="p-2 text-primary-500 material-icons">{icon}</span>
      <div className="flex items-center text-sm font-bold text-secondary-300">
        {content}
      </div>
    </div>
  );
};

export default InfoCard;
