export const IMG_BASE_PATH = `${process.env.PUBLIC_URL}/img`;
export const DISTANCE_IN_KM = 50;
export const WEB_APP_ID = 'getsby-web-app';
export const ORDER_TYPES: TableType[] = [
  'DINEIN',
  'SELFSERVICE',
  'TAKEAWAY',
  'DRIVEIN',
  'DELIVERY'
];

export const SUPPORTED_PAYMENT_METHODS: PaymentMethodType[] = [
  'CARD',
  'EPS',
  'SOFORT',
  'ALIPAY',
  'NONE'
];

export const STORAGE_KEY_EMAIL = 'getsbyUsersEmail';
export const STORAGE_KEY_PHONE = 'getsbyUsersPhone';
export const STORAGE_KEY_PAYMENTMETHOD_TYPE = 'getsbyPaymentMethodType';
export const STORAGE_KEY_GUEST_REGISTRATION_DATA = 'gGuestRegistrationData';
export const STORAGE_KEY_GUEST_REGISTRATION = 'gGuestRegistration';
export const STORAGE_KEY_PERSISTED_LOCATION = 'gGeoLocation';
export const STORAGE_KEY_DELIVERY_ADDRESS = 'gDeliveryAddress';
export const STORAGE_KEY_EPS_BANK = 'gEpsBank';
export const STORAGE_KEY_PRELIMINARY_CREDIT = 'gPreliminaryCredit';
export const STORAGE_KEY_SELECTED_LANGUAGE_CODE = 'gSelectedLanguageCode';
export const STORAGE_KEY_APP_ID = 'gAppId';

export const PERSISTED_LAST_SELECTED_GASTRO = 'gLastSelectedGastro';
export const PERSISTED_LOCKED_URL_PATH_ID = 'gLockedUrlPathId';

export const PERSISTED_TABLE_TYPE_STATE_KEY = 'gTableTypeState';
export const PERSISTED_TABLE_STATE_KEY = 'gTableState';

export const PERSISTED_GASTRONOMY_KEY = 'gGastronomyState';

export const STORAGE_KEY_APP_DATA = 'gAppData';

export const STORAGE_KEY_PUSH_ENABLED = 'gPushEnabled';
export const STORAGE_KEY_PUSH_TOKEN = 'gPushToken';

export const LEAD_TIME_DEFAULT = 15;
export const LEAD_TIME_ASAP_END = 30;
export const DEFAULT_TIP_PERCENTAGE = 10;
export const PREORDER_DAYS_DEFAULT = 1;

export const PRIVACY_POLICY_URL =
  'https://getsby-public.s3.eu-central-1.amazonaws.com/documents/privacy_policy.pdf';

export const NEW_CUSTOMER_COUPON_CODE = 'GDKW34RJLK';
export const NEW_CUSTOMER_CC_COUPON_CODE = 'GZ1C4AE9Y8';

export const USER_AGENT_MARKER = 'getsby/';

export const DEFAULT_GOOGLE_FONTS: FontFamily[] = [
  { family: 'Material Icons' }
];
