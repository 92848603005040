import { isSameDay } from 'date-fns';
import nacl from 'tweetnacl';

export function toBytes(string: string): Uint8Array {
  return new TextEncoder().encode(string);
}

function newNonce() {
  return nacl.randomBytes(nacl.box.nonceLength);
}

export function encrypt(
  message: string,
  theirPublicKey: Uint8Array,
  mySecretKey: Uint8Array
): string {
  const nonce = newNonce();
  const bytes = toBytes(message);
  const encrypted = nacl.box(bytes, nonce, theirPublicKey, mySecretKey);

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  return Buffer.from(fullMessage).toString('base64');
}

export function userIsRegistered(
  guestRegistration: EncryptedGuestRegistration | null,
  gastronomy: {
    uuid?: string;
    options: { enableGuestRegistration?: boolean };
  } | null,
  pathname?: string
): boolean | null {
  if (!gastronomy || !gastronomy.options.enableGuestRegistration) {
    return null;
  }

  let isRegistered = false;

  console.log('gastronomyUUids', guestRegistration?.userUuid, gastronomy.uuid);
  if (guestRegistration && guestRegistration.userUuid === gastronomy.uuid) {
    const regDate = new Date();
    regDate.setTime(guestRegistration.timestamp);
    console.log('is same day', isSameDay(regDate, new Date()));
    if (isSameDay(regDate, new Date())) {
      isRegistered = true;
    }
  }

  if (pathname) {
    return pathname.match('/speisekarte') ||
      pathname.match('/cart') ||
      (pathname.match('/order') && pathname !== '/orders')
      ? isRegistered
      : null;
  }

  return isRegistered;
}
