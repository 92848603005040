import React from 'react';

interface Props {
  text: string;
  id?: string;
  className?: string;
}

const SectionSubHeading: React.FC<Props> = ({ text, id, className }) => {
  return (
    <div
      className={`mb-16 text-xl leading-normal md:text-2xl text-secondary-500 px-4 ${className}`}
      id={id}
    >
      {text}
    </div>
  );
};

export default SectionSubHeading;
