import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { removeSnackbar, snackbarState } from '../store/snackbar';

interface Props {
  snackbar: Snackbar;
}

const SimpleSnackbar: React.FC<Props> = ({ snackbar }: Props) => {
  const setSnackbars = useSetRecoilState(snackbarState);

  useEffect(() => {
    const timer = setTimeout(() => {
      removeSnackbar(snackbar, setSnackbars);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const getTypeIcon = (): React.ReactNode => {
    const styles = 'material-icons mr-2 self-start';

    switch (snackbar.type) {
      case 'INFO':
        return <i className={`${styles} text-blue-500`}>info_outline</i>;
      case 'ERROR':
        return <i className={`${styles} text-red-500`}>error_outline</i>;
      case 'SUCCESS':
        return (
          <i className={`${styles} text-green-500`}>check_circle_outline</i>
        );
      case 'WARNING':
        return <i className={`${styles} text-yellow-500`}>warning_amber</i>;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex items-center justify-between w-64 p-2 mb-2 bg-white border rounded shadow-lg">
      <div className="flex items-center">
        {getTypeIcon()}
        <div>
          <div className="text-base font-bold">{snackbar.title}</div>
          {snackbar.body && (
            <div className="text-sm text-secondary-200">{snackbar.body}</div>
          )}
        </div>
      </div>
      <i
        className="self-start ml-2 cursor-pointer material-icons text-secondary-200"
        onClick={() => removeSnackbar(snackbar, setSnackbars)}
      >
        close
      </i>
    </div>
  );
};

export default SimpleSnackbar;
