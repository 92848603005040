import React, { useEffect } from 'react';
import { GetLastReceipts } from '../services/graphql/operations';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Heading from './heading';
import Picture from './Picture';
import { useQuery } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { userIsAuthenticatedState } from '../store/user';
import { getAppIdFromUserAgent } from '../helpers/app';
import GetsbyLink from './getsbyLink';

const OpenOrders: React.FC = () => {
  const userIsAuthenticated = useRecoilValue(userIsAuthenticatedState);
  const { t } = useTranslation();

  useEffect(() => {
    startPolling(5000);
    return () => stopPolling();
  }, []);

  useEffect(() => {
    refetch();
  }, [userIsAuthenticated]);

  const appId = getAppIdFromUserAgent();
  const isWhiteLabel =
    (appId && !appId.startsWith('by.gets.clientapp')) || false;

  const { data, refetch, stopPolling, startPolling } = useQuery<
    GetLastReceiptsQuery,
    GetLastReceiptsQueryVariables
  >(GetLastReceipts, {
    variables: {
      params: {
        app_id: appId && isWhiteLabel ? appId : undefined,
        status: [
          'PENDING_RECEIVED',
          'RECEIVED',
          'PENDING_ACCEPTED',
          'ACCEPTED',
          'READY'
        ]
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.getClientReceipts && data.getClientReceipts.length === 0) {
      stopPolling();
    }
  }, [data]);

  const getStatusMessage = (receipt: ReceiptFragment): React.ReactNode => (
    <div
      className={`justify-center w-full py-2 text-center ${
        receipt.status === 'READY' ? 'bg-primary-500' : 'bg-gray-200'
      }`}
    >
      <span className="text-lg font-bold">
        {receipt.status === null ? (
          t('orders.waitingForConfirmation')
        ) : (
          <>
            {receipt.status === 'READY'
              ? `${t('order')}${
                  receipt.pickup_code ? ': ' + receipt.pickup_code : ''
                }`
              : t(`orders.status.${receipt.status?.toLowerCase()}`)}
          </>
        )}
      </span>
    </div>
  );

  if (data && data.getClientReceipts.length > 0) {
    return (
      <div className="w-full">
        <Heading text={t('navigation.lastOrders')} />
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {data.getClientReceipts.map((receipt) => {
            const gastronomy = receipt.gastronomy;
            if (gastronomy) {
              return (
                <GetsbyLink
                  className="flex flex-wrap w-full bg-white border border-b rounded hover:shadow-lg"
                  to="/order/:receiptUuid"
                  params={{ receiptUuid: receipt.uuid }}
                  key={receipt.uuid}
                >
                  <div className="flex justify-between w-full p-4">
                    <div className="flex">
                      {gastronomy.logo_image ? (
                        <div className="flex items-center w-12 h-12 mr-4 overflow-hidden rounded-full">
                          <Picture
                            className="w-full h-auto"
                            imageSource={gastronomy.logo_image}
                            width={64}
                          />
                        </div>
                      ) : (
                        <div className="object-cover w-12 h-12 mr-4 rounded-full shadow" />
                      )}

                      <div className="col-span-2 xl:col-span-4">
                        <h3 className="font-semibold text-secondary-500">
                          {gastronomy.name}
                        </h3>
                        {receipt.created_at && (
                          <div className="text-secondary-200">
                            {format(
                              new Date(receipt.created_at),
                              "dd.MM.yyyy' 'HH:mm"
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {receipt.status !== 'ERROR' &&
                      receipt.hasCanceledOrderItems && (
                        <div className="flex items-center">
                          <i
                            className="ml-4 text-red-500 material-icons"
                            title={t('orders.hasChanged')}
                          >
                            warning
                          </i>
                        </div>
                      )}
                  </div>
                  {getStatusMessage(receipt)}
                  <div className="w-full col-span-2 p-4 text-right xl:col-span-1">
                    <span>
                      {t('shoppingCart.total')}{' '}
                      {((receipt.total_price || 0) / 100).toFixed(2)} €
                    </span>
                  </div>
                </GetsbyLink>
              );
            }
          })}
        </div>
        <hr className="my-8" />
      </div>
    );
  }
  return null;
};

export default OpenOrders;
