import { useLazyQuery } from '@apollo/client';
import { format, isFuture, isSameDay, isSameMinute, isToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetPreorderSlots } from '../services/graphql/operations';
import { selectedTableLeadTime, selectedTableState } from '../store/gastronomy';
import locale from 'date-fns/locale/de';
import { useRecoilState } from 'recoil';
import {
  isAsapOrderPossibleSelector,
  preorderModalState,
  preorderSlotState
} from '../store/cart';
import { useTranslation } from 'react-i18next';
import Button from './button';
import { LEAD_TIME_ASAP_END, LEAD_TIME_DEFAULT } from '../constants';
import { getLeadTime } from '../helpers/serviceTimes';
import { isPast, sub } from 'date-fns/esm';
import { useInterval } from '../helpers/useInterval';

interface Props {
  onlyPreorder?: boolean;
}

const PreorderDateSelectWidget: React.FC<Props> = ({
  onlyPreorder = false
}) => {
  const selectedTable = useRecoilValue(selectedTableState);
  const tableLeadTime = useRecoilValue(selectedTableLeadTime);
  const [preorderModal, setPreorderModal] = useRecoilState(preorderModalState);
  const [selectedPreorderDate, setSelectedPreorderDate] =
    useState<PreorderSlots>();
  const [preorderSlot, setPreorderSlot] = useRecoilState(preorderSlotState);
  const isAsapOrderPossible = useRecoilValue(isAsapOrderPossibleSelector);
  const [asap, setAsap] = useState<boolean>(onlyPreorder ? false : true);
  const [message, setMessage] = useState<string>();
  const [noSlots, setNoSlots] = useState<boolean>();
  const { t } = useTranslation();

  const leadTime =
    tableLeadTime && tableLeadTime > LEAD_TIME_DEFAULT
      ? tableLeadTime
      : LEAD_TIME_DEFAULT;

  useInterval(
    () => {
      if (
        selectedPreorderDate &&
        selectedPreorderDate.slots.length > 0 &&
        isPast(
          sub(new Date(selectedPreorderDate.slots[0].from), {
            minutes: leadTime
          })
        )
      ) {
        const slots = selectedPreorderDate.slots.filter((slot) =>
          isFuture(sub(new Date(slot.from), { minutes: leadTime }))
        );
        setSelectedPreorderDate((prev) => {
          if (prev) {
            return { ...prev, slots };
          }
        });
      }
    },
    selectedPreorderDate &&
      selectedPreorderDate.slots.length > 0 &&
      isToday(new Date(selectedPreorderDate.date))
      ? 1000
      : null
  );

  useEffect(() => {
    if (preorderModal) {
      if (preorderModal.availability) {
        setAsap(preorderModal.availability.timerangeAt(new Date()).length > 0);
      }
    }
  }, [preorderModal]);

  useEffect(() => {
    if (isAsapOrderPossible === false) {
      setAsap(false);
    }
  }, [isAsapOrderPossible]);

  const [slotsQuery, slotsResult] = useLazyQuery<
    GetPreorderSlotsQuery,
    GetPreorderSlotsQueryVariables
  >(GetPreorderSlots, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSelectedPreorderDate(undefined);
      setNoSlots(undefined);
      if (data.getPreorderSlots.length > 0) {
        let dateToSet = undefined;
        if (preorderSlot) {
          dateToSet = data.getPreorderSlots.find((slots) => {
            const slotdate = new Date(slots.date);
            return isSameDay(preorderSlot.from, slotdate);
          });
        }
        if (!dateToSet) {
          dateToSet = data.getPreorderSlots.find(
            (dateElement) => dateElement.slots.length > 0
          );
        }
        if (dateToSet) {
          setSelectedPreorderDate(dateToSet);
        } else {
          let hasSlots = false;
          for (const date of data.getPreorderSlots) {
            for (const slot of date.slots) {
              if (slot.from) {
                hasSlots = true;
                break;
              }
            }
            if (hasSlots === true) {
              break;
            }
          }
          if (hasSlots === false) {
            setNoSlots(true);
          }
        }
      } else {
        setNoSlots(true);
      }
    }
  });

  const showAsap = () => {
    if (!onlyPreorder && isAsapOrderPossible && selectedTable) {
      const now = new Date();
      const withinAvailability =
        !preorderModal.availability ||
        preorderModal.availability.timerangeAt(now).length > 0;
      return (
        withinAvailability && getLeadTime(selectedTable) <= LEAD_TIME_ASAP_END
      );
    }
    return false;
  };

  useEffect(() => {
    if (selectedTable && selectedTable.uuid) {
      slotsQuery({
        variables: {
          params: {
            table_uuid: selectedTable.uuid,
            product_group_uuid: preorderModal.productGroupUuid
          }
        }
      });
    }
  }, [selectedTable, preorderModal]);

  const dateRender = (data: PreorderSlots) => {
    const date = new Date(data.date);
    const selectedDate = selectedPreorderDate
      ? new Date(selectedPreorderDate.date)
      : undefined;
    const selected = selectedDate && isSameDay(date, selectedDate);
    const noSlots = data.slots.length === 0;
    return (
      <div
        key={'preorder-slots-' + data.date}
        onClick={() => {
          setSelectedPreorderDate(data);
        }}
        className={
          'flex flex-col px-4 py-2 cursor-pointer rounded-md items-center ' +
          (selected ? ' bg-primary-500 text-secondary-500' : '')
        }
      >
        <span
          className={
            'text-xs ' + (noSlots ? 'text-secondary-100' : 'text-secondary-300')
          }
        >
          {format(date, 'eee', { locale })}
        </span>
        <span className={'font-bold ' + (noSlots && 'text-secondary-100')}>
          {format(date, 'dd')}
        </span>
        <span
          className={
            'text-xs ' + (noSlots ? 'text-secondary-100' : 'text-secondary-300')
          }
        >
          {format(date, 'MMM', { locale })}
        </span>
      </div>
    );
  };

  const slotRender = (slot: PreorderSlot) => {
    const from = new Date(slot.from);
    const to = new Date(slot.to);
    const selected = preorderSlot && isSameMinute(from, preorderSlot.from);
    const available =
      slot.limit === null || slot.limit === undefined || slot.limit > 0;
    if (selectedTable) {
      return (
        <div
          key={'preorder-slot-' + slot.from}
          className={`flex flex-row cursor-pointer rounded p-1 
          ${
            selected ? 'bg-primary-500 text-secondary-500' : 'hover:bg-gray-200'
          }
          ${available ? '' : ' text-gray-500'}
          `}
          onClick={() => {
            if (available) {
              setMessage(undefined);
              if (selectedTable.uuid) {
                setPreorderSlot({
                  from,
                  to,
                  table_uuid: selectedTable.uuid,
                  expires: sub(from, {
                    minutes:
                      tableLeadTime && tableLeadTime > LEAD_TIME_DEFAULT
                        ? tableLeadTime
                        : LEAD_TIME_DEFAULT
                  }).getTime()
                });
                if (preorderModal.closeOnSelect) {
                  setPreorderModal({ showModal: false });
                }
              }
            }
          }}
        >
          <span>
            {format(from, 'H:mm')} - {format(to, 'H:mm')}
          </span>
          <span className="ml-4">
            {typeof slot.limit === 'number'
              ? slot.limit > 0
                ? `${slot.limit} verfügbar`
                : 'ausgebucht'
              : ''}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  if (selectedTable) {
    return (
      <div className="">
        <div className="mb-4">
          {preorderModal.title
            ? preorderModal.title
            : t('preorder.chooseDateAndTime')}
        </div>
        {slotsResult.data && (
          <div className="flex flex-col">
            {showAsap() && (
              <div className="flex flex-row mb-4 bg-gray-200 border-gray-300 rounded-md">
                <div
                  onClick={() => {
                    setAsap(true);
                    setPreorderSlot(null);
                    setPreorderModal((modal) => ({
                      showModal: false
                    }));
                  }}
                  className={
                    'flex justify-center w-1/2 py-2 cursor-pointer ' +
                    (asap && ' bg-primary-500 text-secondary-500 rounded-l-md')
                  }
                >
                  {t('asap')}
                </div>
                <div
                  onClick={() => {
                    setAsap(false);
                    //show preorder
                  }}
                  className={
                    'flex justify-center w-1/2 py-2 cursor-pointer ' +
                    (!asap && 'bg-primary-500 text-secondary-500 rounded-r-md')
                  }
                >
                  {t('preorder.preorder')}
                </div>
              </div>
            )}
            {!asap && !noSlots && (
              <div className="flex pb-2 mb-2 overflow-auto">
                <div className="flex flex-row bg-gray-200">
                  {slotsResult.data.getPreorderSlots.map((data) =>
                    dateRender(data)
                  )}
                </div>
              </div>
            )}
            {(!asap || !showAsap()) && selectedPreorderDate && (
              <div className="h-48 overflow-auto divide-y divide-gray-200">
                {selectedPreorderDate.slots.length > 0 &&
                  selectedPreorderDate.slots.map((slot) => slotRender(slot))}
                {selectedPreorderDate.slots.length === 0 && (
                  <div className="flex justify-center mt-12 text-secondary-300">
                    {t('preorder.noSlotsAvailable')}
                  </div>
                )}
              </div>
            )}
            {noSlots && (
              <div className="h-48 overflow-auto divide-y divide-gray-200">
                <div className="flex justify-center mt-12 text-secondary-300">
                  {t('preorder.noPreorder')}
                </div>
              </div>
            )}
            {preorderModal.actionButton && (
              <>
                {message && (
                  <div className="flex justify-center mt-2 text-red-500">
                    {message}
                  </div>
                )}
                <div className="flex flex-row justify-between mt-4">
                  <Button
                    type="default"
                    title="Cancel"
                    onClick={() => {
                      setMessage(undefined);
                      setPreorderSlot(null);
                      setPreorderModal({ showModal: false });
                    }}
                  />
                  <Button
                    type="primary"
                    title={t('shoppingCart.addToCart')}
                    onClick={() => {
                      setMessage(undefined);
                      if (
                        preorderSlot &&
                        (!preorderModal.availability ||
                          preorderModal.availability.timerangeAt(
                            preorderSlot.from
                          ).length > 0)
                      ) {
                        if (preorderModal.actionButton) {
                          preorderModal.actionButton();
                        }
                        setPreorderModal({ showModal: false });
                      } else {
                        setMessage(t('preorder.selectSlotWithinTime'));
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default PreorderDateSelectWidget;
