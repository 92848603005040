function hashCode(input: string) {
  let hash = 0,
    i,
    chr;
  if (input.length === 0) return hash.toString();
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash.toString();
}

/**
 * Generates a base64 encoded string from an OrderItem object
 * @param orderItem the OrderItem to hash
 * @returns
 */
export default function generateCartItemHash({
  product_uuid,
  comment = '',
  modifiers = []
}: {
  product_uuid: string;
  comment?: string;
  modifiers: { uuid: string }[];
}): string {
  const objString = JSON.stringify({
    product_uuid,
    comment,
    modifiers: modifiers.map((mi) => mi.uuid).sort()
  });
  return hashCode(objString);
}
