import React from 'react';
import { AvailabilityMixin } from '../helpers/availability';
import Product from './product';

interface Props {
  product: ProductFragment;
  availability?: AvailabilityMixin;
  canOrder: boolean;
}

const FeaturedProduct: React.FC<Props> = (props) => {
  return (
    <div className="mx-1 my-4 sm:mx-4 featured-product">
      <Product {...props} featured={true} />
    </div>
  );
};

export default FeaturedProduct;
