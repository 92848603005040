import React, { useEffect, useState } from 'react';
import Spinner from '../components/spinner';
import {
  GetGastronomyUrlPath,
  GetTable,
  LookupQrCode
} from '../services/graphql/operations';
import { useSetRecoilState } from 'recoil';
import { selectedTableState } from '../store/gastronomy';
import shortUuid from 'short-uuid';
import { captureError } from '../helpers/error';
import { useApolloClient } from '@apollo/client';
import Config from '../config';
import { redirectToCorona } from '../helpers/gastronomy';
import GetsbyRedirect from '../components/getsbyRedirect';

interface Props {
  queryParams?: { t?: string; g?: string };
  code?: string;
}

const QueryParamsProcessor: React.FC<Props> = ({
  queryParams,
  code
}: Props) => {
  const [redirectTo, setRedirectTo] = useState<GetsbyRouter | null>(null);
  const [qrCodeNotRecognized, setQrCodeNotRecognized] =
    useState<boolean>(false);
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (queryParams) {
      try {
        if (queryParams.t) {
          const uuid = checkUuid(queryParams.t);
          console.log('table uuid', uuid);
          if (uuid) {
            loadTable(uuid);
          } else {
            setRedirectTo({ to: '/' });
          }
        } else if (queryParams.g) {
          console.log('got g param', queryParams.g);
          const uuid = checkUuid(queryParams.g);
          console.log('got uuid', uuid);
          if (uuid) {
            setGastronomyUrlPathId(uuid);
          } else {
            setRedirectTo({ to: '/' });
          }
        } else {
          setRedirectTo({ to: '/' });
        }
      } catch (error) {
        console.log('invalid uuid', queryParams);
        captureError(error);
        setRedirectTo({ to: '/' });
      }
    }
  }, [queryParams]);

  useEffect(() => {
    if (code) {
      try {
        const loadQrCode = async () => {
          const result = await apolloClient.query<
            LookupQrCodeQuery,
            LookupQrCodeQueryVariables
          >({ query: LookupQrCode, variables: { url: code } });
          if (result.data.lookupQrCode) {
            let tableType = undefined;
            if (result.data.lookupQrCode.table) {
              tableType = result.data.lookupQrCode.table.table_type;
              setSelectedTable(result.data.lookupQrCode.table);
            }
            redirectToGastronomy(
              result.data.lookupQrCode.gastronomy,
              tableType
            );
          } else {
            setQrCodeNotRecognized(true);
          }
        };
        loadQrCode();
      } catch (error) {
        captureError(error);
        setRedirectTo({ to: '/' });
      }
    }
  }, [code]);

  const setGastronomyUrlPathId = async (
    uuid: string,
    tableType?: TableType
  ): Promise<void> => {
    try {
      const result = await apolloClient.query<
        GetGastronomyUrlPathQuery,
        GetGastronomyUrlPathQueryVariables
      >({
        query: GetGastronomyUrlPath,
        variables: { params: { uuid } }
      });

      if (result.data && result.data.getGastronomy) {
        redirectToGastronomy(result.data.getGastronomy, tableType);
      } else {
        throw new Error('no return');
      }
    } catch (error) {
      captureError(error);
      setRedirectTo({ to: '/' });
    }
  };

  const redirectToGastronomy = (
    gastronomy: GetGastronomyUrlPathQuery['getGastronomy'],
    tableType?: TableType
  ) => {
    if (gastronomy) {
      if (gastronomy.options.qrCodeRoute) {
        setRedirectTo({
          to: `/:urlPathId${gastronomy.options.qrCodeRoute}`,
          params: { urlPathId: gastronomy.url_path_id }
        });
        return;
      }

      if (redirectToCorona(gastronomy, tableType)) {
        setRedirectTo({
          to: `/:urlPathId/corona${tableType ? '?tt=' + tableType : ''}`,
          params: { urlPathId: gastronomy.url_path_id }
        });
        return;
      }

      setRedirectTo({
        to: `/:urlPathId/speisekarte${tableType ? '?tt=' + tableType : ''}`,
        params: { urlPathId: gastronomy.url_path_id }
      });
    }
  };

  const loadTable = async (uuid: string) => {
    try {
      const result = await apolloClient.query<GetTableQuery>({
        query: GetTable,
        variables: { uuid }
      });
      console.log('table query result', result);
      if (
        result.data &&
        result.data.getTable &&
        result.data.getTable.table_type
      ) {
        setSelectedTable(result.data.getTable);
        if (result.data.getTable.user_uuid) {
          setGastronomyUrlPathId(
            result.data.getTable.user_uuid,
            result.data.getTable.table_type
          );
        } else {
          setRedirectTo({ to: '/' });
        }
      } else {
        setRedirectTo({ to: '/' });
      }
    } catch (error) {
      console.log('error loading table', error);
      captureError(error);
      setRedirectTo({ to: '/' });
    }
  };

  const checkUuid = (input: string) => {
    const regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    let uuid = input;
    if (input.length < 32) {
      uuid = shortUuid().toUUID(input);
    }
    if (uuid.match(regex)) {
      return uuid;
    }
  };

  if (redirectTo) {
    return <GetsbyRedirect {...redirectTo} />;
  } else if (qrCodeNotRecognized) {
    return (
      <div className="m-4">
        <h1 className="mb-4 text-xl font-bold">QR-Code nicht verknüpft!</h1>
        <p>
          Dieser QR-Code ist keinem Tisch zugeordnet. Bitte sage dem Personal
          bescheid. Danke!
        </p>
        <p className="mt-4">
          Um den QR-Code mit einem Tisch zu veknüpfen melde dich bitte im getsby
          Dashboard für Gastronomiebetriebe an:&nbsp;
          <a
            href={`${Config.dashboardHost}/qr-codes?url=${code}`}
            className="underline"
          >
            QR Code jetzt zuordnen.
          </a>
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner center={true} />
      </div>
    );
  }
};

export default QueryParamsProcessor;
