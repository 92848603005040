import { add, isFuture, isPast } from 'date-fns';
import { AvailabilityMixin } from './availability';
import { getKeyValue } from './utilities';

export function calculateTimeOfNextUpdate(
  mixin: AvailabilityMixin
): { time: number; isOpen: boolean } | null {
  const now = new Date();
  const nowRanges = mixin.timerangeAt(now);
  if (nowRanges.length > 0) {
    return { time: nowRanges[1].getTime(), isOpen: false };
  } else {
    for (let i = 0; i <= 5; i++) {
      const date = add(now, { days: i });
      const ranges = mixin.getOpeningRangesForDate(date);
      if (ranges.length > 0) {
        for (const range of ranges) {
          if (isFuture(range[0])) {
            return { time: range[0].getTime(), isOpen: true };
          }
        }
      }
    }
  }
  return null;
}

export const getDisabledUntilFromGastronomy = (
  tableType: TableType | null,
  gastronomy: GastronomyFragment | null
): string | null | undefined => {
  if (!tableType || !gastronomy) {
    return null;
  }

  const propertyName = `${tableType.toLowerCase()}_disabled_until`;

  return gastronomy.hasOwnProperty(propertyName)
    ? (getKeyValue<GastronomyFragment, keyof GastronomyFragment>(
        propertyName as keyof GastronomyFragment
      )(gastronomy) as string | null | undefined)
    : null;
};

export function tableTypeDisplay(
  gastronomy:
    | {
        options: {
          tableOrderIsRoomService?: boolean;
          tableOrderIsEvent?: boolean;
        };
      }
    | undefined,
  tableType: TableType
): TableType | 'ROOMSERVICE' | 'EVENT' {
  if (gastronomy && tableType === 'DINEIN') {
    if (gastronomy.options.tableOrderIsRoomService === true) {
      return 'ROOMSERVICE';
    } else if (gastronomy.options.tableOrderIsEvent === true) {
      return 'EVENT';
    }
  }
  return tableType;
}

export const canOrderDineInAtGastronomy = (
  gastronomy: GastronomyFragment | null
): boolean => {
  if (!gastronomy) {
    return false;
  }

  const disabledUntil = getDisabledUntilFromGastronomy('DINEIN', gastronomy);
  const hasDineIn = gastronomy.options.tableTypes
    ? gastronomy.options.tableTypes.includes('DINEIN')
    : false;

  if (hasDineIn && (!disabledUntil || isPast(new Date(disabledUntil)))) {
    return true;
  }

  return false;
};

export const redirectToCorona = (
  gastronomy: GastronomyFragment | GetGastronomyUrlPathQuery['getGastronomy'],
  tableType?: TableType
): boolean => {
  if (
    gastronomy.options.enableGuestRegistration &&
    (tableType === 'DINEIN' || tableType === undefined)
  ) {
    return true;
  }

  return false;
};
