import React, { Suspense } from 'react';
import { lazyRetry } from '../helpers/lazyRetry';
import Spinner from './spinner';

const QrReader = React.lazy(() => lazyRetry(() => import('react-qr-reader')));

interface Props {
  onScan: (data: string | null) => void;
  onError: (error: Error) => void;
  showViewFinder?: boolean;
}

const QrReaderWrapper: React.FC<Props> = ({
  onScan,
  onError,
  showViewFinder
}) => {
  // const isApp = AppInitOptionsService.getInstance().isApp();

  // useEffect(() => {
  //   if (isApp) {
  //     window.scanQrCodeResult = (result) => {
  //       console.log('got qr code result ' + result);
  //       if (result) {
  //         onScan(result);
  //       }
  //     };
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({ type: 'scanQrCode' })
  //     );
  //   }
  // }, [isApp]);

  return (
    <>
      {/* {isApp && <div>QR Code Scan</div>}
      {!isApp && ( */}
      <Suspense fallback={<Spinner center={true} />}>
        <QrReader
          onError={onError}
          onScan={onScan}
          facingMode="environment"
          resolution={1024}
          showViewFinder={showViewFinder}
        />
      </Suspense>
      {/* )} */}
    </>
  );
};

export default QrReaderWrapper;
