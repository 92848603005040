import { isAfter, isBefore } from 'date-fns';
import { PaymentRequestOptions, PaymentRequestItem } from '@stripe/stripe-js';
import { IMG_BASE_PATH } from '../constants';

export function generateGooglePayStripeJson(
  cartItems: CartItem[],
  total: number
): string {
  const json = {
    transactionInfo: {
      totalPrice: (total / 100).toFixed(2),
      totalPriceStatus: 'FINAL',
      currencyCode: 'EUR'
      // displayItems: cartItems.map((cartItem) => ({
      //   label: cartItem.product.title,
      //   type: 'LINE_ITEM',
      //   price: getCartItemPrice(cartItem)
      // }))
    },
    merchantInfo: {
      merchantName: 'getsby GmbH'
    },
    emailRequired: true
  };
  return JSON.stringify(json);
}

export function currentlyAvailable(from?: string, to?: string): boolean {
  let available = true;
  if (from && to) {
    const splitFromTime = from.split(':');
    const fromDate = new Date();
    fromDate.setHours(
      parseInt(splitFromTime[0], 10),
      parseInt(splitFromTime[1], 10),
      parseInt(splitFromTime[2], 10)
    );

    const splitToTime = to.split(':');
    const toDate = new Date();
    toDate.setHours(
      parseInt(splitToTime[0], 10),
      parseInt(splitToTime[1], 10),
      parseInt(splitToTime[2], 10)
    );

    const now = new Date();

    if (isBefore(now, fromDate) || isAfter(now, toDate)) {
      available = false;
    }
  }
  return available;
}

export function mapCartToStripeRequestParams(
  cartItems: CartItem[],
  tip: number,
  total: number,
  credit?: { value: number } | null
): PaymentRequestOptions {
  const items: PaymentRequestItem[] = cartItems.map((cartItem: CartItem) => {
    let price = cartItem.product.gross_price || 0;

    if (cartItem.modifiers && cartItem.modifiers.length) {
      price = cartItem.modifiers.reduce(
        (prev: number, modifier) => prev + (modifier.price_modifier || 0),
        price
      );
    }

    return {
      label: cartItem.product.title || '',
      amount: cartItem.quantity * price
    };
  });

  items.push({
    label: 'Tip',
    amount: tip
  });

  if (credit && credit.value) {
    items.push({
      label: 'Guthaben',
      amount: -credit.value
    });
  }

  const gastroName = cartItems[0].gastronomy
    ? cartItems[0].gastronomy.name
    : '';

  const options: PaymentRequestOptions = {
    total: {
      label: gastroName,
      amount: total,
      pending: false
    },
    currency: 'eur',
    country: 'AT',
    displayItems: items,
    requestPayerEmail: true
  };

  return options;
}

export function getPseudoPaymentRequestOptions(): PaymentRequestOptions {
  const options: PaymentRequestOptions = {
    total: {
      label: 'getsby',
      amount: 1,
      pending: false
    },
    currency: 'eur',
    country: 'AT',
    requestPayerEmail: true
  };

  return options;
}

export function getPaymentProviderImagePath(
  type: PaymentMethodType,
  payment: PaymentProvider | null
): string {
  if (type === 'CARD') {
    return payment && payment.brand
      ? `${IMG_BASE_PATH}/${payment.brand.replace(' ', '-').toLowerCase()}.svg`
      : '';
  }

  return `${IMG_BASE_PATH}/${type.toLowerCase()}.svg`;
}

export function getCartItemPrice(cartItem: CartItem): string {
  let price = 0;
  if (cartItem.product.gross_price) {
    price = cartItem.product.gross_price;
  }
  if (cartItem.modifiers) {
    for (const modifier of cartItem.modifiers) {
      price += modifier.price_modifier ? modifier.price_modifier : 0;
    }
  }
  return (price / 100).toFixed(2);
}

export const calculateDeliveryFee = (
  table: TableFragment,
  total: number
): number => {
  if (table.table_type === 'DELIVERY' && table.delivery_config) {
    if (
      table.delivery_config.deliveryFee &&
      table.delivery_config.deliveryFee > 0
    ) {
      if (
        table.delivery_config.freeDeliveryFrom &&
        table.delivery_config.freeDeliveryFrom <= total
      ) {
        return 0;
      }
      return table.delivery_config.deliveryFee;
    }
  }
  return 0;
};

export const getMinimumOrderAmount = (table: TableFragment): number => {
  if (table.delivery_config && table.delivery_config.minOrderAmount) {
    return table.delivery_config.minOrderAmount;
  }
  return 0;
};
