import React from 'react';

interface Props {
  title: string;
  subTitle?: string;
  callToAction?: JSX.Element;
}

const CallToAction: React.FC<Props> = ({
  title,
  subTitle,
  callToAction
}: Props) => {
  return (
    <section className="flex flex-col w-full py-6 mx-auto mb-12 text-center bg-primary-500">
      <h1 className="w-full my-2 text-4xl font-bold leading-tight text-center text-white md:text-5xl">
        {title}
      </h1>
      <div className="w-full mb-4">
        <div className="w-1/6 h-1 py-0 mx-auto my-0 bg-white rounded-t opacity-25" />
      </div>
      <h3 className="my-4 text-xl leading-tight md:text-3xl">{subTitle}</h3>
      <div className="w-full my-4">{callToAction}</div>
    </section>
  );
};

export default CallToAction;
